import apolloClient from "../client";
import { buildRemovePromotionFromCartMutation } from "../mutations";
import getCart from "../responseMappers/cart";
import isObjectEmpty from "@utils/isObjectEmpty";

const removePromotionFromCart = async ({ cartId, context, couponCode }) => {
  if (!apolloClient) return;
  try {
    const REMOVE_PROMOTION = buildRemovePromotionFromCartMutation();
    const variables = {
      cartId,
      couponCode
    };

    const { data: responseData, errors } = await apolloClient.mutate({
      mutation: REMOVE_PROMOTION,
      variables,
      context
    });
    const { authHeader, ...data } = responseData || {};

    if (!isObjectEmpty(data?.removePromotion)) {
      return {
        data: getCart({ cartId, ...data?.removePromotion }),
        authHeader,
        errors
      };
    }
    return { data: null, authHeader, errors };
  } catch (error) {
    // apollo client will throw if networkError is returned because of expired token
    return { data: null, authHeader: null, errors: error };
  }
};

export default removePromotionFromCart;
