export const ACCOUNT_ERROR_REGISTRATION =
  "Oops! We are unable to create your account at this time. If you already have an account, please try to reset your password.";
export const ACCOUNT_ERROR_REGISTRATION_LOGIN =
  "You account has been created, but we are unable to log you in at this time.";
export const ACCOUNT_ERROR_MOVE_ESTIMATE =
  "Please login to move the estimate to your bag.";
export const AFFIRM_BUTTON = "CONTINUE TO REVIEW ORDER";
export const AFFIRM_BUTTON_GO_BACK = "BACK TO CART";
export const AFFIRM_DESCRIPTION = {
  richText: {
    richText: [
      {
        type: "markdown",
        data: "Serena & Lily has partnered with Affirm to offer financing. You will be redirected to Affirm on the Review Order page to securely complete your purchase. Just enter a few pieces of basic information for a real-time decision. Checking your eligibility won’t affect your credit score."
      }
    ],
    fontFamily: "Proxima Nova Regular",
    fontColor: "#383838",
    fontSize: "12px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "12px"
  }
};
export const AFFIRM_MESSAGE_DISABLED_ITEM = {
  richText: {
    richText: [
      {
        type: "markdown",
        data: "This item is not eligible for <u>**Affirm**</u>"
      }
    ],
    fontFamily: "Proxima Nova Regular",
    fontColor: "#383838",
    fontSize: "12px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "12px",
    marginBottom: "0"
  }
};
export const AFFIRM_MESSAGE_DISABLED_ORDER_BILLING = {
  richText: {
    richText: [
      {
        type: "markdown",
        data: "ONE OF THE ITEMS IN YOUR CART IS NOT ELIGIBLE FOR AFFIRM. Please return to cart to place your orders separately."
      }
    ],
    fontFamily: "Proxima Nova Regular",
    fontColor: "#970013",
    fontSize: "12px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "12px"
  }
};
export const AFFIRM_MESSAGE_DISABLED_ORDER = {
  richText: {
    richText: [
      {
        type: "markdown",
        data: 'Not all of the items in your cart are eligible for [**Affirm financing**](/affirm-pay-over-time.html "affirm pay over time"). If you would still like to receive financing on the items that are eligible in your cart, we advise you place separate orders.'
      }
    ],
    fontFamily: "Proxima Nova Regular",
    fontColor: "#383838",
    fontSize: "12px",
    fontSizeTablet: "12px",
    fontSizeDesktop: "12px",
    marginBottom: "0"
  }
};
export const CART_ERROR_ADD =
  "There was an issue adding the item to your cart. Please try again.";
export const CART_ERROR_AUTH_FAILED_REVERSAL =
  "We are unable to fully process your payment at this time. Please contact our Customer Care's Billing Department 866.597.2742 for further assistance placing your order.";
export const CART_ERROR_AUTH_MAX_ATTEMPTS =
  "We are unable to process your payment. To place your order, please contact our Customer Care Team at 866.597.2742.";
export const CART_ERROR_CHECKOUT = "Something isn’t right – please try again.";
export const CART_ERROR_SIFT = "Please provide another form of Payment.";
export const CART_SHIPPING_RESTRICTIONS =
  "At least one product in the cart has shipping restrictions and can't be shipped to the specified address.";
export const CART_NO_WHITEGLOVE_TO_STATE =
  "White Glove shipping is not available to the specified address. Please remove the White Glove item(s) to proceed with your order to this address.";
export const CART_TAX_ERROR =
  "We are unable to calculate taxes for this address. Please update your Shipping Address.";
export const CART_ERROR_CLEAR =
  "There was an issue clearing your cart. Please try again.";
export const CART_ERROR_MOVE_ESTIMATE =
  "There was an issue moving your estimate to your cart. Please try again.";
export const CART_ERROR_MOVE_ESTIMATE_USER =
  "Your request could not be processed. Please contact us at 866.971.1016 or trade@serenaandlily.com.";
export const CART_ERROR_INVALID_PROMO_CODE =
  "Your shopping cart contains a coupon that is invalid at this time. Please remove the coupon before proceeding to checkout.";
export const CART_ERROR_ITEM_INVENTORY =
  "Oops. You have reached the maximum order quantity of ${quantity}.";
export const CART_ERROR_LOAD =
  "There was an issue with your cart. Please try again.";
export const CART_ERROR_MIXED_SHIPPING_CANADA =
  "Oops! We cannot calculate tax based on the shipping address and product combination you have entered. Please update your order to purchase FedEx and White Glove items separately and try again.";
export const CART_ERROR_TOKENIZATION =
  "Something isn’t right – please try again.";
export const CART_ERROR_UPDATE =
  "There was an issue updating your cart, please try again.";
export const CART_ERROR_UPDATE_ADDRESS =
  "There was an issue updating your cart, please check your address and try again.";
export const CART_ERROR_UPDATE_PAYMENT =
  "Oops. Your payment was not successfully submitted. Please review your address information and re-enter your payment information.";
export const CART_UNABLE_TO_SELL_CART_LEVEL =
  "One or more items in your shopping bag is not available to purchase to your selected delivery address.";
export const CART_UNABLE_TO_SELL_ITEM_LEVEL =
  "This item is not available to purchase to your selected delivery address. Visit the product page for details.";
export const CART_WG_ITEMS_DISALLOWED_ERROR_CART_LEVEL =
  "One or more items in your shopping bag is not available for delivery to your selected delivery address.";
export const CART_WG_ITEMS_DISALLOWED_ERROR_ITEM_LEVEL =
  "This item is not available for delivery to Canada or Alaska.";
export const CART_HEADER = "Shopping Bag";
export const CART_HEADER_EMPTY = "Your Shopping Bag is empty.";
export const CART_MESSAGE_ESTIMATE_MERGED =
  "PLEASE NOTE: The styles from your estimate have been consolidated with pre-existing items in your shopping bag.";
export const CART_MESSAGE_ESTIMATE_PROMO_EXPIRED =
  "PLEASE NOTE: The promotion previously applied to your estimate has expired, and the order below is now full price.";
export const CART_MESSAGE_ITEMS_REMOVED =
  "Some items are no longer available and have been removed from your shopping bag below.";
export const CART_MESSAGE_ITEMS_REPRICED =
  "Pricing has changed on some items and has been updated in your shopping bag below.";
export const CART_MESSAGE_OUT_OF_STOCK =
  "Oh no… some items in your shopping bag are out of stock or have limited quantities. To continue; please remove items with prices marked as N/A. Questions? Please contact Customer Care at customercare@serenandlily.com or 866.597.2742";
export const CART_MESSAGE_PROMO_REMOVED =
  "One or more Promotional Codes are no longer available and have been removed from your cart";
export const CART_PRICE_NULL = "--";
export const CART_SUBHEADER_EMPTY = "Want to add an Item?";
export const CART_TAX_EXEMPT_DESCRIPTION =
  "To waive sales tax on a qualifying order, federal, state, and local regulations require appropriate tax document be on file with Serena & Lily. Please contact our dedicated Trade Team at 866.971.1016 or designers@serenaandlily.com for more information or to submit your tax exemption documents";
export const CART_TAX_EXEMPT_TITLE = "WHY WAS THIS ORDER TAXED?";
export const CART_TAX_EXEMPT_ESTIMATE_TITLE = "WHY WAS THIS ESTIMATE TAXED?";
export const CHECK_BUTTON = "CONTINUE TO REVIEW ORDER";
export const CHECK_INPUT_PLACEHOLDER = "CHECK NUMBER (Optional)";
export const CHECK_MESSAGE =
  "Merchandise will not be reserved until funds are cleared. Any delay in submitting payment may result in changes to lead time and availability.";
export const CHECK_MESSAGE_PREFIX = "Please Note: ";

export const CHECKOUT_REGISTRATION_SUBTITLE =
  "Want a faster checkout? Create an account with us and you can skip a few steps next time. You can also access your order history.";
export const CHECKOUT_REGISTRATION_TITLE = "Create an Account";
export const CUSTOMER_SEARCH_ERROR =
  "There was an issue performing customer lookup. Please try again.";
export const CUSTOMER_SELECT_ERROR =
  "There was an issue selecting customer. Please try again.";
export const DELIVERY_GROUP_TOOLTIP =
  "Approximate delivery date is an estimate. Actual date will depend on destination.";
export const DO_NOT_SHIP_INFO =
  "All eligible items will be released for shipment after the selected do not ship until (DNSU) date; and deliver within the lead times noted below. Backordered items that restock after the DNSU date will ship as they become available. Made-to-order and drop ship items are not eligible for DNSU.";
export const DO_NOT_SHIP_CHECKBOX_LABEL = "Do not ship until";
export const DO_NOT_SHIP_SELECT_LABEL = "Select Date";
export const NO_BALANCE_ORDER_MESSAGE =
  "Your order has no balance, so no payment is required to complete this order.";
export const NO_BALANCE_ORDER_BUTTON = "CONTINUE TO REVIEW ORDER";
export const ORDER_ERROR_NOT_FOUND =
  "There was an issue loading your order information. You can look for your recent orders in your order history.";
export const PAYPAL_BUTTON = "CONTINUE TO PAYPAL";
export const PAYPAL_BUTTON_APPLIED = "CONTINUE TO REVIEW ORDER";
export const PAYPAL_DESCRIPTION =
  'After clicking the "Continue to PayPal" button below, you will be redirected to the PayPal website to verify your payment information.';
export const PAYPAL_DESCRIPTION_APPLIED = "";
export const PAYPAL_ERROR_INITIALIZATION =
  "Unable to start Paypal Order. Please try again.";
export const PAYPAL_MESSAGE_DISABLED_ITEM =
  "This item is not eligible for Paypal";
export const PAYPAL_MESSAGE_DISABLED_ORDER =
  "Not all of the items in your cart are eligible for Paypal financing. If you would still like to receive financing on the items that are eligible in your cart, we advise you to place separate orders.";
export const APPLE_PAY_DESCRIPTION = "";
export const APPLE_PAY_BUTTON = "CONTINUE WITH APPLEPAY";
export const PROMO_CODE_ERROR =
  'Oh no. Promo code "${promoCode}" does not apply to the items in your shopping bag.';
export const PROMO_CODE_ERROR_EXPIRED =
  'Whoops, promo code "${promoCode}" has expired.';
export const PROMO_CODE_ERROR_INVALID =
  'Oops! The promo "${promoCode}" seems to be incorrect. Please check it and try again.';
export const PROMO_CODE_ERROR_REMOVE =
  "There was an issue remove the promo code from your bag. Please try again.";
export const PROMO_CODE_HEADING = "HAVE A PROMO CODE?";
export const PROMO_CODE_HEADING_APPLIED = "PROMO CODE";
export const PROMO_CODE_INPUT_PLACEHOLDER = "PROMO CODE";
export const PROMO_CODE_MESSAGE_NOT_APPLIED =
  'Oh; no. Promo code "${promoCode}" does not apply to the items in your shopping bag.';
export const PROMO_CODE_MESSAGE_SUCCESS =
  "Success! Promo code ${promoCode} has been applied to your order.";
export const TAX_EXEMPT_SUMMARY_TITLE = "WHY WAS THIS ORDER TAXED?";
export const TAX_EXEMPT_SUMMARY_ESTIMATE_TITLE = "WHY WAS THIS ESTIMATE TAXED?";
export const TAX_EXEMPT_SUMMARY_DESCRIPTION =
  "To waive sales tax on a qualifying order, federal, state, and local regulations require appropriate tax document be on file with Serena & Lily. Please contact our dedicated Trade Team at 866.971.1016 or designers@serenaandlily.com for more information or to submit your tax exemption documents";
export const TOKEN_EXPIRED_LOGOUT_MESSAGE =
  "You have been automatically logged out due to inactivity. Please log back in to continue.";
export const TRADE_USER_BANNER_MESSAGE = "Exclusively for our Trade members";
export const URL_CART = "/shoppingbag/v1";
export const URL_SHIPPING_FEES = "/shipping-fees.html";
export const WIRE_BUTTON = "CONTINUE TO REVIEW ORDER";
export const WIRE_MESSAGE =
  "Merchandise will not be reserved until funds are cleared. Any delay in submitting payment may result in changes to lead time and availability.";
export const WIRE_MESSAGE_PREFIX = "Please Note: ";

// TRADE USER STRINGS
export const RETAIL_PRICE_LABEL = "RETAIL";
export const TRADE_PRICE_LABEL = "TRADE";
