/* eslint-disable complexity */
import MSFluidTypography from "../../utils/MSFluidTypography";
import { Breakpoints } from "../../constants/constants";

const configureFontSize = (fontSize) =>
  typeof fontSize === "number" ? fontSize : fontSize.replace("px", "");

const getFontSize = (textSize, modular, useNewBreakpoints = false) => {
  if (modular || modular === 0) return MSFluidTypography.calculateFont(modular);

  if (textSize) {
    if (typeof textSize === "object") {
      // if font is manually-set responsive
      const { fontSize, fontSizeTablet, fontSizeDesktop } = textSize;

      let fontSizeCss = "";
      if (useNewBreakpoints) {
        if (fontSize) {
          fontSizeCss += `
            font-size: ${configureFontSize(fontSize)}px;
            &.mobile {
              font-size: ${configureFontSize(fontSize)}px !important;
            }`;
        }
        if (fontSizeTablet) {
          fontSizeCss += `
          @media screen and (min-width: ${
            Breakpoints.SMTabletMin
          }) and (max-width: ${Breakpoints.SMTabletMax}) {

            font-size: ${configureFontSize(fontSizeTablet)}px;
          }
          &.tablet {
            font-size: ${configureFontSize(fontSizeTablet)}px !important;
          }`;
        }
        if (fontSizeDesktop) {
          fontSizeCss += `
          @media screen and (min-width: ${Breakpoints.MDLaptopMin}) {
            font-size: ${configureFontSize(fontSizeDesktop)}px;
          }
          &.desktop {
              font-size: ${configureFontSize(fontSizeDesktop)}px !important;
          }`;
        }

        return fontSizeCss;
      }
      if (fontSize) {
        fontSizeCss += `
          font-size: ${configureFontSize(fontSize)}px;
          &.mobile {
            font-size: ${configureFontSize(fontSize)}px !important;
          }`;
      }
      if (fontSizeTablet) {
        fontSizeCss += `
        @media screen and (min-width: ${Breakpoints.IPad}) {
          font-size: ${configureFontSize(fontSizeTablet)}px;
        }
        &.tablet {
          font-size: ${configureFontSize(fontSizeTablet)}px !important;
        }`;
      }
      if (fontSizeDesktop) {
        fontSizeCss += `
        @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
          font-size: ${configureFontSize(fontSizeDesktop)}px;
        }
        &.desktop {
            font-size: ${configureFontSize(fontSizeDesktop)}px !important;
        }`;
      }

      if (fontSizeCss) {
        return fontSizeCss;
      }
    }
    // if font-size is numeric
    if (typeof textSize === "number") {
      return `font-size: ${textSize}px;`;
    }
    // if font-size is a string
    return `font-size: ${configureFontSize(textSize)}px;`;
  }

  return `font-size: 12px;`;
};

export default getFontSize;
