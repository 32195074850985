const EVENT_INTERNAL_NAMESPACE = "a-";
const EVENT_EXTERNAL_NAMESPACE = "analyze-";

export const EVENTS = {
  PAGE_VIEW: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}page-view`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "initialDimensions",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "dimension13",
        "dimension46",
        "dimension49",
        "dimension53",
        "dimension16",
        "dimension17",
        "dimension18"
      ],
      REQUIRED: ["dimension11", "dimension12", "dimension15", "dimension41"]
    }
  },

  CUSTOM_ATTRIBUTES: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}custom-attributes`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "customAttributes",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "storeID",
        "email",
        "customerID",
        "new_customer",
        "customerEmailHashed",
        "productName"
      ],
      REQUIRED: []
    }
  },

  PDP: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}pdp`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "productDetail",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: ["actionField", "item_list_name", "index"],
      REQUIRED: [
        "item_id",
        "item_name",
        "item_brand",
        "item_category",
        "item_category2",
        "item_category3",
        "item_category4",
        "item_variant",
        "affiliation",
        "price",
        "discount",
        "currency",
        "quantity",
        "dimension28",
        "dimension29",
        "dimension30",
        "dimension33",
        "dimension35",
        "dimension47"
      ]
    }
  },

  RIMPRESSIONS: {
    INT_NAME: `r-impressions`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "productImpressions",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: ["products.item_list_name", "products.index"],
      REQUIRED: [
        "products.item_id",
        "products.item_name",
        "products.item_brand",
        "products.item_category",
        "products.item_category2",
        "products.item_category3",
        "products.item_category4",
        "products.item_variant",
        "products.affiliation",
        "products.price",
        "products.currency",
        "products.quantity",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension33",
        "products.dimension35",
        "products.dimension47"
      ]
    }
  },
  YIMPRESSIONS: {
    INT_NAME: `y-impressions`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "productImpressions",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: ["products.item_list_name", "products.index"],
      REQUIRED: [
        "products.item_id",
        "products.item_name",
        "products.item_brand",
        "products.item_category",
        "products.item_category2",
        "products.item_category3",
        "products.item_category4",
        "products.item_variant",
        "products.affiliation",
        "products.price",
        "products.currency",
        "products.quantity",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension33",
        "products.dimension35",
        "products.dimension47"
      ]
    }
  },
  IMPRESSIONS: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}impressions`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "productImpressions",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: ["products.item_list_name", "products.index"],
      REQUIRED: [
        "products.item_id",
        "products.item_name",
        "products.item_brand",
        "products.item_category",
        "products.item_category2",
        "products.item_category3",
        "products.item_category4",
        "products.item_variant",
        "products.affiliation",
        "products.price",
        "products.currency",
        "products.quantity",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension33",
        "products.dimension35",
        "products.dimension47"
      ]
    }
  },

  CART: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}cart`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "cartContents",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "products.variant",
        "products.category",
        "products.price",
        "products.list"
      ],
      REQUIRED: [
        "products.id",
        "products.name",
        "products.quantity",
        "products.dimension24",
        "products.dimension27",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension31",
        "products.dimension32",
        "products.dimension33",
        "products.dimension35",
        "products.dimension36",
        "products.dimension37",
        "products.dimension38",
        "products.dimension39",
        "products.dimension47",
        "products.dimension55",
        "products.metric2"
      ]
    }
  },

  CART_REMOVE: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}cart-remove`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "remove_from_cart",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "item_variant", // was variant
        "item_category", // was category
        "item_list_name", // was list
        "item_list_id", // new
        "index", //new
        "item_brand", // was brand
        "item_category2", // was dimension38 item_category2 department
        "dimension28", //new
        "dimension29", //stays color
        "dimension30", //stays, must work
        "dimension35", //stays
        "item_category3", // dimension 37 division
        "dimension47", // stays
        "discount" // new
      ],
      REQUIRED: [
        "item_id", // was id
        "item_name", // was name
        "price", // stays
        "quantity", // stays
        "currency", // new
        "dimension33", //stays
        "item_category4", //was dimension 36
        "affiliation" // new
      ]
    }
  },

  CART_ADD: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}cart-add`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "addToCart",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: ["actionField", "item_list_name", "index"],
      REQUIRED: [
        "item_id",
        "item_name",
        "item_brand",
        "item_category",
        "item_category2",
        "item_category3",
        "item_category4",
        "item_variant",
        "affiliation",
        "price",
        "discount",
        "currency",
        "quantity",
        "dimension28",
        "dimension29",
        "dimension30",
        "dimension33",
        "dimension35",
        "dimension47"
      ]
    }
  },

  CHECKOUT: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}checkout`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "checkout",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "products.variant",
        "products.category",
        "products.list",
        "products.position"
      ],
      REQUIRED: [
        "actionField",
        "products.id",
        "products.name",
        "products.quantity",
        "products.price",
        "products.brand",
        "products.dimension24",
        "products.dimension27",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension31",
        "products.dimension32",
        "products.dimension33",
        "products.dimension35",
        "products.dimension36",
        "products.dimension37",
        "products.dimension38",
        "products.dimension39",
        "products.dimension47",
        "products.dimension55",
        "products.metric2"
      ]
    }
  },

  VIEWCART: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}view-cart`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "view_cart",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "products.item_list_name",
        "products.index",
        "products.position",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension33",
        "products.dimension35",
        "products.discount",
        "products.item_category",
        "products.item_category2",
        "products.item_category3",
        "products.item_category4",
        "products.item_variant",
        "products.dimension47",
        "cartValue"
      ],
      REQUIRED: [
        "products.item_id",
        "products.item_name",
        "products.affiliation",
        "products.price",
        "products.currency",
        "products.quantity"
      ]
    }
  },
  BEGINCHECKOUT: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}begin-checkout`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "begin_checkout",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "products.item_list_name",
        "products.index",
        "products.position",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension33",
        "products.dimension35",
        "products.discount",
        "products.item_category",
        "products.item_category2",
        "products.item_category3",
        "products.item_category4",
        "products.item_variant",
        "products.dimension47",
        "cartValue"
      ],
      REQUIRED: [
        "products.item_id",
        "products.item_name",
        "products.affiliation",
        "products.price",
        "products.currency",
        "products.quantity"
      ]
    }
  },

  ADDSHIPPINGINFO: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}add-shipping-info`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "add_shipping_info",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "products.item_list_name",
        "products.index",
        "products.position",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension33",
        "products.dimension35",
        "products.discount",
        "products.item_category",
        "products.item_category2",
        "products.item_category3",
        "products.item_category4",
        "products.item_variant",
        "products.dimension47",
        "shippingTier",
        "cartValue"
      ],
      REQUIRED: [
        "products.item_id",
        "products.item_name",
        "products.affiliation",
        "products.price",
        "products.currency",
        "products.quantity"
      ]
    }
  },

  ADDPAYMENTINFO: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}add-payment-info`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "add_payment_info",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "products.item_list_name",
        "products.index",
        "products.position",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension33",
        "products.dimension35",
        "products.discount",
        "products.item_category",
        "products.item_category2",
        "products.item_category3",
        "products.item_category4",
        "products.item_variant",
        "products.dimension47",
        "paymentType",
        "cartValue"
      ],
      REQUIRED: [
        "products.item_id",
        "products.item_name",
        "products.affiliation",
        "products.price",
        "products.currency",
        "products.quantity"
      ]
    }
  },

  REVIEWORDER: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}review-order`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "review_order",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "products.item_list_name",
        "products.index",
        "products.position",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension33",
        "products.dimension35",
        "products.discount",
        "products.item_category",
        "products.item_category2",
        "products.item_category3",
        "products.item_category4",
        "products.item_variant",
        "products.dimension47",
        "shippingTier",
        "paymentType",
        "cartValue"
      ],
      REQUIRED: [
        "products.item_id",
        "products.item_name",
        "products.affiliation",
        "products.price",
        "products.currency",
        "products.quantity"
      ]
    }
  },

  PURCHASE: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}purchase`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "purchase",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      OPTIONAL: [
        "products.item_list_name",
        "products.index",
        "products.position",
        "products.dimension28",
        "products.dimension29",
        "products.dimension30",
        "products.dimension33",
        "products.dimension35",
        "products.discount",
        "products.item_category",
        "products.item_category2",
        "products.item_category3",
        "products.item_category4",
        "products.item_variant",
        "products.dimension47",
        "customerData"
      ],
      REQUIRED: [
        "products.item_id",
        "products.item_name",
        "products.affiliation",
        "products.price",
        "products.currency",
        "products.quantity",
        "orderDetails"
      ]
    }
  },

  SIGN_UP_ATTEMPT: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}sign-up-attempt`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "signUpAttempt",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      REQUIRED: ["actionField"]
    }
  },

  SIGN_UP: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}sign-up`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "signUp",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      REQUIRED: ["actionField"]
    }
  },

  SIGN_UP_FAILURE: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}sign-up-failure`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "signUpFailure",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      REQUIRED: ["actionField"]
    }
  },

  LOGIN_ATTEMPT: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}login-attempt`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "loginAttempt",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      REQUIRED: ["actionField"]
    }
  },

  LOGIN: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}login`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "login",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      REQUIRED: ["actionField"]
    }
  },

  LOGIN_FAILURE: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}login-failure`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "loginFailure",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      REQUIRED: ["actionField"]
    }
  },

  COUPON_ERROR: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}coupon-error`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "loginFailure",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      REQUIRED: ["actionField"]
    }
  },

  LOGOUT: {
    INT_NAME: `${EVENT_INTERNAL_NAMESPACE}logout`,
    EXT_NAME: `${EVENT_EXTERNAL_NAMESPACE}ecom-event`,
    EXT_DESCRIPTION: "logout",
    SUBSCRIBER_NAMES: [],
    FIELDS: {
      REQUIRED: ["actionField"]
    }
  }
};

export const FIELD_DEPENDENCIES = {
  DEFAULT: ["detail", "dom"],
  dimension13: ["search"],
  dimension16: ["global"],
  dimension17: ["global"],
  dimension18: ["global"],
  dimension24: ["product"],
  dimension28: ["product"],
  dimension29: ["product"],
  dimension31: ["inventories"],
  dimension35: ["global"],
  dimension37: ["product"],
  dimension38: ["product"],
  dimension39: ["product"],
  dimension41: ["global", "pageType"],
  dimension42: ["product"],
  dimension46: ["search"],
  dimension49: ["search"],
  dimension50: ["search"],
  dimension53: ["global"],
  metric2: ["price"],
  id: ["product"],
  variant: ["product"],
  name: ["product"],
  category: ["product"],
  list: ["product"],
  quantity: ["product"],
  price: ["price"],
  actionField: ["actionField"],
  productName: ["product"],
  customerData: ["customerData"],
  orderDetails: ["orderDetails"],
  item_id: ["product"],
  item_name: ["product"],
  item_category: ["product"],
  item_category2: ["product"],
  item_category3: ["product"],
  item_category4: ["price"],
  item_variant: ["product"],
  discount: ["price"],
  shippingTier: ["shippingTier"],
  paymentType: ["paymentType"],
  cartValue: ["cartValue"],

  // temp hack
  "products.id": ["products"],
  "products.item_id": ["products"]
};
