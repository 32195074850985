// REACT IMPORTS //
import React from "react";

// 3RD PARTY IMPORTS //
import PropTypes from "prop-types";

// STYLED IMPORTS //
import { IconSvg } from "../IconsStyled";

// CONSTANT IMPORTS //
import {
  Colors,
  MAGIC_NUMBERS_2,
  MAGIC_NUMBERS_66
} from "@serenaandlily/constants/constants";

const SwipeLine = ({
  width = MAGIC_NUMBERS_66,
  height = MAGIC_NUMBERS_2,
  fill = Colors.Midnight
}) => {
  return (
    <IconSvg width={width} height={height} viewBox="0 0 66 2">
      <line
        x1="1"
        y1="1"
        x2="65"
        y2="1"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </IconSvg>
  );
};

SwipeLine.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

export default SwipeLine;
