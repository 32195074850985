import styled from "styled-components";
import { Typography, Colors } from "@serenaandlily/constants/constants";

export const Counter = styled.text`
  position: absolute;
  right: 0;
  bottom: -4px;
  display: block;
  padding: 3px 4px 2px;
  background-color: ${Colors["Midnight"]};
  border-radius: 4px;
  font-family: ${Typography.BeatriceRegular};
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.24px;
  text-transform: uppercase;
  color: ${Colors["White"]};
`;
