import React from "react";
import PropTypes from "prop-types";
import { Horizontal } from "../../constants/constants";
import { SMBContainer, ContentContainer } from "./SpecialMessagingBannerStyled";
import useComponentTheme from "../Theme/useComponentTheme";
import defaultTheme from "./SpecialMessagingTheme";
import BreakpointRichText from "../BreakpointRichText";
import { useSidePanelContext } from "../../contexts/SidePanelContext";
import SMBCarousel from "../SMBCarousel";
import { useEnableAtBreakpoint } from "@chv1-serenaandlily/hooks/useEnableAtBreakpoint";

export const SMB_ID = "smb";

const SpecialMessagingBanner = ({
  specialMessage,
  horizontalAlignment,
  bgColor,
  fontColor,
  disclaimer,
  isSticky = false,
  theme = defaultTheme,
  smbCarousel,
  useCarouselDesktop,
  useCarouselMobile,
  useCarouselTablet,
  countdown,
  gap
}) => {
  const horizontal = Horizontal[horizontalAlignment] || "justify-center";
  const specialMessageTheme = useComponentTheme({ theme });
  const { handleClosePanel } = useSidePanelContext();

  const { isEnabled: isCarouselEnabled } = useEnableAtBreakpoint({
    desktopEnabled: useCarouselDesktop,
    mobileEnabled: useCarouselMobile,
    tabletEnabled: useCarouselTablet
  });

  return (
    <SMBContainer
      bgColor={bgColor}
      isSticky={isSticky}
      onClick={handleClosePanel}
      id={SMB_ID}
    >
      <ContentContainer horizontal={horizontal} gap={gap || 5}>
        {isCarouselEnabled ? (
          <SMBCarousel {...smbCarousel} arrowColor={fontColor} />
        ) : (
          <BreakpointRichText
            {...specialMessage}
            countdownProps={countdown}
            ctaProps={disclaimer}
            theme={specialMessageTheme}
            gap={gap}
          />
        )}
      </ContentContainer>
    </SMBContainer>
  );
};

SpecialMessagingBanner.propTypes = {
  bgColor: PropTypes.string,
  isSticky: PropTypes.bool,
  horizontalAlignment: PropTypes.string,
  theme: PropTypes.object,
  disclaimer: PropTypes.object,
  countdown: PropTypes.shape({
    endDate: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired
  })
};

export default SpecialMessagingBanner;
