import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 14;

const CheckboxCheck = ({ height, width, fill }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill="none"
      stroke={fill}
      viewBox="0 0 14 9"
    >
      <path vectorEffect="non-scaling-stroke" d="M1 3.1L5.94118 8L13 1" strokeLinecap="round" strokeLinejoin="round"/>
    </IconSvg>
  );
};

CheckboxCheck.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CheckboxCheck;
