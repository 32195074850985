import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 14;

const Close = ({ height, width, fill = "currentColor"}) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill="none"
      stroke={fill}
      viewBox="0 0 14 14"
    >
      <path d="M1 1L13 13M13 1L1 13" strokeLinecap="round"/>
    </IconSvg>
  );
};

Close.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Close;
