import {
  NO_LONGER_AVAILABLE,
  OUT_OF_STOCK
} from "@constants/products/productStatus";
import { CART_WARNINGS } from "@serenaandlily/contexts/CartContext/constants";

/* eslint-disable max-lines-per-function */
function getGroupDeliveryItems({
  deliveryGroups,
  cartItems = [],
  deletedCartItems = [],
  warnings = "[]"
}) {
  const parsedWarnings = JSON.parse(warnings) || [];
  const cartItemsWithWarnings = cartItems?.map((cartItem) => {
    const warnings = parsedWarnings.filter(
      (war) => war.payload.productId === cartItem.productId
    );
    return {
      ...cartItem,
      warnings
    };
  });

  const grouped = deliveryGroups
    ?.sort((a, b) => new Date(a?.edd) - new Date(b?.edd))
    .reduce((acc, group) => {
      if (group?.headline === NO_LONGER_AVAILABLE) return acc;

      const groupCartItems = [];
      group?.cartItemIds.forEach((itemId) => {
        const item = cartItemsWithWarnings?.find((item) => item?.id === itemId);
        const inventoryWarning = item?.warnings?.find(
          (war) => war.code === CART_WARNINGS.CART_ERROR_INVENTORY
        );
        const shouldAddItemToGroup =
          item && (!inventoryWarning || inventoryWarning?.payload?.total > 0);

        // if the item has an inventory warning and the total(available stock) is greater than 0, add it to the group,
        // otherwise, skip it
        if (shouldAddItemToGroup) {
          const shouldUpdateItemQuantity =
            inventoryWarning &&
            inventoryWarning?.payload?.total < item?.quantity;
          const totalAvailable = inventoryWarning?.payload?.total;
          groupCartItems.push({
            ...item,
            shouldUpdateItemQuantity,
            totalAvailable
          });
        }
      });

      if (groupCartItems.length === 0) return acc;

      const headlineKey = group?.headline;
      if (!acc[headlineKey]) {
        acc[headlineKey] = {};
      }
      const eddKey = group?.eddFullText;
      if (!acc[headlineKey][eddKey]) {
        acc[headlineKey][eddKey] = {
          deliveryMethod: group?.deliveryMethod,
          edd: group?.edd,
          cartItems: []
        };
      }

      acc[headlineKey][eddKey].cartItems = Array.isArray(
        acc[headlineKey][eddKey].cartItems
      )
        ? acc[headlineKey][eddKey].cartItems
        : [];
      acc[headlineKey][eddKey].cartItems.push(...groupCartItems);

      if (
        group?.deliveryMethod?.toLowerCase() === "white glove" &&
        acc[headlineKey][eddKey].cartItems?.length > 1
      ) {
        acc[headlineKey][eddKey].isShipmentConsolidationEligible = true;
      }

      return acc;
    }, {});

  const groups = Object.entries(grouped)?.map(([headline, eddGroups]) => ({
    headline: headline,
    isShipmentConsolidationEligible: Object.entries(eddGroups).some(
      ([_, data]) => data?.isShipmentConsolidationEligible
    ),
    deliveryMethods: Object.entries(eddGroups)
      .map(([edd, data]) => ({
        eddFullText: edd,
        isShipmentConsolidationEligible: data?.isShipmentConsolidationEligible,
        deliveryMethod: data?.deliveryMethod,
        edd: data?.edd,
        cartItems: data?.cartItems
      }))
      .sort((a, b) => new Date(a?.edd) - new Date(b?.edd))
  }));

  const outOfStockCartItems = cartItemsWithWarnings
    ?.map((item) => {
      const inventoryWarning = item?.warnings?.find(
        (war) => war.code === CART_WARNINGS.CART_ERROR_INVENTORY
      );
      if (
        item.warnings.length > 0 &&
        inventoryWarning &&
        inventoryWarning?.payload?.total === 0
      ) {
        return { ...item, unavailable: true };
      }
    })
    .filter(Boolean);
    
  if (deletedCartItems?.length > 0 || outOfStockCartItems?.length > 0) {
    const deletedItems =
      deletedCartItems
        ?.map(
          (deletedItem) =>
            cartItems?.find(
              (item) => item.productId === deletedItem.productId
            ) || null
        )
        .flat(Infinity)
        .filter(Boolean) || [];
    groups.push({
      headline: OUT_OF_STOCK,
      deliveryMethods: [
        {
          deliveryMethod: NO_LONGER_AVAILABLE,
          edd: NO_LONGER_AVAILABLE,
          cartItems: [...outOfStockCartItems, ...deletedItems]
        }
      ]
    });
  }

  return groups;
}

export default getGroupDeliveryItems;
