import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 23;

const CallUs = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 23 23"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11.734 5.577c0-.28.23-.507.511-.507 3.119 0 5.633 2.491 5.633 5.577a.51.51 0 0 1-.511.507.51.51 0 0 1-.512-.507c0-2.527-2.057-4.563-4.61-4.563a.51.51 0 0 1-.511-.507Zm0-5.07c0-.28.23-.507.511-.507C18.201 0 23 4.75 23 10.647a.51.51 0 0 1-.513.507.51.51 0 0 1-.512-.507c0-5.335-4.34-9.633-9.73-9.633a.51.51 0 0 1-.511-.507ZM4.978 5.371l1.817 1.63a.315.315 0 0 0 .019.018c1.323 1.309 1.459 3.418.072 4.791l-1.038 1.056c.224.589.803 1.67 1.718 2.576.894.886 1.918 1.419 2.538 1.683l1.374-1.388a3.32 3.32 0 0 1 4.613-.085l2.073 1.862c.516.416.742 1.036.749 1.625.007.591-.207 1.211-.649 1.65l-.011.009c-1.908 1.789-4.149 2.392-6.386 2.152-2.222-.238-4.416-1.301-6.292-2.805-1.877-1.506-3.463-3.475-4.458-5.574-.993-2.095-1.414-4.358-.892-6.424.237-.939.78-1.847 1.375-2.584l.007-.009c.824-.979 2.415-1.119 3.371-.183Zm-2.582.83c-.538.667-.988 1.443-1.177 2.191-.448 1.775-.102 3.793.825 5.749.925 1.951 2.412 3.801 4.175 5.215 1.767 1.417 3.783 2.375 5.758 2.586 1.956.209 3.893-.308 5.567-1.876.218-.218.35-.559.345-.917-.005-.355-.142-.666-.378-.854a.164.164 0 0 1-.025-.021l-2.092-1.879a2.288 2.288 0 0 0-3.19.057l-1.608 1.625a.517.517 0 0 1-.546.121c-.604-.226-2-.843-3.209-2.039-1.197-1.185-1.907-2.649-2.072-3.306a.5.5 0 0 1 .13-.477l1.261-1.281c.947-.936.892-2.398-.062-3.351L4.283 6.115l-.019-.018c-.505-.501-1.403-.444-1.868.104Z"/>
    </IconSvg>
  );
};

CallUs.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CallUs;
