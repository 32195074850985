import { gql } from "@apollo/client";
import { CART_FULL } from "../../../gql/fragments/cart";
const buildRemoveUnavailableItems = () => {
  return gql`
    ${CART_FULL}
    query RemoveUnavailableItems($cartId: String!) {
      removeUnavailableItems(cartId: $cartId) {
        ...CartFull
      }
    }
  `;
};

export default buildRemoveUnavailableItems;
