import React from "react";
import PropTypes from "prop-types";
import { IconSvg, Notification, NotificationIconWrapper } from "../IconsStyled";

const defaultSize = 22;

const Heart = ({ height, width, fill = "currentColor", notification}) => {
  if (notification) {
    return (
      <NotificationIconWrapper>
        <IconSvg
          width={width || defaultSize}
          height={height || defaultSize}
          fill={fill}
          viewBox="0 0 22 20"
        >
          <path d="M3.095 3.299C4.418 1.89 5.966 1.77 7.344 2.331c1.415.577 2.64 1.876 3.169 3.272a.51.51 0 0 0 .951 0c.531-1.396 1.755-2.695 3.169-3.272 1.379-.561 2.925-.441 4.249.968 1.141 1.212 1.603 2.561 1.577 3.949-.015.828-.206 1.679-.542 2.531.342.053.675.141.991.26.348-.912.551-1.843.569-2.772.03-1.652-.53-3.261-1.854-4.667C17.978.85 15.97.685 14.248 1.388c-1.343.547-2.52 1.622-3.26 2.87-.74-1.248-1.915-2.323-3.259-2.87C6.006.685 4 .85 2.353 2.6 1.031 4.006.47 5.615.501 7.267c.03 1.639.641 3.289 1.603 4.816.96 1.523 2.286 2.949 3.797 4.151v-1.326c-1.16-1.013-2.174-2.162-2.936-3.369C2.07 10.12 1.544 8.65 1.519 7.248c-.027-1.388.434-2.737 1.576-3.949Z"/>
        </IconSvg>
        <Notification fill={fill} left="30%" top="55%"><span>{notification}</span></Notification>
      </NotificationIconWrapper>
    )
  } else {
    return(
      <IconSvg
        width={width || defaultSize}
        height={height || defaultSize}
        fill="none"
        stroke={fill}
        viewBox="0 0 22 20"
      >
        <path d="M 11 5.401 C 9.841 2.365 5.696 -0.212 2.72 2.936 C -2.216 8.157 4.389 16.081 11 18.5 C 17.611 16.081 24.217 8.157 19.281 2.936 C 16.304 -0.212 12.158 2.365 11 5.401 Z" strokeLinecap="round" strokeLinejoin="round"/>
      </IconSvg>
    );
  }
};

Heart.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  notification: PropTypes.number,
  width: PropTypes.number,
};

export default Heart;
