import  getNumberValue  from "../getNumberValue";

function isValidString(input) {
  return typeof input === "string" && input.trim().length > 0
}

function isValidNumber(input) {
  return typeof input === "number" && !Number.isNaN(input)
}

function formatAsCurrency(number, locale = "en-US", currencyFormat = "USD") {
  if (!isValidString(number) && !isValidNumber(number)) return;
  const currency = getNumberValue(number);
  if (isValidNumber(currency)) {
    return currency.toLocaleString(locale, {
      style: "currency",
      currency: currencyFormat
    });
  }

}

export default formatAsCurrency    
