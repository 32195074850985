const formatApplePayAddressLines = ({ addressLines }) => {
  let addressLine1 = "";
  let addressLine2 = "";

  if (addressLines && addressLines.length > 0) {
    // If there's only one line, assign it to addressLine1
    if (addressLines.length === 1) {
      addressLine1 = addressLines[0];
    } else {
      // Concatenate all but the last line for addressLine1
      addressLine1 = addressLines.slice(0, -1).join(", ");
      // Assign the last line to addressLine2
      addressLine2 = addressLines[addressLines.length - 1];
    }
  }

  return {
    addressLine1,
    addressLine2
  };
};

export default formatApplePayAddressLines;
