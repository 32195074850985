import React from "react";
import PropTypes from "prop-types";
const CloseChips = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="9"
      viewBox="0 0 8 9"
      fill="none"
    >
      <path
        d="M1.33301 1.8335L6.66634 7.16683"
        stroke="#243953"
        strokeLinecap="round"
      />
      <path
        d="M6.66699 1.8335L1.33366 7.16683"
        stroke="#243953"
        strokeLinecap="round"
      />
    </svg>
  );
};

CloseChips.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CloseChips;
