import { Breakpoints } from "@chv1-serenaandlily/constants/constants";
import styled from "styled-components";

export const SMBCarouselContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 30px;

  @media screen and (max-width: ${Breakpoints.XSMobileMax}) {
    padding: 0;
  }
`;

export const SMBSlideContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SMBSlide = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  max-width: 100%;
  transform: translateX(-50%) translateY(-50%);
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? "auto" : "none")};
  transition: opacity 1s ease-in-out;
  display: flex;
  align-items: center;

  justify-content: center;
`;

export const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: ${({ largestSlideWidth }) => largestSlideWidth}px;
`;

export const SMBArrow = styled.button`
  position: absolute;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  width: 6px;
  cursor: pointer;
  z-index: 1;

  &.prev {
    left: -46px;
  }

  &.next {
    right: -46px;
  }

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${Breakpoints.SMTabletMax}) {
    &.prev {
      left: -30px;
    }

    &.next {
      right: -30px;
    }
  }

  @media screen and (max-width: ${Breakpoints.XSMobileMax}) {
    &.prev {
      left: -18px;
    }

    &.next {
      right: -18px;
    }
  }
`;
