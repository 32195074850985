import styled, { css } from "styled-components";
import { Colors, Breakpoints } from "../../constants/constants";
import zIndex from "@chv1-serenaandlily/constants/zIndex";

export const ModalStyle = styled.div`
  box-sizing: border-box;
  position: absolute;
  max-width: 100vw;
  height: auto;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${Colors.White};
  overflow: ${({ overflowEnabled }) => (overflowEnabled ? "visible" : "auto")};
  outline: none;

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    max-height: 100%;
  }

  ${({ additionalStyle }) =>
    css`
      ${additionalStyle}
    `}
`;

export const OverlayStyle = styled.div`
  position: fixed;
  inset: 0px;
  background-color: ${Colors["Transparent Gray"]};
  z-index: ${zIndex.GLOBAL_POPUP};
  overflow-y: scroll;
`;

export const ModalCloseButton = styled.button`
  border: unset;
  padding: 15px;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  background-color: ${Colors["White"]};
  border: 1px solid ${Colors["Midnight 40%"]};
  border-radius: 40px;
  z-index: 1;
  &:focus {
    outline: 1px solid #383838;
  }
`;
