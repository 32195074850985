const handlePXErrorResponse = (networkError) => {
  if (typeof window === "undefined") return;
  if (!window.isChallengeShowing) {
    window.isChallengeShowing = true;
    window._pxBlockedUrl = networkError?.response?.url;
    window._pxJsClientSrc = networkError?.result.jsClientSrc;
    window._pxFirstPartyEnabled = networkError?.result.firstPartyEnabled;
    window._pxVid = networkError?.result.vid;
    window._pxUuid = networkError?.result.uuid;
    window._pxHostUrl = networkError?.result.hostUrl;
    const modalElement = document.querySelector(".modalWindow");
    modalElement?.classList?.add("modalWindowTarget");
    modalElement.querySelector(
      ".pxRefId"
    ).innerText = `Reference ID: ${window._pxUuid}`;
    modalElement.classList.add("modalWindowTarget");
    const block = `https:${networkError?.result.blockScript}`;
    const script = document.createElement("script");
    script.src = block;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
};
export default handlePXErrorResponse;
