/* eslint-disable no-console */
import createUUID from "../createUUID/createUUID";
import { getCookieValue } from "../getCookieValue";
import fbEvents from "../../gql/utils/fbEvents";
import axios from "axios";

export const setPayload = ({
  facebookEvent,
  eventURL,
  userData,
  eventId,
  customData
}) => {
  const payload = {
    eventName: facebookEvent,
    eventTime: Math.floor(Date.now() / 1000),
    eventSourceURL: eventURL,
    eventId
  };

  const isTestEvent = process.env.FACEBOOK_TEST_MODE;
  if (isTestEvent) {
    const test_event_code = process.env.FACEBOOK_TEST_EVENT_CODE;
    if (!test_event_code) {
      console.error(
        "Attempting to use test event mode without test event code"
      );
    }
    payload.test_event_code = test_event_code;
  }

  payload.userData = userData;
  if (customData) payload.customData = customData;
  return payload;
};

export const setProductPayload = ({
  eventType,
  eventURL,
  defaultAddress,
  productID,
  productName,
  customerId,
  lastName
}) => {
  const eventId = createUUID();
  const payload = setPayload({
    facebookEvent: eventType,
    eventURL,
    userData: {},
    eventId
  });
  const fbCookie = getCookieValue("_fbc");
  if (!payload) return {};
  let addToCartCustomData;
  if (defaultAddress) {
    addToCartCustomData = {
      content_type: "product",
      content_ids: productID,
      content_name: productName,
      externalID: customerId,
      fbc: fbCookie,
      ln: lastName,
      ph: defaultAddress.phone,
      st: defaultAddress.stateCode,
      ct: defaultAddress.city,
      zp: defaultAddress.postalCode,
      country: defaultAddress.countryCode.value
    };
  } else {
    addToCartCustomData = {
      content_type: "product",
      content_ids: productID,
      content_name: productName,
      externalID: customerId,
      fbc: fbCookie
    };
  }
  payload.custom_data = addToCartCustomData;
  return payload;
};

export const emailSignUpFacebook = async ({ email, firstName, lastName }) => {
  const eventId = createUUID();
  const response = await axios.get("/api/user-ip");
  const ipAddress = response.data.ip;
  const facebookEvent = "emailSignUp";
  pushEventToDataLayer({ facebookEvent, eventId });
  const userData = setUserData({ email, ipAddress, firstName, lastName });

  const emailSignupPayload = setPayload({
    facebookEvent,
    eventURL: window.location.href,
    userData,
    eventId
  });

  await fbEvents({
    ...emailSignupPayload
  });
};

function setFbcCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
}

function getFbclidFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("fbclid");
}

function generateFbc(fbclid) {
  if (!fbclid) {
    return null;
  }

  if (fbclid.startsWith("fb.1.")) {
    return fbclid;
  }

  const version = "fb";
  const subdomainIndex = 1;
  const creationTime = Date.now();

  return `${version}.${subdomainIndex}.${creationTime}.${fbclid}`;
}

function getFbcId() {
  const fbcFromUrl = getFbclidFromUrl();
  if (fbcFromUrl) {
    const fbcId = generateFbc(fbcFromUrl);
    return fbcId;
  }

  const fbcId = getCookieValue("_fbc");

  return generateFbc(fbcId);
}

export const pushEventToDataLayer = ({ facebookEvent, product, eventId }) => {
  window.dataLayer = window.dataLayer || [];
  const external_id = getCookieValue("sl_session_id") || "";
  const fbc = getFbcId() || "";

  const eventProperties = {
    external_id,
    fbc
  };

  switch (facebookEvent) {
    case "emailSignUp":
      window.dataLayer.push(
        {
          facebookEmailSignupEventID: eventId
        },
        {
          event: "FacebookEmailSignupEvent",
          metaCustomEventData: eventProperties
        }
      );
      break;

    case "ViewContent":
      window.dataLayer.push({
        facebookViewContentEventID: eventId
      });
      if (product && product.productId) {
        window.dataLayer.push({
          event: "FacebookPDPViewContent",
          FacebookViewContentProductID: product.productId,
          FacebookViewContentProductName: product.productName,
          metaCustomEventData: eventProperties
        });
      }

      break;

    case "AddToCart":
      window.dataLayer.push(
        {
          facebookAddToCartEventID: eventId
        },
        {
          event: "FacebookAddToCartEvent",
          FacebookAddToCartProductID: product.productId,
          FacebookAddToCartProductName: product.productName,
          metaCustomEventData: eventProperties
        }
      );
      break;

    case "Purchase":
      window.dataLayer.push(
        {
          facebookPurchaseEventID: eventId
        },
        {
          event: "FacebookPurchaseEvent",
          metaCustomEventData: eventProperties
        }
      );
      break;

    default:
      break;
  }
  window.dataLayer.push();
};

export const setViewContent = ({ id, name } = {}) => {
  return {
    contentType: "product",
    ...(id && { productIds: [id] }),
    ...(name && { productName: [name] })
  };
};

export const setUserData = ({
  ipAddress,
  email,
  firstName,
  lastName,
  city,
  zipCode,
  phoneNumber
}) => {
  const userData = {
    httpRemoteAddress: ipAddress,
    clientUserAgent: window.navigator.userAgent
  };
  if (email) userData.email = [email];
  if (firstName) userData.firstName = [firstName];
  if (lastName) userData.lastName = [lastName];
  if (phoneNumber) userData.phoneNumber = [phoneNumber];
  if (city) userData.city = [city];
  if (zipCode) userData.zipCode = [zipCode];

  const fbc = getFbcId() || "";
  if (fbc) userData.fbc = fbc;

  const session_id = getCookieValue("sl_session_id") || "";
  if (session_id) userData.externalId = session_id;

  return userData;
};
