import { useCallback, useRef, useState, useEffect } from "react";
import {
  parseYmanViewItemList,
  trackGA4Event,
  trackGA4ViewItemList
} from "./GA4Helpers";
import useDeepCompareEffect from "use-deep-compare-effect";
import { GA4Events } from "./GA4Events";

const parse = (items = []) =>
  parseYmanViewItemList(items, {
    itemListId: "yman",
    itemListName: "You may also need"
  });

export const useYmanHook = (initialItems = []) => {
  const [items, setItems] = useState(parse(initialItems));
  const [pendingItems, setPendingItems] = useState([]);
  const prevInitialItemsRef = useRef(initialItems);

  const updateYmanItem = (id, props) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.item_id === id ? { ...item, ...props } : item
      )
    );
  };

  useEffect(() => {
    const prevInitialItems = prevInitialItemsRef.current;
    let parsedNewItems = [];

    if (initialItems.length > prevInitialItems.length) {
      const newItems = initialItems.slice(prevInitialItems.length);
      parsedNewItems = parse(newItems);
    } else if (initialItems.length < prevInitialItems.length) {
      parsedNewItems = parse(initialItems);
    } else if (
      prevInitialItems.length > 0 &&
      initialItems.length === prevInitialItems.length
    ) {
      parsedNewItems = parse(initialItems);
    }

    // Update the above `initialItems` array reference
    prevInitialItemsRef.current = initialItems;
    // If there are new changes, save them in `pendingItems` for when prices are completed.
    if (parsedNewItems.length > 0) {
      setPendingItems((prev) => [...prev, ...parsedNewItems]);
    }
  }, [initialItems]);

  // Check if all prices are complete and call trackGA4 when prices are updated.
  useEffect(() => {
    const allPricesFilled = items.every((item) => Boolean(item.price));
    // When prices are complete execute trackGA4 function
    if (allPricesFilled && pendingItems.length > 0) {
      const updatedItems = pendingItems.map((_pendingItem) => {
        const pendingItem = items.find(
          (item) => item.item_id === _pendingItem.item_id
        );

        return pendingItem ? pendingItem : _pendingItem;
      });

      trackGA4Event(GA4Events.view_item_list, {
        ecommerce: {
          items: updatedItems
        }
      });
      setPendingItems([]);
    }
  }, [items, pendingItems]);

  return {
    updateYmanItem
  };
};

export const useTrackGA4ViewItemList = (
  data,
  { itemListName, itemListId } = {}
) => {
  const prevDataLength = useRef(0);

  useDeepCompareEffect(() => {
    if (data.length > prevDataLength.current) {
      const newItems = data.slice(prevDataLength.current);
      trackGA4ViewItemList(newItems, { itemListName, itemListId });
    } else if (data.length < prevDataLength.current) {
      trackGA4ViewItemList(data, { itemListName, itemListId });
    } else if (
      prevDataLength.current > 0 &&
      data.length === prevDataLength.current
    ) {
      trackGA4ViewItemList(data, { itemListName, itemListId });
    }

    prevDataLength.current = data.length;
  }, [data]);
};

export const useGA4 = () => {
  const trackEvent = useCallback(async (eventName, eventParams = {}) => {
    return trackGA4Event(eventName, eventParams);
  }, []);

  return {
    trackGA4Event: trackEvent
  };
};

export { trackGA4Event };

export default useGA4;
