export default function getCustomerType(user) {
  const keysToValues = [
    { key: "isDDSO", value: "DDSO" },
    { key: "isDOO", value: "DOO" },
    { key: "isDSO", value: "DSO" }
  ];

  const { data } = user || {};

  const match = keysToValues.find(({ key }) => data?.[key] === true);
  return match ? match.value : "DTC";
}
