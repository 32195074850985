/* global Sailthru */
import { getCookieValue } from "../../getCookieValue";
import getNumberValue from "../../getNumberValue";
const SNL_BASE_URL = "https://www.serenaandlily.com";

function getProductPrice(displayPrice) {
  const designerPrice = displayPrice?.designerPrice;
  const salePrice = displayPrice?.salePrice;
  const unitPrice = displayPrice?.unitPrice;
  if (designerPrice && designerPrice !== "") {
    return designerPrice;
  }
  if (salePrice && salePrice !== "") {
    return salePrice;
  }
  return unitPrice;
}

function getPromoDescription(promo) {
  const description = promo?.description?.trim();
  return description || promo?.couponCode || promo?.name || " ";
}

export function getOrderAdjustments(order, promoCodeList) {
  if (!order?.priceSummary?.deliveryFees) return [];
  const totalDeliveryFeePrice =
    order?.priceSummary?.deliveryFees.reduce(
      (acc, fee) => acc + getNumberValue(fee.price),
      0
    ) || 0;
  const tax = order.priceSummary?.tax;
  const orderAdjustments = [
    {
      title: "Shipping Cost Item",
      price: totalDeliveryFeePrice * 100
    },
    {
      title: "Sales Tax Item",
      price: tax !== "--" ? getNumberValue(order.priceSummary?.tax) * 100 : 0
    }
  ];
  if (promoCodeList && promoCodeList.length > 0) {
    order?.cartItems.forEach((item) => {
      item.appliedPromotions?.forEach((promo) => {
        const price = getProductPrice(item?.displayPrice);
        const totalPriceWithoutPromoDiscount =
          getNumberValue(price) * item.quantity;
        const totalWithDiscount = getNumberValue(
          item?.displayPrice?.totalPrice
        );
        const totalDiscount =
          totalPriceWithoutPromoDiscount - totalWithDiscount;
        orderAdjustments.push({
          title: getPromoDescription(promo),
          price:
            totalDiscount === 0 || !promo?.couponCode
              ? 0
              : totalDiscount * 100 * -1
        });
      });
    });
  }
  return orderAdjustments;
}
function getOrderItems(order) {
  const items = Object.keys(order?.cartItemsById).map((productKey) => {
    const displayPrice = order?.cartItemsById[productKey]?.displayPrice;
    const price = getProductPrice(displayPrice);
    return {
      id: order?.cartItemsById[productKey].sku,
      title: order?.cartItemsById[productKey].name,
      url: `${SNL_BASE_URL}/${
        order?.cartItemsById[productKey].netsuiteId || ""
      }.html`,
      price: getNumberValue(price) * 100,
      sku: order?.cartItemsById[productKey].sku,
      images: {
        full: {
          url: order?.cartItemsById[productKey].imageUrl
        },
        thumb: {
          url: `${order?.cartItemsById[productKey].imageUrl}?fmt=auto&w=100&h=100`
        }
      },
      qty: order?.cartItemsById[productKey].quantity
    };
  });
  return items;
}
export const logPurchase = (order, user) => {
  const isDOO = user?.isDOO;
  const email = order?.email || user?.email;
  const sailthruRunning = typeof Sailthru !== "undefined";
  if (!sailthruRunning) return;
  const promoCodeList =
    [
      ...new Set(
        order?.cartItems
          ?.map((item) => {
            if (item?.appliedPromotions && item.appliedPromotions.length > 0) {
              return item?.appliedPromotions?.map((promo) => promo.couponCode);
            }
          })
          .filter((v) => v)
          .flat(999)
      )
    ] || [];
  Sailthru.integration("purchase", {
    id: email,
    key: "email",
    message_id: getCookieValue("sailthru_bid") || "",
    purchase_keys: {
      extid: order.orderId
    },
    items: getOrderItems(order),
    adjustments: getOrderAdjustments(order, promoCodeList),
    vars: {
      weborderNumber: order.orderId,
      class: isDOO ? "Designer/Non Stocking" : "Direct to Consumer",
      postal_address: order.shippingAddress?.addressLine1,
      postal_address2: order.shippingAddress?.addressLine2,
      postal_code: order.shippingAddress?.postalCode,
      postal_state: order.shippingAddress?.stateProvince,
      postal_city: order.shippingAddress?.city,
      country: order.shippingAddress?.country,
      ...(promoCodeList.length > 0 && { campaign_id: promoCodeList.join(",") })
    }
  });
};
export default logPurchase;
