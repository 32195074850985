import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 24;

const Minus = ({ height, width }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      viewBox="0 0 24 24"
    >
      <path d="M19 13H5v-2h14v2z"></path>
    </IconSvg>
  );
};

Minus.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default Minus;
