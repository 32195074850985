import React, { createContext, useContext, useState, useCallback } from "react";

const SlideInContext = createContext();

export const SlideInProvider = ({ children }) => {
  const [openSlideInCount, setOpenSlideInCount] = useState(0);
  const [closeAllSignal, setCloseAllSignal] = useState(false);

  const increment = () => setOpenSlideInCount((count) => count + 1);
  const decrement = () =>
    setOpenSlideInCount((count) => Math.max(0, count - 1));

  const closeAllSlideIns = useCallback((callback = () => {}) => {
    setCloseAllSignal(true);
    setTimeout(() => {
      setCloseAllSignal(false);
    }, 0);
    callback();
  }, []);

  return (
    <SlideInContext.Provider
      value={{
        openSlideInCount,
        increment,
        decrement,
        closeAllSignal,
        closeAllSlideIns
      }}
    >
      {children}
    </SlideInContext.Provider>
  );
};

export const useSlideInContext = () => useContext(SlideInContext);
