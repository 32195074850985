import apolloClient from "../client";
import buildUpdateCustomerMutation from "../mutations/buildUpdateCustomerMutation";

const updateCustomer = async (customer) => {
  if (!apolloClient) return;
  const UPDATE_CUSTOMER = buildUpdateCustomerMutation();
  const response = await apolloClient.mutate({
    mutation: UPDATE_CUSTOMER,
    variables: {
      customer: customer
    },
    context: { clientName: "endpoint2" }
  });

  return response;
};

export default updateCustomer;
