import {
  getAddressFormValues,
  getDefaultAddress,
  getSelectedAddressId,
  getAddressNickname
} from "../../utils/address";
import USER_TYPES from "../../constants/userTypes";
import {
  EMPTY_CHECKOUT_BILLING_FORM,
  EMPTY_CHECKOUT_SHIPPING_FORM,
  PAYMENT_TYPES
} from "../../constants/checkout";
import currency from "currency.js";

/**
 * Returns the address values to display in the shipping form.
 * @param cart
 * @param addressList
 * @returns "isDefaultShipping" or "default
 */
export const getDefaultField = ({ userType }) => {
  return userType && userType === USER_TYPES.dso
    ? "isDefaultShipping"
    : "default";
};

/**
 * Returns the address values to display in the shipping form.
 * @param cart
 * @param addressList
 * @returns {{firstName: string, lastName: string, country: string, addressNickname: string, city: string, companyName: string, postalCode: string, addressLine1: string, stateProvince: string, addressLine2: string}|*}
 */
export const getShippingAddressValues = ({
  cart = {},
  addressList = [],
  userType,
  currentShippingAddressValues
}) => {
  if (cart.shippingAddress && Object.keys(cart.shippingAddress).length > 0) {
    const selectedAddressId = getSelectedAddressId({
      address: cart.shippingAddress,
      addressList,
      phoneNumber: cart.phoneNumber
    });
    const addressNickname = getAddressNickname({
      address: cart.shippingAddress,
      selectedAddressId: selectedAddressId !== "" ? selectedAddressId : "cart",
      addressList,
      currentShippingAddressValues
    });
    const address = {
      ...cart.shippingAddress,
      phoneNumber: cart.phoneNumber,
      addressSource: "cart",
      id: selectedAddressId !== "" ? selectedAddressId : "cart",
      addressNickname
    };
    if (addressNickname) {
      address.addressNickname = addressNickname;
    }

    return address;
  }
  const defaultField = getDefaultField({ userType });
  const defaultAddress = getDefaultAddress(addressList, defaultField);

  if (defaultAddress)
    return {
      ...getAddressFormValues(defaultAddress),
      id: defaultAddress.id,
      addressSource: defaultField
    };
  // fallback to the the first address for a user that has addresses but no default marked.
  if (addressList && addressList.length > 0) {
    const address = addressList[0];
    return {
      ...getAddressFormValues(address),
      id: address.id,
      addressSource: "savedAddress"
    };
  }
};

/**
 * Returns the phone number to display in the shipping page form.
 * @param cart
 * @param addressList
 * @returns {*}
 */
const getPhoneValue = ({ cart = {}, addressList = [], defaultName }) => {
  return (
    cart.phoneNumber || getDefaultAddress(addressList, defaultName)?.phoneNumber
  );
};

/**
 * Returns the email address to display in the shipping page form.
 * @param cart
 * @param userEmail
 * @param userType
 * @returns {*}
 */
export const getEmailValue = ({ cart = {}, userEmail, userType }) => {
  let email = cart.email;
  if (!email && userType !== USER_TYPES.guest && userType !== USER_TYPES.dso) {
    email = userEmail;
  }
  return email;
};

/**
 * Based on the values of the shipping and billing address in the cart, determines if the same as billing checkbox
 * should be checked. Defaults to true.
 * @param cart
 * @returns {boolean}
 */
const getIsSameAsBilling = ({ cart }) => {
  if (cart?.shippingAddress && cart?.billingAddress) {
    // remove a few fields not found in the billing address.
    let {
      companyName: _companyName,
      attention: _attention,
      poNumber: _poNumber,
      ...shippingAddress
    } = cart.shippingAddress;
    const addressProps = Object.keys(shippingAddress);
    for (let key of addressProps) {
      if (cart.shippingAddress[key] != cart.billingAddress[key]) {
        return false;
      }
    }
    return true;
  }
};

/**
 * Given the shipping methods applied to the cart, determines which of the available shipping item should be selected in
 * the shipping form. Returns the sku of the selected shipping method or an empty string.
 * @param cart
 * @returns {string}
 */
export const getShippingOverride = ({ cart }) => {
  // selected shipping methods are in cart?.shippingItems,
  // find an available method that is in the shippingItem and use that as the default.
  let shippingOverride = "";
  if (
    Array.isArray(cart?.shippingItems?.shippingCartItems) &&
    Array.isArray(cart?.shippingItems?.availableMethods) &&
    cart.shippingItems.availableMethods.length > 1
  ) {
    const selectedMethod = cart.shippingItems.availableMethods.find(
      (availableMethod) =>
        cart.shippingItems.shippingCartItems?.some(
          (shippingItem) => shippingItem.sku === availableMethod.sku
        )
    );
    if (selectedMethod) {
      shippingOverride = selectedMethod.sku;
    }
  }
  return shippingOverride;
};

/**
 * Returns all the values to display in the shipping page form.
 * @param cart
 * @param addressList
 * @param userEmail
 * @param userType
 * @returns {{lastName: string, country: string, isSameAsBilling: boolean, city: string, postalCode: string, stateProvince: string, isDoNotShipUntil: boolean, doNotShipUntilDate: Date, firstName: string, designService: string, phoneNumber: string, vipCheck: boolean, taxableStatus: string, shippingOverride: string, addressLine1: string, savedAddress: string, addressLine2: string, email: string}}
 */
export const getShippingFormValues = ({
  cart,
  addressList,
  userEmail,
  userType
}) => {
  const address = getShippingAddressValues({ cart, addressList, userType });
  const phoneNumber = getPhoneValue({
    cart,
    addressList,
    defaultName: getDefaultField({ userType })
  });

  const taxableStatus = cart?.taxableStatus ? "true" : "false";
  const email = getEmailValue({ cart, userEmail, userType });
  const doNotShipUntilDate = cart?.doNotShipUntilDate;
  const giftInfo = cart?.giftInfo;
  const selectedAddressId = getSelectedAddressId({
    address,
    phoneNumber,
    addressList
  });
  const addressNickname = getAddressNickname({
    address,
    selectedAddressId,
    addressList
  });
  const isSameAsBilling = getIsSameAsBilling({ cart });
  const vipCheck = userType !== USER_TYPES.dso;
  const shippingOverride = getShippingOverride({ cart });

  return {
    ...EMPTY_CHECKOUT_SHIPPING_FORM,
    ...address,
    ...(phoneNumber && { phoneNumber }),
    ...(email && { email }),
    ...(doNotShipUntilDate && {
      doNotShipUntilDate: new Date(doNotShipUntilDate),
      isDoNotShipUntil: !!doNotShipUntilDate
    }),
    taxableStatus,
    ...(selectedAddressId && {
      savedAddress: selectedAddressId,
      addressNickname
    }),
    ...(cart?.designService && {
      designService: cart?.designService
    }),
    isSameAsBilling,
    shippingOverride,
    vipCheck,
    ...(giftInfo && {
      giftInfo: giftInfo
    })
  };
};

/**
 * Returns the values to display in the credit card and check fields on the billing form.
 * @param cart
 * @returns {{checkNumber: (string|*)}|{expireMonth, fullName, expireYear}}
 */
const getPaymentFields = ({ cart }) => {
  if (Array.isArray(cart.payments) && cart.payments.length > 0) {
    if (cart.payments[0]?.type === PAYMENT_TYPES.CYBERSOURCE) {
      return {
        expireMonth: cart.payments[0].expireMonth,
        expireYear: cart.payments[0].expireYear,
        fullName: cart.payments[0].fullName
      };
    }
    if (cart.payments[0]?.type === PAYMENT_TYPES.CHECK) {
      return {
        checkNumber: cart.payments[0].cardCheckNumber
      };
    }
  }
};

/**
 * Returns the values to display in the billing form.
 * @param cart
 * @param addressList
 * @returns {*&{lastName: string, country: string, checkNumber: string, city: string, expireMonth: string, postalCode: string, stateProvince: string, fullName: string, validationCode: string, storedPaymentId: string, paymentToken: string, firstName: string, phoneNumber: string, addressLine1: string, addressLine2: string, captureContext: string, expireYear: string}}
 */
export const getBillingFormValues = ({ cart, addressList }) => {
  const selectedAddressId = getSelectedAddressId({
    address: cart.billingAddress,
    addressList
  });
  const paymentFields = getPaymentFields({ cart });
  return {
    ...EMPTY_CHECKOUT_BILLING_FORM,
    ...cart.billingAddress,
    ...paymentFields,
    ...(selectedAddressId && { savedAddress: selectedAddressId })
  };
};

export const getHasNoBalance = ({ totalPrice }) => {
  return currency(totalPrice).intValue === 0 || totalPrice === "--";
};

export const getIsEditBilling = ({ isDOO, isBillingSameAsShipping }) => {
  return isDOO || !isBillingSameAsShipping;
};
