const theme = {
  iconBackgroundColor: '#D5DEE7',
  iconBorderColor: '#D5DEE7',
  iconBackgroundColorActive: 'Cloud Blue',
  iconColor: 'Midnight',
  iconSize: '20px',
  tooltipBackgroundColor: 'White',
  tooltipOffset: '4px',
  tooltipColor: 'Midnight',
  tooltipShadow: '0px 12px 31px -6px rgba(36, 57, 83, 0.1)',
  tooltipTypography: 'textSmall',
  tooltipLineHeight: '120%',
  tooltipPadding: '16px',
  tooltipBorderRadius: '5px',
  tooltipPlacement: 'bottom-start',
  tooltipWidth: '266px'
};

export default theme;