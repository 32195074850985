/* eslint-disable max-lines-per-function */
import { gql } from "@apollo/client";
import { CART_FULL } from "../fragments/cart";

const buildAddPromotionToCartMutation = () => {
  return gql`
    ${CART_FULL}
    mutation AddPromotion($cartId: String!, $couponCode: String!) {
      addPromotion(cartId: $cartId, couponCode: $couponCode) {
        ...CartFull
      }
    }
  `;
};

export default buildAddPromotionToCartMutation;
