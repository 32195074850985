import { formatAsCurrency, getNumberValue } from "@utils";

export const SHIPPING_FEES_URL = "/shipping-methods";
export const NULL_PRICE_STRING = "-";

/*
OUTPUT:
 priceSummary: {
  subtotal: "",
  deliveryFees: [
    {
      link: "",
      name: "",
      price: ""
    }
  ],
  tax: "",
  totalPrice: "",
  discounts: [{ name: "", price: "" }]
} */

export default function (
  respPriceSummary = {},
  shippingItems = {},
  shippingAddress = {}
) {
  const {
    subtotal,
    discounts = "",
    tax,
    totalPrice,
    mattressSurcharge
  } = respPriceSummary;

  const hasAddress = shippingAddress && Object.keys(shippingAddress).length > 0;

  const getPriceForDisplay = (price, shouldAvoidFormat) => {
    return (!price || getNumberValue(price) === 0) && !shouldAvoidFormat
      ? NULL_PRICE_STRING
      : formatAsCurrency(price);
  };

  const getDeliveryFees = (shippingItems) => {
    if (Array.isArray(shippingItems?.shippingCartItems)) {
      return shippingItems.shippingCartItems.map((shippingItem) => {
        return {
          name: shippingItem.name,
          link: SHIPPING_FEES_URL,
          price: hasAddress
            ? getPriceForDisplay(shippingItem.displayPrice?.formatted, true)
            : getPriceForDisplay(shippingItem.displayPrice?.formatted)
        };
      });
    }
    return [];
  };
  const priceResponse = {
    deliveryFees: getDeliveryFees(shippingItems),
    discounts,
    subtotal: getPriceForDisplay(subtotal),
    tax: hasAddress ? getPriceForDisplay(tax, true) : getPriceForDisplay(tax),
    totalPrice: getPriceForDisplay(totalPrice)
  };
  if (mattressSurcharge) {
    priceResponse.mattressSurcharge = getPriceForDisplay(
      mattressSurcharge,
      true
    );
  }
  return priceResponse;
}
