import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import getJsonFromString from "../../utils/getJsonFromString";
import isAuthError from "../../utils/errors/isAuthError";
import { PHASE_PRODUCTION_BUILD } from "next/dist/shared/lib/constants";
import handlePXErrorResponse from "@chv1-serenaandlily/utils/handlePXErrorResponse";
import RESPONSE from "@chv1-serenaandlily/constants/statusCodes";

const DD_S2S_TOKEN = process.env.NEXT_PUBLIC_DD_S2S_TOKEN;

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore"
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  },
  mutate: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  }
};

const setBaseURL = () => {
  let BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
  if (BASE_URL) {
    BASE_URL = `${BASE_URL}`;
  } else if (typeof window !== "undefined" && window?.location?.origin) {
    BASE_URL = window?.location?.origin;
  } else if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    BASE_URL = process.env.NEXT_PUBLIC_VERCEL_URL;
  } else {
    BASE_URL = "";
  }
  return BASE_URL;
};

let tokenPromise = null;

const fetchToken = async () => {
  const params = {
    url: `/api/token`,
    method: "get",
    withCredentials: true
  };
  try {
    const { data } = await axios(params);
    const { token, ddCookie } = data;
    return { token, ddCookie };
  } catch (e) {
    if (e.response.status !== RESPONSE.STATUS_404) {
      // We only care about token issues when we're not a guest - ie. not a 404
      // eslint-disable-next-line no-console
      console.error(`Failure to get user token - ${e}`);
    }
    throw e;
  }
};

const withToken = setContext(async () => {
  if (process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD) {
    return { token: null };
  }

  if (!tokenPromise) {
    tokenPromise = fetchToken().finally(() => {
      tokenPromise = null;
    });
  }

  try {
    const { token, ddCookie } = await tokenPromise;
    return {
      token,
      ddCookie
    };
  } catch (e) {
    return { token: null };
  }
});

const afterwareLink = new ApolloLink((operation, forward) => {
  const { token, ddCookie } = operation.getContext();
  let defaultHeaders = {
    "x-api-key": process.env.NEXT_PUBLIC_BACKEND_API_KEY,
    "ecp-app-source": process.env.NEXT_PUBLIC_APP_SOURCE,
    "x-datadome-clientid": ddCookie
  };

  operation.setContext(({ headers }) => {
    if (typeof window === "undefined" && DD_S2S_TOKEN) {
      defaultHeaders["x-dd-next-s2s"] = DD_S2S_TOKEN;
    }
    return {
      headers: {
        ...headers,
        ...defaultHeaders,
        Authorization:
          token ||
          headers?.Authorization ||
          process.env.NEXT_PUBLIC_DEFAULT_TOKEN
      }
    };
  });

  return forward(operation).map((data) => {
    const context = operation.getContext();
    const baseURL = setBaseURL();
    const authHeader = context?.response?.headers?.get("x-auth-token");
    // Update the token for logged-in users.
    // Anonymous users can continue to use the default token.
    if (authHeader && context.token) {
      axios({
        url: `${baseURL}/api/updatetoken`,
        method: "post",
        data: { authHeader }
      });
    }
    return { ...data, authHeader };
  });
});

const endpoint2 = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_NEW_BACKEND_ENDPOINT}/graphql`
});

export const errorLink = onError(({ networkError }) => {
  if (isAuthError(networkError?.result?.errors)) {
    axios({
      url: "/api/logout",
      method: "post"
    }).then(() => {
      window.location.href = "/account?logout=auto";
    });
  }
  if (networkError?.statusCode === 403 && networkError?.result?.appId) {
    handlePXErrorResponse(networkError);
  }
});

const formatErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(({ data, errors }) => {
    let parsedErrors;
    if (errors) {
      parsedErrors = errors.map((error) => {
        const { errorInfo, errorType, ...rest } = error;
        const parsedError = { ...rest };
        parsedError.errorType = getJsonFromString(errorType);
        parsedError.errorInfo = getJsonFromString(errorInfo);
        return parsedError;
      });
    }
    return { data, errors: parsedErrors };
  });
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    withToken,
    afterwareLink,
    errorLink,
    formatErrorLink,
    endpoint2
  ]),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions
});

export default apolloClient;
