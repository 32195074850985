export default function getSegmentId(source) {
  const segments = {
    Popup: 1,
    "Site Banner": 3,
    BirthdayTreat: 5,
    DesignAdvice: 6,
    RegistrationForm: 7,
    MonthlySweeps: 9,
    CatalogRequest: 10
  };
  return segments[source] || 0;
}
