import Script from "next/script";
import PropTypes from "prop-types";

const invalidIds = [null, undefined, ""];
const defaultId = process.env.NEXT_PUBLIC_CONSTRUCTOR_BEACON_ID || "";

export const ConstructorScript = ({ id }) => {
  const beaconId = id || defaultId;
  if (invalidIds.includes(beaconId)) return null;

  const scriptUrl = `https://cnstrc.com/js/cust/${beaconId}.js`;
  return <Script src={scriptUrl} strategy="afterInteractive" />;
};

ConstructorScript.propTypes = {
  id: PropTypes.string
};
