import merge from "deepmerge";
import resolveComponentTheme from "./resolveComponentTheme";
import deepRemoveEmpty from "./deepRemoveEmpty";

const getComponentTheme = ({
  globalTheme,
  globalNamespace,
  themeName,
  theme,
  themeOverrides
}) => {
  const namespacedTheme = globalTheme?.[globalNamespace] || {};
  // start with the named theme or default theme
  const baseTheme =
    namespacedTheme?.[themeName] || namespacedTheme?.["default"] || {};
  // merge chosen theme and theme overrides into the default
  return merge.all([
    baseTheme,
    resolveComponentTheme(globalTheme, deepRemoveEmpty(theme)),
    resolveComponentTheme(globalTheme, deepRemoveEmpty(themeOverrides))
  ]);
};

export default getComponentTheme;
