const groupCartWarningsByCode = (warnings = []) => {
  if (!warnings?.length) return {};
  return warnings.reduce((acc, obj) => {
    const key = obj.code;
    if (!acc[key]) {
      acc[key] = {
        code: key,
        message: obj.message,
        payloads: [],
      };
    }
    if (obj.payload) {
      acc[key].payloads.push(obj.payload);
    }
    return acc;
  }, {});
};

export default groupCartWarningsByCode;
