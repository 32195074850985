/* global zE */
import React, { useState, useEffect, useMemo } from "react";
import Script from "next/script";
import useSWRImmutable from "swr/immutable";
import { useRouter } from "next/router";
import useUser from "@chv1-serenaandlily/hooks/useUser";
import { useAccount } from "@chv1-serenaandlily/contexts/AccountContext";
const ZENDESK_KEY = process.env.NEXT_PUBLIC_ZENDESK_KEY;
const ZENDESK_SDK_URL = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`;

function filterCustomerGroups(form, userData) {
  if (!form.customerGroups) {
    return form;
  }
  if (form.customerGroups.some((group) => userData?.userType === group)) {
    return form;
  }
  return null;
}
function getQueryParams(asPath, searhParams) {
  const queryParams = asPath.split("?");
  if (queryParams.length > 1) {
    return queryParams[1];
  }
  return searhParams;
}

const ZendeskWidgetScript = () => {
  const [initialized, setInitialized] = useState(false);
  const router = useRouter();
  const { trackOrderCriteria } = useAccount();
  const searhParams = new URLSearchParams(trackOrderCriteria).toString();
  const currentSlug = router.asPath
    .split("/")
    .pop()
    .split("?")[0]
    .split("#")[0];
  const queryParams = getQueryParams(router.asPath, searhParams);
  const { user } = useUser();
  const userData = user?.data;
  const { data: formsData } = useSWRImmutable(() =>
    !!currentSlug && currentSlug !== "/"
      ? `/api/zendesk/${currentSlug}?${queryParams}`
      : null
  );
  const forms = useMemo(() => {
    return (
      formsData?.data?.ticketForms
        .map((form) => filterCustomerGroups(form, userData))
        .filter((v) => v) || []
    );
  }, [formsData, userData]);

  useEffect(() => {
    if (initialized && formsData) {
      zE("webWidget", "updateSettings", {
        webWidget: {
          contactForm: {
            ticketForms: forms
          }
        }
      });
      if (forms.length > 0) {
        zE("webWidget", "show");
      } else {
        zE("webWidget", "hide");
      }
    }
  }, [initialized, formsData]);

  const handleOnReady = () => {
    setInitialized(true);
  };

  if (ZENDESK_KEY && formsData) {
    return (
      <Script id="ze-snippet" src={ZENDESK_SDK_URL} onReady={handleOnReady} />
    );
  }

  return null;
};

export default ZendeskWidgetScript;
