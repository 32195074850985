import React from "react";

const AddCardIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M0.550728 9H17.5213M9.03601 0.514719V17.4853"
        stroke="#A7B0BA"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AddCardIcon;
