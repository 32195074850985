/* eslint-disable max-lines-per-function */
import { gql } from "@apollo/client";
import {
  CART_APPLIED_PROMOTION_FIELDS,
  CART_DELIVERY_GROUP_FIELDS,
  CART_ITEMS_FIELDS,
  CART_PRICE_FIELDS,
  CART_SHIPPING_ITEM_FIELDS
} from "../fragments/cart";

const buildRemovePromotionFromCartMutation = () => {
  return gql`
    ${CART_APPLIED_PROMOTION_FIELDS}
    ${CART_PRICE_FIELDS}
    ${CART_DELIVERY_GROUP_FIELDS}
    ${CART_SHIPPING_ITEM_FIELDS}
    ${CART_ITEMS_FIELDS}
    mutation RemovePromotion($cartId: String!, $couponCode: String!) {
      removePromotion(cartId: $cartId, couponCode: $couponCode) {
        cartId
        warnings
        deliveryGroups {
          ...CartDeliveryGroupFields
        }
        priceSummary {
          ...CartPriceFields
        }
        shippingItems {
          ...CartShippingItemFields
        }
        cartItems {
          ...CartItemFields
        }
        appliedPromotions {
          ...CartAppliedPromotionFields
        }
      }
    }
  `;
};

export default buildRemovePromotionFromCartMutation;
