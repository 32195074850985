import React from "react";
import PropTypes from "prop-types";

const defaultSize = 22;

const QuestionMarkTooltip = ({ height, width }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="#D5DEE7" />
      <path
        d="M11.7411 14.0624C11.4352 14.0624 11.1826 13.814 11.2045 13.5088C11.2266 13.2007 11.2743 12.8936 11.3718 12.6036C11.4888 12.2558 11.6549 11.9742 11.8704 11.7588C12.0858 11.5434 12.3443 11.3448 12.646 11.1633C12.8275 11.0525 12.9906 10.9217 13.1353 10.7709C13.2799 10.617 13.3938 10.44 13.4769 10.24C13.5631 10.0399 13.6062 9.81833 13.6062 9.5752C13.6062 9.27359 13.5354 9.01198 13.3938 8.79039C13.2522 8.5688 13.063 8.39799 12.826 8.27797C12.589 8.15794 12.3259 8.09792 12.0366 8.09792C11.7842 8.09792 11.5411 8.15024 11.3072 8.25488C11.0733 8.35952 10.8778 8.52418 10.7209 8.74885C10.6504 8.84968 10.5933 8.96445 10.5495 9.09318C10.4501 9.38539 10.2088 9.63059 9.90018 9.63059C9.56529 9.63059 9.2903 9.34751 9.37191 9.02272C9.44006 8.75145 9.54656 8.50628 9.69141 8.2872C9.9407 7.9148 10.2685 7.63012 10.6747 7.43315C11.084 7.23618 11.538 7.1377 12.0366 7.1377C12.5782 7.1377 13.0491 7.24541 13.4492 7.46085C13.8524 7.67628 14.1632 7.97174 14.3817 8.34721C14.6033 8.72269 14.7141 9.15048 14.7141 9.63059C14.7141 9.96914 14.6618 10.2754 14.5572 10.5493C14.4556 10.8232 14.3079 11.0679 14.114 11.2833C13.9232 11.4987 13.6924 11.6895 13.4215 11.8557C13.1507 12.025 12.9337 12.2035 12.7706 12.3912C12.6075 12.5759 12.489 12.796 12.4151 13.0514C12.3719 13.2008 12.3431 13.3544 12.3246 13.5095C12.2883 13.8133 12.0471 14.0624 11.7411 14.0624Z"
        fill="#243953"
      />
      <circle cx="11.7898" cy="15.9284" r="0.934301" fill="#243953" />
    </svg>
  );
};

QuestionMarkTooltip.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default QuestionMarkTooltip;
