import { useBreakpoint } from "../components/Breakpoints";

export const useEnableAtBreakpoint = ({
  desktopEnabled,
  mobileEnabled,
  tabletEnabled
}) => {
  const breakpoints = useBreakpoint() || {};
  const isDesktop = breakpoints?.["MDLaptopMin"];
  const isMobile = breakpoints?.["XSMobileMax"];
  const isTablet = breakpoints?.["SMTabletMax"] && breakpoints?.["SMTabletMin"];

  let isEnabled = false;

  if (isDesktop) {
    isEnabled = desktopEnabled;
  } else if (isTablet) {
    isEnabled = tabletEnabled;
  } else if (isMobile) {
    isEnabled = mobileEnabled;
  }

  return { isEnabled };
};
