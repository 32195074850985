import { css } from "styled-components";

export const Colors = {
  "Accent Color": "#1b7eac",
  "Account Tabs": "#f7f6f5",
  Alabaster: "#f7f7f7",
  Beige: "#f5f5dc",
  Black: "#000",
  "Border Gray": "#ccc",
  "Dark Brown": "#ac9076",
  "Darkest Brown": "#977E66",
  "Cloud Blue": "#ACBECF",
  Cloud: "#f9f9f9",
  Crimson: "#970013",
  Cream: "#FBF7EE",
  "Cu Color Black": "#161616",
  "Cu Color Brown": "#877669",
  "Cu Color Coral": "#9A473F",
  "Cu Color Dark Blue": "#172b5c",
  "Cu Color Green": "#687B66",
  "Cu Color Grey": "#aeaeae",
  "Cu Color Light Blue": "#7995be",
  "Cu Color Tan": "#f1e7d9",
  "Cu Color Yellow": "#EEC668",
  "Dark Primary": "#383838",
  "Dark Sky": "#527296",
  "Gray Dropdown Bg": "#f2f2f2",
  "Divider Lighter": "#d4d0c3",
  "Dove Gray": "#6f6f6f",
  "Einsten Carousel Grey": "#f5f3ee",
  "Error Sale": "#970013",
  Fog: "#eee",
  "Gray Medium": "#858585",
  Gray: "#cbcaca",
  "Hover Dropdown Gray": "rgba(255, 255, 255, 0.50)",
  Green: "#576A44",
  "Header Primary": "#4a4a4a",
  "Light Grey": "#d6d6d6",
  "Light Primary": "#cbcaca",
  "Light Secondary": "#efefef",
  Light: "#eee",
  "Low Transparent Gray": "hsla(0, 0%, 80%, 0.2)",
  "Medium Primary": "#767676",
  "Midnight 40%": "#A7B0BA",
  "Midnight 70%": "#667487",
  Midnight: "#243953",
  Natural: "#F5F3EE",
  Pea: "#22a1dc",
  "Persian Rose": "#fe249a",
  "Primary Midnight": "#243953",
  "Promo Background": "#091520",
  "Red Beech": "#783201",
  "Regent Grey": "#8f979d",
  "Special Message Grey": "#E5EAF0",
  Silver: "#ccc",
  "Alternate Silver": "#DDDDDD",
  Sky: "#55778d",
  Tan: "#f3eeeb",
  "Tooltip Link": "#445eab",
  Transparent: "rgba(255, 255, 255, 0)",
  "Transparent Gray": "rgba(204, 204, 204, 0.5)",
  "Transparent Silver": "rgba(204, 204, 204, 0.9)",
  "Transparent White": "rgba(255, 255, 255, 0.5)",
  "Warning Red": "#DC143C",
  White: "#fff",
  "Wishlist Gray": "#E5E7EA",
  "Wishlist Border Gray": "#DCDCDC",
  "Wishlist Transparent White": "rgba(255, 255, 255, 0.8)",
  Yellow: "#ff0"
};

export const Shadows = {
  FabricSlideIn: "-5px 10px 10px 0px rgba(0,0,0,0.1)"
};

export const Diagonals = {
  "White Diagonal Thin":
    "linear-gradient(to bottom right, rgba(255, 255, 255, 0.1) 47%, $white, 54%, rgba(255, 255, 255, 0.1) 0)",
  "White Diagonal Cu Colors":
    "linear-gradient(to bottom right,hsla(0,0%,100%,.1) 47%,$white,51%,hsla(0,0%,100%,.1) 0)",
  "Silver Diagonal Cu Colors":
    "linear-gradient(to bottom right,hsla(0,0%,100%,.1) 47%,$snl-color-silver,51%,hsla(0,0%,100%,.1) 0)"
};

export const Horizontal = {
  Left: "flex-start",
  Center: "center",
  Right: "flex-end"
};

export const Vertical = {
  "Top Line": "flex-start",
  Middle: "center",
  "Base Line": "flex-end"
};

export const ModularVertical = {
  Top: "flex-start",
  Center: "center",
  Bottom: "flex-end",
  "Top and Bottom": "space-between"
};

export const FlexDirs = {
  Left: "row",
  Right: "row-reverse",
  Top: "column",
  Bottom: "column-reverse"
};

export const ModularOrder = {
  First: 1,
  Second: 2,
  Third: 3,
  Fourth: 4,
  Fifth: 5,
  Sixth: 6
};

export const ElementHeights = {
  Button: "38px"
};

export const LetterSpacing = {
  XxSmal: "0.4px",
  XSmall: "1px",
  Small: "2px",
  Regular: "2.5px"
};

export const FontSizes = {
  Xxsmall: "9px",
  Xsmall: "10px",
  Small: "11px",
  Base: "12px",
  Large: "14px",
  Xlarge: "16px",
  Xxlarge: "18px",
  LinkHeader: "20px",
  HeaderSmall: "21px",
  Header: "24px",
  HeaderLarge: "28px"
};

export const Spacing = {
  Xsmall: 8,
  MediumSmall: 12,
  Small: 16,
  Base: 24,
  Medium: 26,
  Large: 32,
  Xlarge: 40
};

export const FontWeights = {
  lighter: "300",
  normal: "400",
  bold: "600"
};

export const Typography = {
  georgia: "Georgia, serif",
  helvetica: "Helvetica, Arial, sans-serif",
  trebuchet: '"Trebuchet MS", Helvetica, sans-serif',
  ProximaNova: "'proxima-nova', sans-serif",
  ProximaNovaLt: '"proxima_novalight", sans-serif',
  ProximaNovaRegular: "proxima_novaregular",
  Caslon540Roman: '"caslon-540-roman", Adobe Caslon Pro, serif',
  Caslon540Italic: '"caslon-540-italic", Adobe Caslon Pro, serif',
  CaslonCaps: '"CaslonFSCapsBookRegular", Adobe Caslon Pro, serif',
  SCExtraLight: "saintecolombe-extralight",
  SCExtraLightItalic: "saintecolombe-extralightitalic",
  SCItalic: "saintecolombe-italic",
  SCRegular: "saintecolombe-regular",
  Baskerville: "Baskerville",
  BeatriceLight: "BeatriceLight, sans-serif",
  BeatriceThin: "Beatrice Thin, sans-serif",
  BeatriceRegular: "BeatriceRegular, sans-serif",
  Beatrice: "Beatrice, sans-serif",
  BeatriceMedium: "BeatriceMedium, sans-serif",
  BeatriceSemiBold: "Beatrice Semibold, sans-serif",
  BeatriceBold: "Beatrice Bold, sans-serif",
  BeatriceExtraBold: "Beatrice Extra Bold, sans-serif",
  BeatriceItalicThin: "Beatrice Italic Thin",
  BeatriceItalicLight: "Beatrice Italic Light",
  BeatriceItalicRegular: "Beatrice Italic Regular",
  BeatriceItalicMedium: "Beatrice Italic Medium",
  BeatriceItalicSemiBold: "Beatrice Italic Semibold",
  BeatriceItalicBold: "Beatrice Italic Bold",
  BeatriceItalicExtraBold: "Beatrice Italic Extra Bold",
  PPFragmentThin: "PP Fragment Thin",
  PPFragmentExtraLight: "PP Fragment Extra Light",
  PPFragmentLight: "PP Fragment Light",
  PPFragment: "'PP Fragment', serif",
  PPFragmentMedium: "'PP Fragment Medium', serif",
  PPFragmentSemibold: "'PP Fragment Semibold', serif",
  PPFragmentBold: "'PP Fragment Bold', serif",
  PPFragmentExtrabold: "'PP Fragment Extrabold', serif",
  PPFragmentBlack: "'PP Fragment Black', serif",
  PPFragmentItalic: "'PP Fragment Italic Regular', serif",
  PPFragmentItalicThin: "'PP Fragment Italic Thin', serif",
  PPFragmentItalicExtralight: "'PP Fragment Italic Extra Light', serif",
  PPFragmentItalicLight: "'PP Fragment Italic Light', serif",
  PPFragmentItalicRegular: "'PP Fragment Italic Regular', serif",
  PPFragmentItalicMedium: "'PP Fragment Italic Medium', serif",
  PPFragmentItalicSemibold: "'PP Fragment Italic Semibold', serif",
  PPFragmentItalicBold: "'PP Fragment Italic Bold', serif",
  PPFragmentItalicExtrabold: "'PP Fragment Italic Extrabold', serif",
  PPFragmentItalicBlack: "'PP Fragment Italic Black', serif"
};

export const FONTS = {
  "Sainte Colombe Italic": Typography.SCItalic,
  "Sainte Colombe Extra Light": Typography.SCExtraLight,
  "Sainte Colombe Extra Light Italic": Typography.SCExtraLightItalic,
  "Sainte Colombe Regular": Typography.SCRegular,
  "Beatrice Light": Typography.BeatriceLight,
  "Beatrice Thin": Typography.BeatriceThin,
  Beatrice: Typography.Beatrice,
  "Beatrice Medium": Typography.BeatriceMedium,
  "Beatrice Regular": Typography.BeatriceRegular,
  "Beatrice Semibold": Typography.BeatriceSemiBold,
  "Beatrice Bold": Typography.BeatriceBold,
  "Beatrice Extrabold": Typography.BeatriceExtraBold,
  "Beatrice Italic Thin": Typography.BeatriceItalicThin,
  "Beatrice Italic Light": Typography.BeatriceItalicLight,
  "Beatrice Italic Regular": Typography.BeatriceItalicRegular,
  "Beatrice Italic Medium": Typography.BeatriceItalicMedium,
  "Beatrice Italic Semibold": Typography.BeatriceItalicSemiBold,
  "Beatrice Italic Bold": Typography.BeatriceItalicBold,
  "Beatrice Italic Extrabold": Typography.BeatriceItalicExtraBold,
  "Caslon Roman": Typography.Caslon540Roman,
  "Caslon Italic": Typography.Caslon540Italic,
  "PP Fragment Thin": Typography.PPFragmentThin,
  "PP Fragment Extra Light": Typography.PPFragmentExtraLight,
  "PP Fragment Light": Typography.PPFragmentLight,
  "PP Fragment": Typography.PPFragment,
  "PP Fragment Medium": Typography.PPFragmentMedium,
  "PP Fragment Semibold": Typography.PPFragmentSemibold,
  "PP Fragment Bold": Typography.PPFragmentBold,
  "PP Fragment Extrabold": Typography.PPFragmentExtrabold,
  "PP Fragment Black": Typography.PPFragmentBlack,
  "PP Fragment Italic": Typography.PPFragmentItalic,
  "PP Fragment Italic Thin": Typography.PPFragmentItalicThin,
  "PP Fragment Italic Extra Light": Typography.PPFragmentItalicExtralight,
  "PP Fragment Italic Light": Typography.PPFragmentItalicLight,
  "PP Fragment Italic Regular": Typography.PPFragmentItalicRegular,
  "PP Fragment Italic Medium": Typography.PPFragmentItalicMedium,
  "PP Fragment Italic Semibold": Typography.PPFragmentItalicSemibold,
  "PP Fragment Italic Bold": Typography.PPFragmentItalicBold,
  "PP Fragment Italic Extrabold": Typography.PPFragmentItalicExtrabold,
  "PP Fragment Italic Black": Typography.PPFragmentItalicBlack,
  "Proxima Nova Semi Bold": Typography.ProximaNova,
  "Proxima Nova Regular": Typography.ProximaNova,
  "Proxima Nova Light": Typography.ProximaNovaLt,
  "Proxima Nova Thin": Typography.ProximaNovaLt
};

export const Breakpoints = {
  IPhoneSE: "375px",
  IPhone11: "414px",
  SmallMobile: "480px",
  DesktopMobile: "668px",
  IPad: "768px",
  IPhone11ProMax: "896px",
  LgTablet: "1023px",
  ScreenWidth: "1024px",
  IPhone14ProMax: "932px",
  Iphone14: "844px",
  DesktopMedium: "1280px",
  DesktopLarge: "1440px",
  DesktopXL: "1728px",

  XSMobileMin: "0px",
  XSMobileSmallMax: "375px",
  XSMobileMidMax: "376px",
  XSMobileMax: "767px",
  SMTabletMin: "768px",
  SMTabletMax: "1023px",
  MDLaptopMin: "1024px",
  MDLaptopMax: "1439px",
  LGDesktopMin: "1440px"
};

export const desktopMargin = {
  fullWidthwithMargin: "2px",
  wideMargin: "5px"
};

export const mobileMargin = {
  fullWidthwithMargin: "1px",
  wideMargin: "0.5%"
};

export const GridSpacing = {
  GridGutter: "24px",
  GridGutterMobile: "16px"
};

export const HiddenLabelStyles = css`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

export const GlobalCssValues = {
  MaxWidth: "1440px"
};

export const DEFAULT_AFFIRM_PAYMENT_MIN_TOTAL = 250;
export const DEFAULT_AFFIRM_PAYMENT_MAX_TOTAL = 17500;

export const SLIDE_OUT_MENU_DEBOUNCE_TIME = 185;
export const NO_IMAGE_AMPLIENCE_URL =
  "https://cdn.media.amplience.net/i/serenaandlily/noimagemedium";
