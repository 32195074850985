import React from "react";
import PropTypes from "prop-types";
import RichText from "../RichText";
import { useBreakpoint } from "../Breakpoints";

const BreakpointRichText = ({
  desktopMessage,
  tabletMessage,
  mobileMessage,
  countdownProps,
  ctaProps,
  gap,
  theme
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const isDesktopMed = breakpoints["DesktopMedium"];
  const specialMessage = {
    ...desktopMessage?.richText,
    richText: desktopMessage?.richText.richText
  };
  if (isMobile) {
    specialMessage["richText"] = mobileMessage?.richText;
  } else if (isDesktopMed && !isMobile) {
    specialMessage["richText"] = tabletMessage?.richText;
  }

  return (
    <RichText
      {...specialMessage}
      theme={theme}
      countdownProps={countdownProps}
      ctaProps={ctaProps}
      gap={gap}
    />
  );
};

export default BreakpointRichText;

BreakpointRichText.propTypes = {
  desktopMessage: PropTypes.object,
  tabletMessage: PropTypes.object,
  mobileMessage: PropTypes.object,
  countdownProps: PropTypes.object,
  ctaProps: PropTypes.object
};
