import { useEffect } from "react";
import useUser from "@chv1-serenaandlily/hooks/useUser";
import { datadogRum } from "@datadog/browser-rum";
import { useCart } from "@chv1-serenaandlily/contexts/CartContext";

const DataDogUser = () => {
  const { user } = useUser();
  const cart = useCart();
  const { isDSO, isDOO, employeeId, customerId, id, email, name, cartId } =
    user?.data || {};

  useEffect(() => {
    datadogRum?.setUser?.({
      ...(id && { id }),
      ...(name && { name }),
      ...(email && { email }),
      ...(customerId && { customerId }),
      cartId: cartId || cart?.cartId,
      isDso: isDSO || false,
      isTrade: isDOO || false,
      isEmployee: !!employeeId || false
    });
  }, [user, cart?.cartId]);

  return null;
};

export default DataDogUser;
