// see https://www.robinwieruch.de/react-uselocalstorage-hook/
import { useState, useEffect } from "react";
const useSessionStorage = (key, initialValue) => {
  const [value, setValue] = useState(
    (typeof window !== "undefined" &&
      JSON.parse(sessionStorage.getItem(key))) ??
      initialValue
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
};
export default useSessionStorage;
