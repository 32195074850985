import apolloClient from "@chv1-serenaandlily/gql/client";
import { buildSetPaymentInfoOnCartMutation } from "../mutations";
import getCart from "../responseMappers/cart";
import isObjectEmpty from "@chv1-utils/isObjectEmpty";

const updateCartPayments = async ({
  cardholderName,
  cartId,
  context,
  paymentAmount,
  paymentToken,
  processor,
  cardCheckNumber,
  storedPaymentId,
  nsStoredPaymentId,
  tokenType,
  maskedCard,
  cardType
}) => {
  if (!apolloClient) return;
  try {
    const SET_PAYMENT = buildSetPaymentInfoOnCartMutation();
    const variables = {
      cartPayments: {
        cartId,
        payments: [
          {
            ...(paymentAmount && {
              paymentAmount: Number(paymentAmount.replace(/[^0-9.-]+/g, ""))
            }),
            ...(processor && { processor }),
            ...(paymentToken && { paymentToken }),
            ...(cardholderName && { cardholderName }),
            ...(cardCheckNumber && { cardCheckNumber }),
            ...(storedPaymentId && { storedPaymentId }),
            ...(nsStoredPaymentId && { nsStoredPaymentId }),
            ...(tokenType && { tokenType }),
            ...(maskedCard && { maskedCard }),
            ...(cardType && { cardType })
          }
        ]
      }
    };

    const { data: responseData, errors } = await apolloClient.mutate({
      mutation: SET_PAYMENT,
      variables,
      context
    });
    const { authHeader, ...data } = responseData || {};

    if (
      process.env.NODE_ENV !== "production" &&
      process.env.CHECKOUT_DEBUG === "true"
    ) {
      console.log(SET_PAYMENT.loc.source.body);
      console.log(JSON.stringify(variables));
      console.log(data, errors);
    }

    if (!isObjectEmpty(data?.updateCartPayments)) {
      return {
        data: getCart({ cartId, ...data?.updateCartPayments }),
        authHeader,
        errors
      };
    }
    return { data: null, authHeader, errors };
  } catch (error) {
    // apollo client will throw if networkError is returned because of expired token
    return { data: null, authHeader: null, errors: error };
  }
};

export default updateCartPayments;
