import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultLoaderSize = 24;

const CaretDown = ({ height, width }) => {
  return (
    <IconSvg
      width={width || defaultLoaderSize}
      height={height || defaultLoaderSize}
      viewBox="0 0 24 24"
    >
      <path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
    </IconSvg>
  );
};

CaretDown.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default CaretDown;
