import { gql } from "@apollo/client";

const buildValidateAddressQuery = () => {
  return gql`
    query ValidateAddress($address: InputCartAddressItem) {
      validateAddress(address: $address) {
        addressType
        addressValidationStatus
        originalAddress {
          firstName
          lastName
          attention
          fullName
          companyName
          addressLine1
          addressLine2
          city
          stateProvince
          postalCode
          country
        }
        suggestedAddress {
          firstName
          lastName
          attention
          fullName
          companyName
          addressLine1
          addressLine2
          city
          stateProvince
          postalCode
          country
        }
      }
    }
  `;
};
export default buildValidateAddressQuery;
