import { Typography } from "../../constants/constants";

const defaultTheme = {
  message: {
    themeName: "text",
    fontFamily: Typography.BeatriceRegular,
    fontSize: "14px",
    fontSizeTablet: "14px",
    fontSizeDesktop: "14px"
  }
};

export default defaultTheme;
