const ICON_TYPES = {
  Account: "Account",
  AddedToBag: "AddedToBag",
  ArrowDownLight: "ArrowDownLight",
  ArrowFilled: "ArrowFilled",
  ArrowFilledWhite: "ArrowFilledWhite",
  ArrowIcon: "ArrowIcon",
  ArrowLeftLight: "ArrowLeftLight",
  ArrowRight: "ArrowRight",
  ArrowRightLight: "ArrowRightLight",
  ArrowUpLight: "ArrowUpLight",
  Bag: "Bag",
  CallUs: "CallUs",
  CaretDown: "CaretDown",
  CaretDownLight: "CaretDownLight",
  CaretLeftLight: "CaretLeftLight",
  CaretRight: "CaretRight",
  CaretRightLight: "CaretRightLight",
  CaretUp: "CaretUp",
  CaretUpLight: "CaretUpLight",
  Chat: "Chat",
  CheckboxCheck: "CheckboxCheck",
  CheckCircleOutline: "CheckCircleOutline",
  CircularLoader: "CircularLoader",
  Close: "Close",
  Customize: "Customize",
  Delivery: "Delivery",
  Drag: "Drag",
  FullscreenView: "FullscreenView",
  Heart: "Heart",
  HeartFull: "HeartFull",
  Ideas: "Ideas",
  InstallmentPlan: "InstallmentPlan",
  Minus: "Minus",
  MinusLight: "MinusLight",
  Menu: "Menu",
  Plus: "Plus",
  PlusLight: "PlusLight",
  ProfileIcon: "ProfileIcon",
  RemoveFromBag: "RemoveFromBag",
  QuestionMark: "QuestionMark",
  Returns: "Returns",
  Search: "Search",
  ShopMenu: "ShopMenu",
  Stores: "Stores",
  Warning: "Warning",
  PointLoader: "PointLoader"
};

export default ICON_TYPES;
