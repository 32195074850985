import React, { useMemo, useState, useRef, useEffect } from "react";
import Icons from "../Icons";
import {
  SMBCarouselContainer,
  SMBSlideContainer,
  SMBSlide,
  ArrowContainer,
  SMBArrow
} from "./SMBCarouselStyled";
import SMBCarouselContent from "./SMBCarouselContent";
import PropTypes from "prop-types";
import useGA4 from "@chv1-serenaandlily/hooks/analytics/useGA4";
import { GA4Events } from "@chv1-serenaandlily/hooks/analytics/GA4Events";
import { usePathname } from "next/navigation";
import useUser from "@chv1-lib/useUser";
import getCustomerType from "@chv1-serenaandlily/hooks/analytics/utils/getCustomerType";
import { getPathRoot } from "@chv1-serenaandlily/utils";
import { Colors } from "@chv1-serenaandlily/constants/constants";

const SMBCarousel = ({ slides = [], arrowColor }) => {
  const { trackGA4Event } = useGA4();
  const pathname = usePathname();
  const { user } = useUser();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [largestSlideWidth, setLargestSlideWidth] = useState(0);

  const slideContainerRef = useRef(null);
  const carouselContainerRef = useRef(null);

  const currentSlide = useMemo(
    () => slides?.[currentIndex],
    [slides, currentIndex]
  );

  useEffect(() => {
    if (!slideContainerRef?.current || !carouselContainerRef?.current) return;

    const updateLargestSlideWidth = () => {
      const slideRefs = slideContainerRef?.current?.children;
      if (!slideRefs || slideRefs.length === 0) {
        setLargestSlideWidth(0);
        return;
      }
      const largestSlideWidth = Math.max(
        ...Array.from(slideRefs).map((slide) => slide?.clientWidth || 0)
      );

      setLargestSlideWidth(largestSlideWidth);
    };

    const observer = new MutationObserver(updateLargestSlideWidth);
    observer.observe(slideContainerRef?.current, {
      childList: true,
      subtree: true
    });

    updateLargestSlideWidth();
    window.addEventListener("resize", updateLargestSlideWidth);
    const interval = setInterval(updateLargestSlideWidth, 500);

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", updateLargestSlideWidth);
      observer.disconnect();
    };
  }, [slides, setLargestSlideWidth]);

  useEffect(() => {
    if (!slides?.length) return;

    const timer = setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % slides?.length);
    }, currentSlide?.slideDuration || 1000);

    return () => clearTimeout(timer);
  }, [currentSlide, setCurrentIndex]);

  const goToPrevSlide = () => {
    setCurrentIndex((prev) => (prev === 0 ? slides?.length - 1 : prev - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prev) => (prev + 1) % slides?.length);
  };

  const triggerGA4 = ({ ga4Identifier }) => {
    trackGA4Event(GA4Events.smb_carousel_click, {
      ecommerce: {
        path: getPathRoot(pathname),
        smb_identifier: ga4Identifier,
        customerData: {
          user_logged_in: user?.data?.isLoggedIn
            ? "Logged In"
            : "Not Logged In",
          user_type: user?.data?.userType || "guest",
          customer_type: getCustomerType(user)
        }
      }
    });
  };

  if (!slides?.length) return null;

  return (
    <SMBCarouselContainer ref={carouselContainerRef}>
      <SMBSlideContainer ref={slideContainerRef}>
        {slides?.map?.((slide, index) => (
          <SMBSlide
            key={index}
            onClick={() => triggerGA4({ ga4Identifier: slide?.ga4Identifier })}
            active={index === currentIndex}
          >
            <SMBCarouselContent
              slideContent={slide?.slideContent}
              countdown={slide?.countdown}
              disclaimer={slide?.disclaimer}
              gap={slide?.gap || 5}
            />
          </SMBSlide>
        ))}
      </SMBSlideContainer>
      {slides?.length > 1 && (
        <ArrowContainer largestSlideWidth={largestSlideWidth}>
          <SMBArrow
            className="prev"
            aria-label="previous message"
            onClick={goToPrevSlide}
          >
            <Icons type="ChevronLeft" fill={arrowColor || Colors?.["White"]} />
          </SMBArrow>
          <SMBArrow
            className="next"
            aria-label="next message"
            onClick={goToNextSlide}
          >
            <Icons type="ChevronRight" fill={arrowColor || Colors?.["White"]} />
          </SMBArrow>
        </ArrowContainer>
      )}
    </SMBCarouselContainer>
  );
};

SMBCarousel.propTypes = {
  slides: PropTypes.array
};
export default SMBCarousel;
