import React from "react";
import PropTypes from "prop-types";
import RichTextContent from "./RichTextContent";
import useComponentTheme from "../Theme/useComponentTheme";
import RichTextWithCountdown from "../RichTextWithCountdown";

/**
 * RichText component displays markdown content. It is expected the markdown is
 * restricted to paragraphs. If this is expanded, up dated the styled components
 * to style child H1-6, etc.
 *
 * Usage:
 * name a typographic variant:
 * <RichText themeName="headline1" richText={richText} />
 *
 * pass in a typographic theme content type
 * <RichText theme={theme} richText={richText} />
 *
 * name a typographic type that should be merged with a theme:
 * <RichText themeName="headline1" theme={theme} richText={richText} />
 *
 * override specific properties of the named variant:
 * <RichText themeName="headline1" fontColor="blue" richText={richText} />
 *
 * @param richText
 * @param maxLen
 * @param themeName
 * @param theme
 * @param themeOverrides
 * @returns {*}
 * @constructor
 */

const RichText = ({
  richText,
  maxLen,
  themeName,
  theme,
  countdownProps,
  ctaProps,
  gap,
  ...themeOverrides
}) => {
  const richTextTheme = useComponentTheme({
    globalNamespace: "typography",
    themeName,
    theme,
    themeOverrides
  });

  return (
    <React.Fragment>
      {richText &&
        richText?.map((content, idx) => {
          const key = content?.type + idx;
          return countdownProps || ctaProps ? (
            <RichTextWithCountdown
              key={key}
              {...content}
              maxLen={maxLen}
              countdownProps={countdownProps}
              ctaProps={ctaProps}
              gap={gap}
              theme={richTextTheme}
            />
          ) : (
            <RichTextContent
              key={key}
              {...content}
              maxLen={maxLen}
              theme={richTextTheme}
            />
          );
        })}
    </React.Fragment>
  );
};

export default RichText;

RichText.propTypes = {
  richText: PropTypes.array,
  themeName: PropTypes.string,
  theme: PropTypes.object,
  countdownProps: PropTypes.object,
  ctaProps: PropTypes.object,
  maxLen: PropTypes.number
};
