export default function getAddressFormValues(addressBookAddress) {
  const {
    addressNickname = "",
    companyName = "",
    firstName = "",
    lastName = "",
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    stateProvince = "",
    postalCode = "",
    country = "",
    addressee = ""
  } = addressBookAddress;
  return {
    addressNickname,
    companyName: companyName || addressee,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    stateProvince,
    postalCode,
    country
  };
}
