import styled from "styled-components";
import { TypographyWrapper } from "../Typography/TypographyStyled";
import getDynamicSizeValue from "@serenaandlily/utils/getDynamicSizeValue";

export const MarkdownContainer = styled(TypographyWrapper)`
  ${({ theme }) => `
    margin-bottom: ${
      theme?.scaleBottomMargin ? 0 : `${theme?.marginBottom ?? 0}px`
    };
    p {
      margin-top: 0;
      margin-bottom: ${theme?.marginBottom ?? 0}px;
      ${
        theme?.scaleBottomMargin
          ? getDynamicSizeValue({
              attr: "margin-bottom",
              size: theme?.scaledBottomMarginMobile,
              sizeTablet: theme?.scaledBottomMarginTablet,
              sizeDesktop: theme?.scaledBottomMarginDesktop
            })
          : `${theme?.marginBottom ?? 11}px`
      };
      ${
        theme.scaleFontSize &&
        getDynamicSizeValue({
          attr: "font-size",
          size: theme?.fontSize,
          sizeTablet: theme?.fontSizeTablet,
          sizeDesktop: theme?.fontSizeDesktop
        })
      };
      a {
        color: ${theme?.fontColor};
      }
    }
  `}
`;
