import { formatAsCurrency } from "@chv1-utils";

const getDiscounted = (a, b) => ((a?.amount || 0) < (b?.amount || 0) ? a : b);
const getList = (a, b) => ((a?.amount || 0) < (b?.amount || 0) ? b : a);

const getPrices = ({ prices = [] }) => {
  const res = {
    designer: "",
    discounted: "",
    dso: "",
    sale: "",
    list: ""
  };

  const pricesArray = prices || [];
  pricesArray.forEach((p) => {
    res[p?.type] = {
      formatted: p?.formatted || "",
      amount: parseInt(p?.amount || 0)
    };
  });

  if (res.dso) {
    if (res.discounted) res.discounted = getDiscounted(res.discounted, res.dso);
    if (res.list) res.list = getList(res.list, res.dso);
  }

  return res;
};

export default function (respCartItem) {
  if (!respCartItem) return;
  const { displayPrice, ...rest } = respCartItem;
  const { prices, totalPrice } = displayPrice;
  const _prices = getPrices({ prices });
  return {
    displayPrice: {
      ...displayPrice,
      designerPrice: _prices?.designer?.formatted || "",
      discountedPrice: _prices?.discounted?.formatted || "",
      priceWithoutTax: formatAsCurrency(totalPrice) || "",
      salePrice: _prices?.sale?.formatted || "",
      unitPrice: _prices?.list?.formatted || ""
    },
    ...rest
  };
}
