import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo
} from "react";
import PropType from "prop-types";
import { useRouter } from "next/router";
import logSailthruPurchase from "../../utils/sailthru/purchase/logPurchase";
import getPepperjamSegmentId from "../../utils/pepperjam/getSegmentId";
import { default as useUser } from "../../hooks/useUser";
import { sailthruAddToCart } from "../../utils";
import {
  productBrowsed as agiloneProductBrowsed,
  emailSubscribe as agiloneEmailSubscribe,
  checkout as agiloneCheckout,
  addToCart as agiloneAddToCart
} from "../../utils/agilone";
import fbEvents from "../../gql/utils/fbEvents";
import axios from "axios";
import {
  pushEventToDataLayer,
  setPayload,
  setUserData,
  setViewContent
} from "../../utils/facebookCAPI/libFacebook";
import { pushTrackEvent } from "../../utils/pepperjam/pepperjam";
import currency from "currency.js";
import { VIEWCONTENT_PAGES } from "../../constants/analytics/viewContentPagesMaping";
import createUUID from "../../utils/createUUID/createUUID";
import { pushToDatalayer } from "../../utils/analyticEvents";

const MarketingContext = createContext();

const MarketingProvider = ({ children }) => {
  const [agiloneLoaded, setAgiloneLoaded] = useState(false);
  const router = useRouter();

  const user = useUser();

  const shouldTrackViewContent = useMemo(() => {
    return VIEWCONTENT_PAGES.includes(
      router?.asPath?.split("/")[1].split("?")[0]
    );
  }, [router?.asPath]);

  useEffect(() => {
    if (
      router?.isReady &&
      user?.isUserLoading === false &&
      shouldTrackViewContent
    ) {
      const eventId = createUUID();
      const facebookEvent = "ViewContent";
      pushEventToDataLayer({
        facebookEvent,
        eventId
      });
    }
  }, [router?.isReady, user?.isUserLoading, shouldTrackViewContent]);

  const fbBuildProductEvent = async ({
    email,
    firstName,
    lastName,
    productId,
    productName,
    facebookEvent,
    price,
    orderId,
    city,
    zipCode,
    phoneNumber
  }) => {
    const eventId = createUUID();
    const response = await axios.get("/api/user-ip");
    const ipAddress = response.data.ip;
    const userData = setUserData({
      email,
      ipAddress,
      firstName,
      lastName,
      city,
      zipCode,
      phoneNumber
    });
    const viewContentData = setViewContent({
      id: productId,
      name: productName
    });
    if (orderId) {
      viewContentData.value = currency(price);
      viewContentData.currency = "USD";
      viewContentData.orderId = orderId;
      pushEventToDataLayer({
        facebookEvent: "Purchase",
        eventId
      });
    }
    if (price && !orderId) {
      viewContentData.value = price;
      viewContentData.currency = "USD";
      pushEventToDataLayer({
        facebookEvent: "AddToCart",
        eventId,
        product: {
          productId,
          productName
        }
      });
    }
    const fbPayload = setPayload({
      facebookEvent,
      eventURL: window.location.href,
      userData,
      eventId,
      customData: viewContentData
    });
    await fbEvents({
      ...fbPayload
    });
  };

  const handleAgiloneLoad = (isLoaded) => {
    setAgiloneLoaded(isLoaded);
  };

  const handleTrackProductBrowsed = (netsuiteId, customerEmail) => {
    agiloneProductBrowsed(netsuiteId, customerEmail);
  };
  const handleTrackEmailSubscription = (customerEmail, source) => {
    agiloneEmailSubscribe(customerEmail);
    pushTrackEvent({ segment: getPepperjamSegmentId(source) });
  };
  const trackFormSubmission = (source) => {
    pushTrackEvent({ segment: getPepperjamSegmentId(source) });
  };
  const handleTrackPurchase = async (order, user) => {
    logSailthruPurchase(order, user);
    agiloneCheckout(order, user?.email);
    fbBuildProductEvent({
      email: order?.email,
      firstName: user?.firstName,
      lastName: user?.lastName,
      orderId: order?.orderId,
      price: order?.priceSummary?.subtotal,
      phoneNumber: order?.phoneNumber,
      city: order?.billingAddress?.city,
      zipCode: order?.billingAddress?.postalCode,
      facebookEvent: "purchase"
    });
  };
  const handleTrackAddToCart = (cart, customerEmail) => {
    if (customerEmail && customerEmail !== "") {
      sailthruAddToCart(cart, customerEmail);
    }
    const productIds = cart?.cartItems.map((item) => item?.netsuiteId);
    agiloneAddToCart(productIds, customerEmail);
  };
  const handleLogSlideoutClick = ({
    category,
    subCategory,
    subcategoryTwo,
    subcategoryThree
  }) => {
    pushToDatalayer({
      event: "sidebar-navigation-click",
      payload: {
        navigationCategory: category,
        navigationSubcategory: subCategory,
        navigationSubcategory2: subcategoryTwo,
        navigationSubcategory3: subcategoryThree
      }
    });
  };

  return (
    <MarketingContext.Provider
      value={{
        agiloneLoaded,
        handleAgiloneLoad,
        fbBuildProductEvent,
        handleTrackProductBrowsed,
        handleTrackEmailSubscription,
        handleTrackPurchase,
        handleTrackAddToCart,
        trackFormSubmission,
        handleLogSlideoutClick
      }}
    >
      {children}
    </MarketingContext.Provider>
  );
};

function useMarketing() {
  const context = useContext(MarketingContext);
  if (context) {
    return context;
  } else {
    //eslint-disable-next-line  no-console
    console.warn("Missing MarketingProvider for useMarketing");
    return {};
  }
}

export { MarketingProvider, useMarketing };

MarketingProvider.propTypes = {
  children: PropType.any,
  user: PropType.object
};
