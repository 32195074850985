import { useEffect } from "react";
import useUser from "@serenaandlily/hooks/useUser";
import { datadogRum } from "@datadog/browser-rum";
import { useCart } from "@serenaandlily/contexts/CartContext";

const DataDogUser = () => {
  const { user } = useUser();
  const cart = useCart();
  const {
    isDSO,
    isDOO,
    employeeId,
    customerId,
    id,
    email,
    name,
    cartId,
    isLoggedIn
  } = user?.data || {};

  useEffect(() => {
    datadogRum?.setUser?.({
      ...(id && { id }),
      ...(name && { name }),
      ...(email && { email }),
      ...(customerId && { customerId }),
      cartId: cartId || cart?.cartId,
      isDso: isDSO || false,
      isTrade: isDOO || false,
      isEmployee: !!employeeId || false,
      isGuest: !isLoggedIn ? true : false,
      isRegistered: isLoggedIn && !isDSO && !isDOO ? true : false
    });
  }, [user, cart?.cartId]);

  useEffect(() => {
    if ((email && email.endsWith("serenaandlily.com")) || isDOO) {
      datadogRum.startSessionReplayRecording({
        force: true
      });
    }

    if (
      location.pathname.includes("/checkout") ||
      location.pathname.includes("/shoppingbag")
    ) {
      if (!isLoggedIn) {
        if (
          Math.random() <
          parseFloat(
            process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_RATE_GUEST_CHECKOUT
          )
        ) {
          datadogRum.startSessionReplayRecording({
            force: true
          });
        }
      }
    }
  }, [user]);

  return null;
};

export default DataDogUser;
