import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 24;

const FigmaClose = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path d="M6 6L18 18M18 6L6 18" stroke="#243953" stroke-linecap="round" />
    </IconSvg>
  );
};

FigmaClose.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default FigmaClose;
