import { z } from "zod";
import { GA4Events } from "../GA4Events";

export const LOGIN_SCHEMA = z
  .object({
    event: z.literal(GA4Events.login),
    method: z.string().default("email")
  })
  .strict();

export const LOGIN_ATTEMPT_SCHEMA = z
  .object({
    event: z.literal(GA4Events.login_attempt),
    method: z.string().default("email")
  })
  .strict();

export const LOGIN_FAILURE_SCHEMA = z
  .object({
    event: z.literal(GA4Events.login_failure),
    method: z.string().default("email"),
    failure_reason: z.string(),
    page_type: z.string()
  })
  .strict();

export const LOGOUT_SCHEMA = z
  .object({
    event: z.literal(GA4Events.logout),
    method: z.string().default("email")
  })
  .strict();

export const SIGN_UP_SCHEMA = z
  .object({
    event: z.literal(GA4Events.sign_up),
    method: z.string().default("email")
  })
  .strict();

export const SIGN_UP_ATTEMPT_SCHEMA = z
  .object({
    event: z.literal(GA4Events.sign_up_attempt),
    method: z.string().default("email")
  })
  .strict();

export const SIGN_UP_FAILURE_SCHEMA = z
  .object({
    event: z.literal(GA4Events.sign_up_failure),
    method: z.string().default("email"),
    failure_reason: z.string(),
    page_type: z.string()
  })
  .strict();
