import apolloClient from "../client";
import buildAddCustomerMutation from "../mutations/buildAddCustomerMutation";

const addCustomer = async (customer) => {
  if (!apolloClient) return;
  const ADD_CUSTOMER = buildAddCustomerMutation();
  const response = await apolloClient.mutate({
    mutation: ADD_CUSTOMER,
    variables: {
      customer: { ...customer }
    },
    context: { clientName: "endpoint2" }
  });
  return response;
};

export default addCustomer;
