import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultLoaderSize = 24;

const CaretUpLightSummary = ({ height, width }) => {
  return (
    <IconSvg
      width={width || defaultLoaderSize}
      height={height || defaultLoaderSize}
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3536 14.3536C17.5488 14.1583 17.5488 13.8417 17.3536 13.6464L12.3536 8.64645C12.1583 8.45118 11.8417 8.45118 11.6464 8.64645L6.64645 13.6464C6.45118 13.8417 6.45118 14.1583 6.64645 14.3536C6.84171 14.5488 7.15829 14.5488 7.35355 14.3536L12 9.70711L16.6464 14.3536C16.8417 14.5488 17.1583 14.5488 17.3536 14.3536Z"
        fill="#243953"
      />
    </IconSvg>
  );
};

CaretUpLightSummary.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default CaretUpLightSummary;
