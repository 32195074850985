import React, { useEffect } from "react";
import "../styles/globals.css";
import {
  BreakpointProvider,
  QUERIES
} from "@serenaandlily/components/Breakpoints";
import { ErrorBoundary } from "react-error-boundary";
import GlobalPopup from "@serenaandlily/components/GlobalPopup";
import { GlobalPopupProvider } from "@serenaandlily/components/GlobalPopup/GlobalPopupContext";
import { CartProvider } from "@serenaandlily/contexts/CartContext";
import { GlobalProductsProvider } from "@serenaandlily/contexts/GlobalProductsContext";
import apolloClient from "@serenaandlily/gql/client";
import { AccountProvider } from "@serenaandlily/contexts/AccountContext";
import { SearchProvider } from "@serenaandlily/contexts/SearchContext";
import ThemeProvider from "@serenaandlily/components/Theme/ThemeProvider";
import { UserProvider } from "@serenaandlily/contexts/UserContext";
import { MarketingProvider } from "@serenaandlily/contexts/MarketingContext";
import { ContentProvider } from "@serenaandlily/contexts/ContentContext";
import { SlideOutMenuProvider } from "@serenaandlily/contexts/SlideOutMenuContext";
import { SidePanelProvider } from "@serenaandlily/contexts/SidePanelContext";
import { ProductDiscountProvider } from "@serenaandlily/contexts/ProductDiscountContext";
import { ProductProvider } from "@serenaandlily/contexts/ProductContext";
import Head from "next/head";
import { ApolloProvider } from "@apollo/client";
import Script from "next/script";
import affirmScript from "@utils/affirmScript/affirmScript";
import { loadAnalytics } from "@utils";
import { mPulseSnippet } from "@constants";
import "react-datepicker/dist/react-datepicker.css";
import { SWRConfig } from "swr";
import axios from "axios";
import sailthruInit from "@components/Sailthru";
import useUser from "@lib/useUser";
import SiftScript from "@components/SiftScript";
import AgiloneScript from "@components/AgiloneScript";
import ZendeskWidgetScript from "@components/ZendeskWidgetScript";
import { ReCaptchaProvider } from "next-recaptcha-v3";
import yotpoScript from "@utils/yotpoScript/yotpoScript";
import { WishlistProvider } from "@serenaandlily/contexts/WishlistContext";
import { ConstructorScript } from "@utils/ConstructorHelpers/constructorScript";
import { Provider as JotaiProvider } from "jotai";
import onErrorRetryHandler from "@utils/swr/onErrorRetryHandler";
import { useRouter } from "next/router";
import { SlideInProvider } from "@serenaandlily/contexts/SlideInContext";
import ApplicationErrorOverlay from "@serenaandlily/layouts/ApplicationErrorOverlay";
import logApplicationError from "@serenaandlily/utils/logApplicationError";
import DataDogUser from "@components/DataDog/DataDogUser";

import { IS_DEV } from "@utils/environment/environmentCheck";
import { monitorGA4Events } from "@serenaandlily/hooks/analytics/GA4Devtool";
import { OptimizelyScript } from "@components";
import DataDogInit from "@components/DataDog/DataDogInit";
import { setUserGeolocation } from "@utils/getUserLocation/setUserGeolocation";

// Checkout V1 Imports
import ThemeProviderV1 from "@chv1-serenaandlily/components/Theme/ThemeProvider";
import { ContentProvider as ContentProviderV1 } from "@chv1-serenaandlily/contexts/ContentContext";
import { UserProvider as UserProviderV1 } from "@chv1-serenaandlily/contexts/UserContext";
import { ProductDiscountProvider as ProductDiscountProviderV1 } from "@chv1-serenaandlily/contexts/ProductDiscountContext";
import { MarketingProvider as MarketingProviderV1 } from "@chv1-serenaandlily/contexts/MarketingContext";
import { GlobalProductsProvider as GlobalProductsProviderV1 } from "@chv1-serenaandlily/contexts/GlobalProductsContext";
import { AccountProvider as AccountProviderV1 } from "@chv1-serenaandlily/contexts/AccountContext";
import { SearchProvider as SearchProviderV1 } from "@chv1-serenaandlily/contexts/SearchContext";
import { CartProvider as CartProviderV1 } from "@chv1-serenaandlily/contexts/CartContext";
import { BreakpointProvider as BreakpointProviderV1 } from "@chv1-serenaandlily/components/Breakpoints";
import { SlideOutMenuProvider as SlideOutMenuProviderV1 } from "@chv1-serenaandlily/contexts/SlideOutMenuContext";
import { ProductProvider as ProductProviderV1 } from "@chv1-serenaandlily/contexts/ProductContext";
import { SidePanelProvider as SidePanelProviderV1 } from "@chv1-serenaandlily/contexts/SidePanelContext";
import { GlobalPopupProvider as GlobalPopupProviderV1 } from "@chv1-serenaandlily/components/GlobalPopup/GlobalPopupContext";
import { SlideInProvider as SlideInProviderV1 } from "@chv1-serenaandlily/contexts/SlideInContext";
import { WishlistProvider as WishlistProviderV1 } from "@chv1-serenaandlily/contexts/WishlistContext";

import GlobalPopupV1 from "@chv1-serenaandlily/components/GlobalPopup";
import ZendeskWidgetScriptV1 from "@chv1-components/ZendeskWidgetScript";
import AgiloneScriptV1 from "@chv1-components/AgiloneScript";
import DataDogUserV1 from "@chv1-components/DataDog/DataDogUser";

if (process.env.API_SW_MOCK === "enabled") {
  import("../mocks");
}

if (!globalThis?.process?.env) {
  loadAnalytics.default(document.body);
  // TODO this needs to be put in GTM so we have a code bottleneck
  // that we can change on the live site
  document.body.addEventListener("analyze-ecom-event", (e) => {
    const { description, ...rest } = e.detail;
    let payload;
    if (description === "customAttributes") {
      payload = { ...rest };
    } else {
      payload = {
        event: description,
        ...rest
      };
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(payload);
  });
}

// eslint-disable-next-line max-lines-per-function, complexity
function MyApp({ Component, pageProps }) {
  const {
    initialCrossSellTiles = {},
    initialProductTiles = {},
    initialProducts = {},
    isCartPage,
    siteTheme,

    // Content for content provider
    cartHeadingData,
    checkoutRegistration,
    confirmationHeadline,
    confirmationInStoreHeadline,
    formMessages,
    giftCardMessage,
    messages: pageMessages,
    needHelp,
    promoCode,
    affirmMessages,
    siteMessages,
    taxExemptSummary,
    dnsuNonElegibleProductMessage,
    dsoShoppingBagPayAndCarryOptionsHeadline,
    estimatedShippingHeadline,
    eddShippingTooltipHeadline,
    appliedPromoAmplienceOverrides,
    consolidationMessageHeadline,
    // everything else
    ...rest
  } = pageProps;

  const DD_IS_ACTIVE = process.env.NEXT_PUBLIC_DATADOME_IS_ACTIVE === "true";
  const DD_CLIENT_KEY = process.env.NEXT_PUBLIC_DATADOME_CLIENT_SIDE_KEY || "";

  const router = useRouter();

  const checkoutVersion = router?.pathname?.includes("/v1") ? 1 : 2;

  useEffect(() => {
    if (IS_DEV) {
      monitorGA4Events();
    }
  }, []);

  useEffect(() => {
    const handlePageCountIncrement = () => {
      const currentCount = parseInt(
        localStorage.getItem("pageCount") || "0",
        10
      );
      localStorage.setItem("pageCount", currentCount + 1);
    };

    router.events.on("routeChangeComplete", handlePageCountIncrement);

    handlePageCountIncrement();

    return () => {
      router.events.off("routeChangeComplete", handlePageCountIncrement);
    };
  }, [router.events]);

  useEffect(() => {
    globalThis?.document?.body?.dispatchEvent(new CustomEvent("a-cart"));
    setUserGeolocation();
  }, []);
  const user = useUser();

  // merge site messages with page messages
  const messages = {
    ...siteMessages,
    ...pageMessages,
    ...(pageProps?.globalContent?.siteMessages || {})
  };

  const renderCheckoutVersion = () => {
    if (checkoutVersion === 2) {
      return (
        <JotaiProvider>
          <ReCaptchaProvider src={process.env.NEXT_PUBLIC_RECAPTCHA_SOURCE}>
            <ThemeProvider theme={siteTheme}>
              <ContentProvider
                cartHeadingData={cartHeadingData}
                checkoutRegistration={checkoutRegistration}
                confirmationHeadline={confirmationHeadline}
                confirmationInStoreHeadline={confirmationInStoreHeadline}
                formMessages={formMessages}
                giftCardMessage={giftCardMessage}
                messages={messages}
                needHelp={needHelp}
                promoCode={promoCode}
                affirmMessages={affirmMessages}
                taxExemptSummary={taxExemptSummary}
                pdpSettings={pageProps?.globalContent?.pdpSettings}
                siteGlobals={pageProps?.globalContent?.siteGlobals}
                siteConfigs={pageProps?.globalContent?.siteConfigs}
                wishlistConfigs={pageProps?.globalContent?.wishlistConfigs}
                siteMessages={pageProps?.globalContent?.siteMessages}
                dnsuNonElegibleProductMessage={dnsuNonElegibleProductMessage}
                dsoShoppingBagPayAndCarryOptionsHeadline={
                  dsoShoppingBagPayAndCarryOptionsHeadline
                }
                estimatedShippingHeadline={estimatedShippingHeadline}
                eddShippingTooltipHeadline={eddShippingTooltipHeadline}
                consolidationMessageHeadline={consolidationMessageHeadline}
                appliedPromoAmplienceOverrides={appliedPromoAmplienceOverrides}
              >
                <UserProvider user={user}>
                  <ProductDiscountProvider>
                    <MarketingProvider>
                      <AgiloneScript />
                      <GlobalProductsProvider
                        initialProducts={initialProducts}
                        initialProductTiles={initialProductTiles}
                        initialCrossSellTiles={initialCrossSellTiles}
                      >
                        <AccountProvider>
                          <SearchProvider>
                            <CartProvider
                              apolloClient={apolloClient}
                              isCartPage={isCartPage}
                            >
                              <DataDogUser />
                              <BreakpointProvider queries={QUERIES}>
                                <WishlistProvider>
                                  <SlideOutMenuProvider>
                                    <ProductProvider>
                                      <SlideInProvider>
                                        <SidePanelProvider>
                                          <GlobalPopupProvider>
                                            <ZendeskWidgetScript />
                                            <Component {...rest} />
                                            <GlobalPopup />
                                          </GlobalPopupProvider>
                                        </SidePanelProvider>
                                      </SlideInProvider>
                                    </ProductProvider>
                                  </SlideOutMenuProvider>
                                </WishlistProvider>
                              </BreakpointProvider>
                            </CartProvider>
                          </SearchProvider>
                        </AccountProvider>
                      </GlobalProductsProvider>
                    </MarketingProvider>
                  </ProductDiscountProvider>
                </UserProvider>
              </ContentProvider>
            </ThemeProvider>
          </ReCaptchaProvider>
        </JotaiProvider>
      );
    }

    return (
      <JotaiProvider>
        <ReCaptchaProvider src={process.env.NEXT_PUBLIC_RECAPTCHA_SOURCE}>
          <ThemeProviderV1 theme={siteTheme}>
            <ContentProviderV1
              cartHeadingData={cartHeadingData}
              checkoutRegistration={checkoutRegistration}
              confirmationHeadline={confirmationHeadline}
              confirmationInStoreHeadline={confirmationInStoreHeadline}
              formMessages={formMessages}
              giftCardMessage={giftCardMessage}
              messages={messages}
              needHelp={needHelp}
              promoCode={promoCode}
              affirmMessages={affirmMessages}
              taxExemptSummary={taxExemptSummary}
              pdpSettings={pageProps?.globalContent?.pdpSettings}
              siteGlobals={pageProps?.globalContent?.siteGlobals}
              siteConfigs={pageProps?.globalContent?.siteConfigs}
              wishlistConfigs={pageProps?.globalContent?.wishlistConfigs}
              siteMessages={pageProps?.globalContent?.siteMessages}
              dnsuNonElegibleProductMessage={dnsuNonElegibleProductMessage}
              dsoShoppingBagPayAndCarryOptionsHeadline={
                dsoShoppingBagPayAndCarryOptionsHeadline
              }
              estimatedShippingHeadline={estimatedShippingHeadline}
              eddShippingTooltipHeadline={eddShippingTooltipHeadline}
              consolidationMessageHeadline={consolidationMessageHeadline}
              appliedPromoAmplienceOverrides={appliedPromoAmplienceOverrides}
            >
              <UserProviderV1 user={user}>
                <ProductDiscountProviderV1>
                  <MarketingProviderV1>
                    <AgiloneScriptV1 />
                    <GlobalProductsProviderV1
                      initialProducts={initialProducts}
                      initialProductTiles={initialProductTiles}
                      initialCrossSellTiles={initialCrossSellTiles}
                    >
                      <AccountProviderV1>
                        <SearchProviderV1>
                          <CartProviderV1>
                            <DataDogUserV1 />
                            <BreakpointProviderV1 queries={QUERIES}>
                              <WishlistProviderV1>
                                <SlideOutMenuProviderV1>
                                  <ProductProviderV1>
                                    <SlideInProviderV1>
                                      <SidePanelProviderV1>
                                        <GlobalPopupProviderV1>
                                          <ZendeskWidgetScriptV1 />
                                          <Component {...rest} />
                                          <GlobalPopupV1 />
                                        </GlobalPopupProviderV1>
                                      </SidePanelProviderV1>
                                    </SlideInProviderV1>
                                  </ProductProviderV1>
                                </SlideOutMenuProviderV1>
                              </WishlistProviderV1>
                            </BreakpointProviderV1>
                          </CartProviderV1>
                        </SearchProviderV1>
                      </AccountProviderV1>
                    </GlobalProductsProviderV1>
                  </MarketingProviderV1>
                </ProductDiscountProviderV1>
              </UserProviderV1>
            </ContentProviderV1>
          </ThemeProviderV1>
        </ReCaptchaProvider>
      </JotaiProvider>
    );
  };

  return (
    <React.Fragment>
      <ErrorBoundary
        fallback={<ApplicationErrorOverlay />}
        onError={logApplicationError}
      >
        <DataDogInit />
        <ApolloProvider client={apolloClient}>
          <Head>
            <link rel="shortcut icon" href="/favicon-32x32.png" />
            <link
              rel="preload"
              as="style"
              href="https://use.typekit.net/vsh6rrg.css"
            />
            <link rel="stylesheet" href="https://use.typekit.net/vsh6rrg.css" />
            {/* stop mobile devices from shrinking page when slideout is open */}
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no"
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_KEY}');`
              }}
            />
            {DD_IS_ACTIVE && (
              <>
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                window.ddjskey = "${DD_CLIENT_KEY}";  
                window.ddoptions = { 
                  ajaxListenerPath: [
                    { host: location.host },
                    { host: 'serenaandlily.com' }
                  ],
                  disableAutoRefreshOnCaptchaPassed: true,
                  sessionByHeader: true
                  };`
                  }}
                />

                <script
                  src="https://dd.serenaandlily.com/tags.js"
                  async={true}
                />
              </>
            )}
          </Head>

          {process.env.NEXT_PUBLIC_AKAMAI_PIM_ENABLED === "2" && (
            <script src="https://p11.techlab-cdn.com/6410a77779594e00132b6c9a.js" />
          )}

          {process.env.OSANO_COOKIE_SCRIPT_ENABLED === "true" && (
            <script src={process.env.OSANO_COOKIE_SCRIPT} />
          )}

          <ConstructorScript id={pageProps?.constructorId} />
          <Script
            src="https://ak.sail-horizon.com/spm/spm.v1.min.js"
            onReady={sailthruInit}
          ></Script>
          <Script
            id="affirm-script"
            dangerouslySetInnerHTML={{
              __html: affirmScript(
                pageProps?.globalContent?.siteConfigs?.configAffirm
              )
            }}
            strategy="lazyOnload"
          />
          <Script
            id="yotpo-script"
            dangerouslySetInnerHTML={{
              __html: yotpoScript
            }}
            strategy="lazyOnload"
          />
          <SiftScript />

          <BreakpointProvider queries={QUERIES}>
            <OptimizelyScript />
          </BreakpointProvider>

          <SWRConfig
            value={{
              fetcher: async (input) => await axios(input),
              onError: (err) => {
                //eslint-disable-next-line no-console
                console.error(err);
              },
              onErrorRetry: onErrorRetryHandler
            }}
          >
            {renderCheckoutVersion()}
          </SWRConfig>

          {process.env.NEXT_PUBLIC_MPULSE_ENABLED === "TRUE" && (
            <Script id="mpulse-script">{mPulseSnippet}</Script>
          )}
        </ApolloProvider>
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default MyApp;
