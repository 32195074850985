const getPriceDiscount = ({ wasPriceRange, nowPriceRange }) => {
  const removeDollarSignAndConvertToNumber = (str) => {
    if (!str) return 0;
    return parseFloat(str.replace(/^\$/, "").replace(/,/g, ""));
  };

  if (
    !wasPriceRange?.min ||
    !nowPriceRange?.min ||
    typeof wasPriceRange?.min !== "string" ||
    typeof nowPriceRange?.min !== "string" ||
    (wasPriceRange?.max && typeof wasPriceRange?.max !== "string") ||
    (nowPriceRange?.max && typeof nowPriceRange?.max !== "string")
  ) {
    return {
      isUpTo: false,
      value: 0
    };
  }

  const wasMinPrice = removeDollarSignAndConvertToNumber(wasPriceRange.min);
  const nowMinPrice = removeDollarSignAndConvertToNumber(nowPriceRange.min);

  const wasMaxPrice = wasPriceRange.max
    ? removeDollarSignAndConvertToNumber(wasPriceRange.max)
    : wasMinPrice;
  const nowMaxPrice = nowPriceRange.max
    ? removeDollarSignAndConvertToNumber(nowPriceRange.max)
    : nowMinPrice;

  const minDiscountPercentage =
    ((wasMinPrice - nowMinPrice) / wasMinPrice) * 100;
  const maxDiscountPercentage =
    ((wasMaxPrice - nowMaxPrice) / wasMaxPrice) * 100;

  const higherDiscountPercentage = Math.max(
    minDiscountPercentage,
    maxDiscountPercentage
  );
  const isUpTo = (!!wasPriceRange?.max && !!nowPriceRange?.max) || (!!nowPriceRange?.min && !!nowPriceRange?.max);

  return {
    isUpTo,
    value: parseInt(Math.round(higherDiscountPercentage))
  };
};

export default getPriceDiscount;
