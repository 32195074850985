import React from "react";

const CaretUpCheckout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7071 16.7071C23.0976 16.3166 23.0976 15.6834 22.7071 15.2929L12.7071 5.29289C12.3166 4.90237 11.6834 4.90237 11.2929 5.29289L1.29289 15.2929C0.902369 15.6834 0.902369 16.3166 1.29289 16.7071C1.68342 17.0976 2.31658 17.0976 2.70711 16.7071L12 7.41421L21.2929 16.7071C21.6834 17.0976 22.3166 17.0976 22.7071 16.7071Z"
        fill="#243953"
      />
    </svg>
  );
};

export default CaretUpCheckout;
