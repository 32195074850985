import React from "react";
import PropTypes from "prop-types";
import { IconSvg, Notification, NotificationIconWrapper } from "../IconsStyled";


const defaultSize = 20;

const Customize = ({ height, width, fill = "currentColor", notification }) => {
  if(notification) {
    return (
      <NotificationIconWrapper>
        <IconSvg
          width={width || defaultSize}
          height={height || defaultSize}
          fill={fill}
          viewBox="0 0 20 20"
        >
          <path d="M14.728 9h-1.174a2.501 2.501 0 0 1 4.641.144A5.007 5.007 0 0 0 17 9h-.036a1.497 1.497 0 0 0-2.236 0Zm-6.91.5a5 5 0 0 0-1.389 1H2a.5.5 0 0 1 0-1h5.818Z"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M3.5 6a2.5 2.5 0 0 0 2.45-2.003A.42.42 0 0 0 6 4h12a.5.5 0 0 0 0-1H6l-.05.002A2.5 2.5 0 1 0 3.5 6Zm0-1a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 3.5 5Zm.002 14.074a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0-1a1.5 1.5 0 1 0-.001-3.001 1.5 1.5 0 0 0 .001 3.001Z"/>
        </IconSvg>
        <Notification fill={fill} left="35%" top="50%"><span>{notification}</span></Notification>
      </NotificationIconWrapper>
    );
  } else {
    return (
      <IconSvg
        width={width || defaultSize}
        height={height || defaultSize}
        fill={fill}
        viewBox="0 0 20 20"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M3.5 6a2.5 2.5 0 0 0 2.45-2.002L6 4h12a.5.5 0 0 0 0-1H6l-.05.002A2.5 2.5 0 1 0 3.5 6Zm0-1a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 3.5 5Zm0 14.052a2.5 2.5 0 0 0 2.45-2.002l.05.002h12a.5.5 0 0 0 0-1H6l-.05.002a2.5 2.5 0 1 0-2.45 2.998Zm0-1a1.5 1.5 0 1 0-.001-3.001 1.5 1.5 0 0 0 .001 3.001Zm14.846-8.026a2.5 2.5 0 0 1-4.951.498.803.803 0 0 1-.05.002H2a.5.5 0 0 1 0-1h11.345l.05.002a2.501 2.501 0 0 1 4.951.498Zm-1 0a1.5 1.5 0 1 1-3.001-.001 1.5 1.5 0 0 1 3.001.001Z"/>
      </IconSvg>
    );
  }
};

Customize.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  notification: PropTypes.number,
  width: PropTypes.number,
};

export default Customize;
