import React from "react";
import PropTypes from "prop-types";
import { Colors } from "../../../constants/constants";
import { IconSvg } from "../IconsStyled";

const defaultSize = 24;

const ProfileIcon = ({ height, width, title }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      viewBox="0 0 24 24"
      fill="none"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g stroke="none">
          <path d="M0 0H24V24H0V0Z" fill="none"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill={Colors["Dark Primary"]}
            d="M12 10.7775C13.7238 10.7775 15.1212 9.38004 15.1212 7.65625C15.1212 5.93245 13.7238 4.53503 12 4.53503C10.2762 4.53503 8.87878 5.93245 8.87878 7.65625C8.87878 9.38004 10.2762 10.7775 12 10.7775ZM16.1212 7.65625C16.1212 9.93233 14.2761 11.7775 12 11.7775C9.72391 11.7775 7.87878 9.93233 7.87878 7.65625C7.87878 5.38016 9.72391 3.53503 12 3.53503C14.2761 3.53503 16.1212 5.38016 16.1212 7.65625Z"
          ></path>
          <path
            d="M3.75757 20.3604C3.75757 20.3604 3.75757 13.2528 12 13.2528C20.2423 13.2528 20.2423 20.465 20.2423 20.465"
            stroke={Colors["Dark Primary"]}
          ></path>
        </g>
      </g>
    </IconSvg>
  );
};

ProfileIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  title: PropTypes.string
};

export default ProfileIcon;
