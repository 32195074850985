import { gql } from "@apollo/client";

const buildGetStores = () => {
  return gql`
    query GetStores {
      getStores {
        storeId
        postcode
        stateProvince
        phone
        nsClass
        name
        hideAssociatesInfo
        email
        customerId
        country
        addressLine2
        city
        addressLine1
        isStoreLive
        lat
        lng
      }
    }
  `;
};

export default buildGetStores;
