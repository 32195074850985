import { gql } from "@apollo/client";

const buildGetProductQuery = (variables) => {
  const slugDef = variables?.slug ? "$slug: String" : "";
  const slug = variables?.slug ? "slug: $slug," : "";
  const pidDef = variables?.productId ? "$productId: String" : "";
  const pid = variables?.productId ? "id: $productId" : "";
  const mpnDef = variables?.mpn ? "$mpn: String" : "";
  const mpn = variables?.mpn ? "mpn: $mpn" : "";
  const vseUrlDef = variables?.vseUrl ? "$vseUrl: String" : "";
  const vseUrl = vseUrlDef ? "vseUrl: $vseUrl" : "";

  return gql`
    query GetProduct(${mpnDef} ${slugDef} ${pidDef} ${vseUrlDef}) {
      getProduct(${mpn} ${slug} ${pid} ${vseUrl}) {
    id
    netsuiteId
    overRideMediaSet
    slug
    sku
    productCallout
    name
    productType
    isRetailAssortment
    isRetailExclusive
    ctaCalloutText
    ctaCalloutLink
    description {
      type
      data
    }
    masterCollection
    collection
    division
    department
    subDepartment
    childProducts {
      id
      netsuiteId
      overRideMediaSet
      sku
      variants {
        name
        value
      }
    }
    customUpholstery {
      variations {
        id
        options {
          name
          description
        }
      }
      filters {
        colors
        grades
        types
      }
      fabrics {
        quickshipFabrics {
          code
          name
          sku
          id
          color
          colorName
          subType
          grade
          type
          netsuiteId
          swatchProduct {
            id
            netsuiteId
            slug
          }
        }
        madeToOrderFabrics {
          code
          name
          sku
          id
          color
          colorName
          subType
          grade
          type
          netsuiteId
          swatchProduct {
            id
            netsuiteId
            slug
          }
        }
        subTypeOrder
      }
      otherOptions {
        name
        values {
          value
          rgbColor
        }
      }
    }
    specsAndCare {
      type
      data
    }
    shippingAndDeliveryInfo {
      type
      data
    }
    variations {
      id
      name
      options {
        name
        description
      }
    }
    breadcrumbs {
      categories {
        name
        slug
      }
    }
    crossSellProducts
    youMayAlsoNeedProducts
    specialProductMessage
    productContent
    productContentItems
    swatchHeadings {
      regularArrival
      priorityArrival
    }
      }
    }
  `;
};

export default buildGetProductQuery;
