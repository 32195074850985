import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { useProductContext } from "../ProductContext";
import { useSlideInContext } from "../SlideInContext";
import { SMB_ID } from "@chv1-serenaandlily/components/SpecialMessagingBanner";
import { LOGGED_IN_BANNER } from "@chv1-serenaandlily/components/LoggedInBanner";

const SidePanelContext = createContext();

const useSidePanelContext = () => useContext(SidePanelContext);

const SidePanelProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [content, setContent] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [closeOnHover, setCloseOnHover] = useState(false);
  const { closeAllSlideIns } = useSlideInContext();

  const { selectedFilters, setSelectedFilters } = useProductContext();
  const filterType = {
    color: "selectedColors",
    grade: "selectedGrades",
    type: "selectedTypes"
  };

  const handleOpenPanel = (delay = 0, closeSlideIns = true) => {
    if (closeSlideIns) closeAllSlideIns();
    setTimeout(() => {
      setIsOpen(true);
      const body = document?.querySelector("body");
      const smb = document?.getElementById(SMB_ID);
      const loggedInBanner = document?.getElementById(LOGGED_IN_BANNER);
      const html = document?.querySelector("html");
      body.classList.add("overflow-hide");

      const isLandscape =
        window.innerHeight < 480 && window.innerWidth > window.innerHeight;

      if (window.innerWidth < 480 || isLandscape) {
        body?.classList.add("side-panel-active");
        smb?.classList.add("hide-small-mobile");
        loggedInBanner?.classList?.add("hide-small-mobile");
        html.style.height = "100%";
      }
    }, delay);
  };

  const handleClosePanel = () => {
    setIsVisible(false);
    setIsFilterOpen(false);
    setCloseOnHover(false);
    setSelectedFilters({
      ...selectedFilters,
      [filterType.color]: [],
      [filterType.grade]: [],
      [filterType.type]: []
    });
    const body = document.querySelector("body");
    const smb = document?.getElementById(SMB_ID);
    const loggedInBanner = document?.getElementById(LOGGED_IN_BANNER);
    const html = document.querySelector("html");

    body.classList.remove("overflow-hide");

    const isLandscape =
      window.innerHeight < 480 && window.innerWidth > window.innerHeight;
    if (window.innerWidth < 480 || isLandscape) {
      body.classList.remove("side-panel-active");
      smb?.classList.remove("hide-small-mobile");
      loggedInBanner?.classList?.remove("hide-small-mobile");
      html.style.height = "";
    }
  };

  return (
    <SidePanelContext.Provider
      value={{
        isOpen,
        setIsOpen,
        handleOpenPanel,
        handleClosePanel,
        isVisible,
        setIsVisible,
        content,
        setContent,
        isFilterOpen,
        setIsFilterOpen,
        closeOnHover,
        setCloseOnHover
      }}
    >
      {children}
    </SidePanelContext.Provider>
  );
};

export { useSidePanelContext, SidePanelProvider };

SidePanelProvider.propTypes = {
  children: PropTypes.any
};
