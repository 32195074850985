export const COUNTRY_STATES = [
  {
    countryName: "United States",
    countryCode: "US",
    statesLabel: "State",
    states: [
      { label: "Alabama", value: "AL" },
      { label: "Alaska", value: "AK" },
      { label: "Arizona", value: "AZ" },
      { label: "Arkansas", value: "AR" },
      { label: "California", value: "CA" },
      { label: "Colorado", value: "CO" },
      { label: "Connecticut", value: "CT" },
      { label: "Delaware", value: "DE" },
      { label: "District of Columbia", value: "DC" },
      { label: "Florida", value: "FL" },
      { label: "Georgia", value: "GA" },
      { label: "Hawaii", value: "HI" },
      { label: "Idaho", value: "ID" },
      { label: "Illinois", value: "IL" },
      { label: "Indiana", value: "IN" },
      { label: "Iowa", value: "IA" },
      { label: "Kansas", value: "KS" },
      { label: "Kentucky", value: "KY" },
      { label: "Louisiana", value: "LA" },
      { label: "Maine", value: "ME" },
      { label: "Maryland", value: "MD" },
      { label: "Massachusetts", value: "MA" },
      { label: "Michigan", value: "MI" },
      { label: "Minnesota", value: "MN" },
      { label: "Mississippi", value: "MS" },
      { label: "Missouri", value: "MO" },
      { label: "Montana", value: "MT" },
      { label: "Nebraska", value: "NE" },
      { label: "Nevada", value: "NV" },
      { label: "New Hampshire", value: "NH" },
      { label: "New Jersey", value: "NJ" },
      { label: "New Mexico", value: "NM" },
      { label: "New York", value: "NY" },
      { label: "North Carolina", value: "NC" },
      { label: "North Dakota", value: "ND" },
      { label: "Ohio", value: "OH" },
      { label: "Oklahoma", value: "OK" },
      { label: "Oregon", value: "OR" },
      { label: "Pennsylvania", value: "PA" },
      { label: "Rhode Island", value: "RI" },
      { label: "South Carolina", value: "SC" },
      { label: "South Dakota", value: "SD" },
      { label: "Tennessee", value: "TN" },
      { label: "Texas", value: "TX" },
      { label: "Utah", value: "UT" },
      { label: "Vermont", value: "VT" },
      { label: "Virginia", value: "VA" },
      { label: "Washington", value: "WA" },
      { label: "West Virginia", value: "WV" },
      { label: "Wisconsin", value: "WI" },
      { label: "Wyoming", value: "WY" }
    ]
  },
  {
    countryName: "Canada",
    countryCode: "CA",
    statesLabel: "Province",
    states: [
      { label: "Alberta", value: "AB" },
      { label: "British Columbia", value: "BC" },
      { label: "Manitoba", value: "MB" },
      { label: "New Brunswick", value: "NB" },
      { label: "Newfoundland and Labrador", value: "NL" },
      { label: "Northwest Territories", value: "NT" },
      { label: "Nova Scotia", value: "NS" },
      { label: "Nunavut", value: "NU" },
      { label: "Ontario", value: "ON" },
      { label: "Prince Edward Island", value: "PE" },
      { label: "Quebec", value: "QC" },
      { label: "Saskatchewan", value: "SK" },
      { label: "Yukon", value: "YT" }
    ]
  }
];

export const PAYMENT_TYPES = {
  AFFIRM: "AFFIRM_PAYMENT",
  CHECK: "CHECK",
  CYBERSOURCE: "CYBERSOURCE_CREDIT",
  PAYPAL: "PAYPAL_EXPRESS",
  WIRE: "WIRE_TRANSFER",
  NO_PAYMENT: "NO_PAYMENT",
  STORED_PAYMENT: "STORED_PAYMENT",
  APPLE_PAY: "APPLE_PAY",
  NO_BALANCE_PAYANDCARRY: "NO_BALANCE_PAYANDCARRY"
};

export const PAYMENT_TYPE_DISPLAY_NAMES = {
  [PAYMENT_TYPES.AFFIRM]: "Affirm",
  [PAYMENT_TYPES.APPLE_PAY]: "Apple Pay",
  [PAYMENT_TYPES.CHECK]: "Check",
  [PAYMENT_TYPES.CYBERSOURCE]: "Credit Card",
  [PAYMENT_TYPES.PAYPAL]: "Paypal",
  [PAYMENT_TYPES.WIRE]: "Wire Transfer"
};

export const EMPTY_BILLING_ADDRESS = {
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  customOrderAgreement: false,
  country: "US",
  stateProvince: "",
  city: "",
  postalCode: "",
  phoneNumber: ""
};

export const EMPTY_SHIPPING_ADDRESS = {
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  country: "US",
  stateProvince: "",
  city: "",
  postalCode: "",
  isSameAsBilling: true
};

export const initialFromDate = new Date();

export const EMPTY_DSO_SHIPPING_DATE = {
  isDoNotShipUntil: false,
  doNotShipUntilDate: initialFromDate
};
export const EMPTY_CONTACT_FORM = {
  email: "",
  phoneNumber: "",
  vipCheck: true
};

export const EMPTY_GIFT_MESSAGE_FORM = {
  giverName: "",
  isGift: "false",
  message: "",
  recipientEmail: "",
  recipientName: ""
};

export const EMPTY_CHECKOUT_SHIPPING_FORM = {
  ...EMPTY_SHIPPING_ADDRESS,
  ...EMPTY_CONTACT_FORM,
  ...EMPTY_DSO_SHIPPING_DATE,
  ...EMPTY_GIFT_MESSAGE_FORM,
  addressNickname: "", // logged in users
  attention: "", //DOO
  companyName: "", //DOO
  designService: "default",
  poNumber: "", // DOO
  saveShippingAddress: false, // logged in users
  savedAddress: "", // logged in users
  shippingOverride: "",
  taxableStatus: "false" // value from radio button is a string
};

export const EMPTY_PAYMENT_FORM = {
  fullName: "",
  paymentToken: "",
  captureContext: "",
  expireMonth: "",
  expireYear: "",
  storedPaymentId: "",
  validationCode: "",
  checkNumber: ""
};

export const EMPTY_CHECKOUT_BILLING_FORM = {
  ...EMPTY_BILLING_ADDRESS,
  ...EMPTY_PAYMENT_FORM
};

export const BAG_URL = "/shoppingbag";
export const CHECKOUT_URL = "/checkout";
export const CATEGORY_URL = "/category";
export const SEARCH_URL = "/search";
export const ESTIMATE_URL = "/estimate";
export const ESTIMATE_EDIT_URL = "/estimate-edit";

export const ORDER_CONFIRMATION_URL = "/order-confirmation";

export const PAYPAL_URLS = {
  APPROVED: "/checkout/paypal-payment-approved",
  CANCELLED: "/checkout/paypal-payment-cancelled"
};

// these correspond with the component names for each view
export const SHIPPING_STEP = "Shipping";
export const BILLING_STEP = "Billing";
export const REVIEW_STEP = "Review";

export const ORDERED_CHECKOUT_STEPS = [
  SHIPPING_STEP,
  BILLING_STEP,
  REVIEW_STEP
];

export const MAX_AUTH_ATTEMPTS = 3;
export const CYBERSOURCE_KEY_TTL = 900000;

export const UPDATE_SHIPPING_DEBOUNCE = 1250;
