import resolveComponentThemeValues from "./resolveComponentThemeValues";
import resolveComponentThemeTypography from "./resolveComponentThemeTypography";

/**
 * Resolve the values in a component theme using the fonts, colors, and variables from a global theme.
 * @param globalTheme
 * @param componentTheme
 * @returns {*}
 */
const resolveComponentTheme = (globalTheme = {}, componentTheme = {}) => {
  const resolvedValues = resolveComponentThemeValues(globalTheme, componentTheme) || {};
  return resolveComponentThemeTypography(globalTheme, resolvedValues) || {}
};

export default resolveComponentTheme