export const PAGE_TYPE = {
  account: "account",
  cart: "cart",
  checkout: "checkout",
  orderConfirmation: "order-confirmation",
  PDP: "PDP",
  category: "category",
  search: "search",
  quickshop: "quickshop",
  register: "register",
  stl: "stl",
  xsells: "recommendation: constructor xsells",
  YMAN: "recommendation: YMAN"
};
