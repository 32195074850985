export const COUNTRIES_MAP = {
  US: "United States",
  CA: "Canada"
};

export const getBillingAddress = ({
  cart,
  billingAddress,
  isBillingSameAsShipping
}) => {
  if (!isBillingSameAsShipping) {
    return {
      givenName: billingAddress.firstName,
      familyName: billingAddress.lastName,
      addressLines: [
        billingAddress.addressLine1,
        billingAddress.addressLine2
      ].filter(Boolean),
      locality: billingAddress.city,
      administrativeArea: billingAddress.stateProvince,
      postalCode: billingAddress.postalCode,
      country: COUNTRIES_MAP[billingAddress.country] || billingAddress.country,
      emailAddress: cart.email,
      phoneNumber: cart.phoneNumber,
      countryCode: cart.shippingAddress.country
    };
  }

  const givenName =
    cart.shippingAddress?.firstName && cart.shippingAddress?.firstName !== ""
      ? cart.shippingAddress.firstName
      : billingAddress?.firstName;
  const familyName =
    cart.shippingAddress?.lastName && cart.shippingAddress?.lastName !== ""
      ? cart.shippingAddress.lastName
      : billingAddress?.lastName;
  return {
    givenName,
    familyName,
    addressLines: [
      cart.billingAddress.addressLine1,
      cart.billingAddress.addressLine2
    ].filter(Boolean),
    locality: cart.billingAddress.city,
    administrativeArea: cart.billingAddress.stateProvince,
    postalCode: cart.billingAddress.postalCode,
    country:
      COUNTRIES_MAP[cart.billingAddress.country] || cart.billingAddress.country,
    emailAddress: cart.email,
    phoneNumber: cart.phoneNumber,
    countryCode: cart.shippingAddress.country
  };
};
