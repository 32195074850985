import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 11;

const CaretLeftLight = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 6 11"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5.854.146a.502.502 0 0 0-.708 0l-5 5a.502.502 0 0 0 0 .708l5 5a.502.502 0 0 0 .708-.708L1.207 5.5 5.854.854a.502.502 0 0 0 0-.708Z"/>
    </IconSvg>
  );
};

CaretLeftLight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CaretLeftLight;
