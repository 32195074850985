import { z } from "zod";

export const SMB_CAROUSEL_ITEM_SCHEMA = z.object({
  event: z.string(),
  ecommerce: z.object({
    path: z.string(),
    smb_identifier: z.string(),
    customerData: z.object({
      user_logged_in: z.boolean(),
      user_type: z.string(),
      customer_type: z.string()
    })
  })
});
