import React from "react";
import PropTypes from "prop-types";
import { ArrowContainer, IconSvg } from "../IconsStyled";

const defaultLoaderSize = 10;

const ArrowIcon = ({ height, width }) => {
  return (
    <ArrowContainer>
      <IconSvg
        viewBox="0 0 37 18"
        height={height || defaultLoaderSize}
        width={width || defaultLoaderSize}
      >
        <g
          id="Full-Bleed---Grid-(24px-margin)"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
        >
          <g
            id="desktop_1024px-copy"
            transform="translate(-793.000000, -3499.000000)"
            fillRule="nonzero"
            stroke="#383838"
          >
            <g
              id="Arrow-1"
              transform="translate(811.500000, 3508.000000) rotate(-270.000000) translate(-811.500000, -3508.000000) translate(802.500000, 3490.000000)"
            >
              <polyline
                id="Path-2-Copy"
                transform="translate(9.000000, 18.000000) rotate(-90.000000) translate(-9.000000, -18.000000) "
                points="-9 9 9 27 27 9"
              />
            </g>
          </g>
        </g>
      </IconSvg>
    </ArrowContainer>
  );
};

ArrowIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default ArrowIcon;
