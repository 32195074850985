export const headline1 = {
  themeName: "headline1",
  tag: "h1",
  fontFamily: "PP Fragment",
  fontColor: "Midnight",
  fontSize: "40px",
  fontSizeTablet: "60px",
  fontSizeDesktop: "80px",
  fontWeight: "normal",
  lineHeight: "1.1"
};

export const headline2 = {
  themeName: "headline2",
  tag: "h2",
  fontFamily: "PP Fragment",
  fontColor: "Midnight",
  fontSize: "32px",
  fontSizeTablet: "48px",
  fontSizeDesktop: "60px",
  fontWeight: "normal",
  lineHeight: "1.1"
};

export const filterByHeadline = {
  themeName: "filterByHeadline",
  tag: "h3",
  fontFamily: "PP Fragment",
  fontColor: "Midnight",
  fontSize: "24px",
  fontSizeTablet: "32px",
  fontSizeDesktop: "40px",
  fontWeight: "normal",
  lineHeight: "120%"
};

export const headline3 = {
  themeName: "headline3",
  tag: "h3",
  fontFamily: "PP Fragment",
  fontColor: "Midnight",
  fontSize: "24px",
  fontSizeTablet: "32px",
  fontSizeDesktop: "40px",
  fontWeight: "normal",
  lineHeight: "1.1"
};
export const headline4 = {
  themeName: "headline4",
  tag: "h4",
  fontFamily: "PP Fragment",
  fontColor: "Midnight",
  fontSize: "18px",
  fontSizeTablet: "18px",
  fontSizeDesktop: "21px",
  fontWeight: "normal",
  lineHeight: "1.1"
};
export const headline4Sans = {
  ...headline4,
  themeName: "headline4Sans",
  tag: "h4",
  fontFamily: "Beatrice Medium",
  letterSpacing: "0.02em"
};
export const headline5 = {
  themeName: "headline5",
  tag: "h5",
  fontFamily: "Beatrice Medium",
  fontColor: "Midnight",
  fontSize: "14px",
  fontSizeTablet: "15px",
  fontSizeDesktop: "18px",
  fontWeight: "normal",
  lineHeight: "1.2",
  letterSpacing: "0.02em"
};
export const headline5Medium = {
  themeName: "headline5Medium",
  tag: "h5",
  fontFamily: "Beatrice Medium",
  fontColor: "Midnight",
  fontSize: "14px",
  fontSizeTablet: "15px",
  fontSizeDesktop: "16px",
  fontWeight: "normal",
  lineHeight: "1.2",
  letterSpacing: "0.02em"
};
export const headline6 = {
  themeName: "headline6",
  tag: "h6",
  fontFamily: "Beatrice Medium",
  fontColor: "Midnight",
  fontSize: "12px",
  fontSizeTablet: "12px",
  fontSizeDesktop: "13px",
  fontWeight: "normal",
  lineHeight: "1.2",
  letterSpacing: "0.02em"
};
export const deliveryTitle = {
  themeName: "deliveryTitle",
  tag: "h3",
  fontFamily: "PP Fragment",
  fontColor: "Midnight",
  fontSize: "24px",
  fontSizeTablet: "24px",
  fontSizeDesktop: "32px",
  fontWeight: "400",
  lineHeight: "1.1"
};
export const text = {
  themeName: "text",
  tag: "p",
  fontFamily: "Beatrice Light",
  fontColor: "Midnight",
  fontSize: "14px",
  fontSizeTablet: "15px",
  fontSizeDesktop: "18px",
  lineHeight: "1.2",
  letterSpacing: "0.01em"
};
export const fabricsDescription = {
  themeName: "fabricsDescription",
  tag: "p",
  fontFamily: "Beatrice Light",
  fontColor: "Midnight 70%",
  fontSize: "8px",
  fontSizeTablet: "10px",
  fontSizeDesktop: "10px"
};
export const textDescription = {
  themeName: "textDescription",
  tag: "p",
  fontFamily: "Beatrice Light",
  fontColor: "Midnight",
  fontSize: "14px",
  fontSizeTablet: "15px",
  fontSizeDesktop: "16px"
};
export const textResponsive = {
  themeName: "textResponsive",
  tag: "p",
  fontFamily: "Beatrice Light",
  fontColor: "Midnight",
  fontSize: "11px",
  fontSizeTablet: "14px",
  fontSizeDesktop: "14px",
  lineHeight: "1.2",
  letterSpacing: "0.01em"
};
export const textSmall = {
  themeName: "textSmall",
  tag: "p",
  fontFamily: "Beatrice Light",
  fontColor: "Midnight",
  fontSize: "11px",
  fontSizeTablet: "12px",
  fontSizeDesktop: "14px",
  lineHeight: "1.2",
  letterSpacing: "0.01em"
};

// TODO: places that use this should use errorSmall and just override any needed properties in theme.
export const textRedSmall = {
  themeName: "textRedSmall",
  tag: "p",
  fontFamily: "Beatrice Light",
  fontColor: "#970013",
  fontSize: "12px",
  fontSizeTablet: "12px",
  fontSizeDesktop: "14px",
  lineHeight: "1.2",
  letterSpacing: "0.01em"
};

export const textYellowSmall = {
  themeName: "textYellowSmall",
  tag: "p",
  fontFamily: "Beatrice Light",
  fontColor: "#EEC668",
  fontSize: "12px",
  fontSizeTablet: "12px",
  fontSizeDesktop: "14px",
  lineHeight: "1.2",
  letterSpacing: "0.01em"
};

export const error = {
  ...text,
  themeName: "error",
  fontColor: "Crimson"
};

export const errorSmall = {
  ...textSmall,
  themeName: "errorSmall",
  fontColor: "Crimson"
};

// TODO: places that use this should use textSMall and just override any needed properties in theme.
export const textBlueSmall = {
  themeName: "textBlueSmall",
  tag: "p",
  fontFamily: "Beatrice Light",
  fontColor: "#445eab",
  fontSize: "12px",
  fontSizeTablet: "12px",
  fontSizeDesktop: "14px",
  lineHeight: "1.2",
  letterSpacing: "0.01em"
};
export const label = {
  themeName: "label",
  tag: "label",
  fontFamily: "Beatrice Regular",
  fontColor: "Midnight",
  fontSize: "14px",
  fontSizeTablet: "14px",
  fontSizeDesktop: "14px",
  lineHeight: "1",
  letterSpacing: "0.01em"
};

export const cta = {
  themeName: "cta",
  tag: "span",
  fontFamily: "Beatrice Medium",
  fontColor: "Midnight",
  fontSize: "13px",
  fontSizeTablet: "14px",
  fontSizeDesktop: "15px",
  lineHeight: "1",
  letterSpacing: "0.01em",
  textTransform: "uppercase"
};
export const ctaSmall = {
  themeName: "ctaSmall",
  tag: "span",
  fontFamily: "Beatrice Medium",
  fontColor: "Midnight",
  fontSize: "11px",
  fontSizeTablet: "11px",
  fontSizeDesktop: "12px",
  lineHeight: "1",
  letterSpacing: "0.01em",
  textTransform: "uppercase"
};

export const textMedium = {
  themeName: "textMedium",
  tag: "span",
  fontFamily: "Proxima Nova Regular",
  fontColor: "Medium Primary",
  fontSize: "12px",
  lineHeight: "1",
  letterSpacing: "1px",
  textTransform: "capitalize"
};

//TODO: this is pretty component specific and doesn't belong in global
export const specialMessageBanner = {
  themeName: "specialMessageBanner",
  tag: "span",
  fontFamily: "Proxima Nova Regular",
  fontColor: "White",
  fontSize: "12px",
  fontSizeTablet: "14px",
  fontSizeDesktop: "15px",
  lineHeight: "1.5",
  letterSpacing: "0.01em"
};

export const beatriceSmall = {
  themeName: "beatriceSmall",
  tag: "p",
  fontFamily: "Beatrice Regular",
  fontColor: "Midnight",
  fontSize: "12px",
  fontSizeTablet: "12px",
  fontSizeDesktop: "12px",
  lineHeight: "1.5",
  letterSpacing: "0.01em"
};

export const beatriceSmallLight = {
  themeName: "beatriceSmallLight",
  tag: "p",
  fontFamily: "Beatrice Light",
  fontColor: "Midnight",
  fontSize: "12px",
  fontSizeTablet: "12px",
  fontSizeDesktop: "12px",
  lineHeight: "1.5",
  letterSpacing: "0.01em"
};

export const beatriceSmallBold = {
  themeName: "beatriceSmallBold",
  tag: "p",
  fontFamily: "Beatrice Medium",
  fontColor: "Midnight",
  fontSize: "12px",
  fontSizeTablet: "12px",
  fontSizeDesktop: "12px",
  lineHeight: "1.5",
  letterSpacing: "0.01em"
};

export const beatriceTile = {
  themeName: "beatriceTile",
  tag: "p",
  fontFamily: "Beatrice Medium",
  fontColor: "Midnight",
  fontSize: "12px",
  fontSizeTablet: "12px",
  fontSizeDesktop: "14px",
  lineHeight: "1.5",
  letterSpacing: "0.01em"
};

export const wishListTitle = {
  themeName: "wishListTitle",
  tag: "h2",
  fontFamily: "PP Fragment",
  fontColor: "Midnight",
  fontSize: "32px",
  fontSizeTablet: "48px",
  fontSizeDesktop: "48px",
  fontWeight: "normal"
};

export default [
  beatriceTile,
  beatriceSmall,
  beatriceSmallLight,
  beatriceSmallBold,
  headline1,
  headline2,
  headline3,
  headline4,
  headline4Sans,
  headline5,
  headline5Medium,
  headline6,
  filterByHeadline,
  text,
  textResponsive,
  textSmall,
  textRedSmall,
  textYellowSmall,
  error,
  errorSmall,
  fabricsDescription,
  textBlueSmall,
  textDescription,
  label,
  cta,
  ctaSmall,
  wishListTitle,
  textMedium,
  specialMessageBanner,
  deliveryTitle,
  {
    ...textSmall,
    themeName: "default"
  }
];
