import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 22;

const Stores = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 16 22"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3.72817 2.91488C2.25864 4.01844 1.00128 5.97169 1.10126 9.44154C1.17634 12.0471 2.34788 14.4168 3.83714 16.4257C5.1966 18.2596 6.79621 19.7603 8.00048 20.8323C9.20474 19.7603 10.8043 18.2596 12.1638 16.4257C13.653 14.4168 14.8245 12.0471 14.8996 9.44154C14.9996 5.97169 13.7422 4.01844 12.2727 2.91488C10.7734 1.789 8.98804 1.5 8.00042 1.5C7.0128 1.5 5.22742 1.789 3.72817 2.91488ZM3.12768 2.11525C4.85012 0.821764 6.86845 0.5 8.00042 0.5C9.13239 0.5 11.1507 0.821764 12.8732 2.11525C14.6253 3.43106 16.0076 5.70579 15.8992 9.47035C15.8159 12.3589 14.5212 14.9249 12.9671 17.0212C11.4118 19.1193 9.56723 20.7861 8.33099 21.8752C8.14207 22.0416 7.85888 22.0416 7.66996 21.8752C6.43373 20.7861 4.58913 19.1193 3.0338 17.0212C1.47972 14.9249 0.184911 12.3589 0.101678 9.47035C-0.00679747 5.70579 1.37552 3.43106 3.12768 2.11525Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.99999 10.5C9.51877 10.5 10.75 9.26878 10.75 7.75C10.75 6.23122 9.51877 5 7.99999 5C6.48121 5 5.24999 6.23122 5.24999 7.75C5.24999 9.26878 6.48121 10.5 7.99999 10.5ZM7.99999 11.5C10.0711 11.5 11.75 9.82107 11.75 7.75C11.75 5.67893 10.0711 4 7.99999 4C5.92892 4 4.24999 5.67893 4.24999 7.75C4.24999 9.82107 5.92892 11.5 7.99999 11.5Z"/>
    </IconSvg>
  );
};

Stores.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Stores;
