import React from "react";
import PropTypes from "prop-types";
const CloseChips = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L13 13M13 1L1 13" stroke="#243953" strokeLinecap="round" />
    </svg>
  );
};

CloseChips.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CloseChips;
