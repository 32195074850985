import { z } from "zod";
import { GA4Events } from "../GA4Events";
import { zStringOrUndefined } from "./schemaSnippets";

export const viewItemList = z.array(
  z
    .object({
      item_id: z.string(),
      item_name: z.string(),
      item_brand: z.string(),
      item_category: zStringOrUndefined,
      item_category2: zStringOrUndefined,
      item_category3: zStringOrUndefined,
      item_variant: zStringOrUndefined,
      affiliation: z.string(),
      price: z.string(),
      discount: zStringOrUndefined,
      currency: z.string(),
      quantity: z.string(),
      item_list_name: zStringOrUndefined,
      item_list_id: zStringOrUndefined,
      index: z.string().refine((value) => !isNaN(Number(value)), {
        message: "Index must be a number"
      })
    })
    .strict()
    .refine((data) => {
      const shouldBeIncludeKeys = [
        "item_category2",
        "item_category3",
        "item_variant",
        "discount",
        "item_list_name",
        "item_list_id"
      ];

      const originalKeys = Object.keys(data);
      for (const key of shouldBeIncludeKeys) {
        if (!originalKeys.includes(key)) {
          return false;
        }
      }

      return true;
    })
);

export const SELECT_ITEM_SCHEMA = z
  .object({
    event: z.literal(GA4Events.select_item),
    ecommerce: z.object({
      items: viewItemList
    })
  })
  .strict();

export const VIEW_ITEM_LIST_SCHEMA = z.object({
  event: z.literal(GA4Events.view_item_list),
  ecommerce: z.object({
    items: viewItemList
  })
});
