import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 18;

const ArrowDownLight = ({ height, width, fill="currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 11 18"
    >
      <path d="M6 .513A.507.507 0 0 0 5.5 0c-.276 0-.5.23-.5.513V16.25L.854 11.998a.493.493 0 0 0-.708 0 .522.522 0 0 0 0 .725l5 5.126a.491.491 0 0 0 .708 0l5-5.126a.522.522 0 0 0 0-.725.493.493 0 0 0-.708 0L6 16.25V.513Z"/>
    </IconSvg>
  );
};

ArrowDownLight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default ArrowDownLight;
