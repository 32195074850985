import React from "react";
import PropTypes from "prop-types";
import { IconSvg, Notification, NotificationIconWrapper } from "../IconsStyled";

const defaultSize = 20;

const Bag = ({ height, width, fill = "currentColor", notification }) => {
  
  if (notification) {
    return (
      <NotificationIconWrapper>
        <IconSvg
          width={width || defaultSize}
          height={height || defaultSize}
          fill={fill}
          viewBox="0 0 20 20"
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M6.45 5.366V3.415C6.45 1.529 8.039 0 10 0c1.961 0 3.55 1.529 3.55 3.415v1.951h5.436c.613 0 1.086.52 1.005 1.105l-.547 3.942a5.293 5.293 0 0 0-1.001-.159l.543-3.912H1.014L2.42 16.486c.184 1.32 1.264 2.337 2.604 2.513.071.348.182.684.326 1.001-1.995-.041-3.67-1.475-3.935-3.386L.009 6.471c-.081-.585.392-1.105 1.005-1.105H6.45Zm1.014-1.951C7.464 2.067 8.599.976 10 .976s2.536 1.091 2.536 2.439v1.951H7.464V3.415Z"/>
        </IconSvg>
        <Notification fill={fill} left="30%" top="55%"><span>{notification}</span></Notification>
      </NotificationIconWrapper>);
  } else {
    return (
      <IconSvg
        width={width || defaultSize}
        height={height || defaultSize}
        fill={fill}
        viewBox="0 0 20 20"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M18.986 6.341H1.014L2.42 16.485c.202 1.454 1.492 2.539 3.017 2.539h9.126c1.526 0 2.815-1.085 3.017-2.539l1.406-10.144ZM1.014 5.366c-.613 0-1.086.52-1.005 1.104l1.406 10.144C1.684 18.552 3.403 20 5.437 20h9.126c2.034 0 3.753-1.448 4.022-3.386L19.991 6.47c.081-.584-.392-1.104-1.005-1.104H1.014Z"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10 .976c-1.401 0-2.536 1.091-2.536 2.439v2.926H6.45V3.415C6.45 1.529 8.039 0 10 0c1.961 0 3.55 1.529 3.55 3.415v2.926h-1.014V3.415c0-1.348-1.135-2.439-2.536-2.439Zm0 12.683c1.401 0 2.536-1.092 2.536-2.439V8.78c0-.269.227-.487.507-.487s.507.218.507.487v2.44c0 1.885-1.589 3.414-3.55 3.414-1.961 0-3.55-1.529-3.55-3.414V8.78c0-.269.227-.487.507-.487s.507.218.507.487v2.44c0 1.347 1.135 2.439 2.536 2.439Z"/>
      </IconSvg>
    );
  }
};

Bag.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  notification: PropTypes.number,
  width: PropTypes.number,
};

export default Bag;
