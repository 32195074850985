import apolloClient from "../client";
import buildGetStores from "../queries/buildGetStoresQuery";

const fetchStores = async () => {
  if (!apolloClient) return;
  const GET_STORES = buildGetStores();
  const { data } = await apolloClient.query({
    query: GET_STORES,
    variables: { },
    errorPolicy: "none",
    context: { clientName: "endpoint2" }
  });

  return data;
};

export default fetchStores;
