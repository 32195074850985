import deepResolveValues from "./deepResolveValues";
import deepMergeTypography from "./deepMergeTypography";
import createTypographyResolver from "./createTypographyResolver";


const resolveComponentThemeTypography = (globalTheme = {}, componentTheme = {}) => {
  // eslint-disable-next-line no-unused-vars
  const { typography, ...rest } = globalTheme;
  const typographyResolver = createTypographyResolver(typography);
  const resolvedTypographyTheme = deepResolveValues(componentTheme, typographyResolver);
  return deepMergeTypography(resolvedTypographyTheme);
};

export default resolveComponentThemeTypography