import { formatAsCurrency } from "@chv1-utils";

export default function (respPayment) {
  if (!respPayment) return;
  const { token, expYear, expMonth, paymentAmount, ...rest } = respPayment;

  return {
    expireYear: expYear,
    expireMonth: expMonth,
    paymentAmount: formatAsCurrency(paymentAmount),
    ...rest
  };
}
