import React from "react";
import PropTypes from "prop-types";
import { marked } from "marked";
import { MarkdownContainer } from "./RichTextStyled";
import DCContentLink from "./DCContentLink";

// eslint-disable-next-line complexity
const RichTextContent = ({ type, data, theme }) => {
  switch (type) {
    case "markdown": {
      let html = data ? marked.parse(data) : "";

      return (
        html && (
          <MarkdownContainer
            theme={theme}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )
      );
    }
    case "dc-content-link":
      return <DCContentLink {...data} />;
    default:
      return null;
  }
};

export default RichTextContent;

RichTextContent.propTypes = {
  type: PropTypes.string,
  data: PropTypes.any,
  maxLen: PropTypes.number,
  theme: PropTypes.object
};
