import axios from "axios";
import getAmpVideoUrl from "../../getAmpVideoUrl";

async function fetchImageSet(netsuiteId, overRideMediaSet) {
  try {
    const mediaSetUrl = `${overRideMediaSet || netsuiteId}_SET`;
    const mediaSetResponse = await axios.get(
      `https://cdn.media.amplience.net/s/serenaandlily/${mediaSetUrl}.json?metadata=true`
    );
    const data = mediaSetResponse.data;
    if (data && data?.items) {
      const updatedItems = await Promise.all(
        data?.items.map(async (item) => {
          if (item.type === "video") {
            const response = await getAmpVideoUrl(item.src);
            return { ...item, videoSrc: response?.data?.media[0]?.src };
          }
          return item;
        })
      );
      mediaSetResponse.data.items = updatedItems;
    }
    return mediaSetResponse;
  } catch (e) {
    return null;
  }
}

export default fetchImageSet;
