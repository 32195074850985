import {
  CART_UNABLE_TO_SELL_ITEM_LEVEL,
  CART_UNABLE_TO_SELL_CART_LEVEL,
  CART_WG_ITEMS_DISALLOWED_ERROR_ITEM_LEVEL,
  CART_WG_ITEMS_DISALLOWED_ERROR_CART_LEVEL
} from "@chv1-serenaandlily/constants/strings";
import {
  BAG_URL,
  CHECKOUT_URL,
  ESTIMATE_URL,
  ESTIMATE_EDIT_URL
} from "../../constants/checkout";

const getInventoryVerb = (total) => (total === 1 ? "is" : "are");

export const EMPTY_CART = {
  appliedPromotions: [],
  billingAddress: {},
  cartItems: [],
  cartItemsById: {},
  deliveryGroups: [],
  payments: [],
  priceSummary: {},
  shippingAddress: {},
  itemCount: 0,
  hasCart: false
};

export const CART_WARNINGS = {
  CART_ERROR_INVENTORY: "CART_ERROR_INVENTORY",
  CART_ITEM_REPRICED: "CART_ITEM_REPRICED",
  CART_ITEM_NO_LONGER_AVAILABLE: "CART_ITEM_NO_LONGER_AVAILABLE",
  CART_ERROR_GIFT_INFO: "CART_ERROR_GIFT_INFO",
  CART_CHECKOUT_MISSING_CUSTOMER: "CART_CHECKOUT_MISSING_CUSTOMER",
  CART_CHECKOUT_MISSING_PAYMENT: "CART_CHECKOUT_MISSING_PAYMENT",
  PROMO_CODE_REMOVED_UNAVAIL: "PROMO_CODE_REMOVED_UNAVAIL",
  PRODUCT_NOT_FOUND: "PRODUCT_NOT_FOUND",
  CART_UNABLE_TO_SELL: "CART_UNABLE_TO_SELL",
  CART_WG_ITEMS_DISALLOWED_ERROR: "CART_WG_ITEMS_DISALLOWED_ERROR",
  CART_ERROR_LOAD: "CART_ERROR_LOAD",
  CART_ITEM_ERROR_MISSING_OPTIONS: "CART_ITEM_ERROR_MISSING_OPTIONS",
  CART_PROMO_NOT_REMOVED: "CART_PROMO_NOT_REMOVED",
  PROMO_CODE_MESSAGE_NOT_APPLIED: "PROMO_CODE_MESSAGE_NOT_APPLIED",
  PROMO_CODE_ERROR_EXPIRED: "PROMO_CODE_ERROR_EXPIRED",
  PRODUCT_IS_RETAIL_EXCLUSIVE: "PRODUCT_IS_RETAIL_EXCLUSIVE"
};

export const CART_WARNINGS_MESSAGES = {
  CART_ITEM_REPRICED: ({ from = "", to = "" } = {}) =>
    `This item was repriced from ${from} to ${to}`,
  CART_ERROR_INVENTORY: ({ total = 0 } = {}) =>
    `There ${getInventoryVerb(
      total
    )} ${total} available. Please adjust your quantity.`,
  CART_ERROR_INVENTORY_ESTIMATE: ({ total = 0 } = {}) =>
    `There ${getInventoryVerb(
      total
    )} ${total} available and none on order. Please reduce the quantity and add back to this Estimate or select an alternate item.`,
  CART_ITEM_NO_LONGER_AVAILABLE: () =>
    "Product is no longer available and must be removed from your cart",
  PRODUCT_NOT_FOUND: () => "Product is no longer available.",
  PRODUCTS_UNKNOWN_NOT_FOUND: () =>
    "Some items on this Estimate are no longer available and have not been added to the Shopping Bag.",
  TAKE_ACTION_WARNING: () =>
    "Please review and adjust the quantities of some items in your cart.",
  UNAVAILABLE: () =>
    "This product is no longer available. Please remove it from your cart.",
  CART_ERROR_GIFT_INFO: () => "The gift card has an error.",
  CART_UNABLE_TO_SELL: ({ messages, warningLevel = "item" }) => {
    if (warningLevel === "cart") {
      return (
        messages?.cartUnableToSellCartLevel || CART_UNABLE_TO_SELL_CART_LEVEL
      );
    } else {
      return (
        messages?.cartUnableToSellItemLevel || CART_UNABLE_TO_SELL_ITEM_LEVEL
      );
    }
  },
  CART_WG_ITEMS_DISALLOWED_ERROR: ({ messages, warningLevel = "item" }) => {
    if (warningLevel === "cart") {
      return (
        messages?.cartWgItemsDisallowedErrorCartLevel ||
        CART_WG_ITEMS_DISALLOWED_ERROR_CART_LEVEL
      );
    } else {
      return (
        messages?.cartWgItemsDisallowedErrorItemLevel ||
        CART_WG_ITEMS_DISALLOWED_ERROR_ITEM_LEVEL
      );
    }
  },
  PROMO_CODE_MESSAGE_NOT_APPLIED: () =>
    "This promo code does not apply to the items in your shopping bag.",
  CART_CHECKOUT_MISSING_CUSTOMER: () =>
    "The customer is missing from the cart.",
  CART_CHECKOUT_MISSING_PAYMENT: () => "The payment is missing.",
  CART_ERROR_LOAD: () => "There was an error loading the cart.",
  CART_ITEM_ERROR_MISSING_OPTIONS: () => "There are options missing.",
  CART_PROMO_NOT_REMOVED: () => "The promotion code was not removed.",
  PROMO_CODE_REMOVED_UNAVAIL: () =>
    "The promotion code was removed because it is not available anymore.",
  PROMO_CODE_ERROR_EXPIRED: () => "The promo code has expired",
  PRODUCT_IS_RETAIL_EXCLUSIVE: () =>
    "Some of the items in your cart are available In-Store Only and have not been added to your cart."
};

export const FATAL_WARNINGS = new Set([
  CART_WARNINGS?.CART_UNABLE_TO_SELL,
  CART_WARNINGS?.CART_WG_ITEMS_DISALLOWED_ERROR
]);

export const ROUTE_TO_TYPES = {
  [BAG_URL]: "cart",
  [CHECKOUT_URL]: "checkout",
  [ESTIMATE_URL]: "cart",
  [ESTIMATE_EDIT_URL]: "cart"
};
