/* eslint-disable max-lines-per-function */
import getAddress from "./address";
import getPriceSummary from "./priceSummary";
import getPayment from "./payment";
import getCartItem from "./cartItem";
import getGroupDeliveryItems from "@serenaandlily/gql/responseMappers/getGroupDeliveryItems";
const getItemCount = (cartItems = []) => {
  let count = 0;
  cartItems?.forEach((item) => {
    count += item?.quantity;
  });
  return count;
};

export default function getCart(respCart) {
  try {
    if (!respCart) return;
    const {
      shippingAddress,
      billingAddress,
      priceSummary,
      payments,
      attention,
      poNumber,
      netsuiteContactId,
      cartItems,
      companyName,
      designServiceCode,
      // everything else
      ...rest
    } = respCart;
    let cart = {
      ...rest,
      attention,
      poNumber,
      companyName,
      netsuiteContactId
    };
    if (designServiceCode) cart.designService = designServiceCode;
    if (shippingAddress)
      cart.shippingAddress = getAddress({
        ...shippingAddress,
        attention,
        poNumber,
        companyName
      });
    if (billingAddress) cart.billingAddress = getAddress(billingAddress);
    if (priceSummary)
      cart.priceSummary = getPriceSummary(
        priceSummary,
        cart.shippingItems,
        cart.shippingAddress
      );
    if (payments)
      cart.payments = payments.map((payment) => getPayment(payment));
    if (cartItems) {
      cart.cartItems = cartItems.map((cartItem) => getCartItem(cartItem));
    }

    // convenience properties
    if (cart?.cartItems) {
      cart.cartItemsById = cart.cartItems.reduce((acc, cartItem) => {
        acc[cartItem.id] = cartItem;
        return acc;
      }, {});
      cart.hasPendingDeletedCartItems =
        cart.deletedCartItems && cart.deletedCartItems.length > 0;
      cart.hasCart = Array.isArray(cart.cartItems) && cart.cartItems.length > 0;
      cart.itemCount = getItemCount(cart.cartItems);

      cart.groupedDelivery = getGroupDeliveryItems({
        deliveryGroups: cart?.deliveryGroups,
        cartItems: cart?.cartItems,
        deletedCartItems: cart?.deletedCartItems,
        warnings: cart?.warnings
      });

      if (cart.groupedDelivery?.length > 0) {
        cart.groupedCartItems = cart.groupedDelivery?.flatMap((group) =>
          group.deliveryMethods?.flatMap((method) => method?.cartItems)
        );
      }
    }

    // removing null properties because we are currently not getting back consistent response
    // on all requests, and sometimes we have a bunch of null values
    Object.keys(cart).forEach((key) => {
      if (cart[key] == null && key !== "doNotShipUntilDate") delete cart[key];
    });

    return cart;
  } catch (e) {
    /* eslint-disable-next-line */
    console.log("e", e);
  }
}
