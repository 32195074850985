import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 19;

const AddedToBag = ({ height, width, fill="currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 19 19"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M9.5 1a8.5 8.5 0 1 0 .001 17.001A8.5 8.5 0 0 0 9.5 1ZM0 9.5A9.5 9.5 0 0 1 9.5 0 9.5 9.5 0 0 1 19 9.5 9.5 9.5 0 0 1 9.5 19 9.5 9.5 0 0 1 0 9.5Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.482 9.574a.5.5 0 0 1 .707 0L8.215 11.6l4.594-4.594a.5.5 0 0 1 .707.707L8.569 12.66a.499.499 0 0 1-.707 0l-2.38-2.379a.5.5 0 0 1 0-.707Z"/>
    </IconSvg>
  );
};

AddedToBag.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default AddedToBag;
