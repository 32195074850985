import logData from "@utils/logData";

const stackCutOff = 500;

const logApplicationError = (error, info) => {
  logData({
    func: "Error Boundary",
    data: {
      message: "Browser Application Error",
      isError: true,
      error: error?.message,
      location: window?.location?.href,
      componentStack: info?.componentStack?.slice?.(0, stackCutOff)
    }
  });
};

export default logApplicationError;
