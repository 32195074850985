/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-console */
import { useCallback } from "react";
import constructApplePayRequestObject from "@chv1-serenaandlily/components/ApplePay/utils/constructApplePayRequestObject";
import { PAYMENT_TYPES } from "@chv1-serenaandlily/constants/checkout";
import {
  addPromotionToCart,
  removePromotionFromCart,
  updateCart,
  updateCartPayments
} from "@chv1-gql/utils";
import useUser from "./useUser";
import convertAppleAddressToAddress from "@chv1-serenaandlily/components/ApplePay/utils/convertAppleAddressToAddress";
import { fetchValidateApplePay } from "@chv1-serenaandlily/gql/utils";
import getApplePayShippingMethodChanged from "@chv1-serenaandlily/components/ApplePay/utils/getApplePayShippingMethodChanged";
import { getApolloAuthContext } from "@chv1-utils/getApolloAuthContext/getApolloAuthContext";
import {
  calculateErrors,
  calculateShippingErrors,
  getCouponCodeErrorCode
} from "@chv1-serenaandlily/components/ApplePay/utils/calculateErrors";
import getNumberValue from "../../utils/getNumberValue";
import { buildUpdateCartPayload } from "@chv1-serenaandlily/components/ApplePay/utils/buildUpdateCartPayload";
import {
  APPLE_PAY_VERSION,
  MIN_SAFARI_VERSION_APPLE_PAY,
  NOT_SAFARI
} from "@chv1-serenaandlily/constants/applePay";
import validateAddress from "@chv1-utils/addressValidation/validateAddress";

const useApplePay = () => {
  const { user: { data: { isDOO } = {} } = {} } = useUser();

  const getSafariVersion = () => {
    if (!window?.navigator) return;
    const userAgent = window.navigator.userAgent;
    // We need version to make sure it's Safari and not Chrome in iOS
    if (
      userAgent.includes("Safari") &&
      userAgent.includes("Version/") &&
      !userAgent.includes("Chrome") &&
      !userAgent.includes("Chromium")
    ) {
      return userAgent.split("Version/")[1].split(" ")[0];
    }
    return NOT_SAFARI;
  };

  const isApplePayAvailable =
    typeof window !== "undefined" &&
    window.ApplePaySession &&
    window.ApplePaySession.supportsVersion(APPLE_PAY_VERSION) &&
    (parseFloat(getSafariVersion()) >= MIN_SAFARI_VERSION_APPLE_PAY ||
      getSafariVersion() === NOT_SAFARI);

  const handleApplePay = useCallback(
    async (
      cart,
      source,
      handleCartCheckout,
      setIsLoading,
      isBillingSameAsShipping,
      billingAddress,
      addressValidationEnabled
    ) => {
      if (
        !window.ApplePaySession ||
        !ApplePaySession.canMakePayments() ||
        !ApplePaySession.supportsVersion(APPLE_PAY_VERSION)
      ) {
        return;
      }
      const cartId = cart?.cartId;
      const isFromShoppingBag = source === "shoppingbag";
      let updatedCart = cart;

      const paymentRequest = constructApplePayRequestObject({
        cart,
        isFromShoppingBag,
        billingAddress,
        isBillingSameAsShipping,
        isDOO
      });
      const session = new ApplePaySession(APPLE_PAY_VERSION, paymentRequest);

      session.oncancel = () => {
        setIsLoading(false);
      };

      session.onvalidatemerchant = async (event) => {
        try {
          const validationURL = event.validationURL;

          const { data: responseData, errors } = await fetchValidateApplePay(
            validationURL
          );

          if (errors) {
            return;
          }

          session.completeMerchantValidation(
            responseData.validateApplePayEndpoint
          );
        } catch (error) {
          console.error(error.message);
        }
      };

      session.onshippingcontactselected = async (event) => {
        try {
          const { countryCode } = event?.shippingContact || {};
          const updateCartPayload = buildUpdateCartPayload({
            cart: updatedCart,
            cartId,
            shippingContact: event?.shippingContact,
            isDOO,
            isFromShoppingBag
          });

          const { data: updateCartResponse, errors } = await updateCart(
            updateCartPayload
          );
          updatedCart = updateCartResponse;

          if (errors?.length) {
            session.completeShippingContactSelection({
              errors: calculateShippingErrors(errors),
              newTotal: {
                label: "Serena & Lily",
                amount: getNumberValue(cart?.priceSummary?.totalPrice) || 10
              }
            });
            return;
          }
          const payload = getApplePayShippingMethodChanged(updateCartResponse);
          if (countryCode !== "US" && countryCode !== "CA") {
            session.completeShippingContactSelection({
              errors: calculateShippingErrors(errors, countryCode),
              ...payload
            });
            return;
          }

          session.completeShippingContactSelection({
            status: ApplePaySession.STATUS_SUCCESS,
            ...payload
          });
        } catch (error) {
          session.completeShippingContactSelection({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [
              new ApplePayError(
                "shippingContactInvalid",
                "addressLines",
                "There's an issue with the cart"
              )
            ],
            newTotal: {
              label: "Serena & Lily",
              amount: getNumberValue(cart?.priceSummary?.totalPrice) || 10
            }
          });
        }
      };

      session.onpaymentauthorized = async (event) => {
        try {
          const payment = event.payment;

          const shippingAddress = convertAppleAddressToAddress({
            address: payment?.shippingContact
          });

          if (isFromShoppingBag) {
            const validatedAddress = await validateAddress({
              addressValidationEnabled,
              variables: {
                address: shippingAddress
              }
            });

            const { addressType, addressValidationStatus, suggestedAddress } =
              validatedAddress;

            shippingAddress.addressType = addressType || null;
            shippingAddress.addressValidationStatus =
              addressValidationStatus || "DISABLED";
            shippingAddress.suggestedAddress =
              JSON.stringify(suggestedAddress) || null;
          }

          const billingAddress = convertAppleAddressToAddress({
            address: payment?.billingContact
          });

          let updateCartPayload = {
            cartId,
            shippingAddress,
            billingAddress,
            email: payment?.shippingContact?.emailAddress,
            phoneNumber: payment?.shippingContact?.phoneNumber
          };
          if (isDOO && !isFromShoppingBag) {
            updateCartPayload.taxableStatus = cart.taxableStatus;
            updateCartPayload.attention = cart.attention;
            updateCartPayload.companyName = cart.companyName;
            updateCartPayload.shippingAddress = {
              ...updateCartPayload.shippingAddress,
              companyName: cart.companyName,
              firstName: "",
              lastName: ""
            };
          }

          const { data: updateCartData, errors: errorsCart } = await updateCart(
            updateCartPayload
          );
          if (errorsCart?.length) {
            session.completePayment({
              status: ApplePaySession.STATUS_FAILURE,
              errors: errorsCart
            });
          }
          updatedCart = updateCartData;

          const updatePaymentPayload = {
            cartId,
            processor: PAYMENT_TYPES.CYBERSOURCE,
            tokenType: PAYMENT_TYPES.APPLE_PAY,
            paymentToken: btoa(JSON.stringify(payment.token.paymentData)),
            maskedCard: payment?.token?.paymentMethod?.displayName,
            cardType: payment?.token?.paymentMethod?.network,
            paymentAmount: cart?.priceSummary?.totalPrice
          };

          const { errors: errorsCartPayments } = await updateCartPayments(
            updatePaymentPayload
          );
          if (errorsCartPayments?.length) {
            session.completePayment({
              status: ApplePaySession.STATUS_FAILURE,
              errors: errorsCartPayments
            });
          }

          const checkout = await handleCartCheckout({
            cartId: updateCartData?.cartId,
            email: updateCartData?.email
          });
          if (checkout?.errors?.length) throw new Error(checkout?.error);

          const result = {
            status: ApplePaySession.STATUS_SUCCESS
          };
          session.completePayment(result);
        } catch (error) {
          const result = {
            status: ApplePaySession.STATUS_FAILURE,
            error
          };
          session.completePayment(result);
        }
      };

      session.onshippingmethodselected = async (event) => {
        try {
          const shippingMethod = event.shippingMethod;

          let updateCartPayload = {
            cartId,
            shippingOverride: shippingMethod.identifier,
            email: cart.email
          };

          if (isDOO && !isFromShoppingBag) {
            updateCartPayload.taxableStatus = cart.taxableStatus;
            updateCartPayload.attention = cart.attention;
            updateCartPayload.companyName = cart.companyName;
            updateCartPayload.shippingAddress = {
              ...updateCartPayload.shippingAddress,
              companyName: cart.companyName,
              firstName: "",
              lastName: ""
            };
          }

          const { data: updateCartResponse } = await updateCart(
            updateCartPayload
          );
          updatedCart = updateCartResponse;

          const payload = getApplePayShippingMethodChanged(updateCartResponse);
          session.completeShippingMethodSelection(payload);
        } catch (error) {
          session.completeShippingMethodSelection({
            status: ApplePaySession.STATUS_FAILURE
          });
        }
      };

      session.oncouponcodechanged = async (event) => {
        try {
          const couponCode = event.couponCode;
          const addPromoResponse = await addPromotionToCart({
            cartId,
            couponCode,
            context: getApolloAuthContext()
          });
          if (addPromoResponse?.errors && addPromoResponse?.errors.length) {
            let removePromo;
            if (cart.appliedPromotions.length > 0) {
              removePromo = await removePromotionFromCart({
                cartId,
                couponCode: cart.appliedPromotions[0].couponCode,
                context: getApolloAuthContext()
              });
            }
            throw {
              errors: addPromoResponse?.errors,
              cart: removePromo?.data || cart
            };
          }
          const payload = getApplePayShippingMethodChanged(
            addPromoResponse.data
          );

          session.completeCouponCodeChange(payload);
        } catch (error) {
          const couponCodeError = getCouponCodeErrorCode(error.errors);
          console.error({ couponCodeError });

          const payload = getApplePayShippingMethodChanged(error.cart);

          const result = {
            errors: calculateErrors(error.errors),
            ...payload
          };
          session.completeCouponCodeChange(result);
        }
      };

      session.begin();
    },
    [isDOO]
  );

  return { handleApplePay, isApplePayAvailable };
};

export default useApplePay;
