import { handleCookies } from "@serenaandlily";
import fetchStores from "@serenaandlily/gql/utils/fetchStores";
import { calculeHaversineDistance } from "@utils/getUserLocation/calculeHaversineDistance.util";
import { getUserPosition } from "@utils/getUserLocation/getUserPosition";
import { getLocation } from "@utils/getUserLocation/getLocation";
import { USER_GEOLOCATION_COOKIE_NAME } from "@constants";
import { getCookieValue } from "@serenaandlily/utils/getCookieValue";
const DEFAULT_COOKIE_LIFETIME_IN_SECONDS = 3600;
const COOKIE_LIFETIME_IN_SECONDS =
  process.env.NEXT_PUBLIC_GEO_COOKIE_TTL_SEC ?? DEFAULT_COOKIE_LIFETIME_IN_SECONDS;

const getClosestLocations = (referencePointUser, storeLocations) => {
  const maxStores = 3;
  return storeLocations.getStores
    ?.map((storeLocation) => ({
      ...storeLocation,
      distance: calculeHaversineDistance(referencePointUser, storeLocation)
    }))
    .sort((a, b) => a.distance - b.distance)
    .slice(0, maxStores);
};

const getInformation = (location, type) => {
  const address = location.results.find((d) =>
    d.types.includes("street_address")
  );
  return (
    address?.address_components?.find((d) => d.types.includes(type))
      ?.short_name || ""
  );
};

const makeLocationDataLayer = (location, stores, latitude, longitude) => {
  const minutes = 60;
  const expiration = Date.now() + 60 * minutes * 1000;
  return {
    countryCode: getInformation(location, "country"),
    state: getInformation(location, "administrative_area_level_1"),
    zipCode: getInformation(location, "postal_code"),
    city: getInformation(location, "locality"),
    expiration,
    lat: latitude,
    lng: longitude,
    stores: stores.map((store) => {
      return {
        storeId: store.storeId,
        storeName: store.name,
        distanceFromStoreMi: store.distance
      };
    })
  };
};

export const setUserGeolocation = async () => {
  const userGeolocation = getCookieValue(USER_GEOLOCATION_COOKIE_NAME);

  if (userGeolocation) {
    return;
  }

  let latitude, longitude;
  try {
    let position = await getUserPosition();
    latitude = position.latitude;
    longitude = position.longitude;
  } catch (error) {
    return;
  }
  let location;
  try {
    location = await getLocation(latitude, longitude);
  } catch (error) {
    return;
  }
  if (!location || getInformation(location, "country") !== "US") {
    return;
  }
  const stores = await fetchStores();

  const userReferencePoint = { latitude: latitude, longitude: longitude };
  const closestStores = getClosestLocations(userReferencePoint, stores);
  const locationDataLayer = makeLocationDataLayer(
    location,
    closestStores,
    latitude,
    longitude
  );
  handleCookies(USER_GEOLOCATION_COOKIE_NAME).updateCookie(
    encodeURIComponent(btoa(JSON.stringify(locationDataLayer))),
    {
      maxAge: COOKIE_LIFETIME_IN_SECONDS
    }
  );
};
