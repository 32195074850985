import currency from "currency.js";

const formatProductPrice = (data) => {
  const productPrices = {};
  const pricesArray = data?.displayPrice?.prices;
  if (pricesArray) {
    for (const priceData of pricesArray) {
      switch (priceData.type) {
        case "discounted":
          productPrices["discounted"] = priceData.formatted;
          break;
        case "list":
          productPrices["list"] = priceData.formatted;
          break;
        case "sale":
          productPrices["sale"] = priceData.formatted;
          break;
        case "designer":
          productPrices["designer"] = priceData.formatted;
          break;
      }
    }
  }
  if (productPrices.discounted && productPrices.discounted !== "") {
    return {
      priceType: "discounted",
      price: currency(productPrices.discounted).toString()
    };
  }
  if (productPrices.designer) {
    return {
      priceType: "designer",
      price: currency(productPrices.designer).toString()
    };
  }
  if (productPrices.sale) {
    return {
      priceType: "sale",
      price: currency(productPrices.sale).toString()
    };
  }
  return { priceType: "list", price: currency(productPrices.list).toString() };
};

export default formatProductPrice;
