import { gql } from "@apollo/client";

const buildValidateApplePayURL = () => {
  return gql`
    query validateApplePayEndpoint($validationUrl: String) {
      validateApplePayEndpoint(validationUrl: $validationUrl) {
        epochTimestamp
        expiresAt
        merchantSessionIdentifier
        nonce
        merchantIdentifier
        domainName
        displayName
        signature
        operationalAnalyticsIdentifier
        retries
        pspId
      }
    }
  `;
};

export default buildValidateApplePayURL;
