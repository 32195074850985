import React, { forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  CallToActionWrapper,
  CallToAction,
  IconWrapper,
  IconContainer,
  CTATextWrapper
} from "./CTAStyled";
import ICON_TYPES from "../Icons/constants";
import Icons from "../Icons";
import useComponentTheme from "../Theme/useComponentTheme";
import applyOverridesToObject from "../../utils/applyOverridesToObject";
import Link from "next/link";
import CounterBadge from "../CounterBadge";

const CTA = forwardRef(
  // eslint-disable-next-line max-lines-per-function, complexity
  (
    {
      buttonType = "button",
      caretRight = false,
      className,
      cta,
      ctaIcon,
      ctaIconPlacement = "right",
      disabled,
      elementName = "cta",
      icon,
      iconOnHover = false,
      isMockCTA = false,
      isPromoIcon,
      linkSpacing,
      orientation = "Horizontal",
      selectedCategory,
      scaleFontSize,
      theme,
      themeName,
      useButtonTag = false,
      tabIndex,
      useNextLink = false,
      counter,
      useMultiUnderline = false,
      ...themeOverrides
    },
    ref
  ) => {
    const {
      ctaText,
      url,
      openNewTab,
      ctaAlignment,
      marginBottom,
      scaleBottomMargin,
      scaledBottomMarginMobile,
      scaledBottomMarginTablet,
      scaledBottomMarginDesktop,
      onCtaClick,
      isLoading,
      customConfigs
    } = cta || {};

    const isCookieBannerToggle = url === "cookie-banner-trigger";

    const openCookieBanner = (event) => {
      event.preventDefault();
      Osano.cm.showDrawer("osano-cm-dom-info-dialog-open");
    };

    const buttonTheme = useComponentTheme({
      globalNamespace: "buttons",
      themeName: themeName,
      theme,
      themeOverrides
    });

    const [ctaTheme, setCtaTheme] = useState(buttonTheme);

    const buildCtaTheme = () => {
      applyOverridesToObject(
        buttonTheme,
        buttonTheme?.themeOverrides,
        setCtaTheme
      );
    };

    function determineHref(url, isCookieBannerToggle) {
      if (!url || isCookieBannerToggle) {
        return "#";
      }
      return url;
    }

    const href = determineHref(url, isCookieBannerToggle);
    let elementType = !url || isCookieBannerToggle ? "button" : "";

    if (useButtonTag) {
      elementType = buttonType;
    }

    useEffect(() => {
      buttonTheme?.themeOverrides && buildCtaTheme();
    }, []);

    useEffect(() => {
      buttonTheme?.themeOverrides && buildCtaTheme();
      !buttonTheme?.themeOverrides && setCtaTheme(buttonTheme);
    }, [buttonTheme]);

    const iconType = ICON_TYPES.CircularLoader;

    const target = openNewTab ? "_blank" : "_self";

    const ctaAlignmentValue = ctaAlignment ? ctaAlignment : "none";

    let alignmentProp;

    let tagType = "a";
    if (useButtonTag) tagType = "button";
    if (isMockCTA) tagType = "div";

    let buttonClassName = className;
    if (!useButtonTag && disabled) {
      buttonClassName += " disabled";
    }

    // TODO: convert this to a util
    switch (ctaAlignmentValue) {
      case "Left":
        alignmentProp = "flex-start";
        break;
      case "Center":
        alignmentProp = "center";
        break;
      case "Right":
        alignmentProp = "flex-end";
        break;
      default:
        alignmentProp = "";
    }

    const isSelected = selectedCategory === ctaText;
    let iconHoverDisplay = "block";
    if (iconOnHover) iconHoverDisplay = "none";
    if (isSelected) iconHoverDisplay = "block";

    let ctaIconFlexDir = "row";
    let iconPadding = "0 0 0 12px";
    if (ctaIconPlacement === "left") {
      ctaIconFlexDir = "row-reverse";
      iconPadding = "0 12px 0 0";
    }
    if (isPromoIcon) iconPadding = "0px";

    const conditionalHref = !useButtonTag ? { href } : {};
    const conditionalTarget = !useButtonTag ? { target } : {};
    const conditionalTabIndex = tabIndex ? { tabIndex } : {};

    const CTAContent = (
      <React.Fragment>
        <CallToAction
          ref={ref}
          type={elementType}
          {...conditionalHref}
          {...conditionalTarget}
          {...conditionalTabIndex}
          onClick={isCookieBannerToggle ? openCookieBanner : onCtaClick}
          isLoading={isLoading}
          as={useNextLink ? Link : tagType}
          tabIndex={tabIndex}
          customConfigs={customConfigs}
          theme={ctaTheme}
          className={buttonClassName}
          disabled={disabled}
          data-element={elementName}
        >
          <CTATextWrapper
            linkSpacing={linkSpacing}
            orientation={buttonTheme?.orientation || orientation}
            ctaIconFlexDir={ctaIconFlexDir}
          >
            {isLoading && (
              <IconWrapper>
                <Icons height={15} width={15} type={iconType} />
              </IconWrapper>
            )}
            {icon}
            {ctaText}
            {caretRight && (
              <Icons height={11} width={6} type="CaretRightLight" />
            )}
            {(ctaIcon || cta?.ctaIcon || buttonTheme?.ctaIconType) && (
              <IconContainer
                theme={buttonTheme}
                iconHoverDisplay={iconHoverDisplay}
                iconPadding={iconPadding}
              >
                <Icons
                  type={ctaIcon || cta?.ctaIcon || buttonTheme?.ctaIconType}
                  height={buttonTheme?.iconHeight}
                  width={buttonTheme?.iconWidth}
                  fill={cta?.ctaFill}
                />
                {!!counter && <CounterBadge>{counter}</CounterBadge>}
              </IconContainer>
            )}
          </CTATextWrapper>
        </CallToAction>
      </React.Fragment>
    );

    return alignmentProp || marginBottom || scaleBottomMargin ? (
      <CallToActionWrapper
        alignment={alignmentProp}
        multiUnderLineAlignment={ctaAlignmentValue}
        marginBottom={marginBottom}
        scaleBottomMargin={scaleBottomMargin}
        scaledBottomMarginMobile={scaledBottomMarginMobile}
        scaledBottomMarginTablet={scaledBottomMarginTablet}
        scaledBottomMarginDesktop={scaledBottomMarginDesktop}
        theme={ctaTheme}
      >
        {CTAContent}
      </CallToActionWrapper>
    ) : (
      CTAContent
    );
  }
);

export default CTA;

CTA.propTypes = {
  buttonType: PropTypes.oneOf(["button", "submit", "reset"]),
  caretRight: PropTypes.bool,
  className: PropTypes.string,
  cta: PropTypes.shape({
    ctaAlignment: PropTypes.string,
    ctaIcon: PropTypes.string,
    ctaText: PropTypes.string,
    marginBottom: PropTypes.number,
    onCtaClick: PropTypes.func,
    openNewTab: PropTypes.bool,
    url: PropTypes.string
  }),
  ctaIcon: PropTypes.string,
  ctaIconPlacement: PropTypes.string,
  disabled: PropTypes.bool,
  elementName: PropTypes.string,
  icon: PropTypes.any,
  iconOnHover: PropTypes.bool,
  isMockCTA: PropTypes.bool,
  isPromoIcon: PropTypes.bool,
  linkSpacing: PropTypes.number,
  orientation: PropTypes.string,
  selectedCategory: PropTypes.string,
  theme: PropTypes.object,
  themeName: PropTypes.string,
  themeOverrides: PropTypes.object,
  useButtonTag: PropTypes.bool,
  useNextLink: PropTypes.bool,
  tabIndex: PropTypes.string
};

CTA.displayName = "CTA";
