export const WISHLIST_FIELDS_SHORT = `
    customerEmail
    guestToken    
`;

export const WISHLIST_FIELDS_FULL = `
    customerEmail
    guestToken
    folders {
      id
      name
      note
      items {
        netsuiteId
        id
        customUpholsteryOptions {
          topLineVariation
          fabricCode
          fabricName
          otherOptions {
            name
            value
          }
        }
        quantity
        note
        details {
          name
          slug
          masterSku
          productType
          pdpUri
          imageUrl
          altImageUrl
          wasPrice
          nowPrice
          designerPrice
          isAvailableForPurchase
          newFlags
          showRolloverImage
          colorways {
            name
            pdpUri
          }
          variants {
            name
            value
          }
          promoMessages {
            message
            fontColor
            backgroundColor
            isBold
          }
          edd
        }
      }
    }
`;
