import React from "react";
import PropTypes from "prop-types";
import { DsoBanner, BannerContent } from "./LoggedInBannerStyled";
import Text from "../Text";
import getBannerMessage from "./util/getBannerMessage";
import defaultTheme from "./LoggedInBannerTheme";
import useComponentTheme from "../Theme/useComponentTheme";
import { Colors } from "../../constants/constants";
import { useContent } from "../../contexts/ContentContext";
import { TRADE_USER_BANNER_MESSAGE } from "../../constants/strings";

export const LOGGED_IN_BANNER = "logged-in-banner";
const LoggedInBanner = ({ user, theme = defaultTheme }) => {
  const {
    messages: { tradeUserBannerMessage = TRADE_USER_BANNER_MESSAGE } = {}
  } = useContent();

  const copy = getBannerMessage(user, tradeUserBannerMessage);
  const bannerTheme = useComponentTheme({ theme });

  return (
    <DsoBanner id={LOGGED_IN_BANNER}>
      <BannerContent>
        <Text {...bannerTheme?.message} copy={copy} fontColor={Colors.White} />
      </BannerContent>
    </DsoBanner>
  );
};

export default LoggedInBanner;

LoggedInBanner.propTypes = {
  user: PropTypes.object,
  collaborator: PropTypes.object,
  theme: PropTypes.object
};
