/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { getShippingOverride } from "@chv1-serenaandlily/contexts/CheckoutContext/utils";
import getNumberValue from "../../../utils/getNumberValue";
import { getBillingAddress } from "./getBillingAddress";

const COUNTRIES_MAP = {
  US: "United States",
  CA: "Canada"
};

const constructApplePayRequestObject = ({
  cart,
  isFromShoppingBag,
  billingAddress,
  isBillingSameAsShipping,
  isDOO
}) => {
  const totalPrice = getNumberValue(cart.priceSummary.totalPrice);
  const subtotal = getNumberValue(cart.priceSummary.subtotal);
  const taxes =
    cart.priceSummary.tax !== "--" ? getNumberValue(cart.priceSummary.tax) : 0;
  const discounts = getNumberValue(cart.priceSummary.discounts);
  let paymentBaseRequest = {
    applePayLaterAvailability: "unavailableItemIneligible",
    countryCode: "US",
    currencyCode: "USD",
    merchantCapabilities: ["supports3DS"],
    supportedNetworks: ["visa", "masterCard", "amex"],
    supportsCouponCode: false,
    shippingType: "shipping",
    requiredBillingContactFields: ["postalAddress", "name", "email"],
    requiredShippingContactFields: ["postalAddress", "name", "phone", "email"],
    // supportedCountries: ["US", "CA"],
    shippingMethods: [],
    lineItems: [],
    total: {
      label: "Serena & Lily",
      amount: totalPrice
    }
  };

  paymentBaseRequest.lineItems.push({
    label: "Subtotal",
    amount: subtotal
  });

  paymentBaseRequest.lineItems.push({
    label: "Taxes",
    amount: taxes
  });

  if (cart.priceSummary.discounts && discounts !== 0) {
    paymentBaseRequest.lineItems.push({
      label: "Discounts",
      amount: discounts
    });
  }

  if (cart.appliedPromotions.length > 0) {
    const codes = cart.appliedPromotions.map((promo) => promo.couponCode);
    paymentBaseRequest.couponCode = codes.join(",");
  }

  if (
    !isFromShoppingBag &&
    cart.shippingAddress &&
    cart.shippingAddress.postalCode
  ) {
    paymentBaseRequest.requiredShippingContactFields = [
      "postalAddress",
      "name",
      "phone",
      "email"
    ];
    paymentBaseRequest.shippingContact = {
      givenName: isDOO
        ? cart.shippingAddress.companyName
        : cart.shippingAddress.firstName,
      familyName: cart.shippingAddress.lastName,
      emailAddress: cart.email,
      phoneNumber: cart.phoneNumber,
      addressLines: [
        cart.shippingAddress.addressLine1,
        cart.shippingAddress.addressLine2
      ].filter(Boolean),
      locality: cart.shippingAddress.city,
      administrativeArea: cart.shippingAddress.stateProvince,
      postalCode: cart.shippingAddress.postalCode,
      country:
        COUNTRIES_MAP[cart.shippingAddress.country] ||
        cart.shippingAddress.country,
      countryCode: cart.shippingAddress.country
    };

    if (
      cart.priceSummary.deliveryFees &&
      cart.priceSummary.deliveryFees.length > 0
    ) {
      let shippingCost = 0;
      cart.priceSummary.deliveryFees.forEach((fee) => {
        shippingCost += fee.price !== "--" ? getNumberValue(fee.price) : 0;
      });

      paymentBaseRequest.lineItems.push({
        label: "Shipping",
        amount: shippingCost
      });
    }
  }

  if (!isFromShoppingBag && cart.billingAddress) {
    paymentBaseRequest.billingContact = getBillingAddress({
      cart,
      billingAddress,
      isBillingSameAsShipping
    });

    paymentBaseRequest.requiredBillingContactFields = ["postalAddress"];
  }

  if (cart.shippingItems.availableMethods.length > 0) {
    const selectedShippingMethodSku = getShippingOverride({ cart });
    if (selectedShippingMethodSku && selectedShippingMethodSku !== "") {
      const shippingMethod = cart.shippingItems.availableMethods.find(
        (method) => method.sku === selectedShippingMethodSku
      );

      paymentBaseRequest.shippingMethods.push({
        label: shippingMethod.name,
        amount: getNumberValue(shippingMethod.price),
        identifier: shippingMethod.sku,
        detail: ""
      });
      cart.shippingItems.availableMethods.forEach((method) => {
        if (method.sku !== selectedShippingMethodSku) {
          paymentBaseRequest.shippingMethods.push({
            label: method.name,
            amount: getNumberValue(method.price),
            identifier: method.sku,
            detail: ""
          });
        }
      });
    } else {
      const shippingMethodsOrdered = cart.shippingItems.availableMethods.sort(
        (a, b) => getNumberValue(a.price) - getNumberValue(b.price)
      );
      shippingMethodsOrdered.forEach((method) => {
        paymentBaseRequest.shippingMethods.push({
          label: method.name,
          amount: getNumberValue(method.price),
          identifier: method.sku,
          detail: ""
        });
      });
    }
  }
  return paymentBaseRequest;
};

export default constructApplePayRequestObject;
