import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

const fetchEdgeConfigValue = async (key) => {
  // Only run this on the client
  if (typeof window === "undefined") {
    return;
  }
  try {
    const res = await fetch(`/api/edge/getEdgeConfigValue?key=${key}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    });
    const configValue = await res.json();
    return configValue.value;
  } catch (error) {
    /* eslint-disable no-console */
    console.error(`Error fetching from edge config: ${error.message}`);
  }
};

(async () => {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || "";
  const DATADOG_SERVICE_NAME = baseUrl.replace(/^https?:\/\//, "");
  const middlewareBackend =
    process.env.NEXT_PUBLIC_NEW_BACKEND_ENDPOINT + "/graphql" || "";
  const RUM_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_RUM_TOKEN;
  const DATADOG_ENV = process.env.NEXT_PUBLIC_DATADOG_ENV;

  const sessionSampleRate = await fetchEdgeConfigValue(
    process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE
  );
  const sessionReplaySampleRate = await fetchEdgeConfigValue(
    process.env.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_RATE
  );

  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID,
    clientToken: RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: DATADOG_SERVICE_NAME,
    env: DATADOG_ENV,
    sessionSampleRate: sessionSampleRate,
    sessionReplaySampleRate: sessionReplaySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    trackInteractions: true,
    distributedTracingEnabled: true,
    allowedTracingUrls: [
      { match: baseUrl, propagatorTypes: ["tracecontext", "datadog"] },
      {
        match: middlewareBackend,
        propagatorTypes: ["tracecontext", "datadog"]
      }
    ],
    excludedActivityUrls: [
      middlewareBackend
    ],
    version: process.env.NEXT_PUBLIC_RELEASE_VERSION,
    beforeSend: (event, context) => {
      // discard a RUM error if its message includes 'profile is not defined'
      if (
        event.type === "resource" &&
        event.resource.type === "fetch" &&
        event.resource.url.includes("/graphql")
      ) {
        const parsedBody = JSON.parse(context?.requestInit?.body || "{}");
        event.context.operationName = parsedBody?.operationName;
        return true;
      }
    }
  });

  datadogLogs.init({
    clientToken: RUM_CLIENT_TOKEN,
    site: "datadoghq.com",
    env: DATADOG_ENV,
    service: DATADOG_SERVICE_NAME,
    forwardConsoleLogs: ["error"],
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
  });
})();

datadogRum.setGlobalContext({
  "git.commit.sha": process.env.NEXT_PUBLIC_GIT_COMMIT_SHA,
  "git.repository_url": process.env.NEXT_PUBLIC_GIT_REPOSITORY_URL
});

const DataDogInit = () => {
  return null;
};

export default DataDogInit;
