import useSWRImmutable from "swr/immutable";
import fetchWishlist from "../gql/utils/fetchWishlist";

export const constructWishlistQueryKey = ({
  customerEmail,
  guestToken,
  maxPromoMessages
}) => {
  const queryKey = ["wishlist"];
  if (customerEmail) queryKey.push(customerEmail);
  if (guestToken) queryKey.push(guestToken);
  if (maxPromoMessages !== undefined) queryKey.push(maxPromoMessages);
  return queryKey;
};

export async function wishlistFetcher({
  customerEmail,
  guestToken,
  maxPromoMessages
}) {
  if (!customerEmail && !guestToken) return false;

  return fetchWishlist({ customerEmail, guestToken, maxPromoMessages });
}

export const useWishlist = (wishlistParams) => {
  const { customerEmail, guestToken, maxPromoMessages } = wishlistParams;

  const queryKey = constructWishlistQueryKey({
    customerEmail,
    guestToken,
    maxPromoMessages,
    location
  });

  const {
    data,
    error,
    mutate: mutateWishlist,
    isValidating: isLoadingWishlist
  } = useSWRImmutable(queryKey, async () => {
    return wishlistFetcher(wishlistParams);
  });

  return {
    data: data?.getWishlist,
    error,
    queryKey,
    mutateWishlist,
    isLoadingWishlist
  };
};
