import { gql } from "@apollo/client";
import { CART_PAYMENT_FIELDS } from "../fragments/cart";

const buildSetPaymentInfoOnCartMutation = () => {
  return gql`
    ${CART_PAYMENT_FIELDS}
    mutation UpdateCartPayments($cartPayments: UpdateCartPayments) {
      updateCartPayments(cartPayments: $cartPayments) {
        payments {
          ...CartPaymentFields
        }
        cartId
      }
    }
  `;
};

export default buildSetPaymentInfoOnCartMutation;
