import remapThemeArrays from './remapThemeArrays';
import createValueResolver from "./createValueResolver";
import deepResolveValues from "./deepResolveValues";

/**
 * 
 * Searches through the theme and replaces any color, font or variable
 * string properties with the corresponding value from the lookup properties in the theme
 * e.g. "Midnight" becomes "#243953"
 * @param theme
 * @returns {*}
 */
const resolveThemeValues = (theme = {}) => {
  // eslint-disable-next-line no-unused-vars
  const {fonts, colors, variables, _meta, ...rest } = remapThemeArrays(theme);
  const resolver = createValueResolver(fonts, colors, variables);
  const resolvedTheme = deepResolveValues(rest, resolver);
  return {fonts, colors, variables, ...resolvedTheme }
};

export default resolveThemeValues