/* eslint-disable complexity */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  ImageWrapper,
  Image as StyledImage,
  NxImage,
  Container as ImageContainer
} from "./ImageStyled";
import getAssetUrl from "../../utils/getAssetUrl";
import checkUrlIsValid from "../../utils/checkUrlIsValid";
import getPlaceholderUrl from "../../utils/getPlaceholderUrl";

const Image = ({
  contentItemIndex,
  src,
  altText,
  width,
  height,
  objectFit,
  marginBottom,
  url
}) => {
  const assetUrl = getAssetUrl(src);
  const hasValidUrl = (url && checkUrlIsValid(url)) || false;
  const Container = hasValidUrl ? ImageContainer : Fragment;
  const imageProps = hasValidUrl ? { href: url } : null;
  const hasImagePriority = contentItemIndex === 0 ? true : false;
  return (
    <Container {...imageProps}>
      <ImageWrapper marginBottom={marginBottom} width={width} height={height}>
        {width && height ? (
          <NxImage
            src={assetUrl}
            width={width}
            height={height}
            objectFit={objectFit}
            placeholder="blur"
            blurDataURL={getPlaceholderUrl(width, height)}
            unoptimized={true}
            data-testid="next-image"
            alt={altText}
            {...(!hasImagePriority && { loading: "lazy" })}
            {...(hasImagePriority && { priority: true })}
          />
        ) : (
          <StyledImage
            src={assetUrl}
            width={width}
            height={height}
            objectFit={objectFit}
            alt={altText}
          />
        )}
      </ImageWrapper>
    </Container>
  );
};

export default Image;

Image.propTypes = {
  src: PropTypes.object,
  altText: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  objectFit: PropTypes.string,
  marginBottom: PropTypes.number,
  url: PropTypes.string,
  contentItemIndex: PropTypes.number
};
