import React, { createContext, useContext, useState, useEffect } from "react";
import PropType from "prop-types";
import getValueByKey from "../../utils/getValueByKey";

const ProductDiscountContext = createContext({});

const useDiscountContext = () => {
  return useContext(ProductDiscountContext);
};
// eslint-disable-next-line max-lines-per-function
const ProductDiscountProvider = ({ children }) => {
  const [discountSettings, setDiscountSettings] = useState();
  const [discountText, setDiscountText] = useState(null);
  const [uptoDiscountText, setUptoDiscountText] = useState(null);
  const [colorDiscountText, setColorDiscountText] = useState(null);
  const [colorDiscountValue, setColorDiscountValue] = useState(null);
  const [enableShowDiscount, setEnableShowDiscount] = useState(null);
  const [discountThreshold, setDiscountThreshold] = useState(null);
  const [requiresDepositSettings, setRequiresDepositSettings] = useState(null);
  useEffect(() => {
    if (discountSettings) {
      setDiscountText(getValueByKey("Discount default text", discountSettings));
      setUptoDiscountText(
        getValueByKey("Discount up to text", discountSettings)
      );
      setColorDiscountText(
        getValueByKey("Color discount text", discountSettings)
      );
      setColorDiscountValue(
        getValueByKey("Color discount value", discountSettings)
      );
      setEnableShowDiscount(
        getValueByKey("Enable show discount", discountSettings)
      );
      setDiscountThreshold(
        parseInt(getValueByKey("Discount display threshold", discountSettings))
      );
    }
  }, [discountSettings]);

  return (
    <ProductDiscountContext.Provider
      value={{
        discountText,
        uptoDiscountText,
        colorDiscountText,
        colorDiscountValue,
        discountThreshold,
        enableShowDiscount,
        requiresDepositSettings,
        discountSettings,
        setRequiresDepositSettings,
        setDiscountSettings
      }}
    >
      {children}
    </ProductDiscountContext.Provider>
  );
};

export { useDiscountContext, ProductDiscountProvider };

ProductDiscountProvider.propTypes = {
  children: PropType.any
};
