import React from "react";

const CaretDownCheckout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.29289 7.29289C0.902369 7.68342 0.902369 8.31658 1.29289 8.70711L11.2929 18.7071C11.6834 19.0976 12.3166 19.0976 12.7071 18.7071L22.7071 8.70711C23.0976 8.31659 23.0976 7.68342 22.7071 7.2929C22.3166 6.90237 21.6834 6.90237 21.2929 7.2929L12 16.5858L2.70711 7.29289C2.31658 6.90237 1.68342 6.90237 1.29289 7.29289Z"
        fill="#243953"
      />
    </svg>
  );
};

export default CaretDownCheckout;
