import { Colors, Typography } from "@serenaandlily/constants/constants";

const primary = {
  themeName: "Primary Button",
  typography: "cta",
  backgroundColor: "Midnight",
  backgroundColorActive: "Midnight 70%",
  backgroundColorDisabled: "Fog",
  backgroundColorHover: "Cloud Blue",
  borderColor: "Midnight",
  borderColorActive: "Midnight 70%",
  borderColorDisabled: "Fog",
  borderColorHover: "Cloud Blue",
  borderRadius: "borderRadiusPill",
  outlineColor: "outlineColor",
  padding: "16px 40px 13px",
  color: "White",
  colorActive: "White",
  colorDisabled: "Midnight 70%",
  colorHover: "Midnight"
};

const secondary = {
  themeName: "Secondary Button",
  typography: "cta",
  backgroundColor: "White",
  backgroundColorActive: "White",
  backgroundColorDisabled: "White",
  backgroundColorHover: "Cloud Blue",
  borderColor: "Midnight",
  borderColorActive: "Midnight",
  borderColorDisabled: "Midnight 70%",
  borderColorHover: "Cloud Blue",
  borderRadius: "borderRadiusPill",
  outlineColor: "outlineColor",
  padding: "16px 40px 13px",
  color: "Midnight",
  colorActive: "Midnight",
  colorDisabled: "Midnight 70%",
  colorHover: "Midnight",
  transform: "uppercase"
};

const secondaryTransparent = {
  themeName: "Secondary Button Transparent",
  typography: "cta",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "Midnight",
  borderColorActive: "Midnight",
  borderColorDisabled: "Midnight 70%",
  borderColorHover: "Cloud Blue",
  borderRadius: "borderRadiusPill",
  outlineColor: "outlineColor",
  padding: "16px 40px 13px",
  color: "Midnight",
  colorActive: "Midnight",
  colorDisabled: "Midnight 70%",
  colorHover: "Midnight",
  transform: "uppercase"
};

const checkout = {
  themeName: "Checkout Button",
  typography: "cta",
  backgroundColor: "Midnight",
  backgroundColorActive: "Midnight 70%",
  backgroundColorDisabled: "Fog",
  backgroundColorHover: "Cloud Blue",
  borderColor: "Midnight",
  borderColorActive: "Midnight 70%",
  borderColorDisabled: "Fog",
  borderColorHover: "Cloud Blue",
  borderRadius: "borderRadiusPill",
  outlineColor: "outlineColor",
  color: "White",
  fontFamily: Typography.BeatriceMedium,
  fontSize: "15px",
  fontSizeDesktop: "15px",
  fontSizeTablet: "15px",
  letterSpacing: "0.3px",
  colorActive: "White",
  colorDisabled: "Midnight 70%",
  colorHover: "Midnight",
  height: "48px"
};
const checkoutSecondary = {
  themeName: "Checkout Secondary Button",
  typography: "cta",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "Cloud Blue",
  borderColor: "Midnight",
  borderColorActive: "Midnight",
  borderColorDisabled: "Midnight 70%",
  borderColorHover: "Cloud Blue",
  borderRadius: "borderRadiusPill",
  outlineColor: "outlineColor",
  color: "Midnight",
  fontFamily: Typography.BeatriceMedium,
  fontSize: "15px",
  fontSizeDesktop: "15px",
  fontSizeTablet: "15px",
  colorActive: "Midnight",
  height: "48px",
  colorDisabled: "Midnight 70%",
  colorHover: "Midnight",
  transform: "uppercase"
};
const text = {
  themeName: "Text CTA",
  typography: "cta",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: "borderRadiusPill",
  outlineColor: "outlineColor",
  padding: "8px 16px 5px",
  color: "Midnight",
  colorActive: "Midnight",
  colorDisabled: "Midnight 70%",
  colorHover: "Midnight",
  showUnderline: true,
  showUnderlineAnimation: true,
  underlineBottomPosition: "-4px"
};

const textSmall = {
  themeName: "Text CTA Small",
  typography: "ctaSmall",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: "borderRadiusPill",
  outlineColor: "outlineColor",
  padding: "8px 16px 5px",
  color: "Midnight",
  colorActive: "Midnight",
  colorDisabled: "Midnight 70%",
  colorHover: "Midnight",
  showUnderline: true,
  showUnderlineAnimation: true,
  underlineBottomPosition: "-4px"
};

const buttonA = {
  themeName: "Button A",
  backgroundColor: "White",
  backgroundColorActive: "White",
  backgroundColorDisabled: "White",
  backgroundColorHover: "White",
  borderColor: "Dark Primary",
  borderColorActive: "Gray Medium",
  borderColorDisabled: "Dark Primary",
  borderColorHover: "Gray Medium",
  borderRadius: 0,
  fontFamily: "Proxima Nova Regular",
  fontSize: "12px",
  letterSpacing: "1px",
  lineHeight: "1",
  outlineColor: "transparent",
  padding: "12px 20px",
  color: "Dark Primary",
  colorActive: "Gray Medium",
  colorDisabled: "Dark Primary",
  colorHover: "Gray Medium",
  transform: "none"
};

const buttonB = {
  themeName: "Button B",
  backgroundColor: "#383838",
  backgroundColorActive: "#858585",
  backgroundColorDisabled: "#858585",
  backgroundColorHover: "#858585",
  borderColor: "#383838",
  borderColorActive: "#858585",
  borderColorDisabled: "#858585",
  borderColorHover: "#858585",
  borderRadius: 0,
  fontFamily: "Proxima Nova Regular",
  fontSize: "12px",
  letterSpacing: "1px",
  lineHeight: "1",
  outlineColor: "transparent",
  padding: "12px 20px",
  color: "White",
  colorActive: "White",
  colorDisabled: "White",
  colorHover: "White",
  transform: "none"
};

const buttonC = {
  themeName: "Button C",
  backgroundColor: "White",
  backgroundColorActive: "Transparent Silver",
  backgroundColorDisabled: "White",
  backgroundColorHover: "Transparent Silver",
  borderColor: "Silver",
  borderColorActive: "Gray Medium",
  borderColorDisabled: "Silver",
  borderColorHover: "Gray Medium",
  borderRadius: 0,
  fontFamily: "Proxima Nova Regular",
  fontSize: "12px",
  letterSpacing: "1px",
  lineHeight: "1",
  outlineColor: "transparent",
  padding: "12px 20px",
  color: "Dark Primary",
  colorActive: "Gray Medium",
  colorDisabled: "Dark Primary",
  colorHover: "Gray Medium",
  transform: "none"
};

const buttonD = {
  themeName: "Button D",
  backgroundColor: "White",
  backgroundColorActive: "White",
  backgroundColorDisabled: "White",
  backgroundColorHover: "White",
  borderColor: "Light Grey",
  borderColorActive: "Light Grey",
  borderColorDisabled: "Light Grey",
  borderColorHover: "Light Grey",
  borderRadius: 0,
  fontFamily: "Caslon Roman",
  fontSize: "9.5px",
  letterSpacing: "unset",
  lineHeight: "1",
  outlineColor: "transparent",
  padding: "5px 16px",
  color: "Dark Primary",
  colorActive: "Dark Primary",
  colorDisabled: "Dark Primary",
  colorHover: "Dark Primary",
  transform: "uppercase"
};

const ctaBlack = {
  themeName: "CTA Black",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: 0,
  fontFamily: "Proxima Nova Regular",
  fontSize: "12px",
  letterSpacing: "1px",
  lineHeight: " 1.43",
  outlineColor: "transparent",
  padding: "none",
  color: "Dark Primary",
  colorActive: "Dark Primary",
  colorDisabled: "Dark Primary",
  colorHover: "Gray Medium",
  transform: "uppercase",
  showUnderline: false,
  showUnderlineAnimation: false
};

const ctaWhite = {
  themeName: "CTA White",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: 0,
  fontFamily: "Proxima Nova Regular",
  fontSize: "12px",
  letterSpacing: "1px",
  lineHeight: " 1.43",
  outlineColor: "transparent",
  padding: "none",
  color: "White",
  colorActive: "White",
  colorDisabled: "White",
  colorHover: "Gray Medium",
  transform: "uppercase",
  showUnderline: false,
  showUnderlineAnimation: false
};

const ctaMidnight = {
  themeName: "CTA Midnight",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: 0,
  fontFamily: "Beatrice Light",
  fontSize: "12px",
  fontSizeDesktop: "14px",
  fontSizeTablet: "12px",
  letterSpacing: "1px",
  lineHeight: " 1.43",
  outlineColor: "transparent",
  padding: "none",
  color: "Midnight 70%",
  colorActive: "Midnight 70%",
  colorDisabled: "Midnight 70%",
  colorHover: "Midnight 40%",
  transform: "none",
  showUnderline: false,
  showUnderlineAnimation: false
};

const ctaDarkMidnight = {
  themeName: "CTA Dark Midnight",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: 0,
  fontFamily: "Beatrice Light",
  fontSize: "16px",
  fontSizeDesktop: "16px",
  fontSizeTablet: "16px",
  letterSpacing: "0.02em",
  lineHeight: " 1.43",
  outlineColor: "transparent",
  padding: "none",
  color: "Midnight",
  colorActive: "Midnight",
  colorDisabled: "Midnight",
  colorHover: "Midnight 70%",
  transform: "none",
  showUnderline: false,
  showUnderlineAnimation: false
};

const ctaSoftMidnight = {
  themeName: "CTA Soft Midnight",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: 0,
  fontFamily: "Beatrice Regular",
  fontSize: "16px",
  fontSizeDesktop: "16px",
  fontSizeTablet: "16px",
  letterSpacing: "0.02em",
  lineHeight: " 1.43",
  iconPadding: "0 10px 0 0",
  outlineColor: "transparent",
  padding: "none",
  color: "Midnight 70%",
  colorActive: "Midnight 70%",
  colorDisabled: "Midnight",
  colorHover: "Midnight 70%",
  transform: "none",
  showUnderline: false,
  showUnderlineAnimation: false
};

const ctaDarkMidnightRegular = {
  themeName: "CTA Dark Midnight Regular",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: 0,
  fontFamily: "BeatriceRegular",
  fontWeight: "300",
  fontSize: "16px",
  fontSizeDesktop: "16px",
  fontSizeTablet: "16px",
  letterSpacing: "0.02em",
  lineHeight: " 1.43",
  outlineColor: "transparent",
  padding: "none",
  color: "Midnight",
  colorActive: "Midnight",
  colorDisabled: "Midnight",
  colorHover: "Midnight 70%",
  transform: "none",
  showUnderline: false,
  showUnderlineAnimation: false
};

const ctaUnderlined = {
  themeName: "CTA Underlined",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: 0,
  fontFamily: "Proxima Nova Regular",
  fontSize: "12px",
  letterSpacing: "1px",
  lineHeight: " 1.67",
  outlineColorActive: "transparent",
  outlineColor: "Dark Primary",
  padding: "none",
  color: "Dark Primary",
  colorActive: "Dark Primary",
  colorDisabled: "Dark Primary",
  colorHover: "Gray Medium",
  transform: "uppercase",
  showUnderline: true,
  showUnderlineAnimation: false
};

const ctaArrow = {
  themeName: "CTA Arrow",
  fontFamily: "Beatrice Regular",
  fontSize: "12px",
  letterSpacing: "1px",
  lineHeight: " 1.67",
  color: "Dark Primary",
  transform: "uppercase",
  ctaIconType: "ArrowFilled",
  iconBottom: "0px",
  iconWidth: 6,
  iconHeight: 6,
  iconPaddingLeft: "3px"
};
const ctaModalText = {
  themeName: "CTA Modal Text",
  fontFamily: "Beatrice Medium",
  fontSize: "13px",
  letterSpacing: "0.3px",
  lineHeight: " 1.04",
  color: "Midnight",
  transform: "uppercase",
  showUnderline: true,
  showUnderlineAnimation: false,
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent"
};
const ctaFilter = {
  themeName: "CTA Filter",
  showUnderline: false,
  showUnderlineAnimation: false,
  fontFamily: "BeatriceRegular",
  fontSize: "12px",
  fontSizeDesktop: "12px",
  fontSizeTablet: "12px",
  lineHeight: "1",
  transform: "uppercase",
  backgroundColor: "#FFFFFF",
  backgroundColorHover: "Natural",
  backgroundColorActive: "#FFFFFF",
  color: "Miodnight",
  colorActive: "Midnight",
  borderColor: "#A7B0BA",
  borderColorActive: "Cloud Blue",
  borderColorHover: "Natural",
  borderRadius: "borderRadiusPill",
  padding: "16px 16px 16px 24px",
  ctaIconType: "CloseFilters",
  iconWidth: 19,
  iconHeight: 18,
  iconPadding: "0 0 0 10px",
  height: "48px",
  width: "max-content"
};
const ctaChips = {
  themeName: "CTA Chips",
  showUnderline: false,
  showUnderlineAnimation: false,
  fontFamily: "Beatrice Light",
  fontSize: "14px",
  fontSizeDesktop: "14px",
  fontSizeTablet: "14px",
  lineHeight: "1.2",
  transform: "none",
  backgroundColor: "#F9F9F9",
  color: "Midnight",
  borderColor: "#A7B0BA",
  borderRadius: "borderRadiusPill",
  padding: "8px 12px",
  height: "max-content",
  width: "max-content",
  ctaIconType: "CloseChips",
  iconWidth: 8,
  iconHeight: 8,
  iconPadding: "0 0 0 5px",
  iconTop: "0px",
  orientation: "Horizontal",
  letterSpacing: "0.14px"
};

const ctaClose = {
  themeName: "CTA Close",
  ctaIconType: "Close",
  borderColor: "#A7B0BA",
  iconWidth: 10,
  iconHeight: 10,
  iconPaddingLeft: "0px",
  padding: "4px",
  height: "max-content",
  width: "max-content"
};

const ctaFooter = {
  themeName: "CTA Footer",
  showUnderline: false,
  showUnderlineAnimation: false,
  fontFamily: "Proxima Nova Regular",
  fontSize: "11px",
  color: "#767676"
};

const ctaNavLinks = {
  themeName: "CTA Nav Links",
  showUnderline: false,
  showUnderlineAnimation: false,
  fontFamily: "Proxima Nova Regular",
  fontSize: "12px",
  fontSizeDesktop: "12px",
  fontSizeTablet: "12px",
  transform: "capitalize",
  backgroundColor: "#FFFFFF",
  color: "Dark Primary",
  padding: "0px",
  display: "inline-block"
};

const breadcrumbLinks = {
  themeName: "Breadcrumb Links",
  showUnderline: false,
  showUnderlineAnimation: false,
  fontFamily: "BeatriceLight",
  fontSize: "12px",
  fontSizeDesktop: "12px",
  fontSizeTablet: "12px",
  transform: "capitalize",
  backgroundColor: "#FFFFFF",
  color: "Midnight",
  padding: "0px",
  display: "inline-block",
  lineHeight: "120%",
  letterSpacing: "0.12px"
};

const ctaSideNav = {
  themeName: "CTA Side Nav",
  showUnderline: false,
  showUnderlineAnimation: false,
  fontFamily: "Proxima Nova Regular",
  fontWeight: "300",
  fontSize: "11px",
  letterSpacing: "1.65px",
  color: "Medium Primary",
  colorHover: "Dark Primary",
  borderColor: "#FFFFFF",
  backgroundColor: "#FFFFFF",
  fontWeightHover: "400",
  padding: "0px",
  lineHeight: "17px"
};

const ctaSideNavActive = {
  ...ctaSideNav,
  themeName: "CTA Side Nav Active",
  letterSpacing: "1.65px",
  color: "Dark Primary",
  fontWeight: "400"
};

const buttonSort = {
  themeName: "Sort Button",
  typography: "cta",
  backgroundColor: "#FFFFFF",
  backgroundColorActive: "White",
  backgroundColorDisabled: "White",
  backgroundColorHover: "Natural",
  borderColorHover: "Natural",
  borderRadius: "borderRadiusPill",
  padding: "10px 20px",
  color: "Midnight",
  transform: "uppercase",
  fontFamily: "Beatrice Regular",
  fontSize: "12px",
  fontSizeDesktop: "12px",
  fontSizeTablet: "12px",
  showUnderline: false,
  showUnderlineAnimation: false,
  iconWidth: 16,
  iconHeight: 16,
  iconPaddingLeft: "16px",
  iconTop: "1px",
  height: "48px"
};

const ctaSlideout = {
  themeName: "CTA Slideout",
  backgroundColor: "transparent",
  backgroundColorActive: "transparent",
  backgroundColorDisabled: "transparent",
  backgroundColorHover: "transparent",
  borderColor: "transparent",
  borderColorActive: "transparent",
  borderColorDisabled: "transparent",
  borderColorHover: "transparent",
  borderRadius: 0,
  fontFamily: "Beatrice Light",
  fontSize: "12px",
  fontSizeDesktop: "14x",
  fontSizeTablet: "14px",
  letterSpacing: "1px",
  lineHeight: " 1.43",
  outlineColor: "transparent",
  padding: "10px 15px",
  color: "Midnight",
  colorActive: "Midnight",
  colorDisabled: "Midnight",
  colorHover: "Midnight",
  transform: "none",
  showUnderline: false,
  showUnderlineAnimation: false
};

const ctaLineItem = {
  themeName: "CTA Line Item",
  backgroundColor: "transparent",
  backgroundColorActive: "Midnight 40%",
  backgroundColorDisabled: "Midnight 40%",
  backgroundColorHover: "Midnight 40%",
  borderColor: "Border Gray",
  borderColorActive: "Border Gray",
  borderColorDisabled: "Border Gray",
  borderColorHover: "Border Gray",
  borderRadius: "borderRadiusPill",
  fontFamily: "Beatrice Medium",
  fontSize: "12px",
  letterSpacing: "0.24px",
  lineHeight: "1",
  outlineColor: "transparent",
  padding: "8px 26px",
  color: "Midnight",
  colorActive: "Midnight",
  colorDisabled: "Midnight",
  colorHover: "Midnight",
  transform: "none"
};

const ctaCheckoutStep = {
  themeName: "CTA Checkout Step",
  backgroundColor: "#383838",
  backgroundColorActive: "#858585",
  backgroundColorDisabled: "#858585",
  backgroundColorHover: "#858585",
  borderColor: "#383838",
  borderColorActive: "#858585",
  borderColorDisabled: "#858585",
  borderColorHover: "#858585",
  borderRadius: 0,
  fontFamily: "Proxima Nova Regular",
  fontSize: "12px",
  letterSpacing: "0.02em",
  lineHeight: "1",
  outlineColor: "transparent",
  padding: "11px 33px",
  color: "White",
  colorActive: "White",
  colorDisabled: "White",
  colorHover: "White",
  transform: "uppercase",
  ctaIconType: "ArrowFilledWhite",
  iconWidth: 6,
  iconHeight: 6,
  iconPaddingLeft: "3px",
  iconTop: "",
  iconBottom: "0px"
};

const ctaWizardButton = {
  themeName: "CTA Wizard Button",
  width: "100%",
  padding: "16px 40px",
  borderRadius: "40px",
  border: `1px solid ${Colors.Cream}`,
  backgroundColor: Colors.Cream,
  color: Colors["Dark Brown"],
  fontFamily: Typography.BeatriceMedium,
  fontSize: "15px",
  height: "48px"
};

export const CTAThemes = [
  breadcrumbLinks,
  buttonA,
  buttonB,
  buttonC,
  buttonD,
  buttonSort,
  ctaArrow,
  ctaBlack,
  ctaCheckoutStep,
  ctaClose,
  ctaDarkMidnight,
  ctaDarkMidnightRegular,
  ctaFilter,
  ctaFilter,
  ctaFooter,
  ctaLineItem,
  ctaMidnight,
  ctaNavLinks,
  ctaSideNav,
  ctaSideNavActive,
  ctaSideNavActive,
  ctaSoftMidnight,
  ctaSlideout,
  ctaUnderlined,
  ctaWhite,
  ctaWizardButton,
  primary,
  secondary,
  secondaryTransparent,
  checkout,
  text,
  textSmall,
  ctaChips,
  checkoutSecondary,
  ctaModalText
];
