import { GA4Events } from "./GA4Events";
import { consoleAnalyticsEventStyles as styles } from "styles/theme/analyticsLogging";

function initializeDataLayer() {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
}

function observeDataLayer(callback) {
  const originalPush = window.dataLayer.push;
  window.dataLayer.push = function (...args) {
    callback(args);
    return originalPush.apply(this, args);
  };

  window.dataLayer.reset = function () {
    this.length = 0;
  };
}

export function monitorGA4Events() {
  initializeDataLayer();
  observeDataLayer((args) => {
    (args || []).forEach((data) => {
      if (data?.event && GA4Events[data?.event]) {
        console.log("%c[GA4-Event]: " + data.event, styles);
        console.log(data);
      }
    });
  });
}
