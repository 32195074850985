import { Breakpoints } from "@serenaandlily/constants/constants";

const ensurePx = (value) => {
  if (typeof value === "number" || !isNaN(Number(value))) {
    return `${value}px`;
  }
  return value;
};

const removePx = (value) => {
  if (typeof value === "string" && value.endsWith("px")) {
    return parseFloat(value);
  }
  return value;
};

const getDynamicSizeValue = ({ attr, size, sizeTablet, sizeDesktop }) => {
  if (!size || !sizeTablet || !sizeDesktop) {
    // eslint-disable-next-line no-console
    console.warn(`Missing sizes for ${attr}.`);
    return "";
  }

  return `
    @media (max-width: ${Breakpoints.XSMobileSmallMax}) {
      ${attr}: ${ensurePx(size)};
    }

    @media (min-width: ${Breakpoints.XSMobileMidMax}) and (max-width: ${
    Breakpoints.XSMobileMax
  }) {
      ${attr}: clamp(${ensurePx(size)},  calc(${ensurePx(size)} + (${removePx(
    sizeTablet
  )} - ${removePx(size)}) * ((100vw - ${
    Breakpoints.XSMobileSmallMax
  }) / (${removePx(Breakpoints.XSMobileMax)} - ${removePx(
    Breakpoints.XSMobileMidMax
  )}))),  ${ensurePx(sizeTablet)});
    }

    @media (min-width: ${Breakpoints.SMTabletMin}) and (max-width: ${
    Breakpoints.MDLaptopMax
  }) {
      ${attr}: clamp(${ensurePx(sizeTablet)}, calc(${ensurePx(
    sizeTablet
  )} + (${removePx(sizeDesktop)} - ${removePx(sizeTablet)}) * ((100vw - ${
    Breakpoints.SMTabletMin
  }) / (${removePx(Breakpoints.MDLaptopMax)} - ${removePx(
    Breakpoints.SMTabletMin
  )}))), ${ensurePx(sizeDesktop)});
    }

    @media (min-width: ${Breakpoints.LGDesktopMin}) {
      ${attr}: ${ensurePx(sizeDesktop)};
    }
  `;
};

export default getDynamicSizeValue;
