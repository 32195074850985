import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 18;

const ArrowLeftLight = ({ height, width, fill="currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 18 11"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5.277.146a.522.522 0 0 1 .725 0c.2.196.2.512 0 .708L1.75 5h15.737c.283 0 .513.224.513.5s-.23.5-.513.5H1.75l4.252 4.146c.2.196.2.512 0 .708a.522.522 0 0 1-.725 0l-5.127-5a.493.493 0 0 1 0-.708l5.127-5Z"/>
    </IconSvg>
  );
};

ArrowLeftLight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default ArrowLeftLight;
