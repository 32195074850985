import apolloClient from "@chv1-serenaandlily/gql/client";
import { buildValidateAddressQuery } from "@chv1-serenaandlily/gql/queries";

const validateAddress = async ({
  addressValidationEnabled = false,
  variables = {}
}) => {
  if (!apolloClient) return;
  if (!addressValidationEnabled) {
    return { addressValidationStatus: "DISABLED" };
  }

  try {
    const VALIDATE_ADDRESS = buildValidateAddressQuery();
    const validatedAddress = await apolloClient.query({
      query: VALIDATE_ADDRESS,
      variables,
      context: { clientName: "endpoint2" }
    });

    const {
      data: {
        validateAddress: {
          addressType,
          addressValidationStatus,
          suggestedAddress
        }
      }
    } = validatedAddress;

    const validationResponse = {
      addressType: addressType,
      addressValidationStatus: addressValidationStatus,
      suggestedAddress: JSON.stringify(suggestedAddress)
    };

    return validationResponse;
  } catch (err) {
    /* eslint-disable-next-line */
    console.error(`Unhandled exception in validateAddress.js: ${err.message}`);
  }
};

export default validateAddress;
