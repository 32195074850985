export const getUserPosition = async () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            ({coords}) => {
                resolve({latitude: coords.latitude, longitude: coords.longitude});
            },
            (err) => {
                reject();
            }
        )
    });
}
