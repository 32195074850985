import resolveThemeTypography from "./resolveThemeTypography";
import resolveThemeValues from "./resolveThemeValues";

/**
 *
 * Resolves values (like colors or fonts) and typography (like "headline1) within a theme.
 * @param theme
 * @returns {*}
 */
const resolveTheme = (theme = {}) => {
  const resolvedValues = resolveThemeValues(theme) || {};
  return resolveThemeTypography(resolvedValues) || {}
};

export default resolveTheme