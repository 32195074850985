export const EMPTY_CREATE_ACCOUNT_FORM = {
  firstName: "",
  lastName: "",
  email: "",
  confirmEmail: "",
  password: "",
  confirmPassword: "",
  addToMailingList: true
};

export const CREATE_ACCOUNT_FORM_TEXTS = {
  formTitle: "Create Account",
  nameSection: "NAME",
  emailSection: "EMAIL / LOGIN INFORMATION",
  regularError: "Please enter your ",
  addToMailingListText: "Please add me to the Serena & Lily email list.",
  submitText: "Create Account",
  passwordErrorMessage: "Please enter your new password.",
  confirmPasswordErrorMessage: "Please confirm your new password.",
  privacyPolicyText: "Privacy Policy."
};

export const CHECKOUT_REGISTRATION_TITLE = "Create an Account";
export const CHECKOUT_REGISTRATION_SUBTITLE =
  "Want a faster checkout? Create an account with us and you can skip a few steps next time. You can also access your order history.";

export const SIGNIN_FORM_TEXTS = {
  formTitle: "SIGN IN TO YOUR ACCOUNT",
  passwordLinkText: "Forgot your password",
  loginErrorText:
    "Oops! Your username or password is incorrect. Please try again. "
};

export const EMPTY_SIGNIN_FORM = {
  email: "",
  password: "",
  rememberUser: true
};

export const CREATE_ACCOUNT_TEXTS = {
  sectionTitle: "CREATE AN ACCOUNT ",
  listTitle: "Create an account and enjoy:",
  benefitsList: [
    "Easier order tracking",
    "Speedier Checkout",
    "Saved payment and shipping information"
  ],
  buttonText: "Let's Go"
};

export const ACCOUNT_VIEWS = {
  login: "Login",
  createAccount: "CreateNewAccount",
  loggedIn: "LoggedIn"
};

export const MIGRATED_FROM_SFCC_USERS_ERROR_TYPE =
  "AUTH_RESET_PASSWORD_REQUIRED";
