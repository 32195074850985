import { gql } from "@apollo/client";

const buildAddCustomerMutation = () => {
  return gql`
    mutation AddCustomer($customer: NewCustomer) {
      addCustomer(customer: $customer) {
        customerData {
          name
          lastName
          id
          firstName
          expires
          email
        }
      }
    }
  `;
};

export default buildAddCustomerMutation;
