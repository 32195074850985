import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 10;

const QuestionMark = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 6 10"
    >
      <path d="M2.74161 7.06238C2.43566 7.06238 2.18306 6.81396 2.20494 6.50879C2.22704 6.20064 2.27477 5.89356 2.37229 5.60357C2.48924 5.2558 2.65544 4.97419 2.87087 4.75876C3.08631 4.54332 3.34483 4.34481 3.64644 4.16323C3.82802 4.05244 3.99114 3.92164 4.13579 3.77083C4.28044 3.61695 4.39431 3.43999 4.47741 3.23994C4.56358 3.03989 4.60667 2.8183 4.60667 2.57516C4.60667 2.27356 4.53588 2.01195 4.39431 1.79036C4.25274 1.56877 4.06346 1.39796 3.82648 1.27793C3.5895 1.15791 3.32636 1.09789 3.03707 1.09789C2.7847 1.09789 2.54156 1.15021 2.30766 1.25485C2.07376 1.35949 1.87833 1.52415 1.72137 1.74882C1.65093 1.84965 1.59381 1.96442 1.55002 2.09315C1.45062 2.38536 1.20932 2.63056 0.900668 2.63056C0.56578 2.63056 0.290793 2.34748 0.372395 2.02269C0.44055 1.75142 0.54705 1.50625 0.691895 1.28717C0.941184 0.914772 1.26895 0.630089 1.6752 0.433119C2.08453 0.236149 2.53849 0.137665 3.03707 0.137665C3.57873 0.137665 4.04961 0.245382 4.44971 0.460818C4.85288 0.676254 5.16372 0.971708 5.38224 1.34718C5.60383 1.72265 5.71462 2.15045 5.71462 2.63056C5.71462 2.9691 5.6623 3.27533 5.55766 3.54924C5.4561 3.82315 5.30837 4.06783 5.11448 4.28326C4.92367 4.4987 4.69284 4.68951 4.42201 4.8557C4.15118 5.02498 3.9342 5.20348 3.77108 5.39122C3.60797 5.57588 3.48948 5.79593 3.41562 6.05137C3.37243 6.20074 3.34362 6.35437 3.32509 6.50952C3.28879 6.81331 3.04756 7.06238 2.74161 7.06238Z"/>
      <circle cx="2.7905" cy="8.92805" r="0.934301"/>
    </IconSvg>
  );
};

QuestionMark.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default QuestionMark;
