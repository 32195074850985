const extraChars = 5;
const getContentFromSchema = (schema) => {
  if (!schema.length) return null;
  const schemaParts = schema.split("/");
  const componentNameExtra = schemaParts[schemaParts.length - 1];
  const contentType = componentNameExtra.slice(
    0,
    componentNameExtra.length - extraChars
  );
  return contentType;
};

export default getContentFromSchema;
