// Import schemas
import {
  SIGN_UP_SCHEMA,
  SIGN_UP_ATTEMPT_SCHEMA,
  SIGN_UP_FAILURE_SCHEMA,
  LOGIN_SCHEMA,
  LOGIN_ATTEMPT_SCHEMA,
  LOGIN_FAILURE_SCHEMA,
  LOGOUT_SCHEMA,
  SELECT_ITEM_SCHEMA,
  VIEW_ITEM_LIST_SCHEMA,
  WISHLIST_ITEM_SCHEMA,
  WISHLIST_ACCESSED_FAVORITES_GLOBAL_HEADER_HEART_SCHEMA,
  WISHLIST_USER_SCHEMA
} from "./schemas";
import { SMB_CAROUSEL_ITEM_SCHEMA } from "./schemas/smbSchemas";

export const eventSchemaValidationsConfig = Object.freeze({
  sign_up: SIGN_UP_SCHEMA,
  sign_up_attempt: SIGN_UP_ATTEMPT_SCHEMA,
  sign_up_failure: SIGN_UP_FAILURE_SCHEMA,
  login: LOGIN_SCHEMA,
  login_attempt: LOGIN_ATTEMPT_SCHEMA,
  login_failure: LOGIN_FAILURE_SCHEMA,
  logout: LOGOUT_SCHEMA,
  select_item: SELECT_ITEM_SCHEMA,
  smb_carousel_click: SMB_CAROUSEL_ITEM_SCHEMA,
  view_item_list: VIEW_ITEM_LIST_SCHEMA,
  item_favorited: WISHLIST_ITEM_SCHEMA,
  item_un_favorited: WISHLIST_ITEM_SCHEMA,
  item_considered_un_favorited: WISHLIST_ITEM_SCHEMA,
  favorites_show_list_view: WISHLIST_USER_SCHEMA,
  favorites_show_gallery_view: WISHLIST_USER_SCHEMA,
  favorites_remove_a_note: WISHLIST_ITEM_SCHEMA,
  favorites_edit_a_note: WISHLIST_ITEM_SCHEMA,
  favorites_add_a_note: WISHLIST_ITEM_SCHEMA,
  favorites_move_to_bag: WISHLIST_ITEM_SCHEMA,
  favorites_register_cta: WISHLIST_USER_SCHEMA,
  accessed_favorites_global_header_heart:
    WISHLIST_ACCESSED_FAVORITES_GLOBAL_HEADER_HEART_SCHEMA,
  accessed_favorites_my_account: WISHLIST_USER_SCHEMA,
  accessed_favorites_default_load: WISHLIST_USER_SCHEMA
});
