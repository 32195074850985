import { Colors } from "./constants";

export const colorOptions = [
  { name: "White", colorCode: Colors.White },
  { name: "Tan", colorCode: Colors["Cu Color Tan"] },
  { name: "Light Blue", colorCode: Colors["Cu Color Light Blue"] },
  { name: "Dark Blue", colorCode: Colors["Cu Color Dark Blue"] },
  { name: "Green", colorCode: Colors["Cu Color Green"] },
  { name: "Coral", colorCode: Colors["Cu Color Coral"] },
  { name: "Yellow", colorCode: Colors["Cu Color Yellow"] },
  { name: "Grey", colorCode: Colors["Cu Color Grey"] },
  { name: "Brown", colorCode: Colors["Cu Color Brown"] },
  { name: "Black", colorCode: Colors["Cu Color Black"] }
];

export const colorOptionsNames = colorOptions.map((color) => color.name);

export const gradeOptions = ["Pattern", "Solid"];

export const typeOptions = [
  "Performance",
  "Leather",
  "Velvet",
  "Cotton",
  "Linen"
];

export const filterTitles = {
  colorTitle: "filter by color:",
  gradeTitle: "filter by grade:",
  typeTitle: "filter by type:"
};

export const initialFilters = {
  selectedColors: [],
  selectedGrades: [],
  selectedTypes: [],
  isQuickshipSelected: false,
  availableFilters: [...gradeOptions, ...typeOptions, ...colorOptionsNames]
};
