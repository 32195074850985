import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 18;

const ShopMenu = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill="none"
      stroke={fill}
      viewBox="0 0 18 16"
    >
      <path d="M17 8H1M17 14.526H1M17 1.474H1" strokeLinecap="round"/>
    </IconSvg>
  );
};

ShopMenu.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default ShopMenu;
