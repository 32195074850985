import useSWR from "swr";
import axios from "axios";

const fetcher = async () => {
  return axios({
    url: "/api/user",
    method: "post",
    headers: { "Content-Type": "application/json" }
  });
};

export default function useUser() {
  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
    mutate: mutateUser
  } = useSWR("/api/user", fetcher, {
    revalidateIfStale: false
  });

  return {
    user,
    userError,
    isUserLoading,
    mutateUser
  };
}
