import React, { createContext, useContext, useState } from "react";
import PropType from "prop-types";

const SearchContext = createContext();

const SearchProvider = ({ children }) => {
  const [searchValue, setSearchValue] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [selectedSort, setSelectedSort] = useState({});
  const [searchBarValue, setSearchBarValue] = useState("");
  const [autocompleteResults, setAutocompleteResults] = useState({});
  const [searchBarChanged, setSearchBarChanged] = useState(false);
  const [loadingRedirection, setLoadingRedirection] = useState(true);
  const [activeSearch, setActiveSearch] = useState(false);
  const [autocompleteString, setAutocompleteString] = useState("");
  const [autocompleteText, setAutocompleteText] = useState("");
  const [page, setPage] = useState(1);
  const [storedSearchItems, setStoredSearchItems] = useState({});
  const [showAllResults, setShowAllResults] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  // tiles prop (mocked) is only here until refactor with constructor.io is implemented
  const [sortTitle, setSortTitle] = useState("SORT");

  return (
    <SearchContext.Provider
      value={{
        autocompleteResults,
        loadingRedirection,
        searchBarChanged,
        searchValue,
        selectedSort,
        searchBarValue,
        showSearchBar,
        activeSearch,
        autocompleteString,
        autocompleteText,
        page,
        storedSearchItems,
        showAllResults,
        showFilters,
        sortTitle,
        setSearchValue,
        setShowSearchBar,
        setSearchBarValue,
        setSelectedSort,
        setAutocompleteResults,
        setSearchBarChanged,
        setLoadingRedirection,
        setActiveSearch,
        setAutocompleteString,
        setAutocompleteText,
        setPage,
        setStoredSearchItems,
        setShowAllResults,
        setShowFilters,
        setSortTitle
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

const useSearch = () => {
  const context = useContext(SearchContext);
  return context;
};

export { useSearch, SearchProvider };

SearchProvider.propTypes = {
  children: PropType.any
};
