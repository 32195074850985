import React from "react";

const WishlistHeart = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 34 34"
      fill="none"
      {...props}
    >
      <path
        d="M16.9989 11.0269C15.3885 6.79229 9.62772 3.19742 5.4911 7.58917C-1.3684 14.872 7.81148 25.9266 16.9989 29.3013C26.1863 25.9266 35.3662 14.872 28.5067 7.58917C24.3701 3.19742 18.6093 6.79229 16.9989 11.0269Z"
        stroke="#243953"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default WishlistHeart;
