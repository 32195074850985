import styled from "styled-components";
import { CallToAction, CallToActionWrapper } from "../CTA/CTAStyled";
import { MarkdownContainer } from "../RichText/RichTextStyled";

export const RichTextWithCountdownWrapper = styled.div`
  ${CallToActionWrapper}, ${CallToAction} {
    display: inline-block;
  }

  ${MarkdownContainer} > * {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: ${({ gap }) => `${gap}px`};
    }
  }
`;
