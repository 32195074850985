import { useMemo } from "react";
import useUser from "@lib/useUser";
import { useCart } from "@serenaandlily/contexts/CartContext";

export const useWishlistUserCreds = () => {
  const { user } = useUser();
  const { email, isDSO, isDOO, customerEmail } = user?.data || {};
  const cart = useCart();

  const cartId = useMemo(
    () => user?.data?.cartId || cart?.cartId,
    [user, cart]
  );

  const wishlistUserCreds = useMemo(
    () => ({
      customerEmail: (isDSO || isDOO) && customerEmail ? customerEmail : email,
      guestToken: email ? null : cartId
    }),
    [email, isDSO, isDOO, customerEmail, cartId]
  );

  return { wishlistUserCreds };
};
