import React from "react";
import Script from "next/script";
import { useRouter } from "next/router";
import { useBreakpoint } from "@serenaandlily/components/Breakpoints";
import setScript from "@serenaandlily/utils/setScript/setScript";

const OPTIMIZELYID = process.env.NEXT_PUBLIC_OPTIMIZELY_KEY;
const scriptUrl = `https://cdn.optimizely.com/js/${OPTIMIZELYID}.js`;

const OptimizelyScript = () => {
  const router = useRouter();
  const isOnHomepage = router.asPath === "/";
  const breakpoints = useBreakpoint();
  const isDesktop = !breakpoints["ScreenWidth"];

  if (isOnHomepage && isDesktop) {
    setScript(scriptUrl, () => {}, "head");
    return null;
  }

  return <Script src={scriptUrl} strategy="lazyOnload" />;
};

export default OptimizelyScript;
