import axios from "axios";
import handleErrors from "../errors/handleErrors";

function generateRequest(type, url, ...args) {
  return axios[type](url, ...args)
    .then((response) => {
      return { data: response.data, status: response.status };
    })
    .catch((error) => {
      return handleErrors(error);
    });
}
const apiClient = {
  get: (url, ...args) => generateRequest("get", url, ...args),
  post: (url, data, ...args) => generateRequest("post", url, data, ...args),
  put: (url, data, ...args) => generateRequest("put", url, data, ...args),
  patch: (url, data, ...args) => generateRequest("patch", url, data, ...args)
};

export default apiClient;
