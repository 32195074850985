import React from "react";
import PropTypes from "prop-types";
const CloseFullModal = ({stroke}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 4L20 20M20 4L4 20" stroke={stroke} strokeLinecap="round" />
    </svg>
  );
};

CloseFullModal.propTypes = {
  stroke: PropTypes.string
};

export default CloseFullModal;
