/* global Sailthru */

const customerID = process.env.NEXT_PUBLIC_SAILTHRU_CUSTOMER_ID;

const checkSailthru = () => {
  return (
    typeof Sailthru !== "undefined" && typeof Sailthru.init !== "undefined"
  );
};

const sailthruInit = () => {
  if (checkSailthru()) {
    Sailthru.init({ customerId: customerID });
  }
};

export default sailthruInit;
