import React, { createContext, useContext } from "react";
import PropType from "prop-types";

const UserContext = createContext();

const UserProvider = ({ children, user }) => {
  return (
    <UserContext.Provider
      value={{
        user: user?.user?.data,
        mutateUser: user?.mutateUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserData = () => {
  const context = useContext(UserContext);
  return context;
};

export { useUserData, UserProvider };

UserProvider.propTypes = {
  children: PropType.any,
  user: PropType.object
};
