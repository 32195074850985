import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 11;

const CaretRightLight = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 6 11"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M.146.146a.502.502 0 0 1 .708 0l5 5a.502.502 0 0 1 0 .708l-5 5a.502.502 0 0 1-.708-.708L4.793 5.5.146.854a.502.502 0 0 1 0-.708Z"/>
    </IconSvg>
  );
};

CaretRightLight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CaretRightLight;
