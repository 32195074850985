import React from "react";
import PropTypes from "prop-types";
import { SpinnerContainer, StyledSpinner } from "../IconsStyled";

const defaultLoaderSize = 50;

const CircularLoader = ({ height, width }) => {
  return (
    <SpinnerContainer data-element="circular-loader">
      <StyledSpinner
        viewBox="0 0 50 50"
        height={height || defaultLoaderSize}
        width={width || defaultLoaderSize}
      >
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="4"
        />
      </StyledSpinner>
    </SpinnerContainer>
  );
};

CircularLoader.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default CircularLoader;
