export const getCookieValue = (name) => {
  const array = document.cookie.split('; ');
  const cookies = {};

  for (let i = 0; i < array.length; i += 1) {
    const [key, value] = array[i].split('=');
    cookies[key] = value;
  }

  return cookies[name];
};