import currency from "currency.js";

const mapCartToAffirmCheckout = (cart) => {
  let BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;
  if (BASE_URL) {
    BASE_URL = `https://${BASE_URL}`;
  } else if (window?.location?.origin) {
    BASE_URL = window?.location?.origin;
  } else {
    BASE_URL = "";
  }

  const getNumericValue = (value) => {
    return !value || isNaN(currency(value)?.intValue)
      ? 0
      : currency(value)?.intValue;
  };

  const AFFIRM_URLS = {
    PAYMENT_CONFIRM: `${BASE_URL}/order-confirmation`,
    PAYMENT_CANCEL: `${BASE_URL}/shoppingbag`
  };

  const {
    shippingAddress: { phoneNumber, ...shippingAddress },
    billingAddress,
    email,
    cartId,
    priceSummary,
    cartItems
  } = cart;

  const mappedCartItems = cartItems?.map?.((item) => {
    return {
      display_name: item?.name,
      sku: item?.sku,
      unit_price: currency(item?.displayPrice?.unitPrice)?.intValue,
      qty: item?.quantity,
      item_image_url: item?.imageUrl,
      item_url: `${BASE_URL}/${item?.netsuiteId}.html`,
      categories: [[item?.department, item?.subDepartment]]
    };
  });

  const shippingSum = priceSummary?.deliveryFees?.reduce?.((sum, currFee) => {
    return currency(sum)?.add(currency(currFee?.price));
  }, currency("0"));

  return {
    merchant: {
      user_confirmation_url: AFFIRM_URLS.PAYMENT_CONFIRM,
      user_cancel_url: AFFIRM_URLS.PAYMENT_CANCEL,
      user_confirmation_url_action: "POST",
      name: "Serena and Lily"
    },
    shipping: {
      name: {
        first: shippingAddress?.firstName,
        last: shippingAddress?.lastName
      },
      address: {
        line1: shippingAddress?.addressLine1,
        line2: shippingAddress?.addressLine2,
        city: shippingAddress?.city,
        state: shippingAddress?.stateProvince,
        zipcode: shippingAddress?.postalCode,
        country: shippingAddress?.country
      },
      phone_number: phoneNumber,
      email: email
    },
    billing: {
      name: {
        first: billingAddress?.firstName,
        last: billingAddress?.lastName
      },
      address: {
        line1: billingAddress?.addressLine1,
        line2: billingAddress?.addressLine2,
        city: billingAddress?.city,
        state: billingAddress?.stateProvince,
        zipcode: billingAddress?.postalCode,
        country: billingAddress?.country
      },
      phone_number: phoneNumber,
      email: email
    },
    items: mappedCartItems,
    metadata: {
      mode: "modal"
    },
    order_id: cartId,
    currency: "USD",
    financing_program: "flyus_3z6r12r",
    shipping_amount: shippingSum?.intValue,
    tax_amount: getNumericValue(priceSummary.tax),
    total: currency(priceSummary?.totalPrice)?.intValue
  };
};

export default mapCartToAffirmCheckout;
