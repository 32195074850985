import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";



const Account = ({ height = 18.5, width = 18, fill="currentColor" }) => {
  return (
    <IconSvg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 18 18.5"
    >
      <path d="m5.306 6.005-.5.028.5-.028Zm7.388 0 .5.028-.5-.028Zm.084-1.511-.5-.028.5.028Zm-7.556 0 .5-.028-.5.028ZM17 18v.5h1V18h-1Zm-16 .5V18H0v.5h1Zm5.5-6h5v-1h-5v1ZM18 18a6.5 6.5 0 0 0-6.5-6.5v1A5.5 5.5 0 0 1 17 18h1ZM1 18a5.5 5.5 0 0 1 5.5-5.5v-1A6.5 6.5 0 0 0 0 18h1ZM12.279 4.466l-.084 1.512.998.055.084-1.511-.998-.056ZM5.805 5.978l-.084-1.512-.998.056.084 1.511.998-.055ZM9 9a3.2 3.2 0 0 1-3.195-3.022l-.998.055A4.2 4.2 0 0 0 9 10V9Zm3.195-3.022A3.2 3.2 0 0 1 9 9v1a4.2 4.2 0 0 0 4.193-3.967l-.998-.055ZM9 1a3.285 3.285 0 0 1 3.279 3.466l.998.056A4.284 4.284 0 0 0 9 0v1Zm0-1a4.284 4.284 0 0 0-4.277 4.522l.998-.056A3.285 3.285 0 0 1 9 1V0Z"/>
    </IconSvg>
  );
};

Account.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Account;
