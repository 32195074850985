import apiClient from "@serenaandlily/utils/api/apiClient";

/**
 * Logs data to the server.
 * @param {object} params - An object containing the following parameters:
 *   @param {string} func - The function or context where the log is generated.
 *   @param {object|array} [data] - Data to include in the log, can be an object or an array.
 */
async function logData({ func, data }) {
  try {
    // Convert data to an array format
    const logEntries = Array.isArray(data) ? data : [data];

    const logData = {
      func,
      data: logEntries
    };

    await apiClient.post("/api/log", logData);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error logging to server:", error);
  }
}

// Export the logging helper function for use in your application
export default logData;
