const deepRemoveEmpty = (obj) => {
  if (Array.isArray(obj)) {
    return (obj).map(member => deepRemoveEmpty(member));
  } else if (typeof obj === "object"  && obj) {
    return Object.entries(obj).reduce((acc, [key, val]) => {
      if (Array.isArray(val)) {
        acc[key] = (val).map(member => deepRemoveEmpty(member));
      } else if (typeof val === "object" && val) { //null is an object this also checking if val is truthy
        acc[key] = deepRemoveEmpty(val);
      } else if (val != null && val.length !== 0) {
        acc[key] = val;
      }
      return acc
    }, {})
  }
};
export default deepRemoveEmpty
