import { useMemo } from "react";
import { useTheme } from "styled-components";
import { getComponentTheme } from "../../utils/themeUtils";

const useComponentTheme = ({
  globalNamespace,
  themeName,
  theme,
  themeOverrides = {}
}) => {
  const globalTheme = useTheme();
  return useMemo(() => {
    return getComponentTheme({
      globalTheme,
      globalNamespace,
      themeName,
      theme,
      themeOverrides
    });
  }, [
    globalNamespace,
    themeName,
    globalTheme,
    theme,
    ...Object.values(themeOverrides)
  ]);
};

export default useComponentTheme;
