// https://betterprogramming.pub/how-to-use-media-queries-programmatically-in-react-4d6562c3bc97
import React, { useState, useEffect, createContext, useContext } from "react";
import PropType from "prop-types";
import { Breakpoints } from "../../constants/constants";
import isEmpty from "../../utils/isEmptyObject/isEmptyObject";

const defaultValue = {};

const BreakpointContext = createContext(defaultValue);

const BreakpointProvider = ({ children, queries }) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        );
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches = {};
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === "string") {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === "string") {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  if (isEmpty(queryMatch)) return <React.Fragment></React.Fragment>;

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  );
};

function useBreakpoint() {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error("useBreakpoint must be used within BreakpointProvider");
  }
  return context;
}

const QUERIES = {
  IPhone11: `(max-width: ${Breakpoints.IPhone11})`,
  SmallMobile: `(max-width: ${Breakpoints.SmallMobile})`,
  DesktopMobile: `(max-width: ${Breakpoints.DesktopMobile})`,
  IPad: `(max-width: ${Breakpoints.IPad})`,
  IPhone11ProMax: `(max-width: ${Breakpoints.IPhone11ProMax})`,
  IPhone14: `(max-width: ${Breakpoints.IPhone14})`,
  IPhone14ProMax: `(max-width: ${Breakpoints.IPhone14ProMax})`,
  ScreenWidth: `(max-width: ${Breakpoints.ScreenWidth})`,
  DesktopMedium: `(max-width: ${Breakpoints.DesktopMedium})`,
  DesktopLarge: `(max-width: ${Breakpoints.DesktopLarge})`,
  LgTablet: `(max-width: ${Breakpoints.LgTablet})`,

  XSMobileMin: `(min-width: ${Breakpoints.XSMobileMin}`,
  XSMobileMax: `(max-width: ${Breakpoints.XSMobileMax}`,
  SMTabletMin: `(min-width: ${Breakpoints.SMTabletMin}`,
  SMTabletMax: `(max-width: ${Breakpoints.SMTabletMax}`,
  MDLaptopMin: `(min-width: ${Breakpoints.MDLaptopMin}`,
  MDLaptopMax: `(max-width: ${Breakpoints.MDLaptopMax}`,
  LGDesktopMin: `(min-width: ${Breakpoints.LGDesktopMin}`
};

export { useBreakpoint, BreakpointProvider, QUERIES };

BreakpointProvider.propTypes = {
  children: PropType.any,
  queries: PropType.object
};
