import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Text from "../Text";

const Countdown = ({ endDate, endTime, timezone, ...rest }) => {
  const [remainingTime, setRemainingTime] = useState("");

  const calculateTime = () => {
    const isoDate = `${endDate}T${endTime}`;

    const targetTime = new Date(new Date(isoDate).toLocaleString("en-US"));

    const now = new Date();
    const nowInTimezone = new Date(
      now.toLocaleString("en-US", { timeZone: timezone })
    );

    const diff = targetTime - nowInTimezone;

    if (diff <= 0) {
      setRemainingTime("00:00:00");
      return;
    }

    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    setRemainingTime(
      `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
    );
  };

  useEffect(() => {
    calculateTime();

    const interval = setInterval(calculateTime, 1000);

    return () => clearInterval(interval);
  }, [endDate, endTime, timezone]);

  return <Text tag="span" copy={remainingTime} {...rest} />;
};

Countdown.propTypes = {
  endDate: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired
};

export default Countdown;
