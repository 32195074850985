import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect
} from "react";
import PropType from "prop-types";
import { initialFilters } from "../../constants/filterOptions";
import { CUSTOM_UPHOLSTERY } from "../../constants/productTypes";
import { getWishlistItemFromProduct } from "../../hooks/analytics/GA4Helpers";

const ProductDisplayProvider = createContext();

const useProductContext = () => {
  return useContext(ProductDisplayProvider);
};
ProductDisplayProvider.displayName = "ProductDisplayProvider";
// eslint-disable-next-line max-lines-per-function
const ProductProvider = ({
  children,
  productId,
  pdpProps,
  productInventories,
  initialMpn,
  preSelectedSwatch = null
}) => {
  const { variations, productType, customUpholstery } = pdpProps || {};

  const hasCustomUpholsteryProductTypes = useMemo(() => {
    return CUSTOM_UPHOLSTERY.includes(productType);
  }, [productType]);

  // eslint-disable-next-line complexity
  let initialVariations = useMemo(() => {
    let initial = {};
    if (initialMpn) {
      // if there is a mpn found in url's queryString, we set the initial variation to the variation of that child product
      const { childProducts } = pdpProps;
      const childProductIds = childProducts.map(
        (product) => product.netsuiteId
      );

      for (const childProduct of childProducts) {
        if (childProduct.netsuiteId === initialMpn) {
          for (const variant of childProduct.variants) {
            initial[variant.name] = {
              name: variant.value,
              description: variant.value
            };
          }
        } else if (!childProductIds.includes(initialMpn)) {
          for (const variant of childProducts[0].variants) {
            initial[variant.name] = {
              name: variant.value,
              description: variant.value
            };
          }
        }
      }
      // if there's nothing, we set initial variation to whichever is the first option
    } else if (variations && variations.length !== 0) {
      for (const variation of variations) {
        initial[variation.name] = variation?.options?.[0];
      }
    }
    if (hasCustomUpholsteryProductTypes) {
      const cuOtherOptions = customUpholstery?.otherOptions || null;
      for (const option of cuOtherOptions) {
        const { name, values } = option;
        const firstColor = values[0];

        initial[name] = {
          name: firstColor?.value,
          description: firstColor?.rgbColor,
          optionName: name
        };
      }

      if (productType === "cuUber") {
        initial["Size"] = {
          name: "Size",
          description: customUpholstery?.variations[0]?.options[0]?.name
        };
      }
    }
    if (preSelectedSwatch && preSelectedSwatch.color) {
      initial["Color"] = {
        name: preSelectedSwatch.color,
        description: preSelectedSwatch.color
      };
    }
    return initial;
  }, [variations, initialMpn, preSelectedSwatch]);

  const initialAvailability = useMemo(() => {
    let initialInventoriesMap = {};
    if (productInventories && productInventories.length > 0) {
      for (const inventory of productInventories) {
        const productId = inventory?.productId;
        initialInventoriesMap[productId] = inventory?.available;
      }
    }
    return initialInventoriesMap;
  }, [productInventories]);

  const [selectedSwatch, setSelectedSwatch] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [filtersCheck, setFiltersCheck] = useState(false);
  const [childProduct, setChildProduct] = useState(null);
  const [currentVariations, setCurrentVariations] = useState(initialVariations);
  const [availability, setAvailability] = useState(initialAvailability);
  const [scene7Url, setScene7Url] = useState(null);
  const [retailCheckoutText, setRetailCheckoutText] = useState({});
  const [cuImageIsLoading, setCuImageIsLoading] = useState(false);
  const [currentProductPrice, setCurrentProductPrice] = useState("");
  const [variationsHaveChanged, setVariationsHaveChanged] = useState(false);
  const [allFilteredSwatches, setAllFilteredSwatches] = useState([]);
  const [wishlistItemData, setWishlistItemData] = useState({});
  const [wishlistStatus, setWishlistStatus] = useState({});

  const wishlistGa4Props = useMemo(() => {
    return getWishlistItemFromProduct({
      productData: pdpProps,
      childProduct: childProduct,
      location: "pdp"
    });
  }, [pdpProps, childProduct]);

  useEffect(() => {
    setAvailability(initialAvailability);
  }, [initialAvailability, productInventories]);

  useEffect(() => {
    setCurrentVariations(initialVariations);
  }, [initialVariations]);
  return (
    <ProductDisplayProvider.Provider
      value={{
        hasCustomUpholsteryProductTypes,
        selectedSwatch,
        childProduct,
        selectedFilters,
        filtersCheck,
        currentVariations,
        retailCheckoutText,
        availability,
        scene7Url,
        cuImageIsLoading,
        currentProductPrice,
        wishlistItemData,
        wishlistStatus,
        wishlistGa4Props,
        setCurrentProductPrice,
        setSelectedSwatch,
        setChildProduct,
        setRetailCheckoutText,
        setSelectedFilters,
        setFiltersCheck,
        setCurrentVariations,
        setAvailability,
        setScene7Url,
        setCuImageIsLoading,
        setWishlistItemData,
        setWishlistStatus,
        productId,
        initialMpn,
        variationsHaveChanged,
        setVariationsHaveChanged,
        allFilteredSwatches,
        setAllFilteredSwatches
      }}
    >
      {children}
    </ProductDisplayProvider.Provider>
  );
};

export { useProductContext, ProductProvider };

ProductProvider.propTypes = {
  children: PropType.any,
  productId: PropType.string,
  initialMpn: PropType.string,
  pdpProps: PropType.object,
  productInventories: PropType.array
};
