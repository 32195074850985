import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 20;

const Chat = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 20 20"
    >
      <path d="M15 7a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v11.87c0 .92 1.137 1.351 1.747.664L4 17h9a2 2 0 0 0 2-2V7Z" fill="#fff"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4 16a1 1 0 0 0-.747.336L1 18.87V7a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4Zm0 1-2.253 2.534C1.137 20.221 0 19.79 0 18.87V7a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4Z"/>
      <path d="M5 2a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v11.87c0 .92-1.137 1.351-1.747.664L16 12H7a2 2 0 0 1-2-2V2Z" fill="#fff"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M16 11a1 1 0 0 1 .747.336L19 13.87V2a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9Zm0 1 2.253 2.534c.61.687 1.747.255 1.747-.664V2a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9Z"/>
      <path d="M13.324 6.064a.824.824 0 1 1-1.648 0 .824.824 0 0 1 1.648 0Zm-2.874 0a.824.824 0 1 1-1.65 0 .824.824 0 0 1 1.65 0Zm5.75 0a.824.824 0 1 1-1.65 0 .824.824 0 0 1 1.65 0Z"/>
    </IconSvg>
  );
};

Chat.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Chat;
