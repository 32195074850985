import { css } from "styled-components";

const defaultSize = 12;

const MSFluidTypography = {
  sizeNumber: 0,
  ratio: 1.125,
  base: 14.22,
  min: 0,
  max: 0,
  plusSize: 0,
  decimals: 2,
  style: "",

  setMinMaxPlusSize: function () {
    for (let i = 0; i <= this.sizeNumber; i++) {
      if (i === this.sizeNumber) {
        this.max = (this.min * this.ratio).toFixed(this.decimals);
      } else {
        this.min = (this.min * this.ratio).toFixed(this.decimals);
      }
    }
  },

  //eslint-disable-next-line complexity
  setMinMaxNoPlusSize: function () {
    for (let i = 0; i >= this.sizeNumber; i--) {
      if (i === this.sizeNumber) {
        this.min = (this.max / this.ratio).toFixed(this.decimals);
      } else if (i !== 0) {
        this.max = (this.max / this.ratio).toFixed(this.decimals);
      }
    }
  },

  calculateMinMaxSize: function () {
    if (this.plusSize) {
      this.setMinMaxPlusSize();
    } else {
      this.setMinMaxNoPlusSize();
    }
  },

  //eslint-disable-next-line complexity
  calculateFont: function (size = defaultSize) {
    this.style = "";
    this.sizeNumber = parseInt(size) || 0;
    this.plusSize = this.sizeNumber >= 0;
    this.min = this.plusSize ? this.base : "";
    this.max = this.plusSize ? "" : this.base;
    this.calculateMinMaxSize();

    this.style += `
            font-size: ${this.min}px;
            @media (min-width: 768px) and (max-width:1024px) {
                font-size:calc(${this.min}px + (${this.max} - ${this.min}) * (100vw - 768px)/256);
            }
            @media (min-width: 1024px) { 
                font-size:${this.max}px;
            }
            line-height: 1.5em;
            letter-spacing: 0.0628em;
            `;
    return css`
      ${this.style}
    `;
  }
};

export default MSFluidTypography;
