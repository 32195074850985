/* eslint-disable no-console */
/* eslint-disable complexity */
import { getShippingOverride } from "@chv1-serenaandlily/contexts/CheckoutContext/utils";
import getNumberValue from "../../../utils/getNumberValue";

const getApplePayShippingMethodChanged = (cart) => {
  const subtotal = getNumberValue(cart.priceSummary.subtotal);
  const taxes =
    cart.priceSummary.tax !== "--" ? getNumberValue(cart.priceSummary.tax) : 0;
  const discounts = getNumberValue(cart.priceSummary.discounts);
  let paymentBaseRequest = {
    newTotal: {
      label: "Serena & Lily",
      amount: getNumberValue(cart.priceSummary.totalPrice)
    },
    newLineItems: [],
    newShippingMethods: []
  };

  paymentBaseRequest.newLineItems.push({
    label: "Subtotal",
    amount: subtotal
  });

  paymentBaseRequest.newLineItems.push({
    label: "Taxes",
    amount: taxes
  });

  if (cart.priceSummary.discounts && discounts !== 0) {
    paymentBaseRequest.newLineItems.push({
      label: "Discounts",
      amount: discounts
    });
  }

  if (
    cart.priceSummary.deliveryFees &&
    cart.priceSummary.deliveryFees.length > 0
  ) {
    let shippingCost = 0;
    cart.priceSummary.deliveryFees.forEach((fee) => {
      shippingCost += fee.price !== "--" ? getNumberValue(fee.price) : 0;
    });

    paymentBaseRequest.newLineItems.push({
      label: "Shipping",
      amount: shippingCost
    });
  }
  if (cart.shippingItems.availableMethods.length > 0) {
    const selectedShippingMethodSku = getShippingOverride({ cart });

    if (selectedShippingMethodSku && selectedShippingMethodSku !== "") {
      const shippingMethod = cart.shippingItems.availableMethods.find(
        (method) => method.sku === selectedShippingMethodSku
      );

      paymentBaseRequest.newShippingMethods.push({
        label: shippingMethod.name,
        amount: getNumberValue(shippingMethod.price),
        identifier: shippingMethod.sku,
        detail: ""
      });
      cart.shippingItems.availableMethods.forEach((method) => {
        if (method.sku !== selectedShippingMethodSku) {
          paymentBaseRequest.newShippingMethods.push({
            label: method.name,
            amount: getNumberValue(method.price),
            identifier: method.sku,
            detail: ""
          });
        }
      });
    } else {
      const shippingMethodsOrdered = cart.shippingItems.availableMethods.sort(
        (a, b) => getNumberValue(a.price) - getNumberValue(b.price)
      );
      shippingMethodsOrdered.forEach((method) => {
        paymentBaseRequest.newShippingMethods.push({
          label: method.name,
          amount: getNumberValue(method.price),
          identifier: method.sku,
          detail: ""
        });
      });
    }
  }
  return paymentBaseRequest;
};

export default getApplePayShippingMethodChanged;
