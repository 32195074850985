/*global $A1*/
const productBrowsed = (productId, email) => {
  if (typeof $A1 === "undefined") {
    return;
  }
  let currentVisitor;
  if (!email) {
    currentVisitor = $A1.Customer({});
  } else {
    currentVisitor = $A1.Customer({
      Email: email,
      SourceCustomerNumber: email
    });
  }
  try {
    $A1
      .Event({
        type: "productBrowsed",
        customer: currentVisitor,
        targets: productId
      })
      .send();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};
export default productBrowsed;
