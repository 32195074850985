import styled from "styled-components";
import { Breakpoints, Typography, Colors } from "../../constants/constants";
import Image from "next/image";

export const Header = styled.header`
  display: block;
  text-align: center;
`;

export const Container = styled.div`
  display: block;
  text-align: center;
  width: 100%;
  height: 500px;
  background: #f5f3eeb2;
  padding-top: 50px;

  @media screen and (max-width: ${Breakpoints.SMTabletMin}) {
    height: 375px;
  }
`;

export const ApplicationErrorImage = styled(Image)`
  width: 704px;
  height: 383px;
  margin-top: 50px;
  object-fit: cover;
  object-position: center bottom;

  @media screen and (max-width: ${Breakpoints.SMTabletMin}) {
    width: 355px;
    height: 215px;
  }
`;

export const Logo = styled(Image)`
  width: 367px;
  height: 38px;
  margin-top: 33px;
  margin-bottom: 32px;
`;

export const TextContent = styled.div`
  text-align: center;
  margin: auto;
`;

export const Title = styled.h1`
  font-family: ${Typography.PPFragment};
  color: ${Colors.Midnight};
  text-align: center;
  font-size: 60px;
  line-height: 66px;
  font-weight: 400;
  margin-top: 0;

  @media screen and (max-width: ${Breakpoints.SMTabletMin}) {
    font-size: 40px;
  }
`;

export const Message = styled.p`
  display: none;
  font-family: ${Typography.BeatriceLight};
  color: ${Colors.Midnight};
  max-width: 400px;
  margin: auto;
  margin-bottom: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 300;

  @media screen and (max-width: ${Breakpoints.SMTabletMin}) {
    display: ${({ desktopOnly }) => (desktopOnly ? "none" : "block")};
    max-width: 200px;
  }

  @media screen and (min-width: ${Breakpoints.SMTabletMin}) {
    display: ${({ desktopOnly }) => (desktopOnly ? "block" : "none")};
  }
`;

export const ReturnLink = styled.a`
  font-family: ${Typography.BeatriceMedium};
  color: ${Colors.Midnight};
  border: 1px solid ${Colors["Midnight 70%"]};
  width: fit-content;
  display: block;
  background-color: transparent;
  padding: 16px 50px;
  margin: auto;
  border-radius: 100px;
  text-align: center;
  font-size: 15px;
  line-height: 21.6px;
  font-weight: 500;
  text-decoration: none;
`;
