import { z } from "zod";
import { zStringOrUndefined } from "./schemaSnippets";

export const WISHLIST_ACCESSED_FAVORITES_GLOBAL_HEADER_HEART_SCHEMA = z.object({
  event: z.string(),
  ecommerce: z.object({
    path: z.string().optional(),
    customerData: z.object({
      user_logged_in: z.boolean(),
      user_type: z.string(),
      customer_type: z.string()
    })
  })
});

export const WISHLIST_USER_SCHEMA =
  WISHLIST_ACCESSED_FAVORITES_GLOBAL_HEADER_HEART_SCHEMA;

export const WISHLIST_ITEM_SCHEMA = z.object({
  event: z.string(),
  ecommerce: z.object({
    view_type: zStringOrUndefined,
    customerData: z.object({
      user_logged_in: z.boolean(),
      user_type: z.string(),
      customer_type: z.string()
    }),
    favorites_item: z.object({
      favorites_item_id: z.string(),
      favorites_item_name: z.string(),
      favorites_item_category: zStringOrUndefined,
      favorites_item_category2: zStringOrUndefined,
      favorites_item_category3: zStringOrUndefined,
      favorites_item_variant: zStringOrUndefined,
      favorites_page_path: zStringOrUndefined
    })
  })
});
