import styled from "styled-components";
import { Colors } from "../../constants/constants";

export const DsoBanner = styled.div`
  background-color: ${Colors["Primary Midnight"]};
`;

export const BannerContent = styled.div`
  text-align: center;
  margin: auto;
  padding: 13px 10px;
  word-break: break-word;
`;
