import { gql } from "@apollo/client";
import {
  CART_DELIVERY_GROUP_FIELDS,
  CART_PRICE_FIELDS
} from "@chv1-gql/fragments/cart";

const buildUpdateCartShippingMutation = (variables) => {
  // optional arguments
  const taxableStatusDef =
    typeof variables?.taxableStatus === "boolean"
      ? "$taxableStatus: Boolean"
      : "";
  const billingAddressDef = variables?.billingAddress
    ? "$billingAddress: InputCartAddressItem"
    : "";
  const designServiceCodeDef = variables?.designServiceCode
    ? "$designServiceCode: String"
    : "";
  const shippingOverrideDef = variables?.shippingOverride
    ? "$shippingOverride: String"
    : "";
  const doNotShipUntilDateDef =
    variables?.doNotShipUntilDate || variables?.doNotShipUntilDate === ""
      ? "$doNotShipUntilDate: String"
      : "";
  const taxableStatus = taxableStatusDef ? "taxableStatus: $taxableStatus" : "";
  const billingAddress = variables?.billingAddress
    ? "billingAddress: $billingAddress"
    : "";
  const designServiceCode = variables?.designServiceCode
    ? "designServiceCode: $designServiceCode"
    : "";
  const shippingOverride = variables?.shippingOverride
    ? "shippingOverride: $shippingOverride"
    : "";
  const doNotShipUntilDate =
    variables?.doNotShipUntilDate || variables?.doNotShipUntilDate === ""
      ? "doNotShipUntilDate: $doNotShipUntilDate"
      : "";
  const shippingAddressInputDef = variables?.shippingAddress
    ? "$shippingAddress: InputCartAddressItem"
    : "";
  const shippingAddressDef = variables?.shippingAddress
    ? "shippingAddress: $shippingAddress"
    : "";
  const phoneNumberDef = variables?.phoneNumber ? "$phoneNumber: String" : "";
  const phoneNumber = variables?.phoneNumber ? "phoneNumber: $phoneNumber" : "";

  const giftInfoDef = variables?.giftInfo ? "$giftInfo: GiftInfo" : "";
  const giftInfo = variables?.giftInfo ? "giftInfo: $giftInfo" : "";
  const emailDef = variables?.email ? "$email: String" : "";
  const email = variables?.email ? "email: $email" : "";

  return gql`
    ${CART_PRICE_FIELDS}
    ${CART_DELIVERY_GROUP_FIELDS}
    mutation UpdateCart(
      $cartId: String
      ${shippingAddressInputDef}
      ${billingAddressDef}
      ${designServiceCodeDef}
      ${doNotShipUntilDateDef}
      ${emailDef}
      ${phoneNumberDef}
      ${shippingOverrideDef}
      ${taxableStatusDef}
      ${giftInfoDef}
    ) {
      updateCart(
        cartId: $cartId
        ${email}
        ${shippingAddressDef}
        ${billingAddress}
        ${designServiceCode}
        ${doNotShipUntilDate}
        ${phoneNumber}
        ${shippingOverride}
        ${taxableStatus}
        ${giftInfo}
        ) {
        attention
        doNotShipUntilDate
        warnings
        taxableStatus
        deliveryGroups {
          ...CartDeliveryGroupFields
        }
        priceSummary {
          ...CartPriceFields
        }
        giftInfo {
          giverName
          isGift
          message
          recipientEmail
          recipientName
        }
        shippingItems {
          appliedPromotions {
            couponCode
            name
          }
          shippingCartItems {
            id
            sku
            name
            displayPrice {
              amount
              currency
              includes_tax
              formatted
            }
            shippingCosts
          }
          availableMethods {
            sku
            name
            price
          }
        }
        shippingAddress {
          firstName
          lastName
          addressLine1
          addressLine2
          city
          stateProvince
          postalCode
          country
        }
      }
    }
  `;
};

export default buildUpdateCartShippingMutation;
