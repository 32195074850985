export default function getJsonFromString(str) {
  if (typeof str === "string") {
    try {
      return JSON.parse(str);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("could not convert string to json");
    }
  }
  return str;
}
