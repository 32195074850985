import buildFbEvent from "../mutations/buildFbEvent";
import apolloClient from "../client";

const fbEvents = async (params) => {
  if (!apolloClient) return;
  const BUILD_FB_EMAIL_SIGNUP = buildFbEvent();
  const response = await apolloClient.mutate({
    mutation: BUILD_FB_EMAIL_SIGNUP,
    variables: {
      event: { ...params }
    },
    context: { clientName: "endpoint2" }
  });
  return response;
};

export default fbEvents;
