const createValueResolver = (fonts = {}, colors = {}, variables = {}) => (value) => {
  const variableValue = variables[value];
  if (variableValue) {
    return colors[variableValue] ||
      fonts[variableValue] ||
      variableValue;
  }
  return colors[value] || fonts[value] || value;
};

export default createValueResolver