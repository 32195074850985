import React from "react";
import BreakpointRichText from "@serenaandlily/components/BreakpointRichText";
import PropTypes from "prop-types";

const SMBCarouselContent = ({ slideContent, countdown, disclaimer, gap }) => {
  return (
    <BreakpointRichText
      {...slideContent}
      countdownProps={countdown}
      ctaProps={disclaimer}
      gap={gap}
    />
  );
};

SMBCarouselContent.propTypes = {
  slideContent: PropTypes.object,
  countdown: PropTypes.object,
  disclaimer: PropTypes.object
};

export default SMBCarouselContent;
