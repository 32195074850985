import React from "react";
import PropTypes from "prop-types";
import { useBreakpoint } from "../Breakpoints";
import Typography from "../Typography";

/**
 * Text component conditionally displays text based on breakpoint.
 *
 * Usage:
 * name a typographic theme:
 * <Text themeName="textSmall" copy={"foo"} mobileCopy={"bar"} />
 *
 * pass in a typographic theme
 * <Text theme={theme} copy={"foo"} mobileCopy={"bar"} />
 *
 * merge a passed in theme with a named theme
 * <Text themeName="textSmall" theme={theme} copy={"foo"} mobileCopy={"bar"}/>
 *
 * override specific theme properties:
 * <Text themeName="textSmall" fontColor="blue" copy={"foo"} mobileCopy={"bar"} />
 *
 * @param copy
 * @param mobileCopy
 * @param themeName
 * @param theme
 * @param dataset
 * @param themeOverrides
 * @returns {*}
 * @constructor
 */
const Text = ({
  copy,
  mobileCopy,
  themeName,
  theme,
  elementName = "",
  "data-a-id": dataAId,
  "data-testid": dataTestId,
  ...themeOverrides
}) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const text = isMobile ? mobileCopy || copy : copy;
  if (text) {
    return (
      <Typography
        themeName={themeName}
        theme={theme}
        themeOverrides={themeOverrides}
        data-a-id={dataAId}
        data-testid={dataTestId}
        elementName={elementName}
      >
        {text}
      </Typography>
    );
  }
  return null;
};

export default Text;

Text.propTypes = {
  copy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileCopy: PropTypes.string,
  themeName: PropTypes.string,
  theme: PropTypes.object,
  "data-a-id": PropTypes.string,
  elementName: PropTypes.string
};
