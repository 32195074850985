import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import PropTypes from "prop-types";
import { useWishlist } from "@chv1-serenaandlily/hooks/useWishlist";
import { useWishlistUserCreds } from "@chv1-serenaandlily/hooks/useWishlistUserCreds";

const WishlistContext = createContext();

const WishlistProvider = ({ children }) => {
  const [currentFolder, setCurrentFolder] = useState("DEFAULT");
  const [optimisticAddedItems, setOptimisticAddedItems] = useState([]);
  const [optimisticRemovedItems, setOptimisticRemovedItems] = useState([]);
  const [wishlistContent, setWishlistContent] = useState({});
  const [loadingInitialized, setLoadingInitialized] = useState(false);

  const { wishlistUserCreds } = useWishlistUserCreds();

  const keyReady =
    wishlistUserCreds?.customerEmail || wishlistUserCreds?.guestToken;

  const {
    data: wishlistData,
    queryKey,
    mutateWishlist,
    isLoadingWishlist
  } = useWishlist({
    ...wishlistUserCreds
  });

  useEffect(() => {
    if (keyReady) {
      setLoadingInitialized(true);
    }
  }, [keyReady]);

  // This ensures we don't flicker the loading state
  const isLoading = !loadingInitialized || !keyReady || isLoadingWishlist;

  const currentFolderItems = useMemo(() => {
    return wishlistData?.folders?.find?.(
      (folder) => folder?.id === currentFolder
    )?.items;
  }, [wishlistData, currentFolder]);

  return (
    <WishlistContext.Provider
      value={{
        currentFolder,
        currentFolderItems,
        setCurrentFolder,
        wishlistContent,
        setWishlistContent,
        isLoadingWishlist,
        isLoading,
        wishlistData,
        mutateWishlist,
        optimisticAddedItems,
        setOptimisticAddedItems,
        optimisticRemovedItems,
        setOptimisticRemovedItems,
        queryKey
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

function useWishlistContext() {
  const context = useContext(WishlistContext);
  if (context) {
    return context;
  } else {
    //eslint-disable-next-line  no-console
    console.warn("Missing WishlistProvider for useWishlistContext");
    return {};
  }
}

export { useWishlistContext, WishlistProvider };

WishlistProvider.propTypes = {
  children: PropTypes.any
};
