export const applyOverridesToObject = (theme, themeOverrides, action) => {
  let initialValue = { ...theme };
  for (const key in themeOverrides) {
    if (key in theme || key === "useMultiUnderline") {
      initialValue = { ...initialValue, [key]: themeOverrides[key] };
    }
  }
  action(initialValue);
};

export default applyOverridesToObject;
