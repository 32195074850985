/*global $A1*/

const emailSubscribe = (email) => {
  if (typeof $A1 === "undefined") {
    return;
  }
  try {
    $A1
      .Event({
        type: "emailSubscribe",
        customer: $A1.Customer({
          Email: email,
          SourceCustomerNumber: email
        })
      })
      .send();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export default emailSubscribe;
