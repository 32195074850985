import styled, { css } from "styled-components";
import { Breakpoints } from "../../constants/constants";
import getFontSize from "../../utils/getFontSize";

// a mixin for getting type typography styles from theme by themeName
export const typographyCss = ({ theme, themeName }) => css`
  margin: ${theme?.typography?.[themeName]?.margin || 0};
  font-family: ${theme?.typography?.[themeName]?.fontFamily};
  color: ${theme?.typography?.[themeName]?.fontColor || "inherit"};
  font-size: ${typeof theme?.typography?.[themeName]?.fontSize === "number"
    ? `${theme?.typography?.[themeName]?.fontSize}px`
    : theme?.typography?.[themeName]?.fontSize};
  font-weight: normal;
  letter-spacing: ${typeof theme?.typography?.[themeName]?.letterSpacing ===
  "number"
    ? `${theme?.typography?.[themeName]?.letterSpacing}px`
    : theme?.typography?.[themeName]?.letterSpacing};
  line-height: ${theme?.typography?.[themeName]?.lineHeight};
  text-transform: ${theme?.typography?.[themeName]?.textTransform};
  @media screen and (min-width: ${Breakpoints.IPad}) {
    font-size: ${typeof theme?.typography?.[themeName]?.fontSizeTablet ===
    "number"
      ? `${theme?.typography?.[themeName]?.fontSizeTablet}px`
      : theme?.typography?.[themeName]?.fontSizeTablet};
  }
  @media screen and (min-width: ${Breakpoints.DesktopLarge}) {
    font-size: ${typeof theme?.typography?.[themeName]?.fontSizeDesktop ===
    "number"
      ? `${theme?.typography?.[themeName]?.fontSizeDesktop}px`
      : theme?.typography?.[themeName]?.fontSizeDesktop};
  }
`;

export const TypographyWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme?.margin || 0};
    ${theme?.marginBottom && `margin-bottom: ${theme?.marginBottom}px`};
    font-family: ${theme?.fontFamily};
    color: ${theme?.mobileFontColor ||
    theme?.tabletFontColor ||
    theme?.fontColor ||
    "inherit"};
    font-weight: ${theme?.fontWeight || "normal"};
    letter-spacing: ${typeof theme?.letterSpacing === "number"
      ? `${theme?.letterSpacing}px`
      : theme?.letterSpacing};
    line-height: ${theme?.lineHeight};
    text-transform: ${theme?.textTransform};
    max-width: ${theme?.maxWidth};
    text-overflow: ${theme?.textOverflow};
    overflow: ${theme?.overflow};
    white-space: ${theme?.whiteSpace};
    text-decoration: ${theme?.textDecoration || "none"};

    ${theme?.fontSize && (theme?.fontSizeTablet || theme?.fontSizeDesktop)
      ? getFontSize(
          {
            fontSize: theme?.fontSize,
            fontSizeTablet: theme?.fontSizeTablet,
            fontSizeDesktop: theme?.fontSizeDesktop
          },
          theme?.modular
        )
      : getFontSize(theme?.fontSize, theme?.modular)}

    a {
      color: inherit !important;
    }
    @media screen and (min-width: ${Breakpoints.DesktopMobile}) {
      color: ${theme?.tabletFontColor || theme?.fontColor || "inherit"};
      a {
        color: inherit !important;
      }
    }

    @media screen and (min-width: ${Breakpoints.ScreenWidth}) {
      color: ${theme?.fontColor || "inherit"};
      a {
        color: inherit !important;
      }
    }
  `}
`;
