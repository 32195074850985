/* eslint-disable complexity */
const getAssetUrl = (image, isSVG) => {
  const img = image?.diImage ? image?.diImage?.image : image;

  if (img) {
    const { endpoint, defaultHost, name } = img;
    const url = `https://${defaultHost}/i/${endpoint}/${name}?fmt=auto&qlt=default&fmt.jp2.qlt=60&fmt.webp.qlt=70&img404=noimagemedium`;
    const svgUrl = `https://serenaandlily.a.bigcontent.io/v1/static/${name}`;
    const query = image?.diImage?.query;

    const hasSVGInName = name?.includes("SVG");

    if (isSVG || hasSVGInName) {
      return query ? `${svgUrl}&${query}` : `${svgUrl}`;
    }

    return query ? `${url}&${query}` : `${url}`;
  }

  return "";
};

export default getAssetUrl;
