export default function getAddressNickname({
  address,
  selectedAddressId,
  addressList
}) {
  if (address?.addressNickname) return address.addressNickname;
  if (
    selectedAddressId &&
    Array.isArray(addressList) &&
    addressList.length > 0
  ) {
    return (
      addressList?.find((address) => address.id === selectedAddressId)
        ?.addressNickname || ""
    );
  }
  return "";
}
