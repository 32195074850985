import React, { useState, useEffect } from "react";
import Script from "next/script";
import useUser from "@serenaandlily/hooks/useUser";

const SIFT_BEACON_KEY = process.env.NEXT_PUBLIC_SIFT_BEACON_KEY;

const SiftScript = () => {
  const [initialized, setInitialized] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    if (SIFT_BEACON_KEY && user && !initialized) {
      window._sift = window._sift || [];
      window._sift.push(["_setAccount", SIFT_BEACON_KEY]);
      window._sift.push(["_setSessionId", user?.data?.sessionId || ""]);
      window._sift.push(["_setUserId", user?.data?.email || ""]);
      window._sift.push(["_trackPageview"]);
      setInitialized(true);
    }
  }, [user]);

  if (SIFT_BEACON_KEY) {
    return <Script id="sift-script" src="https://cdn.sift.com/s.js" />;
  }

  return null;
};

export default SiftScript;
