import formatApplePayAddressLines from "./formatApplePayAddressLines";

const convertAppleAddressToAddress = ({ address }) => {
  const { addressLine1, addressLine2 } = formatApplePayAddressLines({
    addressLines: address?.addressLines || []
  });
  return {
    addressLine1,
    addressLine2,
    city: address?.locality,
    country: address?.countryCode,
    firstName: address?.givenName,
    lastName: address?.familyName,
    postalCode: address?.postalCode,
    stateProvince: address?.administrativeArea
  };
};

export default convertAppleAddressToAddress;
