import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultLoaderSize = 10;

const ArrowFilledIcon = ({ height, width }) => {
  return (
    <IconSvg
      width={width || defaultLoaderSize}
      height={height || defaultLoaderSize}
      viewBox="0 0 24 24"
    >
      <path d="M21 12l-18 12v-24z" />
    </IconSvg>
  );
};

ArrowFilledIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default ArrowFilledIcon;
