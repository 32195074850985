import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultLoaderSize = 24;

const CaretDownLightSummary = ({ height, width }) => {
  return (
    <IconSvg
      width={width || defaultLoaderSize}
      height={height || defaultLoaderSize}
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.64645 9.64645C6.45118 9.84171 6.45118 10.1583 6.64645 10.3536L11.6464 15.3536C11.8417 15.5488 12.1583 15.5488 12.3536 15.3536L17.3536 10.3536C17.5488 10.1583 17.5488 9.84171 17.3536 9.64645C17.1583 9.45119 16.8417 9.45119 16.6464 9.64645L12 14.2929L7.35355 9.64645C7.15829 9.45118 6.84171 9.45118 6.64645 9.64645Z"
        fill="#243953"
      />
    </IconSvg>
  );
};

CaretDownLightSummary.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default CaretDownLightSummary;
