const getBannerMessage = (user) => {
  const {
    customerId,
    customerCompany,
    customerName,
    customerEmail,
    estimateName,
    estimateId,
    firstName,
    isAnonymous,
    isDSO,
    isDOO,
    isDDSO,
    name,
    email
  } = user;

  const dooText = `Currently logged in as ${name} - ${email}`;

  if (estimateId) {
    return `Estimate #${estimateId} ${
      estimateName && `"${estimateName}"`
    } in Shopping Bag`;
  }
  if (isDOO && !isDDSO) return dooText || "Exclusively for our Trade Members";
  if (isDSO) {
    if (isAnonymous)
      return `Hi ${firstName}! You're currently collaborating with an Anonymous Customer`;
    if (customerId === "UNKNOWN")
      return `Hi ${firstName}! You're currently collaborating with a New Customer`;

    if (customerName || customerEmail) {
      return `Hi ${firstName}! You're currently collaborating with ${
        customerName || customerEmail
      } ${customerCompany && "from " + customerCompany}`;
    }

    return `Currently logged in as ${firstName}`;
  }
};

export default getBannerMessage;
