import { TOKEN_COOKIE_NAME } from "../../constants";

export function getApolloAuthContext(req) {
  let headers = {};

  const addHeader = (key, value) => {
    if (value) headers[key] = value;
  };

  addHeader(
    "user-agent",
    req?.headers["user-agent"] || req?.headers["User-Agent"]
  );
  addHeader(
    "x-px-captcha-testing",
    req?.headers["x-px-captcha-testing"] || req?.headers["X-Px-Captcha-Testing"]
  );
  addHeader("Cookie", req?.headers["cookie"] || req?.headers["Cookie"]);
  addHeader(
    "x-forwarded-for",
    req?.headers["x-forwarded-for"] || req?.headers["X-Forwarded-For"]
  );
  addHeader(
    "Authorization",
    req?.cookies?.[TOKEN_COOKIE_NAME]
      ? decodeURIComponent(req.cookies[TOKEN_COOKIE_NAME])
      : process.env.NEXT_PUBLIC_DEFAULT_TOKEN
  );

  return {
    headers
  };
}
