import axios from "axios";

const getAmpVideoUrl = async (videoSrc) => {
  try {
    const ampResponse = await axios.get(
      `${videoSrc}.json`
    );
    return ampResponse;
  } catch (e) {
    return null;
  }
};

export default getAmpVideoUrl;