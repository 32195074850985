const INTEGRATION_VALUES = {
  URL: process.env.NEXT_PUBLIC_PEPPERJAM_TRACK_URL,
  INT: "DYNAMIC",
  PROGRAM_ID: process.env.NEXT_PUBLIC_PEPPERJAM_PROGRAM_ID,
  TYPE: 2,
  ITEMS: [
    {
      id: "SIGNUP",
      price: 0.0,
      quantity: 1
    }
  ]
};
const buildQueryParams = (queryParams = {}) =>
  Object.entries(queryParams).reduce((prev, current) => {
    const [key, value] = current;

    return `${prev}${key}=${value}&`;
  }, "");

const buildItemsQueryObject = (items = []) =>
  items.reduce((accumulator, item, index) => {
    const itemObject = {};
    const actualIndex = index + 1;

    itemObject[`ITEM_ID${actualIndex}`] = item.id;
    itemObject[`ITEM_PRICE${actualIndex}`] = item.price;
    itemObject[`QUANTITY${actualIndex}`] = item.quantity;

    return {
      ...accumulator,
      ...itemObject
    };
  }, {});

const createTag = ({ queryParams = "" }) => {
  const tagUrl = `${INTEGRATION_VALUES.URL}?${queryParams}`;

  const frameId = "pepperjam-push-event-frame";

  const tag = document.createElement("iframe");
  tag.src = tagUrl;
  tag.id = frameId;
  tag.height = 0;
  tag.width = 0;
  const currentFrame = document.getElementById(frameId);

  if (currentFrame?.length > 0) {
    currentFrame.remove();
  }

  document.querySelector("body").appendChild(tag);
};

const generateRandomOrderId = () => Math.floor(new Date() / 1000);

export const pushTrackEvent = (params) => {
  const {
    orderId = generateRandomOrderId(),
    items = INTEGRATION_VALUES.ITEMS,
    segment = null
  } = params;

  let queryValues = {
    INT: INTEGRATION_VALUES.INT,
    PROGRAM_ID: INTEGRATION_VALUES.PROGRAM_ID,
    TYPE: INTEGRATION_VALUES.TYPE
  };

  if (segment) {
    const itemsQueryParams = buildItemsQueryObject(items);
    const orderNumber = orderId || generateRandomOrderId();

    queryValues = {
      ...queryValues,
      ORDER_ID: orderNumber,
      SEGMENT: segment,
      ...itemsQueryParams
    };
  }

  const queryParams = buildQueryParams(queryValues);
  createTag({ queryParams });
};
