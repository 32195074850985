import axios from "axios";

const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_PLACES_APIKEY;
const GOOGLE_MAPS_URL = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_URL;

export const getLocation = async (latitude, longitude) => {
    if(GOOGLE_MAPS_URL === undefined || GOOGLE_MAPS_API_KEY === undefined) {
        return false
    }
    const response = await axios.get(`${GOOGLE_MAPS_URL}/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`);
    try {
        return response.data
    } catch (error) {
        return error;
    }
}
