import { CTAThemes } from "../components/CTA/CTATheme";
import { Colors, FONTS } from "./constants";
import TypographyThemes from "../components/Typography/TypographyThemes";
import formTheme from "../components/FormComponents/FormComponentsTheme";
import tooltipTheme from "../components/Tooltip/TooltipTheme";

export const defaultTheme = {
  // Let fonts colors and variables be set in theme using array types
  fonts: Object.entries(FONTS).map(([key, value]) => ({ name: key, value })),
  colors: Object.entries(Colors).map(([key, value]) => ({ name: key, value })),
  variables: [
    { name: "borderRadius", value: "0.25rem" },
    { name: "borderRadiusPill", value: "9999px" },
    { name: "outlineWidth", value: "0.375rem" },
    { name: "outlineColor", value: "Cloud Blue" },
    { name: "shadow", value: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)" }
  ],

  /* Block Colors */
  backgroundColorDefault: "White",
  backgroundColorAlternative: "Natural",

  borderColorDefault: "Natural",
  borderColorAlternative: "Midnight 70%",

  /* Link Colors */
  linkFontColor: "Midnight",
  linkFontColorHover: "Cloud Blue",

  /* Tooltip */
  tooltip: tooltipTheme,

  /* Forms */
  // there is a single theme for forms
  // ex: theme.forms.controlBorderColor
  forms: formTheme,

  /* Button Styles */
  buttons: CTAThemes,

  /* Text Styles */
  typography: TypographyThemes
};
