import apolloClient from "@chv1-serenaandlily/gql/client";
import {
  buildUpdateCartMutation,
  buildUpdateCartShippingMutation
} from "../mutations";
import getCart from "../responseMappers/cart";
import isObjectEmpty from "@chv1-utils/isObjectEmpty";
import formatDateForMutation from "@chv1-utils/formatDateForMutation";

const updateCart = async ({
  billingAddress,
  cartId,
  context,
  designServiceCode,
  doNotShipUntilDate,
  email,
  phoneNumber,
  attention,
  poNumber,
  shippingAddress,
  shippingOverride,
  taxableStatus,
  updateCartShipping,
  giftInfo
}) => {
  if (!apolloClient) return;

  const updateCartMutation = updateCartShipping
    ? buildUpdateCartShippingMutation
    : buildUpdateCartMutation;

  try {
    const UPDATE_CART = updateCartMutation({
      billingAddress,
      designServiceCode,
      doNotShipUntilDate,
      shippingOverride,
      phoneNumber,
      email,
      taxableStatus,
      shippingAddress,
      updateCartShipping,
      giftInfo
    });

    const variables = {
      cartId,
      billingAddress,
      doNotShipUntilDate: formatDateForMutation(doNotShipUntilDate),
      designServiceCode,
      shippingAddress,
      email,
      phoneNumber,
      attention,
      poNumber,
      shippingOverride,
      taxableStatus,
      giftInfo
    };

    const { data: responseData, errors } = await apolloClient.mutate({
      mutation: UPDATE_CART,
      variables,
      context
    });
    const { authHeader, ...data } = responseData || {};

    if (
      process.env.NODE_ENV !== "production" &&
      process.env.CHECKOUT_DEBUG === "true"
    ) {
      console.log(UPDATE_CART.loc.source.body);
      console.log(JSON.stringify(variables));
      console.log(data, errors);
    }

    if (!isObjectEmpty(data?.updateCart)) {
      return {
        data: getCart(data?.updateCart),
        authHeader,
        errors
      };
    }
    return { data: null, authHeader, errors };
  } catch (error) {
    // apollo client will throw if networkError is returned because of expired token
    return { data: null, authHeader: null, errors: error };
  }
};

export default updateCart;
