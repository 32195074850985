import React, {useMemo} from "react";
import PropTypes from "prop-types";
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import {defaultTheme} from '../../constants/theme';
import {resolveTheme} from '../../utils/themeUtils';
import merge from 'deepmerge';

const ThemeProvider = ({children, theme}) => {
  const resolvedTheme = useMemo(() => {
    if (theme) {
      return resolveTheme(merge(defaultTheme, theme))
    } else {
      return resolveTheme(defaultTheme)
    }
  },[theme]);
  return(<SCThemeProvider theme={resolvedTheme}>{children}</SCThemeProvider>);
};

export default ThemeProvider;

ThemeProvider.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.object
};
