import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

const SlideOutMenuContext = createContext();

const useSlideOutMenuContext = () => useContext(SlideOutMenuContext);

const SlideOutMenuProvider = ({ children }) => {
  const [activeMobileSlideOutIdx, setActiveMobileSlideOutIdx] = useState(0);
  const [secondaryFlyout, setSecondaryFlyout] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [slideOutMenuContent, setSlideOutMenuContent] = useState(null);
  const [tertiaryFlyout, setTertiaryFlyout] = useState();
  const [expandedItems, setExpandedItems] = useState([]);
  const [expandedSubItems, setExpandedSubItems] = useState([]);
  const [secondaryFlyoutSrc, setSecondaryFlyoutSrc] = useState();
  const [tertiaryyFlyoutSrc, setTertiaryyFlyoutSrc] = useState();
  const [showFixedSlide, setShowFixedSlide] = useState();
  const [fixedElemententsHeight, setFixedElemententsHeight] = useState();
  const [accordionScrollValue, setAccordionScrollValue] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [hoverEnabled, setHoverEnabled] = useState(false);

  return (
    <SlideOutMenuContext.Provider
      value={{
        activeMobileSlideOutIdx,
        setActiveMobileSlideOutIdx,
        secondaryFlyout,
        setSecondaryFlyout,
        isVisible,
        setIsVisible,
        tertiaryFlyout,
        setTertiaryFlyout,
        expandedItems,
        setExpandedItems,
        expandedSubItems,
        setExpandedSubItems,
        hoverEnabled,
        setHoverEnabled,
        secondaryFlyoutSrc,
        setSecondaryFlyoutSrc,
        tertiaryyFlyoutSrc,
        setTertiaryyFlyoutSrc,
        showFixedSlide,
        setShowFixedSlide,
        fixedElemententsHeight,
        setFixedElemententsHeight,
        slideOutMenuContent,
        setSlideOutMenuContent,
        accordionScrollValue,
        setAccordionScrollValue,
        isOpen,
        setIsOpen
      }}
    >
      {children}
    </SlideOutMenuContext.Provider>
  );
};

export { useSlideOutMenuContext, SlideOutMenuProvider };

SlideOutMenuProvider.propTypes = {
  children: PropTypes.any
};
