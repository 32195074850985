export default function getAddressNickname({
  address,
  selectedAddressId,
  addressList,
  currentShippingAddressValues
}) {
  if (address?.addressNickname) return address.addressNickname;
  if (
    selectedAddressId &&
    Array.isArray(addressList) &&
    addressList.length > 0
  ) {
    return (
      addressList?.find((address) => address.id === selectedAddressId)
        ?.addressNickname || ""
    );
  }

  if (currentShippingAddressValues?.addressNickname)
    return currentShippingAddressValues.addressNickname;
  return "";
}
