import React, { createContext, useContext, useState } from "react";
import PropType from "prop-types";

const GlobalPopupContext = createContext();
const GlobalPopupUpdateContext = createContext();

const useGlobalPopup = () => {
  return useContext(GlobalPopupContext);
};

const useGlobalPopupUpdate = () => {
  return useContext(GlobalPopupUpdateContext);
};

const GlobalPopupProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [overflowEnabled, setOverflowEnabled] = useState(false);
  const [describedById, setDescribedById] = useState(null);
  const [labelledById, setLabelledById] = useState(null);
  const [focusId, setFocusId] = useState(null);
  const [content, setContent] = useState(<p>No Component Provided</p>);
  const [style, setStyle] = useState(null);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const openPopup = (
    newContent,
    enableOverflow = false,
    accessibilityOptions = {},
    style = {}
  ) => {
    const { newDescribedById, newLabelledById, newFocusId } =
      accessibilityOptions;
    setDescribedById(newDescribedById || null);
    setLabelledById(newLabelledById || null);
    setFocusId(newFocusId || null);
    setOverflowEnabled(enableOverflow);
    setContent(newContent || <p>No Component Provided</p>);
    setShowPopup(true);
    setStyle(style);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <GlobalPopupContext.Provider
      value={{
        showPopup,
        overflowEnabled,
        describedById,
        labelledById,
        focusId,
        content,
        style,
        handleClosePopup
      }}
    >
      <GlobalPopupUpdateContext.Provider value={{ openPopup, closePopup }}>
        {children}
      </GlobalPopupUpdateContext.Provider>
    </GlobalPopupContext.Provider>
  );
};

export { useGlobalPopup, useGlobalPopupUpdate, GlobalPopupProvider };

GlobalPopupProvider.propTypes = {
  children: PropType.any
};
