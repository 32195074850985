export default function getAddressFormValues(
  addressBookAddress,
  isNested = false,
  formName = ""
) {
  const {
    addressNickname = "",
    companyName = "",
    firstName = "",
    lastName = "",
    addressLine1 = "",
    addressLine2 = "",
    city = "",
    stateProvince = "",
    postalCode = "",
    phoneNumber,
    country = "",
    addressee = "",
    id = ""
  } = addressBookAddress || {};

  const addressFields = {
    addressNickname,
    companyName: companyName || addressee,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    stateProvince,
    postalCode,
    phoneNumber,
    country,
    id
  };

  if (isNested) {
    return {
      [formName]: addressFields
    };
  }

  return addressFields;
}
