import { gql } from "@apollo/client";
import {
  CART_ADDRESS_FIELDS,
  CART_DELIVERY_GROUP_FIELDS,
  CART_ITEMS_FIELDS,
  CART_PRICE_FIELDS,
  CART_SHIPPING_ITEM_FIELDS
} from "../fragments/cart";

const buildUpdateCartMutation = (variables) => {
  // optional arguments
  const shippingOverrideDef = variables?.shippingOverride
    ? "$shippingOverride: String"
    : "";
  const shippingOverride = variables?.shippingOverride
    ? "shippingOverride: $shippingOverride"
    : "";
  const billingAddressDef = variables?.billingAddress
    ? "$billingAddress: InputCartAddressItem"
    : "";
  const billingAddress = variables?.billingAddress
    ? "billingAddress: $billingAddress"
    : "";
  const doNotShipUntilDateDef =
    variables?.doNotShipUntilDate || variables?.doNotShipUntilDate === ""
      ? "$doNotShipUntilDate: String"
      : "";
  const doNotShipUntilDate =
    variables?.doNotShipUntilDate || variables?.doNotShipUntilDate === ""
      ? "doNotShipUntilDate: $doNotShipUntilDate"
      : "";

  const designServiceCodeDef = variables?.designServiceCode
    ? "$designServiceCode: String"
    : "";
  const designServiceCode = variables?.designServiceCode
    ? "designServiceCode: $designServiceCode"
    : "";

  const taxableStatusDef =
    typeof variables?.taxableStatus === "boolean"
      ? "$taxableStatus: Boolean"
      : "";
  const taxableStatus =
    typeof variables?.taxableStatus === "boolean"
      ? "taxableStatus: $taxableStatus"
      : "";
  const phoneNumberDef = variables?.phoneNumber ? "$phoneNumber: String" : "";
  const phoneNumber = variables?.phoneNumber ? "phoneNumber: $phoneNumber" : "";
  const shippingAddressInputDef = variables?.shippingAddress
    ? "$shippingAddress: InputCartAddressItem"
    : "";
  const shippingAddressDef = variables?.shippingAddress
    ? "shippingAddress: $shippingAddress"
    : "";
  const giftInfoDef = variables?.giftInfo ? "$giftInfo: GiftInfo" : "";
  const giftInfo = variables?.giftInfo ? "giftInfo: $giftInfo" : "";
  const emailDef = variables?.email ? "$email: String" : "";
  const email = variables?.email ? "email: $email" : "";

  return gql`
    ${CART_PRICE_FIELDS}
    ${CART_DELIVERY_GROUP_FIELDS}
    ${CART_ADDRESS_FIELDS}
    ${CART_SHIPPING_ITEM_FIELDS}
    ${CART_ITEMS_FIELDS}
    mutation UpdateCart(
      $cartId: String
      ${shippingAddressInputDef}
      ${billingAddressDef}
      ${doNotShipUntilDateDef}
      ${emailDef}
      ${phoneNumberDef}
      $attention: String
      $poNumber: String
      ${shippingOverrideDef}
      ${designServiceCodeDef}
      ${taxableStatusDef}
      ${giftInfoDef}
    ) {
      updateCart(
        cartId: $cartId
        ${shippingAddressDef}
        ${billingAddress}
        ${doNotShipUntilDate}
        ${email}
        ${phoneNumber}
        attention: $attention
        poNumber: $poNumber
        ${shippingOverride}
        ${designServiceCode}
        ${taxableStatus}
        ${giftInfo}
      ) {
        cartId
        warnings
        disablePaypal
        disableAffirm
        companyName
        email
        phoneNumber
        attention
        poNumber
        designServiceCode
        doNotShipUntilDate
        taxableStatus
        deletedCartItems {
          sku
          name
          productId
        }
        deliveryGroups {
          ...CartDeliveryGroupFields
        }
        priceSummary {
          ...CartPriceFields
        }
        shippingItems {
          ...CartShippingItemFields
        }
        shippingAddress {
          ...CartAddressFields
        }
        billingAddress {
          ...CartAddressFields
        },
        cartItems {
          ...CartItemFields
        }
        giftInfo {
          giverName
          isGift
          message
          recipientEmail
          recipientName
        }
      }
    }
  `;
};

export default buildUpdateCartMutation;
