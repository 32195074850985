const getItem = (key) =>
  document.cookie.split("; ").reduce((total, currentCookie) => {
    const item = currentCookie.split("=");
    const storedKey = item[0];
    const storedValue = item[1];

    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, "");

const setItem = (key, value, options) => {
  let cookieAttributes = "path=/";
  if (options?.secure) {
    cookieAttributes += ";secure";
  }
  if (options?.samesite) {
    cookieAttributes += ";samesite";
  }
  document.cookie = `${key}=${value}; ${cookieAttributes}`;
};

const handleCookie = (key, defaultValue) => {
  const getCookie = () => getItem(key) || defaultValue;

  const updateCookie = (value, options) => {
    setItem(key, value, options);
  };

  return { getCookie, updateCookie };
};

export default handleCookie;
