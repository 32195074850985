import handlePXErrorResponse from "@serenaandlily/utils/handlePXErrorResponse";

const onErrorRetryHandler = (err, revalidate, { retryCount }) => {
  if (
    err?.networkError?.statusCode === 403 &&
    err?.networkError?.result?.appId
  ) {
    handlePXErrorResponse(err?.networkError);
    return;
  }

  if (retryCount >= 3) return;

  if (err?.response?.status === 500) return;

  setTimeout(() => revalidate({ retryCount }), 5000);
};
export default onErrorRetryHandler;
