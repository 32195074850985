import { getShippingOverride } from "@serenaandlily/contexts/CheckoutContext/utils";

export function buildUpdateCartPayload({
  cart,
  cartId,
  shippingContact,
  isFromShoppingBag,
  isDOO
}) {
  const { locality, administrativeArea, postalCode, countryCode } =
    shippingContact;
  let payload = {
    cartId: cartId,
    shippingAddress: {
      city: locality,
      stateProvince: administrativeArea,
      postalCode: postalCode,
      country: countryCode
    },
    shippingOverride: getShippingOverride({ cart })
  };
  if (isDOO) {
    payload.taxableStatus = cart.taxableStatus;
    if (isFromShoppingBag) {
      payload.taxableStatus = false;
    } else {
      payload.attention = cart.attention;
      payload.companyName = cart.companyName;
      payload.shippingAddress = {
        ...payload.shippingAddress,
        companyName: cart.companyName,
        firstName: "",
        lastName: ""
      };
    }
  }

  return payload;
}
