import styled, { css } from "styled-components";
import getFontSize from "../../utils/getFontSize";
import getDynamicSizeValue from "@chv1-serenaandlily/utils/getDynamicSizeValue";

const buttonTagStyles = `
  border: none;
  cursor: pointer;
`;

const underline = ({ theme }) =>
  theme.showUnderline &&
  `
    span {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        bottom: ${theme.underlineBottomPosition || 0};
        left: 0;
        background-color: ${theme.color};
      }
    }

    &:hover,
    &.hover {
      span::before {
        background-color: ${theme.colorHover};
      }
    }

    &:disabled,
    &.disabled {
      span::before {
        background-color: ${theme.colorDisabled};
      }
    }
`;

const animatedUnderline = ({ theme }) =>
  theme.showUnderline &&
  theme.showUnderlineAnimation &&
  `
    span {
      position: relative;
      &::before {
        transform: scaleX(1);
        transition: transform 0.3s ease;
      }
    }

    &:focus,
    &.focus,
    &:active,
    &.active  {
      span::before {
        display: none
      }
    }

    &:hover,
    &.hover {
      span::before {
        transform: scaleX(.5);
      }
    }
`;

const button = ({ theme }) =>
  `
    align-items: center;
    background-color: ${theme.backgroundColor};
    border: solid 1px ${theme.borderColor};
    border-radius: ${theme.borderRadius};
    flex-direction: ${({ orientation, ctaIconFlexDir }) =>
      orientation === "Horizontal" ? ctaIconFlexDir : "column"};
    box-sizing: border-box;
    color: ${theme.color};
    display: ${theme?.display || "flex"};
    font-family: ${theme.fontFamily};
    ${
      theme?.fontSize && (theme?.fontSizeTablet || theme?.fontSizeDesktop)
        ? getFontSize(
            {
              fontSize: theme?.fontSize,
              fontSizeTablet: theme?.fontSizeTablet,
              fontSizeDesktop: theme?.fontSizeDesktop
            },
            theme?.modular
          )
        : getFontSize(theme?.fontSize, theme?.modular)
    }
    ${
      theme.scaleFontSize &&
      getDynamicSizeValue({
        attr: "font-size",
        size: theme?.fontSize,
        sizeTablet: theme?.fontSizeTablet,
        sizeDesktop: theme?.fontSizeDesktop
      })
    };
    font-weight: ${theme.fontWeight || "normal"};
    justify-content: center;
    letter-spacing: ${theme.letterSpacing};
    line-height: ${theme.lineHeight};
    padding: ${theme.padding};
    text-decoration: none;
    text-transform: ${theme.transform};
    white-space: nowrap;
    width: fit-content;

    &:hover,
    &.hover {
      background-color: ${theme.backgroundColorHover};
      border-color: ${theme.borderColorHover};
      color: ${theme.colorHover};
      font-weight: ${theme.fontWeightHover};
    }

    &:disabled,
    &.disabled{
      pointer-events: none;
      background-color: ${theme.backgroundColorDisabled};
      border-color: ${theme.borderColorDisabled};
      color: ${theme.colorDisabled};
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 2px ${theme.outlineColor};
    }

    &:active,
    &.active {
      background-color: ${theme.backgroundColorActive};
      border-color: ${theme.borderColorActive};
      color: ${theme.colorActive};
    }
    height: ${theme.height};
    width: ${theme.width};
  `;

export const CallToActionWrapper = styled.span`
  display: flex;
  justify-content: ${(props) => props.alignment};
  ${({
    scaledBottomMarginMobile,
    scaledBottomMarginTablet,
    scaledBottomMarginDesktop,
    scaleBottomMargin,
    marginBottom
  }) =>
    scaleBottomMargin
      ? `${getDynamicSizeValue({
          attr: "margin-bottom",
          size: scaledBottomMarginMobile,
          sizeTablet: scaledBottomMarginTablet,
          sizeDesktop: scaledBottomMarginDesktop
        })};
      `
      : `margin-bottom: ${marginBottom}px`};
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
`;

export const IconContainer = styled.div`
  ${({ theme, iconHoverDisplay }) => css`
    padding: ${({ iconPadding }) =>
      theme.iconPadding || iconPadding || "0 0 0 12px"};
    position: relative;
    top: ${theme?.iconTop};
    bottom: ${theme?.iconBottom};
    svg {
      display: ${iconHoverDisplay};
    }
  `}
`;

export const CallToAction = styled.a`
  ${({ as }) => (as === "button" ? buttonTagStyles : null)}
  ${button}
  ${underline}
  ${animatedUnderline}
  :hover {
    svg {
      display: block;
    }
  }
`;

export const CTATextWrapper = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  flex-direction: ${({ orientation, ctaIconFlexDir }) =>
    orientation === "Horizontal" ? ctaIconFlexDir : "column"};
`;
