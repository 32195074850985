import React from "react";

const ChevronLeft = (props) => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.85355 11.3536C5.65829 11.5488 5.34171 11.5488 5.14645 11.3536L0.146447 6.35355C-0.0488148 6.15829 -0.0488148 5.84171 0.146447 5.64645L5.14645 0.646446C5.34171 0.451184 5.65829 0.451184 5.85355 0.646446C6.04882 0.841708 6.04882 1.15829 5.85355 1.35355L1.20711 6L5.85355 10.6464C6.04882 10.8417 6.04882 11.1583 5.85355 11.3536Z"
      />
    </svg>
  );
};

export default ChevronLeft;
