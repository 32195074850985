import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 24;

const FigmaPlus = ({ height, width }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      viewBox="0 0 24 24"
    >
      <path
        d="M5 12.071H19.1421M12.0711 5.47138L12.0711 19.1421"
        stroke="#243953"
        stroke-linecap="round"
      />
    </IconSvg>
  );
};

FigmaPlus.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default FigmaPlus;
