import React from "react";
import PropTypes from "prop-types";
import { marked } from "marked";
import Countdown from "../Countdown";
import CTA from "../CTA";
import { MarkdownContainer } from "../RichText/RichTextStyled";
import { RichTextWithCountdownWrapper } from "./RichTextWithCountdownStyled";

const localMarked = new marked.Renderer();
localMarked.paragraph = (text) => text;

const renderMarkdownWithComponents = (data, countdownProps, ctaProps) => {
  if (!data || typeof data !== "string") {
    return null;
  }

  const cleanedData = data
    .replace(/\\\[/g, "[")
    .replace(/\\\]/g, "]")
    .replace(/\r\n|\r/g, "\n")
    .replace(/\n{2,}/g, "\n")
    .trim();

  const parts = cleanedData.split(/(\[\[COUNTDOWN\]\]|\[\[CTA\]\]|\n)/);

  return parts.map((part, index) => {
    if (part === "[[COUNTDOWN]]" && countdownProps) {
      return (
        <span key={`countdown-${index}`}>
          <Countdown {...countdownProps} />
        </span>
      );
    }
    if (part === "[[CTA]]" && ctaProps) {
      return (
        <span key={`cta-${index}`}>
          <CTA {...ctaProps} />
        </span>
      );
    }
    if (part === "\n") {
      return <br key={`br-${index}`} />;
    }
    if (part.trim()) {
      const html = marked(part, { renderer: localMarked });

      return (
        <span
          key={`text-${index}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    }
    return null;
  });
};

const RichTextWithCountdown = ({
  type,
  data,
  theme,
  countdownProps,
  gap,
  ctaProps
}) => {
  if (type !== "markdown") return null;

  const content = renderMarkdownWithComponents(data, countdownProps, ctaProps);

  return (
    <RichTextWithCountdownWrapper gap={gap}>
      <MarkdownContainer theme={theme}>{content}</MarkdownContainer>
    </RichTextWithCountdownWrapper>
  );
};

RichTextWithCountdown.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  theme: PropTypes.object,
  gap: PropTypes.number,
  countdownProps: PropTypes.object,
  ctaProps: PropTypes.object
};

export default RichTextWithCountdown;
