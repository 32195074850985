export default function getNetsuiteIdFromCart({
  cartItems,
  productId,
  customUpholsteryOptions,
  netsuiteId
}) {
  const normalProductsVariantNetsuiteId = cartItems?.find(
    (item) => item?.productId === productId
  )?.netsuiteId;
  if (normalProductsVariantNetsuiteId) {
    return normalProductsVariantNetsuiteId;
  }
  return (
    cartItems?.find((item) => {
      if (!item?.customUpholstery || !customUpholsteryOptions) {
        return false;
      }

      return item?.customUpholstery?.fabricCode ===
          customUpholsteryOptions?.fabricCode &&
        item?.customUpholstery?.topLineVariation ===
          customUpholsteryOptions?.topLineVariation
    })?.netsuiteId ?? netsuiteId
  );
}
