import React from "react";
import PropTypes from "prop-types";
import getContentFromSchema from "../../utils/getContentFromSchema/getContentFromSchema";
import ALLOWED_DC_CONTENT from "./constants/AlllowedDCContent";

const DCContentLink = (data) => {
  const { _meta: meta } = data;

  const { schema } = meta;
  const contentType = getContentFromSchema(schema);

  const Component = ALLOWED_DC_CONTENT[contentType];

  if (Component) return <Component {...data} />;

  return null;
};

export default DCContentLink;

DCContentLink.propTypes = {
  data: PropTypes.object
};
