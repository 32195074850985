import { eventSchemaValidationsConfig } from "./schemaValidationGA4";
import { IS_DEV } from "@utils/environment/environmentCheck";
import { GA4Events } from "./GA4Events";
import { FIELDS } from "@utils/analytics/_resolvers";

function warningLog(message) {
  if (IS_DEV) {
    console.warn(message);
  }
}

export function pushToDataLayer(eventMetadata) {
  const events = [{ ecommerce: null }, eventMetadata];
  if (window.dataLayer) {
    window.dataLayer.push(...events);
  } else {
    console.warn("dataLayer is not defined");
  }
}

async function validateEventMetadata(eventMetadata, schema) {
  if (!schema) {
    const message = `Event ${eventMetadata.event} is not defined in schema`;
    warningLog(message);
    return {
      isValid: false,
      message,
      data: eventMetadata
    };
  }
  try {
    const validEventMetadata = await schema.parseAsync(eventMetadata);
    return {
      isValid: true,
      data: validEventMetadata
    };
  } catch (validationErrors) {
    const message = `Event ${eventMetadata.event} invalid data ${validationErrors}`;
    warningLog(message);

    return {
      isValid: false,
      data: eventMetadata,
      message
    };
  }
}

export function trackGA4ViewItemList(
  products = [],
  { itemListId, itemListName } = {}
) {
  const ga4ProductList = getViewItemList(products, {
    itemListId,
    itemListName
  });

  trackGA4Event(GA4Events.view_item_list, ga4ProductList);
}

export const parseYmanViewItemList = (
  items = [],
  { itemListId, itemListName } = {}
) => {
  return items.map(
    (
      { netsuiteId, id, name, category, breadcrumbs, department, division },
      index
    ) => {
      return {
        item_id: netsuiteId || id,
        item_name: name,
        item_brand: "Serena And Lily",
        item_category:
          (category || breadcrumbs?.[0]?.categories?.slice(-1)?.[0]?.name) ??
          undefined,
        item_category2: department,
        item_category3: division,
        item_variant: undefined,
        affiliation: "Online Store",
        price: undefined,
        discount: undefined,
        currency: "USD",
        quantity: "1",
        item_list_name: itemListName,
        item_list_id: itemListId,
        index: index?.toString()
      };
    }
  );
};

export async function trackGA4Event(eventName, eventParams = {}) {
  const schema = eventSchemaValidationsConfig[eventName];

  const eventMetaData = {
    event: eventName,
    ...eventParams
  };

  const { isValid, data } = await validateEventMetadata(eventMetaData, schema);
  pushToDataLayer(data);
  return { isValid };
}

export function getDiscountValue(minPriceValue, discountPriceValue) {
  const currentPrice = getPriceValue(minPriceValue);
  const discountPrice = getPriceValue(discountPriceValue);

  return Number(currentPrice - discountPrice)?.toFixed(2) || "0.00";
}

export function getPriceValue(price = "") {
  return parseFloat(price.replace(/[$,]/g, "")) || 0;
}

export function getViewItemList(list = [], { itemListId, itemListName } = {}) {
  return {
    ecommerce: {
      items: list
        .map((item, index) => {
          const currentDiscount = getDiscountValue(
            item.productTileData?.priceRange?.min,
            item.productTileData?.nowPriceRange?.min ||
              item.productTileData?.salePriceRange?.min
          );

          return {
            currentDiscount,
            productSpecification: item?.productSpecification,
            productData: {
              ...item?.productSpecification,
              ...item?.productTileData
            },
            itemListId,
            itemListName,
            position: index
          };
        })
        .map(getViewItemProduct)
    }
  };
}

export function getViewItemProduct({
  currentDiscount,
  productSpecification,
  productData,
  itemListId,
  itemListName,
  position
}) {
  return {
    item_id: productSpecification?.product?.productId,
    item_name: productSpecification?.productName,
    item_brand: FIELDS.item_brand(),
    item_category: FIELDS.item_category(productData) || undefined,
    item_category2: FIELDS.item_category2(productData) || undefined,
    item_category3: FIELDS.item_category3(productData) || undefined,
    item_variant: FIELDS.item_variant(productData) || undefined,
    affiliation: FIELDS.affiliation(),
    price: getPriceValue(FIELDS.tile_price(productData))?.toFixed(2),
    discount: currentDiscount,
    currency: FIELDS.currency(),
    quantity: "1",
    item_list_name: itemListName || undefined,
    item_list_id: itemListId || undefined,
    index: (position + 1)?.toString()
  };
}

export function getWishlistItemFromTile({
  productSpecification,
  productData,
  location
}) {
  return {
    favorites_page_path: location,
    favorites_item_id:
      productSpecification?.product?.variationId ||
      productSpecification?.product?.productId,
    favorites_item_name: productSpecification?.productName,
    favorites_item_category: FIELDS.item_category(productData) || undefined,
    favorites_item_category2: FIELDS.item_category2(productData) || undefined,
    favorites_item_category3: FIELDS.item_category3(productData) || undefined,
    favorites_item_variant: FIELDS.item_variant(productData) || undefined
  };
}

export function getWishlistItemFromProduct({
  productData,
  childProduct,
  location
}) {
  return {
    favorites_page_path: location,
    favorites_item_id: childProduct?.netsuiteId || productData?.netsuiteId,
    favorites_item_name: productData?.name,
    favorites_item_category:
      FIELDS.item_category({ product: productData }) || undefined,
    favorites_item_category2:
      FIELDS.item_category2({ product: productData }) || undefined,
    favorites_item_category3:
      FIELDS.item_category3({ product: productData }) || undefined,
    favorites_item_variant:
      FIELDS.item_variant({ product: productData }) || undefined
  };
}

export function selectViewItemProduct({
  currentDiscount,
  productSpecification,
  productData,
  itemListId,
  itemListName,
  position
}) {
  return {
    ecommerce: {
      items: [
        getViewItemProduct({
          currentDiscount,
          productSpecification,
          productData,
          itemListId,
          itemListName,
          position
        })
      ]
    }
  };
}
