/*global $A1*/
const addToCart = (products, customerEmail) => {
  if (typeof $A1 === "undefined") {
    return;
  }
  // Basket contains a list of products already in the cart
  // productId will be added to the basket, but demandware has not added it yet.
  let currentVisitor;
  if (!customerEmail) {
    currentVisitor = $A1.Customer({});
  } else {
    currentVisitor = $A1.Customer({
      Email: customerEmail,
      SourceCustomerNumber: customerEmail
    });
  }
  try {
    const myEvent = $A1.Event({
      type: "cartUpdated",
      customer: currentVisitor
    });
    products.forEach((product) => {
      myEvent.add("targets", product);
    });

    myEvent.send();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};
export default addToCart;
