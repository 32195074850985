import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 16;

const MinusLight = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill="none"
      stroke={fill}
      viewBox="0 0 16 2"
    >
      <path d="M15.5 1H.5" strokeLinecap="round"/>

    </IconSvg>
  );
};

MinusLight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default MinusLight;
