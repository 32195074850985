import React, { createContext, useContext, useEffect, useState } from "react";
import PropType from "prop-types";
import {
  fetchProduct,
  fetchProductById,
  fetchProductByMpn
} from "../../gql/utils";
import fetchImageSet from "../../utils/productsPreprocessing/fetchImageSets/fetchImageSets";

const GlobalProductsContext = createContext({});

const useGlobalProducts = () => {
  return useContext(GlobalProductsContext);
};

const GlobalProductsProvider = ({
  children,
  initialProducts,
  initialProductTiles,
  initialCrossSellTiles
}) => {
  const [products, setProducts] = useState(initialProducts || {});
  const [slugs, setSlugs] = useState({});
  const [loadingProducts, setLoadingProducts] = useState({});

  useEffect(() => {
    setProducts({ ...initialProducts, ...products });
  }, [initialProducts]);

  const getProductById = (id) => {
    return products?.[id];
  };

  const getProductBySlug = (slug) => {
    const id = slugs?.[slug];
    return products?.[id];
  };

  const getProductTileById = (id) => {
    return initialProductTiles?.[id];
  };

  const getCrossSellTileById = (id) => {
    return initialCrossSellTiles?.[id];
  };

  // adds product to loading queue, returns ID
  const addProduct = async (data) => {
    const product = data?.getProduct;
    if (!product) return false;
    const mainProductImageSet = await fetchImageSet(
      product?.netsuiteId,
      product?.overRideMediaSet
    );
    product.imageSet = mainProductImageSet?.data;
    product?.childProducts?.map(async (childProduct) => {
      const imageSet = await fetchImageSet(
        childProduct?.netsuiteId,
        childProduct?.overRideMediaSet
      );
      childProduct.imageSet = imageSet?.data || {};
      return childProduct;
    });
    const productObj = {};
    productObj[product.id] = product;
    setProducts((prev) => ({ ...prev, ...productObj }));
    return product.id;
  };

  const fetchNewProductBySlug = async (slug) => {
    if (slugs?.[slug]) return slugs?.[slug];
    if (loadingProducts[slug]) return;
    setLoadingProducts((prev) => ({ ...prev, [slug]: true }));

    const data = await fetchProduct(slug);
    const newId = await addProduct(data);
    setSlugs((prev) => ({ ...prev, [slug]: newId }));
    setLoadingProducts((prev) => ({ ...prev, [slug]: false }));
    return newId;
  };

  const fetchNewProductById = async (id, useMpn = false) => {
    if (products?.[id]) return products?.[id];
    if (loadingProducts[id]) return;
    setLoadingProducts((prev) => ({ ...prev, [id]: true }));
    const data = await (!useMpn ? fetchProductById(id) : fetchProductByMpn(id));
    const newId = await addProduct(data);
    setLoadingProducts((prev) => ({ ...prev, [newId]: false, [id]: false }));
    return newId;
  };

  const getChildProductFromVariations = (arr, productId) => {
    const currentProduct = products?.[productId];
    const childProducts = currentProduct?.childProducts;
    const key = arr.sort().join("__");
    let currentVariationChild = {};
    for (const child of childProducts) {
      const variantArr = Object.values(child?.variants)?.map(
        (variant) => variant?.value
      );
      const childKey = variantArr?.sort().join("__");
      if (key === childKey) currentVariationChild = child;
    }
    return currentVariationChild;
  };

  return (
    <GlobalProductsContext.Provider
      value={{
        getProductById,
        getProductBySlug,
        fetchNewProductById,
        fetchNewProductBySlug,
        getProductTileById,
        getCrossSellTileById,
        getChildProductFromVariations,
        loadingProducts,
        products
      }}
    >
      {children}
    </GlobalProductsContext.Provider>
  );
};

export { useGlobalProducts, GlobalProductsProvider };

GlobalProductsProvider.propTypes = {
  children: PropType.any,
  initialProducts: PropType.object,
  initialProductTiles: PropType.object,
  initialCrossSellTiles: PropType.object
};
