import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 18;

const Ideas = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill="none"
      viewBox="0 0 18 18"
      stroke={fill} 
    >
      <path d="M0.5 1C0.5 0.723858 0.723858 0.5 1 0.5H7C7.27614 0.5 7.5 0.723858 7.5 1V4C7.5 4.27614 7.27614 4.5 7 4.5H1C0.723858 4.5 0.5 4.27614 0.5 4V1Z" strokeLinejoin="round"/>
      <path d="M0.5 8C0.5 7.72386 0.723858 7.5 1 7.5H7C7.27614 7.5 7.5 7.72386 7.5 8V17C7.5 17.2761 7.27614 17.5 7 17.5H1C0.723858 17.5 0.5 17.2761 0.5 17V8Z" strokeLinejoin="round"/>
      <path d="M10.5 13C10.5 12.7239 10.7239 12.5 11 12.5H17C17.2761 12.5 17.5 12.7239 17.5 13V17C17.5 17.2761 17.2761 17.5 17 17.5H11C10.7239 17.5 10.5 17.2761 10.5 17V13Z"  strokeLinejoin="round"/>
      <path d="M10.5 1C10.5 0.723858 10.7239 0.5 11 0.5H17C17.2761 0.5 17.5 0.723858 17.5 1V9C17.5 9.27614 17.2761 9.5 17 9.5H11C10.7239 9.5 10.5 9.27614 10.5 9V1Z" strokeLinejoin="round"/>
    </IconSvg>
  );
};

Ideas.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Ideas;
