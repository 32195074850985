/* eslint-disable max-lines-per-function */
import { gql } from "@apollo/client";
import { CART_FULL } from "../fragments/cart";

const buildRemovePromotionFromCartMutation = () => {
  return gql`
    ${CART_FULL}
    mutation RemovePromotion($cartId: String!, $couponCode: String!) {
      removePromotion(cartId: $cartId, couponCode: $couponCode) {
        ...CartFull
      }
    }
  `;
};

export default buildRemovePromotionFromCartMutation;
