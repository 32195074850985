import React from "react";
import PropTypes from "prop-types";
import { TypographyWrapper } from "./TypographyStyled";
import useComponentTheme from "../Theme/useComponentTheme";

const Typography = ({
  tag,
  themeName = "",
  theme,
  themeOverrides,
  children,
  className,
  elementName = "",
  "data-a-id": dataAId,
  "data-testid": dataTestId
}) => {
  const typographyTheme = useComponentTheme({
    globalNamespace: "typography",
    themeName,
    theme,
    themeOverrides
  });
  return (
    <TypographyWrapper
      as={tag || typographyTheme?.tag}
      theme={typographyTheme}
      className={className}
      data-a-id={dataAId}
      data-testid={dataTestId}
      data-element={elementName}
    >
      {children}
    </TypographyWrapper>
  );
};

export default Typography;

Typography.propTypes = {
  tag: PropTypes.string,
  themeName: PropTypes.string,
  theme: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  themeOverrides: PropTypes.object,
  "data-a-id": PropTypes.string,
  elementName: PropTypes.string
};
