import clonedeep from "lodash.clonedeep";

// convert arrays to maps for easier lookup using themeName or name as the key
const remapThemeArrays = (theme = {}) => {
  const newTheme = clonedeep(theme);
  Object.entries(newTheme).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      newTheme[key] = value.reduce((acc, v) => {
        if(v.themeName) {
          return {...acc, [v.themeName]: v}
        }
        if(v.name) {
          return {...acc, [v.name]: v.value}
        }
        return acc

      }, {})
    }
  });
  return newTheme
};

export default remapThemeArrays;
