import createValueResolver from "./createValueResolver";
import deepResolveValues from "./deepResolveValues";
import deepMergeTypography from "./deepMergeTypography";

/**
 * resolves references to typography deeply within a theme 
 * The typography values are merged into the object so that the 
 * any overrides proerties in the object will take precedence.
 * @param theme
 * @returns {{typography, variables, fonts, colors}}
 */
const resolveThemeTypography = (theme = {}) => {
  const {fonts, colors, variables, typography, ...rest } = theme;
  const typographyResolver = createValueResolver(typography);
  const resolvedTypographyTheme = deepResolveValues(rest, typographyResolver);
  const finalTheme = deepMergeTypography(resolvedTypographyTheme);
  return {fonts, colors, variables, typography, ...finalTheme }
};

export default resolveThemeTypography