const RESPONSE = {
  STATUS_200: 200,
  STATUS_301: 301,
  STATUS_302: 302,
  STATUS_304: 304,
  STATUS_400: 400,
  STATUS_401: 401,
  STATUS_403: 403,
  STATUS_404: 404,
  STATUS_500: 500
};

export default RESPONSE;