import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 21;

const RemoveFromBag = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 20 21"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M9.36914 0.949766C9.36914 0.600974 9.65189 0.318222 10.0007 0.318222C10.3495 0.318222 10.6322 0.600974 10.6322 0.949765V1.99652H19C19.2761 1.99652 19.5 2.22038 19.5 2.49652C19.5 2.77266 19.2761 2.99652 19 2.99652H17.9475L17.0995 19.1051C17.0436 20.1674 16.166 21 15.1023 21H4.89729C3.83356 21 2.95595 20.1673 2.90006 19.1051L2.05244 2.99652H1C0.723858 2.99652 0.5 2.77266 0.5 2.49652C0.5 2.22038 0.723858 1.99652 1 1.99652H9.36914V0.949766ZM3.054 2.99988L3.89867 19.0525C3.92662 19.5837 4.36543 20 4.89729 20H15.1023C15.6341 20 16.0729 19.5837 16.1009 19.0526L16.946 2.99988H3.054Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.0508 5.77984C7.32651 5.76432 7.5626 5.97523 7.57813 6.25094L8.10987 15.6928C8.12539 15.9685 7.91448 16.2046 7.63877 16.2202C7.36307 16.2357 7.12697 16.0248 7.11145 15.7491L6.57971 6.30717C6.56418 6.03146 6.7751 5.79537 7.0508 5.77984Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9497 5.77984C12.674 5.76432 12.4379 5.97523 12.4224 6.25094L11.8906 15.6928C11.8751 15.9685 12.086 16.2046 12.3617 16.2202C12.6374 16.2357 12.8735 16.0248 12.889 15.7491L13.4208 6.30717C13.4363 6.03146 13.2254 5.79537 12.9497 5.77984Z"/>
    </IconSvg>
  );
};

RemoveFromBag.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default RemoveFromBag;
