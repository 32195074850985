import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";
import { useBreakpoint } from "../../Breakpoints";

const Play = ({ height, width }) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const defaultSize = isMobile ? 30 : 40;

  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      viewBox={isMobile ? "0 0 30 30" : "0 0 40 40"}
    >
      {isMobile ? (
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25ZM20.4958 15.3427C20.7706 15.2013 20.7661 14.8069 20.4881 14.6719L12.011 10.5543C11.7602 10.4325 11.4689 10.6171 11.4721 10.8959L11.5689 19.3246C11.5721 19.6034 11.8675 19.7813 12.1154 19.6538L20.4958 15.3427Z"
          fill="white"
          fill-opacity="0.9"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35ZM27.3278 20.4569C27.6941 20.2685 27.6881 19.7426 27.3175 19.5626L16.0146 14.0724C15.6803 13.91 15.2919 14.1562 15.2962 14.5279L15.4252 25.7662C15.4295 26.1379 15.8234 26.3752 16.1539 26.2051L27.3278 20.4569Z"
          fill="white"
          fillOpacity="0.9"
        />
      )}
    </IconSvg>
  );
};

Play.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default Play;