import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 16;

const PlusLight = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill="none"
      stroke={fill}
      viewBox="0 0 16 16"
    >
      <path d="M8 1v7m0 0v7m0-7h7M8 8H1"  strokeLinecap="round"/>
    </IconSvg>
  );
};

PlusLight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default PlusLight;
