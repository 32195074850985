import React from "react";
import PropTypes from "prop-types";

const Menu = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 14H21"
        stroke="#243953"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 10H21"
        stroke="#243953"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Menu.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Menu;
