import { gql } from "@apollo/client";

const buildUpdateCustomerMutation = () => {
  return gql`
    mutation UpdateCustomer($customer: UpdateCustomer) {
      updateCustomer(customer: $customer) {
        customerData {
          email
          firstName
          id
          lastName
          name
        }
      }
    }
  `;
};

export default buildUpdateCustomerMutation;
