import React from "react";
import PropTypes from "prop-types";
import { Counter } from "./CounterBadgeStyled";

const CounterBadge = ({ children }) => {
  return <Counter>{children}</Counter>;
};

export default CounterBadge;

CounterBadge.propTypes = {
  count: PropTypes.number,
};
