export const VIEWCONTENT_PAGES = [
  "",
  "about-us",
  "shipping-updates",
  "trade-lp",
  "careers",
  "forms",
  "birthday-treat",
  "press",
  "stores",
  "category",
  "inspiration",
  "monthly-sweeps",
  "promotion-dining",
  "rug-guide",
  "social-gallery",
  "inspiration",
  "living-landing",
  "promotion-living",
  "bedding-landing",
  "new-landing",
  "shop-the-look-bedroom",
  "shop-the-look-living",
  "shop-the-look-dining",
  "shop-the-look-outdoor",
  "dining-landing",
  "sale-landing",
  "feature-natural-style",
  "light-bulb-guide",
  "shop-the-look-kitchen-islands",
  "promotion-outdoor",
  "bedding-sleep-guide",
  "dining-measuring-guide",
  "birthday-treat",
  "entertaining-guide-2022",
  "shop-by-pattern-lp",
  "fabric-guide-performance",
  "furniture-landing",
  "productcare-wallcoverings"
];
