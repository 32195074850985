/*global $A1*/

const checkout = (order, customerEmail) => {
  if (typeof $A1 === "undefined") {
    return;
  }
  const email = order?.email || customerEmail;
  try {
    const currentVisitor = $A1.Customer({
      Email: `${email}`,
      SourceCustomerNumber: `${email}`
    });

    const checkoutEvent = $A1.Event({
      type: "checkout",
      customer: currentVisitor,
      targets: {
        SourceTransactionNumber: "Web_Checkout"
      }
    });
    checkoutEvent.send();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error({ e });
  }
};

export default checkout;
