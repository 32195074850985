/* eslint-disable no-console */
export const promoCodesErrorTypes = {
  PROMO_CODE_ERROR: "couponCodeInvalid",
  PROMO_CODE_ERROR_EXPIRED: "couponCodeExpired",
  PROMO_CODE_MESSAGE_NOT_APPLIED: "couponCodeInvalid",
  PROMO_CODE_REMOVED_UNAVAIL: "couponCodeInvalid"
};

export const promoCodesErrorMessages = {
  PROMO_CODE_ERROR: "Invalid",
  PROMO_CODE_ERROR_EXPIRED: "Expired",
  PROMO_CODE_MESSAGE_NOT_APPLIED: "The promo code couldn't be applied.",
  PROMO_CODE_REMOVED_UNAVAIL: "Promo code removed as it's no longer available"
};

export const shippingErrorTypes = {
  CART_WG_ITEMS_DISALLOWED_ERROR: "CART_WG_ITEMS_DISALLOWED_ERROR",
  CART_UNABLE_TO_SELL: "CART_UNABLE_TO_SELL"
};

export const shippingErrorMessages = {
  CART_WG_ITEMS_DISALLOWED_ERROR:
    "White Glove shipping is not available to the specified address",
  CART_UNABLE_TO_SELL: "A product can't be shipped to your state",
  COUNTRY_NOT_ALLOWED: "Only US and Canada addresses accepted."
};

export const getCouponCodeErrorCode = (error) => {
  const errorType = error[0].errorType;
  const errorCode = promoCodesErrorTypes[errorType] || null;
  if (errorCode) return errorCode;
  return "couponCodeInvalid";
};

export const getShippingErrorCode = (error) => {
  const errorType = error[0].errorType;
  const errorCode = shippingErrorTypes[errorType] || null;
  if (errorCode) return errorCode;
  return "couponCodeInvalid";
};

export const calculateErrors = (error) => {
  const errorTypes = error
    .map((v) => {
      return v.errorType;
    })
    .flat(999);
  const errors = errorTypes.map((v) => {
    return new window.ApplePayError(
      getCouponCodeErrorCode(v),
      undefined,
      promoCodesErrorMessages[v]
    );
  });
  return errors;
};

export const calculateShippingErrors = (error, countryCode) => {
  if (countryCode && (countryCode !== "US" || countryCode !== "CA")) {
    return [
      new ApplePayError(
        "shippingContactInvalid",
        "country",
        shippingErrorMessages.COUNTRY_NOT_ALLOWED
      )
    ];
  }

  const errorTypes = error
    .map((v) => {
      return v.errorType;
    })
    .flat(999);
  if (errorTypes.includes(shippingErrorTypes.CART_WG_ITEMS_DISALLOWED_ERROR)) {
    return [
      new ApplePayError(
        "shippingContactInvalid",
        "country",
        shippingErrorMessages.CART_WG_ITEMS_DISALLOWED_ERROR
      )
    ];
  }
  // If not found, check for CART_UNABLE_TO_SELL
  if (errorTypes.includes(shippingErrorTypes.CART_UNABLE_TO_SELL)) {
    return [
      new ApplePayError(
        "shippingContactInvalid",
        "administrativeArea",
        shippingErrorMessages.CART_UNABLE_TO_SELL
      )
    ];
  }

  // Default error
  return [
    new ApplePayError(
      "shippingContactInvalid",
      "addressLines",
      "There's an issue with the cart"
    )
  ];
};
