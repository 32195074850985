/**
 * Checks if given error type (s) exists in array of errorTypes within an array of errors.
 * @param {Array.<Object>} errors
 * @param {(string|string[])} errorType
 * @returns {boolean}
 */
export default function hasErrorType({ errors, errorType }) {
  if (!Array.isArray(errors)) return false;
  let found = false;
  try {
    found = errors?.some((error) => {
      if (Array.isArray(error?.errorType)) {
        if (typeof errorType === "string") {
          return error?.errorType?.some((t) => t === errorType);
        }
        if (Array.isArray(errorType)) {
          return error?.errorType?.some((t) => errorType.indexOf(t) !== -1);
        }
      }
      return false;
    });
  } catch (e) {
    return false;
  }

  return found;
}
