import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 24;

const CheckCircleOutline = ({ height, width }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      viewBox="0 0 24 24"
    >
      <path d="M16.59 7.58 10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
    </IconSvg>
  );
};

CheckCircleOutline.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default CheckCircleOutline;
