import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 18;

const FullscreenView = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 18 18"
    >
      <path d="M.648.644A.498.498 0 0 1 .823.531L.97.499l.033-.001h4.241a.5.5 0 0 1 0 1H2.21l4.588 4.588a.5.5 0 1 1-.707.707L1.502 2.205V5.24a.5.5 0 1 1-1 0V.998A.499.499 0 0 1 .648.644Zm12.595 16.341a.5.5 0 1 0 0 1h4.241a.502.502 0 0 0 .5-.515v-4.229a.5.5 0 0 0-1 0v3.036l-4.588-4.588a.5.5 0 0 0-.707.707l4.588 4.589h-3.034Zm0-16.485a.5.5 0 1 0 0 1h3.036l-4.588 4.588a.5.5 0 1 0 .707.707l4.589-4.588V5.24a.5.5 0 1 0 1 0V1a.5.5 0 0 0-.5-.5h-4.244ZM1.5 13.241a.5.5 0 0 0-1 0v4.241a.498.498 0 0 0 .5.5h4.243a.5.5 0 1 0 0-1H2.207l4.588-4.588a.5.5 0 0 0-.707-.707L1.5 16.275v-3.034Z"/>
    </IconSvg>
  );
};

FullscreenView.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default FullscreenView;
