import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";
import { Colors } from "@serenaandlily/constants/constants";

const defaultSize = 24;

const Edd = ({ width, height, fill = Colors.Midnight }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94935 18C7.95121 18.0396 7.95215 18.0794 7.95215 18.1194C7.95215 19.5001 6.83286 20.6194 5.45215 20.6194C4.07144 20.6194 2.95215 19.5001 2.95215 18.1194C2.95215 18.0794 2.95309 18.0396 2.95495 18H2C1.44772 18 1 17.5523 1 17V5C1 4.44772 1.44772 4 2 4H14C14.5523 4 15 4.44772 15 5V6H19.0109C19.3195 6 19.6108 6.14247 19.8003 6.38606L22.7894 10.2292C22.9259 10.4047 23 10.6207 23 10.8431V17C23 17.5523 22.5523 18 22 18H21.0451C21.0469 18.0396 21.0479 18.0794 21.0479 18.1194C21.0479 19.5001 19.9286 20.6194 18.5479 20.6194C17.1671 20.6194 16.0479 19.5001 16.0479 18.1194C16.0479 18.0794 16.0488 18.0396 16.0507 18H7.94935ZM14 5V5.99901C14 5.99868 14 5.99934 14 5.99901L14 17H7.68815C7.2775 16.1813 6.43045 15.6194 5.45215 15.6194C4.47385 15.6194 3.6268 16.1813 3.21615 17H2V5H14ZM15 17V7H19.0109L22 10.8431V17H20.7839C20.3732 16.1813 19.5261 15.6194 18.5479 15.6194C17.5696 15.6194 16.7225 16.1813 16.3118 17H15ZM6.95215 18.1194C6.95215 18.9478 6.28058 19.6194 5.45215 19.6194C4.62372 19.6194 3.95215 18.9478 3.95215 18.1194C3.95215 17.291 4.62372 16.6194 5.45215 16.6194C6.28058 16.6194 6.95215 17.291 6.95215 18.1194ZM18.5479 19.6194C19.3763 19.6194 20.0479 18.9478 20.0479 18.1194C20.0479 17.291 19.3763 16.6194 18.5479 16.6194C17.7194 16.6194 17.0479 17.291 17.0479 18.1194C17.0479 18.9478 17.7194 19.6194 18.5479 19.6194Z"
        fill={fill}
      />
    </IconSvg>
  );
};

Edd.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string
};

export default Edd;
