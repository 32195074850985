import apolloClient from "../client";
import { buildGetProductQuery } from "../queries";

const fetchProductByMpn = async (mpn, slug, vseUrl = "") => {
  if (!apolloClient) return;
  if (!mpn || mpn === "undefined") {
    return {
      errors: "MPN Not Provided"
    };
  }
  const GET_PRODUCT = buildGetProductQuery({ mpn, slug, vseUrl });
  const { data, errors } = await apolloClient.query({
    query: GET_PRODUCT,
    variables: {
      mpn,
      slug,
      vseUrl
    },
    context: { clientName: "endpoint2" }
  });

  if (errors) data.errors = errors;

  return data;
};

export default fetchProductByMpn;
