import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 22;

const Drag = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 22 8"
    >
      <path d="M18.145.889a.5.5 0 0 1 .707 0l3.001 3a.5.5 0 0 1 0 .707l-2.999 3a.5.5 0 0 1-.707-.708l2.145-2.145h-6.99a.5.5 0 0 1 0-1h6.99l-2.147-2.147a.5.5 0 0 1 0-.707ZM3.853.887a.5.5 0 0 1 0 .707L1.707 3.74H8.5a.5.5 0 1 1 0 1H1.707l2.147 2.147a.5.5 0 1 1-.707.707l-3-3a.5.5 0 0 1 0-.708L3.144.887a.5.5 0 0 1 .708 0Z"/>
    </IconSvg>
  );
};

Drag.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Drag;
