import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 21;


const Returns = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 20 21"
    >
      <path d="M18.8052 11H18.3052H18.8052ZM10.0004 19.8048V19.3048V19.8048ZM2.02864 6.19854C1.88595 6.43496 1.96194 6.74228 2.19836 6.88497C2.43478 7.02766 2.74211 6.95167 2.8848 6.71525L2.02864 6.19854ZM1.73957 11.8596C1.71134 11.5849 1.46577 11.3851 1.19107 11.4133C0.916379 11.4416 0.716578 11.6871 0.744807 11.9618L1.73957 11.8596ZM2.68307 1.26578C2.68307 0.989635 2.45921 0.765778 2.18307 0.765778C1.90692 0.765778 1.68307 0.989635 1.68307 1.26578L2.68307 1.26578ZM2.18307 6.52231H1.68307C1.68307 6.79845 1.90692 7.02231 2.18307 7.02231L2.18307 6.52231ZM7.28191 7.02231C7.55805 7.02231 7.78191 6.79845 7.78191 6.52231C7.78191 6.24617 7.55805 6.02231 7.28191 6.02231V7.02231ZM19.3052 11C19.3052 5.86109 15.1393 1.6952 10.0004 1.6952V2.6952C14.587 2.6952 18.3052 6.41337 18.3052 11H19.3052ZM10.0004 20.3048C15.1393 20.3048 19.3052 16.1389 19.3052 11H18.3052C18.3052 15.5866 14.587 19.3048 10.0004 19.3048V20.3048ZM10.0004 1.6952C6.6175 1.6952 3.65678 3.50083 2.02864 6.19854L2.8848 6.71525C4.33945 4.30501 6.98219 2.6952 10.0004 2.6952V1.6952ZM0.744807 11.9618C1.22648 16.649 5.18622 20.3048 10.0004 20.3048V19.3048C5.7042 19.3048 2.16937 16.0421 1.73957 11.8596L0.744807 11.9618ZM1.68307 1.26578L1.68307 6.52231H2.68307L2.68307 1.26578L1.68307 1.26578ZM2.18307 7.02231L7.28191 7.02231V6.02231L2.18307 6.02231L2.18307 7.02231Z"/>
    </IconSvg>
  );
};

Returns.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Returns;
