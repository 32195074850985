import isObjectEmpty from "@utils/isObjectEmpty";

const getNames = (addressee) => {
  if (!addressee) return {};
  const arr = addressee?.split(" ");
  return {
    fName: arr.slice(0, arr.length - 1).join(" "),
    lName: arr.length > 1 ? arr[arr.length - 1] : ""
  };
};

const getName = (firstName, lastName) => {
  const { fName = "", lName = "" } = getNames(firstName);
  if (firstName && !lastName) {
    return { fName, lName };
  }
  return { fName: firstName, lName: lastName };
};

export default function (respAddress) {
  if (!respAddress || isObjectEmpty(respAddress)) return;
  const {
    Address1,
    Address2,
    addr1,
    addr2,
    addr3,
    addressee,
    address1,
    address2,
    address3,
    addressId,
    addressLine1,
    addressLine2,
    addrphone,
    companyName,
    company_name,
    country,
    countryCode,
    county,
    firstName,
    first_name,
    id,
    lastName,
    last_name,
    line_1,
    line_2,
    line_3,
    name,
    phone,
    phoneNumber,
    phone_number,
    postalCode,
    postcode,
    state,
    stateProvince,
    zip,
    zipcode,
    ...rest
  } = respAddress;

  const { fName = "", lName = "" } = getName(
    firstName || first_name || addressee,
    lastName || last_name
  );

  return {
    addressLine1: addressLine1 || Address1 || line_1 || address1 || addr1,
    addressLine2: addressLine2 || Address2 || line_2 || address2 || addr2,
    addressLine3: line_3 || address3 || addr3,
    addressNickname: name,
    companyName: companyName || company_name || addressee,
    country: country || countryCode,
    firstName: fName,
    id: id || addressId,
    lastName: lName,
    phoneNumber: phoneNumber || phone_number || phone || addrphone,
    postalCode: postalCode || postcode || zip || zipcode,
    stateProvince: stateProvince || state || county, // state is county in EPCC because of a quirk in EPCC address management
    ...rest
  };
}
