export const removeDuplicateWarnings = (warnings) => {
  const seenWarningCodes = new Set([]);
  return (
    warnings?.filter?.((warning) => {
      if (seenWarningCodes?.has?.(warning?.code)) return false;
      seenWarningCodes?.add?.(warning?.code);
      return true;
    }) || []
  );
};
