import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const ArrowRight = ({ height, width }) => {
  return (
    <IconSvg width={width || "19"} height={height || "12"} viewBox="0 0 19 12">
      <path
        d="M12.9092 0.646447C12.714 0.451184 12.3974 0.451184 12.2021 0.646447C12.0068 0.841709 12.0068 1.15829 12.2021 1.35355L16.3486 5.5H1C0.723858 5.5 0.5 5.72386 0.5 6C0.5 6.27614 0.723858 6.5 1 6.5H16.3486L12.2021 10.6464C12.0068 10.8417 12.0068 11.1583 12.2021 11.3536C12.3974 11.5488 12.714 11.5488 12.9092 11.3536L17.9092 6.35355C18.1045 6.15829 18.1045 5.84171 17.9092 5.64645L12.9092 0.646447Z"
        fill="#243953"
      ></path>
    </IconSvg>
  );
};

ArrowRight.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default ArrowRight;
