import apolloClient from "../client";
import buildRemoveUnavailableItems from "../queries/buildRemoveUnavailableItems";
const fetchRemoveUnavailableItems = async (cartId) => {
  if (!apolloClient) return;
  const REMOVE_UNAVAILABLE_ITEMS = buildRemoveUnavailableItems();
  const { data } = await apolloClient.query({
    query: REMOVE_UNAVAILABLE_ITEMS,
    variables: {
      cartId
    },
    errorPolicy: "none",
    context: { clientName: "endpoint2" }
  });
  return data;
};

export default fetchRemoveUnavailableItems;
