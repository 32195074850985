import React from "react";
import PropTypes from "prop-types";
const defaultSize = 24;
const CloseNew = ({ height, width, fill = "#243953" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || defaultSize}
      height={height || defaultSize}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M18 6L6 18M6 6L18 18" stroke={fill} strokeLinecap="round" />
    </svg>
  );
};

CloseNew.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CloseNew;
