const deepResolveValues = (obj, resolver) => {
  if (typeof obj === "string") {
    return resolver(obj);
  } else if (Array.isArray(obj)) {
    return obj.map((member) => deepResolveValues(member, resolver));
  } else if (obj) {
    return Object.entries(obj).reduce((acc, [key, val]) => {
      if (typeof val === "string") {
        acc[key] = resolver(val, key);
      } else if (Array.isArray(val)) {
        acc[key] = val.map((member) => deepResolveValues(member, resolver));
      } else if (typeof val === "object" && val) {
        //null is an object this also checking if val is truthy
        acc[key] = deepResolveValues(val, resolver);
      } else {
        acc[key] = val;
      }
      return acc;
    }, {});
  } else {
    return obj;
  }
};

export default deepResolveValues;
