import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 11;

const CaretDownLight = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 11 6"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M.146.146a.502.502 0 0 0 0 .708l5 5a.502.502 0 0 0 .708 0l5-5a.502.502 0 0 0-.708-.708L5.5 4.793.854.146a.502.502 0 0 0-.708 0Z"/>
    </IconSvg>
  );
};

CaretDownLight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CaretDownLight;
