export const calculeHaversineDistance = (referencePointUser, storeLocation) => {
    const toRad = (angle) => (angle * Math.PI) / 180;

    const EARTH_RADIUS_MILES = 3958.8; // Radius of Earth in miles
    const userLatitude = toRad(referencePointUser.latitude);
    const userLongitude = toRad(referencePointUser.longitude);
    const storeLatitude = toRad(storeLocation.lat);
    const storeLongitude = toRad(storeLocation.lng);

    const deltaLatitude = storeLatitude - userLatitude;
    const deltaLongitude = storeLongitude - userLongitude;

    const haversineFormulaComponent = Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
        Math.cos(userLatitude) * Math.cos(storeLatitude) *
        Math.sin(deltaLongitude / 2) * Math.sin(deltaLongitude / 2);
    const centralAngle = 2 * Math.atan2(Math.sqrt(haversineFormulaComponent), Math.sqrt(1 - haversineFormulaComponent));

    return EARTH_RADIUS_MILES * centralAngle;
};
