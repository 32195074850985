import React from "react";
import {
  Header,
  Title,
  Message,
  ReturnLink,
  Logo,
  Container,
  ApplicationErrorImage
} from "./ApplicationErrorOverlayStyled";

const ApplicationErrorOverlay = () => {
  return (
    <React.Fragment>
      <Header>
        <Logo
          width={584}
          height={72}
          alt="Serena and Lily Logo"
          src="/SVGlogoheader.svg"
        />
      </Header>
      <Container>
        <Title>Oops.</Title>
        <Message desktopOnly={true}>
          {"We're sorry. Something went wrong. Thanks for hanging around."}
        </Message>
        <Message destktopOnly={false}>
          {"You're experiencing a technical error."}
        </Message>
        <ReturnLink href="/">PLEASE RELOAD HOMEPAGE</ReturnLink>
        <ApplicationErrorImage
          width={2097}
          height={1197}
          alt="Application Error Image"
          src="/application_error_background.png"
        />
      </Container>
    </React.Fragment>
  );
};

export default ApplicationErrorOverlay;
