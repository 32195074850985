const deepMergeTypography = (obj) => {
  if (obj && typeof obj === "object") {
    return Object.entries(obj).reduce((acc, [key, val]) => {
      if (typeof val === "object" && val) {
        if (key === 'typography') {
          acc = {
            ...val,
            ...acc
            };
          } else {
          acc[key] = deepMergeTypography(val);
          }
      } else  {
        acc[key] = val;
      }
      return acc
    }, {})
  } else {
    return obj
  }
};

export default deepMergeTypography