import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 20;

const Warning = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 20 20"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10 1.5C5.30558 1.5 1.5 5.30558 1.5 10C1.5 14.6944 5.30558 18.5 10 18.5C14.6944 18.5 18.5 14.6944 18.5 10C18.5 5.30558 14.6944 1.5 10 1.5ZM0.5 10C0.5 4.75329 4.75329 0.5 10 0.5C15.2467 0.5 19.5 4.75329 19.5 10C19.5 15.2467 15.2467 19.5 10 19.5C4.75329 19.5 0.5 15.2467 0.5 10Z"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 4.98169C10.2762 4.98169 10.5 5.20555 10.5 5.48169V11.1613C10.5 11.4374 10.2762 11.6613 10 11.6613C9.72387 11.6613 9.50002 11.4374 9.50002 11.1613V5.48169C9.50002 5.20555 9.72387 4.98169 10 4.98169Z"/>
      <path d="M10.9343 13.4457C10.9343 13.9617 10.516 14.38 9.99999 14.38C9.48399 14.38 9.06569 13.9617 9.06569 13.4457C9.06569 12.9297 9.48399 12.5114 9.99999 12.5114C10.516 12.5114 10.9343 12.9297 10.9343 13.4457Z"/>
    </IconSvg>
  );
};

Warning.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default Warning;
