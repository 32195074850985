import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache
} from "@apollo/client";
import getJsonFromString from "@chv1-serenaandlily/utils/getJsonFromString";

const DD_S2S_TOKEN = process.env.NEXT_PUBLIC_DD_S2S_TOKEN;

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore"
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  },
  mutate: {
    fetchPolicy: "no-cache",
    errorPolicy: "all"
  }
};

const afterwareLink = new ApolloLink(async (operation, forward) => {
  let defaultHeaders = {
    "x-api-key": process.env.NEXT_PUBLIC_BACKEND_API_KEY,
    "ecp-app-source": process.env.NEXT_PUBLIC_APP_SOURCE
  };
  operation.setContext(({ headers }) => {
    if (typeof window === "undefined" && DD_S2S_TOKEN) {
      defaultHeaders["x-dd-next-s2s"] = DD_S2S_TOKEN;
    }
    return {
      headers: {
        ...headers,
        ...defaultHeaders,
        clientIp: headers["x-forwarded-for"]
      }
    };
  });
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const authHeader = context.response.headers.get("x-auth-token");
    if (response.data && authHeader) response.data.authHeader = authHeader;
    return response;
  });
});

const formatErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(({ data, errors }) => {
    let parsedErrors;
    if (errors) {
      parsedErrors = errors.map((error) => {
        const { errorInfo, errorType, ...rest } = error;
        const parsedError = { ...rest };
        parsedError.errorType = getJsonFromString(errorType);
        parsedError.errorInfo = getJsonFromString(errorInfo);
        return parsedError;
      });
    }
    return { data, errors: parsedErrors };
  });
});

const endpoint = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_NEW_BACKEND_ENDPOINT}/graphql`
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([afterwareLink, formatErrorLink, endpoint]),
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions: defaultOptions
});

export default apolloClient;
