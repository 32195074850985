const setScript = (url, callback, location = "body") => {
  if (document.querySelector(`script[src="${url}"]`)) {
    callback();
    return;
  }
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName(location)[0].appendChild(script);
};

export default setScript;
