import apolloClient from "../client";
import buildValidateApplePayURL from "../../gql/queries/buildValidateApplePayURL";

const fetchValidateApplePay = async (validationURL) => {
  if (!apolloClient) return;
  const VALIDATE_APPLE_PAY = buildValidateApplePayURL();

  const response = await apolloClient.query({
    query: VALIDATE_APPLE_PAY,
    errorPolicy: "none",
    variables: {
      validationUrl: validationURL
    },
    context: { clientName: "endpoint2" }
  });

  return response;
};

export default fetchValidateApplePay;
