import { gql } from "@apollo/client";
import { WISHLIST_FIELDS_FULL } from "@gql/fragments/wishlist";

const buildGetWishlistQuery = () => {
  return gql`
    query GetWishlist(
      $customerEmail: String
      $guestToken: String
      $maxPromoMessages: Int
    ) {
      getWishlist(
        customerEmail: $customerEmail
        guestToken: $guestToken
        maxPromoMessages: $maxPromoMessages
      ) {
        ${WISHLIST_FIELDS_FULL}
      }
    }
  `;
};

export default buildGetWishlistQuery;
