import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 20;

const InstallmentPlan = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 20 20"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10.0005 1.76562C5.70424 1.76563 2.16941 5.02833 1.73961 9.21077C1.71138 9.48546 1.46581 9.68526 1.19111 9.65703C0.916418 9.62881 0.716617 9.38324 0.744846 9.10854C1.22652 4.42135 5.18626 0.765626 10.0005 0.765625C15.1394 0.765624 19.3053 4.93151 19.3053 10.0704C19.3053 15.2093 15.1394 19.3752 10.0005 19.3752C7.03065 19.3752 4.38622 17.9836 2.68311 15.8186L2.68311 18.8046C2.68311 19.0808 2.45925 19.3046 2.18311 19.3046C1.90696 19.3046 1.68311 19.0808 1.68311 18.8046L1.6831 14.5481C1.6831 14.272 1.90696 14.0481 2.1831 14.0481L6.28195 14.0481C6.55809 14.0481 6.78195 14.272 6.78195 14.5481C6.78195 14.8242 6.55809 15.0481 6.28195 15.0481L3.3521 15.0481C4.86758 17.069 7.28168 18.3752 10.0005 18.3752C14.5871 18.3752 18.3053 14.657 18.3053 10.0704C18.3053 5.4838 14.5871 1.76562 10.0005 1.76562Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M10 3.5C10.2762 3.5 10.5 3.72386 10.5 4V9.79289L13.3536 12.6464C13.5489 12.8417 13.5489 13.1583 13.3536 13.3536C13.1583 13.5488 12.8417 13.5488 12.6465 13.3536L9.64648 10.3536C9.55272 10.2598 9.50004 10.1326 9.50004 10V4C9.50004 3.72386 9.7239 3.5 10 3.5Z" />
    </IconSvg>
  );
};

InstallmentPlan.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default InstallmentPlan;
