import React from "react";
import PropTypes from "prop-types";
const CloseNew = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M6 6L18 18M18 6L6 18" stroke="#243953" strokeLinecap="round" />
    </svg>
  );
};

CloseNew.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CloseNew;
