import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from "react";
import PropType from "prop-types";
import {
  ACCOUNT_VIEWS,
  MIGRATED_FROM_SFCC_USERS_ERROR_TYPE,
  SIGNIN_FORM_TEXTS
} from "../../constants/login";
import addCustomer from "../../gql/utils/addCustomer";
import updateCustomer from "../../gql/utils/updateCustomer";
import axios from "axios";
import useUser from "../../hooks/useUser";
import USER_TYPES from "../../constants/userTypes";
import getPathRoot from "../../utils/getPathRoot";
import parseLoginErrorTypes from "../../utils/errors/parseLoginErrorTypes";
import {
  CHECKOUT_URL,
  BAG_URL,
  ORDER_CONFIRMATION_URL,
  CATEGORY_URL,
  SEARCH_URL
} from "../../constants/checkout";
import apiClient from "../../utils/api/apiClient";
import { ACCOUNT_ERROR_MOVE_ESTIMATE } from "../../constants/strings";
import { PAGE_TYPE } from "@serenaandlily/constants/analytics/pageType";
import useGA4 from "@serenaandlily/hooks/analytics/useGA4";
import { GA4Events } from "@serenaandlily/hooks/analytics/GA4Events";

function redirectFrom({ isDSO }) {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const fromUrl = searchParams.get("from");

    if (!fromUrl) {
      return false;
    }

    window.location.href = isDSO ? "shoppingbag" : fromUrl;

    return true;
  } catch (error) {
    console.error("Error during redirection:", error);
    return false;
  }
}

const { loginErrorText } = SIGNIN_FORM_TEXTS;

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  const {
    user: { data: { isEstimateUserError, loginSuccessUrl } = {} } = {},
    mutateUser
  } = useUser();
  const { login } = ACCOUNT_VIEWS;
  const [state, setState] = useState(login);
  const [accountNavContent, setAccountNavContent] = useState({});
  const [registrationFormErrors, setRegistrationFormErrors] = useState({});
  const [loginFormErrors, setLoginFormErrors] = useState({});
  const [recoveryFormErrors, setRecoveryFormErrors] = useState(null);
  const [accountAddressFormErrors, setAccountAddressFormErrors] = useState({});
  const [section, setSection] = useState("MyOrders");
  const [userCanLogin, setUserCanLogin] = useState(true);
  const [userLoginErrorTypes, setUserLoginErrorTypes] = useState([]);

  const [orderDetailsData, setOrderDetailsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [customerId, setCustomerId] = useState();
  const [loggedOutMessage, setLoggedOutMessage] = useState(null);
  const [moveEstimateError, setMoveEstimateError] = useState(null);
  const [loginRedirectUrl, setLoginRedirectUrl] = useState("");
  const [trackOrderCriteria, setTrackOrderCriteria] = useState({});

  const { trackGA4Event } = useGA4();

  const isMigratedFromSfccUser = userLoginErrorTypes.includes(
    MIGRATED_FROM_SFCC_USERS_ERROR_TYPE
  );
  const createNewCustomer = async (newCustomer) => {
    return await addCustomer(newCustomer);
  };

  useEffect(() => {
    if (isEstimateUserError) {
      setMoveEstimateError(ACCOUNT_ERROR_MOVE_ESTIMATE);
    }
  }, [isEstimateUserError]);

  useEffect(() => {
    if (loginSuccessUrl) {
      setLoginRedirectUrl(loginSuccessUrl);
    }
  }, [loginSuccessUrl]);

  const loginCustomer = useCallback(
    async ({ email, password, rememberUser, recaptchaData }) => {
      const body = {
        email: email?.toLowerCase(),
        password,
        rememberUser,
        recaptchaData
      };

      // hides past errors and messages
      setUserCanLogin(true);
      setLoggedOutMessage(null);
      setUserLoginErrorTypes([]);
      setIsLoading(true);

      // calls login
      const user = await apiClient.post("/api/login", JSON.stringify(body), {
        headers: { "Content-Type": "application/json" }
      });

      // if login failed show errors.

      if (user.errors) {
        setUserCanLogin(false);
        setUserLoginErrorTypes(parseLoginErrorTypes(user.errors));
        setIsLoading(false);

        trackGA4Event(GA4Events.login_failure, {
          method: "email",
          failure_reason: loginErrorText,
          page_type: PAGE_TYPE.account
        });
        return;
      }

      trackGA4Event(GA4Events.login);

      const isDSO = user?.data?.userType === USER_TYPES.dso;

      if (redirectFrom({ isDSO })) {
        return null;
      }

      // if login worked move along
      if (user?.data?.userType === USER_TYPES.employee || isDSO) {
        // redirects employees to home page after login
        window.location.href = "/";
      } else if (loginRedirectUrl) {
        window.location.href = loginRedirectUrl;
      } else {
        // otherwise, mutate the user which will trigger state updates
        mutateUser(user, false);
        setIsLoading(false);
      }
    },
    [
      mutateUser,
      setUserLoginErrorTypes,
      setUserCanLogin,
      setIsLoading,
      loginRedirectUrl
    ]
  );

  const logoutCustomer = useCallback(async () => {
    try {
      await apiClient.post(
        "/api/customer/selectCustomer",
        { customer: { isAnonymous: false } },
        {
          headers: { "Content-Type": "application/json" }
        }
      );
      const user = await axios({ url: "/api/logout", method: "POST" });
      const pathRoot = getPathRoot();
      trackGA4Event(GA4Events.logout);

      if (pathRoot === CHECKOUT_URL) {
        // Redirect to cart if you log out in checkout
        window.location.href = BAG_URL;
      } else if (pathRoot === ORDER_CONFIRMATION_URL) {
        // Redirect to home if you log out on the confirmation screen
        window.location.href = "/";
      } else if ([CATEGORY_URL, SEARCH_URL].includes(pathRoot)) {
        // Handle both CATEGORY_URL and SEARCH_URL in one block
        const urlParams = new URLSearchParams(window.location.search);
        const searchQuery = urlParams.get("q");

        // Check if we are on the search page and need to preserve 'q'
        let newUrl = window.location.pathname;
        if (pathRoot === SEARCH_URL && searchQuery) {
          // Preserve 'q' param for search page
          newUrl += `?q=${searchQuery}`;
        }

        // Replace URL and reload
        window.history.replaceState(null, "", newUrl);
        window.location.reload();
      } else {
        // In all other cases, mutate the user and refresh data
        mutateUser(user, false);
        window.location.reload();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, []);

  const handleLoginView = useCallback(async () => {
    await apiClient.post("/api/user", {
      action: "LOGIN_VIEW"
    });
  }, []);

  const updateCustomerInfo = async (updateData) => {
    const response = await updateCustomer(updateData).catch(() => {
      throw new Error("unable to update customer information");
    });
    return response;
  };

  return (
    <AccountContext.Provider
      value={{
        accountAddressFormErrors,
        customerId,
        registrationFormErrors,
        recoveryFormErrors,
        state,
        loginCustomer,
        loginFormErrors,
        logoutCustomer,
        userCanLogin,
        section,
        orderDetailsData,
        accountNavContent,
        setAccountNavContent,
        setRegistrationFormErrors,
        setRecoveryFormErrors,
        setCustomerId,
        setLoginFormErrors,
        setState,
        setUserCanLogin,
        setSection,
        setAccountAddressFormErrors,
        createNewCustomer,
        setOrderDetailsData,
        updateCustomerInfo,
        isLoading,
        loggedOutMessage,
        setLoggedOutMessage,
        userLoginErrorTypes,
        isMigratedFromSfccUser,
        handleLoginView,
        moveEstimateError,
        trackOrderCriteria,
        setTrackOrderCriteria
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

const useAccount = () => {
  const context = useContext(AccountContext);
  return context;
};

export { useAccount, AccountProvider };

AccountProvider.propTypes = {
  children: PropType.any
};
