import { gql } from "@apollo/client";

const buildFbEvent = () => {
  return gql`
    mutation facebookEvent($event: FbEventInput) {
      fbEvents(event: $event) {
        code
        messages
        fbtraceId
      }
    }
  `;
};

export default buildFbEvent;
