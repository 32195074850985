import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";
import { useBreakpoint } from "../../Breakpoints";

const Stop = ({ height, width }) => {
  const breakpoints = useBreakpoint();
  const isMobile = breakpoints["DesktopMobile"];
  const defaultSize = isMobile ? 30 : 40;

  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      viewBox={isMobile ? "0 0 30 30" : "0 0 40 40"}
    >
      {isMobile ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25ZM10.8385 10.5C10.6516 10.5 10.5 10.6516 10.5 10.8385V19.1615C10.5 19.3484 10.6516 19.5 10.8385 19.5H19.1615C19.3484 19.5 19.5 19.3484 19.5 19.1615V10.8385C19.5 10.6516 19.3484 10.5 19.1615 10.5H10.8385Z"
          fill="white"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35ZM14.4514 14C14.2021 14 14 14.2021 14 14.4514V25.5486C14 25.7979 14.2021 26 14.4514 26H25.5486C25.7979 26 26 25.7979 26 25.5486V14.4514C26 14.2021 25.7979 14 25.5486 14H14.4514Z"
          fill="white"
        />
      )}
    </IconSvg>
  );
};

Stop.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default Stop;
