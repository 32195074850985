import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 22;

const HeartFull = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      stroke={fill}
      viewBox="0 0 22 20"
    >
      <path d="M11 5.401C9.841 2.365 5.696-.212 2.72 2.936-2.216 8.157 4.389 16.081 11 18.5c6.611-2.419 13.217-10.343 8.281-15.564C16.304-.212 12.158 2.365 11 5.401Z" strokeLinecap="round" strokeLinejoin="round"/>
    </IconSvg>
  );
};

HeartFull.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default HeartFull;
