import styled from "styled-components";
import { Colors } from "../../constants/constants";

export const SpinnerContainer = styled.span`
  display: flex;
`;

//https://gist.github.com/knowbody/578b35164b69e867ed4913423f6bed30
export const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  & .path {
    stroke: ${Colors.Gray};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export const ArrowContainer = styled.span`
  display: flex;
`;

export const IconSvg = styled.svg`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const NotificationIconWrapper = styled.div`
  display: inline-block;
  position: relative;
  line-height: 0;
`;

export const Notification = styled.div`
  ${({ theme, fill, left, top }) => `
    align-items: center;
    background-color: ${fill};
    border-radius: 4px;
    color: #fff;
    display: flex;
    font-family: ${theme.fonts["Beatrice Regular"]};
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    justify-content: center;
    letter-spacing: 0.02em;
    line-height: .76;
    min-width: 12px;
    padding: 4px 2px 2px;
    margin: 0;
    position: absolute;
    left: ${left};
    text-align: center;
    text-transform: uppercase;
    top:${top};
  `}
`;

export const PointLoaderContainer = styled.span`
  width: 9px;
  height: 9px;
  border-radius: 100%;
  display: block;
  margin: 5px 38px;
  position: relative;
  box-sizing: border-box;
  animation: animloader 2s linear infinite;

  @keyframes animloader {
    0% {
      box-shadow: 14px 0 ${Colors.Gray}, 38px 0 ${Colors.Gray},
        -14px 0 ${Colors.Gray}, -38px 0 ${Colors["Dark Primary"]};
    }
    25% {
      box-shadow: 14px 0 ${Colors.Gray}, 38px 0 ${Colors.Gray},
        -14px 0 ${Colors["Dark Primary"]}, -38px 0 ${Colors.Gray};
    }
    50% {
      box-shadow: 14px 0 ${Colors["Dark Primary"]}, 38px 0 ${Colors.Gray},
        -14px 0 ${Colors.Gray}, -38px 0 ${Colors.Gray};
    }
    75% {
      box-shadow: 14px 0 ${Colors.Gray}, 38px 0 ${Colors["Dark Primary"]},
        -14px 0 ${Colors.Gray}, -38px 0 ${Colors.Gray};
    }
    100% {
      box-shadow: 14px 0 ${Colors.Gray}, 38px 0 ${Colors.Gray},
        -14px 0 ${Colors.Gray}, -38px 0 ${Colors["Dark Primary"]};
    }
  }
`;
