import axios from "axios";
import isAuthError from "./isAuthError";
import isRegularError from "./isRegularError";

const handleErrors = (error) => {
  if (isAuthError(error.response?.data?.errors?.networkError?.result?.errors)) {
    return axios({
      url: "/api/logout",
      method: "post"
    }).then(() => {
      window.location.href = "/account?logout=auto";
      return {
        errors: error.response?.data?.errors?.networkError?.result?.errors,
        status: error.response?.status
      };
    });
  }

  if (isRegularError(error)) {
    const networkError = error.response?.data?.errors?.networkError;
    return networkError;
  }

  return error.response?.data?.errors
    ? error.response?.data
    : { errors: error.response?.data, status: error.response?.status };
};

export default handleErrors;
