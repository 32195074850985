import React, { useEffect, useState } from "react";
import Script from "next/script";
import { useMarketing } from "@chv1-serenaandlily/contexts/MarketingContext";
const AGILONE_SDK_URL = process.env.NEXT_PUBLIC_AGILONE_SDK_URL;

const AgiloneScript = () => {
  const { handleAgiloneLoad } = useMarketing();
  const [agiloneToken, setAgiloneToken] = useState(null);

  useEffect(() => {
    const fetchAgiloneToken = async () => {
      try {
        const agiloneTokenResponse = await fetch(`/api/edge/getEdgeConfigValue?key=${process.env.NEXT_PUBLIC_AGILONE_TOKEN_EDGE_KEY}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });
        const agiloneTokenData = await agiloneTokenResponse.json();
        window.$A1Config = {
          key: agiloneTokenData?.value?.key,
          tenantId: agiloneTokenData?.value?.tenantId,
          host: agiloneTokenData?.value?.host
        };
        setAgiloneToken(agiloneTokenData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching Agilone token:", error);
      }
    };

    fetchAgiloneToken();
  }, []);

  const handleReady = () => {
    handleAgiloneLoad(true);
  };

  if (agiloneToken) {
    return (
      <Script id="agilone-script" src={AGILONE_SDK_URL} onReady={handleReady} />
    );
  }

  return null;
};

export default AgiloneScript;
