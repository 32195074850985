const hasMismatch = (prop1, prop2) => {
  return prop1 && prop1 !== prop2;
};

const isObject = (obj) => {
  return typeof obj === "object" && obj !== null;
};

const isNetsuiteAddress = (address) => {
  return (
    Object.prototype.hasOwnProperty.call(address, "addressee") ||
    Object.prototype.hasOwnProperty.call(address, "isCompanyAddress") ||
    Object.prototype.hasOwnProperty.call(address, "isDefaultBilling") ||
    Object.prototype.hasOwnProperty.call(address, "isDefaultShipping")
  );
};

const hasNameMismatch = ({ companyName, firstName, lastName, address }) => {
  if (isNetsuiteAddress(address)) {
    const addressCompanyName = address.addressee || address.companyName;
    return (
      hasMismatch(companyName, addressCompanyName) ||
      hasMismatch(`${firstName || ""} ${lastName || ""}`.trim(), companyName)
    );
  } else {
    return (
      hasMismatch(firstName, address.firstName) ||
      hasMismatch(lastName, address.lastName) ||
      hasMismatch(companyName, address.companyName)
    );
  }
};

const hasPhoneMismatch = ({ phoneNumber, address }) => {
  return hasMismatch(phoneNumber, address.phoneNumber);
};

export default function getSelectedAddressId({
  address: inAddress,
  phoneNumber,
  addressList
}) {
  let selectedAddressId = "";
  if (isObject(inAddress) && Array.isArray(addressList)) {
    // remove fields
    let {
      companyName,
      firstName,
      lastName,
      attention: _attention,
      poNumber: _poNumber,
      ...address
    } = inAddress;
    const addressProps = address ? Object.keys(address) : [];

    const addressMatch = addressList.find((addressListAddress) => {
      // return false as soon as we find a property that
      // does not match so that we are not comparing
      // all fields unnecessarily
      // First, basic address match
      for (let key of addressProps) {
        // somethings NS or EP returns empty strings with spaces
        // so we need to trim them
        const addressListAddressKey =
          typeof addressListAddress[key] === "string"
            ? addressListAddress[key].trim()
            : addressListAddress[key];
        const addressKey =
          typeof address[key] === "string" ? address[key].trim() : address[key];
        if (addressListAddressKey != addressKey) {
          return false;
        }
      }
      // Then check names
      if (
        hasNameMismatch({
          firstName,
          lastName,
          companyName,
          address: addressListAddress
        })
      ) {
        return false;
      }

      // finally, check phone
      return !hasPhoneMismatch({ phoneNumber, address: addressListAddress });
    });
    if (addressMatch) selectedAddressId = addressMatch.id;
  }
  return selectedAddressId;
}
