import React from "react";

const ChevronRight = (props) => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L5.85355 5.64645C6.04882 5.84171 6.04882 6.15829 5.85355 6.35355L0.853554 11.3536C0.658292 11.5488 0.34171 11.5488 0.146448 11.3536C-0.0488147 11.1583 -0.0488147 10.8417 0.146447 10.6464L4.79289 6L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z"
      />
    </svg>
  );
};

export default ChevronRight;
