import NextImage from "next/image";
import styled from "styled-components";

export const ImageWrapper = styled.div`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "0"};
  aspect-ratio: ${({ height, width }) =>
    height && width ? `${width} / ${height}` : "none"};
`;

export const Image = styled.img`
  max-width: 100%;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  object-fit: ${({ objectFit }) => (objectFit ? objectFit : "none")};
  max-height: -webkit-fill-available;
  @-moz-document url-prefix() {
    max-height: 100%;
  }
`;

export const NxImage = styled(NextImage)`
  max-width: 100%;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  object-fit: ${({ objectFit }) => (objectFit ? objectFit : "none")};
  max-height: -webkit-fill-available;
  @-moz-document url-prefix() {
    max-height: 100%;
  }
`;

export const Container = styled.a`
  cursor: pointer;
`;
