import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";

const ContentContext = createContext();

const ContentProvider = ({
  cartHeadingData,
  checkoutRegistration,
  children,
  confirmationHeadline,
  confirmationInStoreHeadline,
  formMessages,
  giftCardMessage,
  messages,
  needHelp,
  promoCode,
  affirmMessages,
  taxExemptSummary,
  wishlistConfigs,
  siteConfigs,
  siteGlobals,
  siteMessages,
  pdpSettings,
  dnsuNonElegibleProductMessage,
  dsoShoppingBagPayAndCarryOptionsHeadline,
  estimatedShippingHeadline,
  consolidationMessageHeadline
}) => {
  const content = useMemo(() => {
    return {
      cartHeadingData,
      checkoutRegistration,
      confirmationHeadline,
      confirmationInStoreHeadline,
      formMessages,
      giftCardMessage,
      messages,
      needHelp,
      promoCode,
      affirmMessages,
      taxExemptSummary,
      wishlistConfigs,
      siteConfigs,
      siteGlobals,
      pdpSettings,
      siteMessages,
      dnsuNonElegibleProductMessage,
      dsoShoppingBagPayAndCarryOptionsHeadline,
      estimatedShippingHeadline,
      consolidationMessageHeadline
    };
  }, [
    cartHeadingData,
    checkoutRegistration,
    confirmationHeadline,
    confirmationInStoreHeadline,
    formMessages,
    giftCardMessage,
    messages,
    needHelp,
    promoCode,
    affirmMessages,
    taxExemptSummary,
    wishlistConfigs,
    siteConfigs,
    siteGlobals,
    pdpSettings,
    siteMessages,
    dnsuNonElegibleProductMessage,
    dsoShoppingBagPayAndCarryOptionsHeadline,
    estimatedShippingHeadline,
    consolidationMessageHeadline
  ]);

  return (
    <ContentContext.Provider value={content}>
      {children}
    </ContentContext.Provider>
  );
};

function useContent() {
  const context = useContext(ContentContext);
  if (context) {
    return context;
  } else {
    //eslint-disable-next-line  no-console
    console.warn("Missing ContentProvider for useContent");
    return {};
  }
}

export { useContent, ContentProvider };

ContentProvider.propTypes = {
  cartHeadingData: PropTypes.object,
  checkoutRegistration: PropTypes.object,
  children: PropTypes.any,
  confirmationHeadline: PropTypes.object,
  formMessages: PropTypes.object,
  giftCardMessage: PropTypes.object,
  messages: PropTypes.object,
  needHelp: PropTypes.object,
  promoCode: PropTypes.object,
  affirmMessages: PropTypes.object,
  taxExemptSummary: PropTypes.object,
  siteConfigs: PropTypes.object,
  siteGlobals: PropTypes.object,
  pdpSettings: PropTypes.object,
  siteMessages: PropTypes.object,
  dnsuNonElegibleProductMessage: PropTypes.object,
  dsoShoppingBagPayAndCarryOptionsHeadline: PropTypes.object
};
