import {
  CART_WARNINGS,
  CART_WARNINGS_MESSAGES,
  ROUTE_TO_TYPES
} from "../../contexts/CartContext/constants";
import getPathRoot from "../../utils/getPathRoot";
import hasErrorType from "../../utils/errors/hasErrorType";
import { MAX_AUTH_ATTEMPTS } from "../../constants/checkout";
import {
  CART_ERROR_ADD,
  CART_ERROR_AUTH_FAILED_REVERSAL,
  CART_ERROR_AUTH_MAX_ATTEMPTS,
  CART_ERROR_CHECKOUT,
  CART_SHIPPING_RESTRICTIONS,
  CART_ERROR_CLEAR,
  CART_ERROR_INVALID_PROMO_CODE,
  CART_ERROR_LOAD,
  CART_ERROR_MIXED_SHIPPING_CANADA,
  CART_ERROR_MOVE_ESTIMATE,
  CART_ERROR_MOVE_ESTIMATE_USER,
  CART_ERROR_UPDATE,
  CART_ERROR_UPDATE_ADDRESS,
  CART_ERROR_UPDATE_PAYMENT,
  CART_MESSAGE_ESTIMATE_MERGED,
  CART_MESSAGE_ESTIMATE_PROMO_EXPIRED,
  CART_MESSAGE_ITEMS_REMOVED,
  CART_MESSAGE_ITEMS_REPRICED,
  CART_MESSAGE_OUT_OF_STOCK,
  CART_MESSAGE_PROMO_REMOVED,
  CART_NO_WHITEGLOVE_TO_STATE,
  PAYPAL_ERROR_INITIALIZATION,
  PROMO_CODE_ERROR,
  PROMO_CODE_ERROR_REMOVE,
  CART_ERROR_SIFT,
  PROMO_CODE_ERROR_EXPIRED,
  PROMO_CODE_ERROR_INVALID,
  PROMO_CODE_MESSAGE_NOT_APPLIED,
  CART_TAX_ERROR
} from "../../constants/strings";

export const getCartTypeForRoute = () => {
  return ROUTE_TO_TYPES[getPathRoot()] || "default";
};

// functional set state. has reference to previous state
export const updateCartMessages = (message) => (prevState) => {
  if (typeof message === "string") {
    return prevState.indexOf(message) < 0 ? [...prevState, message] : prevState;
  }
  if (Array.isArray(message)) {
    let newMessages = message.filter((m) => !prevState.includes(m));
    return newMessages.length > 0 ? [...prevState, ...newMessages] : prevState;
  }
  return prevState;
};

export const getItemQuantity = (cart, cartItemId) =>
  cart?.cartItemsById?.[cartItemId]?.quantity;

export const getInventoryPayloadFromError = (errorType, errors, productId) => {
  if (!Array.isArray(errors)) return {};

  const relevantErrors = errors.filter((error) =>
    error?.errorType?.includes(errorType)
  );

  for (const error of relevantErrors) {
    const innerErrors = error?.errorInfo?.metadata?.errors || [];
    for (const innerError of innerErrors) {
      if (
        innerError.code === errorType &&
        innerError.payload?.productId === productId
      ) {
        return innerError.payload;
      }
    }
  }

  return {};
};

export const getAdjustCartErrorResponse = ({
  errors,
  cart,
  cartItemId,
  data,
  productId
}) => {
  if (hasErrorType({ errors, errorType: "PRODUCT_INSUFFICIENT_STOCK" })) {
    return {
      quantity: getItemQuantity(cart, cartItemId),
      inventory: data?.inventory
    };
  }

  if (hasErrorType({ errors, errorType: CART_WARNINGS.CART_ERROR_INVENTORY })) {
    return {
      quantity: getItemQuantity(cart, cartItemId),
      inventory: getInventoryPayloadFromError(
        CART_WARNINGS.CART_ERROR_INVENTORY,
        errors,
        productId
      )?.total
    };
  }

  return {
    quantity: getItemQuantity(cart, cartItemId)
  };
};

export const getCartLoadError = (
  cartErrors,
  { cartErrorLoad = CART_ERROR_LOAD } = {}
) => {
  if (cartErrors?.route === "/api/cart") {
    return cartErrorLoad;
  }
};

export const getCartUpdateError = (
  cartErrors,
  {
    cartErrorAdd = CART_ERROR_ADD,
    cartErrorMixedShippingCanada = CART_ERROR_MIXED_SHIPPING_CANADA,
    cartErrorUpdate = CART_ERROR_UPDATE,
    cartErrorUpdateAddress = CART_ERROR_UPDATE_ADDRESS,
    cartErrorUpdatePayment = CART_ERROR_UPDATE_PAYMENT,
    cartNoWhiteGloveToState = CART_NO_WHITEGLOVE_TO_STATE,
    taxCalculationError = CART_TAX_ERROR,
    cartItemNoLongerAvailable = CART_WARNINGS_MESSAGES.CART_ITEM_NO_LONGER_AVAILABLE()
  } = {}
) => {
  // error when user is adding item to the cart
  if (cartErrors?.route === "/api/cart/addItems") {
    return cartErrorAdd;
  }

  //error when user is updating items in the cart
  if (
    cartErrors?.route === "/api/cart/updateItem" ||
    (cartErrors?.route === "/api/cart/updateItemQuantity" &&
      !hasErrorType({
        errors: cartErrors?.errors,
        errorType: "PRODUCT_INSUFFICIENT_STOCK"
      }))
  ) {
    return cartErrorUpdate;
  }

  // errors on the shipping step
  if (cartErrors?.route === "/api/checkout/shipping") {
    if (
      hasErrorType({
        errors: cartErrors?.errors?.error, // For some reason the structure for this one is different
        errorType: "CART_TAX_ERROR"
      })
    ) {
      return taxCalculationError;
    }
    if (
      hasErrorType({
        errors: cartErrors?.errors,
        errorType: ["CART_WG_ITEMS_DISALLOWED_ERROR"]
      })
    ) {
      return cartNoWhiteGloveToState;
    }

    if (
      hasErrorType({
        errors: cartErrors?.errors,
        errorType: "CART_CANADA_FEDEX_WG_ITEMS_DISALLOWED_ERROR"
      })
    ) {
      return cartErrorMixedShippingCanada;
    }

    if (
      hasErrorType({
        errors: cartErrors?.errors,
        errorType: CART_WARNINGS.CART_ITEM_NO_LONGER_AVAILABLE
      })
    ) {
      return cartItemNoLongerAvailable;
    }
    return cartErrorUpdateAddress;
  }

  // errors on the billing step
  if (cartErrors?.route === "/api/checkout/billing") {
    return cartErrorUpdatePayment;
  }
};

export const getCartCheckoutError = (
  cartErrors,
  {
    cartErrorAuthFailedReversal = CART_ERROR_AUTH_FAILED_REVERSAL,
    cartErrorAuthMaxAttempts = CART_ERROR_AUTH_MAX_ATTEMPTS,
    cartErrorCheckout = CART_ERROR_CHECKOUT,
    cartErrorSift = CART_ERROR_SIFT,
    cartErrorInvalidPromoCode = CART_ERROR_INVALID_PROMO_CODE,
    cartErrorShippingRestrictions = CART_SHIPPING_RESTRICTIONS,
    cartNoWhiteGloveToState = CART_NO_WHITEGLOVE_TO_STATE
  } = {},
  authAttempts,
  maxAuthAttempts = MAX_AUTH_ATTEMPTS
) => {
  if (!cartErrors?.errors || cartErrors?.route !== "/api/checkout/order") {
    if (
      hasErrorType({
        errors: cartErrors?.errors,
        errorType: ["CART_WG_ITEMS_DISALLOWED_ERROR"]
      })
    ) {
      return cartNoWhiteGloveToState;
    }
    return;
  }

  const { errors } = cartErrors;

  // this error is handled elsewhere
  if (hasErrorType({ errors, errorType: ["PRODUCT_INSUFFICIENT_STOCK"] }))
    return;

  // Not enough product stock
  if (
    hasErrorType({ errors, errorType: ["CART_CHECKOUT_CANNOT_PROCEED"] }) &&
    errors?.some?.((error) =>
      error?.errorInfo?.metadata?.warnings?.some?.((warning) =>
        [
          CART_WARNINGS?.CART_ERROR_INVENTORY,
          CART_WARNINGS?.CART_ITEM_NO_LONGER_AVAILABLE
        ]?.includes?.(warning?.code)
      )
    )
  ) {
    return;
  }

  if (hasErrorType({ errors, errorType: ["SIFT_BLOCKED_PNC"] }))
    return cartErrorSift;
  if (hasErrorType({ errors, errorType: ["CART_COUPONS_NOT_AVAILABLE"] }))
    return cartErrorInvalidPromoCode;
  if (authAttempts > maxAuthAttempts) return cartErrorAuthMaxAttempts;
  if (hasErrorType({ errors, errorType: ["CYBERSOURCE_REVERSAL_FAILURE"] }))
    return cartErrorAuthFailedReversal;

  if (
    hasErrorType({ errors, errorType: ["CART_UNABLE_TO_SELL"] }) &&
    errors?.some?.((error) =>
      error?.errorInfo?.metadata?.items?.some?.((item) =>
        ["BILLING_RESTRICTION", "SHIPPING_RESTRICTION"]?.includes?.(
          item?.reason
        )
      )
    )
  ) {
    return cartErrorShippingRestrictions;
  }

  return cartErrorCheckout;
};

export const getPaypalErrorMessage = (
  cartErrors,
  { paypalErrorInitialization = PAYPAL_ERROR_INITIALIZATION } = {}
) => {
  if (cartErrors?.route == "/api/checkout/paypal") {
    return paypalErrorInitialization;
  }
};

export const getCartClearError = (
  cartErrors,
  { cartErrorClear = CART_ERROR_CLEAR } = {}
) => {
  if (cartErrors?.route === "/api/cart/clearCart") {
    return cartErrorClear;
  }
};

export const getOutOfStockMessage = (
  cartErrors,
  { cartMessageOutOfStock = CART_MESSAGE_OUT_OF_STOCK } = {}
) => {
  if (
    (cartErrors?.route === "/api/cart/updateItemQuantity" ||
      cartErrors?.route === "/api/checkout/order") &&
    hasErrorType({
      errors: cartErrors?.errors,
      errorType: "PRODUCT_INSUFFICIENT_STOCK"
    })
  ) {
    return cartMessageOutOfStock;
  }
};

export const getPromoError = (
  cartErrors,
  {
    errorRemove = PROMO_CODE_ERROR_REMOVE,
    error = PROMO_CODE_ERROR,
    errorExpired = PROMO_CODE_ERROR_EXPIRED,
    errorInvalid = PROMO_CODE_ERROR_INVALID,
    messageNotApplied = PROMO_CODE_MESSAGE_NOT_APPLIED
  } = {}
) => {
  if (cartErrors?.route === "/api/cart/removePromotion") {
    return errorRemove;
  }
  if (cartErrors?.route === "/api/cart/addPromotion") {
    if (cartErrors?.warnings) {
      if (
        cartErrors?.warnings?.find(
          (el) => el?.code === "PROMO_CODE_MESSAGE_NOT_APPLIED"
        )
      )
        return messageNotApplied;
      return;
    }
    if (
      hasErrorType({
        errors: cartErrors?.errors,
        errorType: "PROMO_CODE_ERROR_EXPIRED"
      })
    ) {
      return errorExpired;
    }
    if (
      hasErrorType({
        errors: cartErrors?.errors,
        errorType: "PROMO_CODE_ERROR_INVALID"
      })
    ) {
      return errorInvalid;
    }
    return error;
  }
};

// Instead of checking for auth error types, we're checking for
// known pre-auth errors. That way we can catch unexpected payment
// errors as well as the known auth errors.
export const isPaymentError = (errors) => {
  return !hasErrorType({
    errors,
    errorType: [
      "CART_COUPONS_NOT_AVAILABLE",
      "CART_UNABLE_TO_SELL",
      "PRODUCT_INSUFFICIENT_STOCK"
    ]
  });
};

export const getCartErrorsFromUser = (
  user,
  {
    cartErrorMoveEstimate = CART_ERROR_MOVE_ESTIMATE,
    cartErrorMoveEstimateUser = CART_ERROR_MOVE_ESTIMATE_USER
  } = {}
) => {
  if (user?.data?.isMoveEstimateError) {
    return cartErrorMoveEstimate;
  }
  if (user?.data?.isEstimateUserError) {
    return cartErrorMoveEstimateUser;
  }
};

export const getCartMessagesFromCartData = (
  cart,
  {
    cartMessageItemsRemoved = CART_MESSAGE_ITEMS_REMOVED,
    cartMessageItemsRepriced = CART_MESSAGE_ITEMS_REPRICED,
    cartMessagePromoRemoved = CART_MESSAGE_PROMO_REMOVED
  } = {}
) => {
  const cartMessages = [];

  if (cart?.hasRepricedItems) {
    cartMessages.push(cartMessageItemsRepriced);
  }
  if (cart?.deletedCartItems && cart?.deletedCartItems.length > 0) {
    cartMessages.push(cartMessageItemsRemoved);
  }

  if (cart?.expiredCouponCodes?.length > 0) {
    cartMessages.push(cartMessagePromoRemoved);
  }
  return cartMessages;
};

export const getCartMessagesFromUser = (
  user,
  {
    cartMessageEstimateMerged = CART_MESSAGE_ESTIMATE_MERGED,
    cartMessageEstimatePromoExpired = CART_MESSAGE_ESTIMATE_PROMO_EXPIRED
  } = {}
) => {
  const cartMessages = [];
  if (user?.data?.isEstimateMerged) {
    cartMessages.push(cartMessageEstimateMerged);
  }
  if (user?.data?.isEstimatePromoExpired) {
    cartMessages.push(cartMessageEstimatePromoExpired);
  }
  return cartMessages;
};
