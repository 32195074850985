import React from "react";
import PropTypes from "prop-types";
const defaultSize = 22;
const CheckMark = ({ height, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || defaultSize}
      height={height || defaultSize}
      viewBox="0 0 22 22"
      fill="none"
    >
      <circle cx="11" cy="11" r="11" fill={"#243953"} />
      <path
        d="M17.0404 6.9245L9.11265 14.8522L5.81285 11.5526C5.58669 11.3264 5.22033 11.3264 4.99464 11.5526C4.76871 11.7785 4.76871 12.1449 4.99464 12.3708L8.70357 16.0796C8.81664 16.1926 8.96454 16.2489 9.11267 16.2489C9.26081 16.2489 9.40894 16.1926 9.52178 16.0796L17.8586 7.74271C18.0845 7.51678 18.0845 7.15042 17.8586 6.9245C17.6329 6.69834 17.2661 6.69834 17.0404 6.9245H17.0404Z"
        fill="white"
      />
    </svg>
  );
};

CheckMark.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CheckMark;
