import createValueResolver from "./createValueResolver";
import deepResolveValues from "./deepResolveValues";

/**
 * Resolve the values in a component theme using the fonts, colors, and variables from a global theme.
 * @param globalTheme
 * @param componentTheme
 * @returns {*}
 */
const resolveComponentThemeValues = (globalTheme = {}, componentTheme = {}) => {
  // eslint-disable-next-line no-unused-vars
  const { fonts, colors, variables, ...gRest } = globalTheme;
  // eslint-disable-next-line no-unused-vars
  const { _meta, ...cRest } = componentTheme;
  const resolve = createValueResolver(fonts, colors, variables);
  return deepResolveValues(cRest, resolve);
};

export default resolveComponentThemeValues;
