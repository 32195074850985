import apolloClient from "../client";
import { buildGetProductQuery } from "../queries";

const fetchProductById = async (productId) => {
  if (!apolloClient) return;
  const GET_PRODUCT = buildGetProductQuery({ productId: productId });
  const { data } = await apolloClient.query({
    query: GET_PRODUCT,
    variables: {
      productId: productId
    },
    context: { clientName: "endpoint2" }
  });

  return data;
};

export default fetchProductById;
