import { gql } from "@apollo/client";

export const CART_PRICE_FIELDS = gql`
  fragment CartPriceFields on PriceSummaryItem {
    subtotal
    discounts
    tax
    totalPrice
    mattressSurcharge
  }
`;

export const CART_SHIPPING_ITEM_FIELDS = gql`
  fragment CartShippingItemFields on ShippingItem {
    shippingCartItems {
      id
      sku
      name
      displayPrice {
        formatted
      }
      shippingCosts
    }
    availableMethods {
      sku
      name
      price
    }
  }
`;

export const CART_DELIVERY_GROUP_FIELDS = gql`
  fragment CartDeliveryGroupFields on DeliveryGroupItem {
    cartItemIds
    deliveryMethod
    edd
    eddFullText
    headline
  }
`;

export const CART_ITEMS_FIELDS = gql`
  fragment CartItemFields on CartItem {
    id
    name
    description
    productId
    sku
    slug
    netsuiteId
    category
    masterId
    quantity
    hasFreeShipping
    hasEvergreenFreeShipping
    hasPromoFreeShipping
    requiresDeposit
    isDnsuEligible
    disableAffirm
    isRetailExclusive
    isRetailAssortment
    disablePaypal
    unavailable
    imageUrl
    customUpholstery {
      topLineVariation
      fabricCode
      fabricName
      scene7Url
      otherOptions {
        name
        value
      }
    }
    variations {
      name
      value
    }
    collection
    division
    isDropShip
    department
    subDepartment
    appliedPromotions {
      couponCode
      name
      description
      global
    }
    displayPrice {
      prices {
        type
        formatted
        amount
      }
      totalPrice
    }
  }
`;

export const CART_ADDRESS_FIELDS = gql`
  fragment CartAddressFields on CartAddressItem {
    firstName
    lastName
    addressLine1
    addressLine2
    city
    stateProvince
    country
    postalCode
  }
`;

export const CART_PAYMENT_FIELDS = gql`
  fragment CartPaymentFields on CartPaymentItem {
    type
    paymentAmount
    cardCheckNumber
    paypalPayer
    cardholderName
    cardType
    expMonth
    expYear
    maskedCard
  }
`;

export const CART_APPLIED_PROMOTION_FIELDS = gql`
  fragment CartAppliedPromotionFields on AppliedPromotion {
    couponCode
    name
    description
    global
  }
`;

export const CART_FULL = gql`
  ${CART_ITEMS_FIELDS}
  ${CART_PRICE_FIELDS}
  ${CART_SHIPPING_ITEM_FIELDS}
  ${CART_DELIVERY_GROUP_FIELDS}
  ${CART_ADDRESS_FIELDS}
  ${CART_APPLIED_PROMOTION_FIELDS}
  fragment CartFull on Cart {
    cartId
    designServiceCode
    doNotShipUntilDate
    disablePaypal
    disableAffirm
    email
    phoneNumber
    companyName
    requiresDeposit
    poNumber
    attention
    hasRepricedItems
    warnings
    taxableStatus
    requiresDeposit
    estimateNumber
    netsuiteContactId
    isPayAndCarryAnonymous
    isPayAndCarryEligible
    isPayAndCarry
    hasRetailExclusiveItems
    giftInfo {
      giverName
      isGift
      message
      recipientEmail
      recipientName
    }
    deletedCartItems {
      sku
      name
      productId
    }
    cartItems {
      ...CartItemFields
    }
    priceSummary {
      ...CartPriceFields
    }
    shippingItems {
      ...CartShippingItemFields
    }
    deliveryGroups {
      ...CartDeliveryGroupFields
    }
    shippingAddress {
      ...CartAddressFields
    }
    billingAddress {
      ...CartAddressFields
    }
    appliedPromotions {
      ...CartAppliedPromotionFields
    }
  }
`;
