import apolloClient from "../client";
import buildGetWishlistQuery from "../queries/buildGetWishlistQuery";

const fetchWishlist = async ({
  customerEmail,
  guestToken,
  maxPromoMessages = 3
}) => {
  try {
    if (!apolloClient) return;
    const GET_WISHLIST = buildGetWishlistQuery();

    const variables = {
      maxPromoMessages
    };
    if (customerEmail) variables.customerEmail = customerEmail;
    if (guestToken) variables.guestToken = guestToken;

    const response = await apolloClient.query({
      query: GET_WISHLIST,
      errorPolicy: "none",
      variables,
      context: { clientName: "endpoint2" }
    });
    return response?.data;
  } catch (e) {
    console.error("error fetching wishlist", e);
  }
};

export default fetchWishlist;
