import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 42;

const CircularClose = ({ height, width, fill = "currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill="none"
      stroke={fill}
      viewBox="0 0 42 42"
    >
      <rect
        width="40"
        height="40.157"
        rx="20"
        transform="matrix(-1 0 0 1 41 1.07861)"
        stroke="#A7B0BA"
      />

      <path
        d="M15 15.1101L27 27.1101M27 15.1101L15 27.1101"
        stroke="#243953"
        strokeLinecap="round"
      />
    </IconSvg>
  );
};

CircularClose.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default CircularClose;
