import React from "react";
import PropTypes from "prop-types";
import * as Variants from "./Variants";

const Icons = ({
  type,
  height,
  width,
  fill = "#000",
  notification,
  stroke
}) => {
  const Icon = Variants[type];
  return (
    <Icon
      height={height}
      width={width}
      fill={fill}
      notification={notification}
      stroke={stroke}
    />
  );
};

export default Icons;

Icons.propTypes = {
  type: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  fill: PropTypes.string,
  stroke: PropTypes.string,
  notification: PropTypes.number
};
