import React, { useCallback } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import { useGlobalPopup } from "./GlobalPopupContext";
import {
  ModalStyle,
  OverlayStyle,
  ModalCloseButton
} from "./GlobalPopupStyles";
import { AnimatePresence, motion } from "framer-motion";
import Icons from "../Icons";

const GlobalPopup = ({ rootId = "#__next" }) => {
  const {
    showPopup,
    overflowEnabled,
    handleClosePopup,
    describedById,
    labelledById,
    focusId,
    content,
    style
  } = useGlobalPopup();

  ReactModal.setAppElement(rootId);

  const handleAfterOpen = useCallback(() => {
    if (focusId) {
      document?.getElementById(focusId)?.focus();
    }
  }, [focusId]);

  return (
    <ReactModal
      isOpen={showPopup}
      className="_"
      overlayClassName="_"
      contentLabel="Serena and Lily Popup"
      onRequestClose={handleClosePopup}
      onAfterOpen={handleAfterOpen}
      shouldCloseOnOverlayClick={true}
      contentElement={(props, children) => (
        <AnimatePresence>
          <ModalStyle
            {...props}
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            aria-describedby={describedById}
            aria-labelledby={labelledById}
            overflowEnabled={overflowEnabled}
            additionalStyle={style}
          >
            {children}
          </ModalStyle>
        </AnimatePresence>
      )}
      overlayElement={(props, contentElement) => (
        <AnimatePresence>
          <OverlayStyle
            {...props}
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {contentElement}
          </OverlayStyle>
        </AnimatePresence>
      )}
    >
      <ModalCloseButton onClick={handleClosePopup} aria-label="close popup">
        <Icons type="Close" />
      </ModalCloseButton>
      {content}
    </ReactModal>
  );
};

export default GlobalPopup;

GlobalPopup.propTypes = {
  rootId: PropTypes.string
};
