import styled from "styled-components";
import { Breakpoints, Colors } from "../../constants/constants";

export const SMBContainer = styled.div`
  margin: 0px auto 0px auto;
  width: 100%;
  padding: 0px 30px 0px 30px;
  box-sizing: border-box;
  text-align: center;
  background-color: ${({ bgColor }) => bgColor || Colors.White};
  height: 40px;
  border-bottom: 0px solid ${Colors.White};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: ${({ isSticky }) => (isSticky ? "sticky" : "relative")};
  top: 0;
  z-index: ${({ isSticky }) => (isSticky ? "2000" : "3")};

  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    height: 55px;
  }

  p,
  a {
    margin-bottom: 0 !important;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${({ gap }) => gap}px;
  @media screen and (max-width: ${Breakpoints.DesktopMobile}) {
    max-width: 300px;
  }
`;

export const RichTextWrapper = styled.div`
  margin-right: 16px;
`;
