import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 18;

const ArrowRightLight = ({ height, width, fill="currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 18 11"
    >
      <path d="M12.723.146a.522.522 0 0 0-.725 0c-.2.196-.2.512 0 .708L16.25 5H.513A.507.507 0 0 0 0 5.5c0 .276.23.5.513.5H16.25l-4.252 4.146c-.2.196-.2.512 0 .708.2.195.525.195.725 0l5.126-5a.491.491 0 0 0 0-.708l-5.126-5Z"/>
    </IconSvg>
  );
};

ArrowRightLight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default ArrowRightLight;
