const createTypographyResolver = (typography = {}) => (value, key) => {
  if (key === "typography") {
    if (typography[value]) {
      return typography[value]
    } else if (typography["default"]) {
      return typography["default"]
    }
  }
  return  value;
};

export default createTypographyResolver