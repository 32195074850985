import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultLoaderSize = 24;

const CaretUp = ({ height, width }) => {
  return (
    <IconSvg
      width={width || defaultLoaderSize}
      height={height || defaultLoaderSize}
      viewBox="0 0 24 24"
    >
      <path d="M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
    </IconSvg>
  );
};

CaretUp.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number
};

export default CaretUp;
