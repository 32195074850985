import { FIELD_DEPENDENCIES, EVENTS } from "./_config.js";
import { DEPENDENCIES } from "./_resolvers.js";
import crypto from "crypto";

const waitForDomResult = (selector, parser, interval, r) => {
  const result = parser(document.querySelectorAll(selector));
  return result !== undefined
    ? r(result)
    : setTimeout(() => {
        waitForDomResult(selector, parser, interval, r);
      }, interval);
};

export const domPicker = (
  selector,
  parser = (x) => (x.length > 0 ? x : undefined)
) => new Promise((r) => waitForDomResult(selector, parser, 1000, r));

export const getAnalyticsDatas = (selector, parser = (x) => x) =>
  domPicker(selector, (_nodes) => {
    const nodes = Array.from(_nodes);
    const jsons = nodes?.map((n) => JSON.parse(n?.textContent));
    return jsons.length > 0 && jsons.every((j) => j)
      ? parser(jsons)
      : undefined;
  });

export const getAnalyticsData = (selector, parser = (x) => x) =>
  domPicker(selector, (nodes) => {
    const text = nodes?.[0]?.textContent;
    return text ? parser(JSON.parse(text)) : undefined;
  });

export const daysUntil = (date) =>
  date ? Math.round((new Date(date) - new Date()) / 86400000) : null;

const buildDependencies = (d) =>
  Array.from(
    new Set(
      [].concat(
        ...(d?.FIELDS?.OPTIONAL ?? [])
          .concat(d?.FIELDS?.REQUIRED ?? [])
          .map((f) => {
            const dep = FIELD_DEPENDENCIES[f] ?? FIELD_DEPENDENCIES.DEFAULT;
            if (!dep) {
              throw new Error("Dependency not implemented: " + f);
            } else {
              return dep;
            }
          })
      )
    )
  );

export const buildDeps = async (entire) => {
  const depNames = buildDependencies(entire);
  const dependencies = {
    ...DEPENDENCIES.DEFAULT,
    ...(DEPENDENCIES[entire.NAME] ?? {})
  };
  const badDeps = depNames.filter((name) => !dependencies[name]);
  if (badDeps.length) {
    throw new Error("Dependencies not implemented: " + badDeps.join(", "));
  }
  const depVals = await Promise.all(
    depNames.map((name) => dependencies[name](entire))
  );

  return {
    ...entire,
    data: Object.assign(
      { detail: entire.data.detail },
      ...depNames.map((name, i) => ({
        [name]: depVals[i]
      }))
    )
  };
};

const EVENT_CONFIGS = Object.entries(EVENTS);

export const mapEventSpec = (d) => {
  const [name, config] = EVENT_CONFIGS.find(([_, v]) => v.INT_NAME === d.type);
  return {
    ...d,
    NAME: name,
    FIELDS: config.FIELDS
  };
};

export const hashValue = (value) => {
  return crypto.createHash("sha256").update(value).digest("hex");
};

export const getPaymentType = (payments) => {
  if (!payments || payments.length === 0) return null;
  const [payment] = payments || [];
  const isApplePay = payment?.tokenType === "APPLE_PAY" || false;
  const cardType = payment?.cardType || payment?.type || undefined;
  if (isApplePay) return `APPLE_PAY_${cardType}`;
  return cardType;
};

export const parseCartvalue = (value) => {
  if (typeof value !== "string") {
    throw new TypeError("Input must be a string.");
  }

  const numericParse = value.replace(/[$,]/g, "").trim();
  const number = parseFloat(numericParse);

  if (isNaN(number)) {
    throw new Error("Invalid dollar amount");
  }

  return number;
};
