import {
  daysUntil,
  getAnalyticsData,
  getAnalyticsDatas,
  domPicker,
  hashValue,
  getPaymentType,
  parseCartvalue
} from "./_utils";
import getNumberValue from "../getNumberValue/getNumberValue";
import handleCookies from "@serenaandlily/utils/handleCookies";
import currency from "currency.js";
import formatProductPrice from "../formatProductPrice";
import getPriceDiscount from "@serenaandlily/utils/getPriceDiscount/getPriceDiscount";
/* eslint-disable complexity */

/** PageType
 * @typedef PageType
 * @type {'Home'|'404'|'Grid'|'PDP'|'Cart'|'Order Confirmation'|'Checkout'|'Inspiration'|'Content'|'Pages'|'Register'|'Account'|'Shop the Look'|'Company Pages(contact us)'}
 */

const currencyCode = "USD";

export const FIELDS = {
  /** @type {string} [user] STORE ID (SFID) of logged user on ALL pages */
  dimension11: ({ detail }) => detail?.user?.user?.data?.storeId ?? "no store",

  /** @type {string} [user] Customer ID (EPCC) of logged user on ALL pages */
  dimension12: ({ detail }) => detail?.user?.user?.data?.id ?? "",

  /** @type {number} [hit] Number of search results from constructor (total_num_results) from PLP pages */
  dimension13: ({ search }) => search?.totalItems,

  /** @type {'Logged In'|'Not Logged In'} [hit] User logged-in user logged in */
  dimension14: ({ detail }) =>
    detail?.user?.user?.data?.isLoggedIn ? "Logged In" : "Not Logged In",

  /** @type {'Logged In'|'Not Logged In'} [session] User logged-in session user logged in */
  dimension15: ({ detail }) => {
    return detail?.user?.user?.data?.sessionId &&
      detail?.user?.user?.data?.isLoggedIn
      ? "Logged In"
      : "Not Logged In";
  },

  /** @type {number} [hit] Cart size (cart.itemCount) on ALL (cart) pages */
  dimension16: ({ global }) => global?.cart?.itemCount ?? null,

  /** @type {number} [hit] Cart Amount (cart.priceSummary.priceWithoutTax) from ALL (cart) pages */
  dimension17: ({ global }) =>
    global?.cart?.priceSummary?.priceWithoutTax ?? null,

  /** @type {string} [session] CSV product categories (cart.item categories) from ALL (cart) pages */
  dimension18: ({ global }) =>
    global?.cart?.cartItems?.map((item) => item.category).join(",") ?? null,

  /** @type {boolean} Product Customized? - docs say always false */
  dimension24: ({ product }) => (product?.customUpholstery ? true : false),

  /** @type {string} Product Material - comes from constructor.fabric_type */
  dimension27: ({ product }) => product?.fabricType || null, // TODO: get constructor.fabric_type if possible without an external call

  /** @type {string} Product Size - cartLineItem.variations[{"name":"Size", "value:""}]- */
  dimension28: ({ product }) =>
    product?.variationSize
      ? product?.variationSize
      : product?.variations?.find((item) => item.name === "Size")?.value ??
        null,

  /** @type {string} Product Color - cartLineItem.variations[{"name":"Color", "value:""}] */
  dimension29: ({ product }) =>
    product?.variationColor
      ? product?.variationColor
      : product?.variations?.find((item) => item.name === "Color")?.value ??
        null,

  /** @type {string[]} Product Badging -  */
  // TODO
  dimension30: ({ product }) => product?.productCallout ?? null,

  /** @type {'Fully Stocked'|'Partially Stocked'|'Out of Stock'} */
  dimension31: ({ inventories }) =>
    // eslint-disable-next-line no-nested-ternary
    inventories?.isSoldOut
      ? "Out of Stock"
      : inventories?.inventories?.every((item) => item?.quantity > 0)
      ? "Fully Stocked"
      : "Partially Stock",

  /** @type {string} Product Estimated Delivery Date - */
  dimension32: ({ global, product }) =>
    product?.edd
      ? product?.edd // m/dd/yyy format
      : global?.cart?.deliveryGroups?.[0]?.edd ?? null,

  /** @type {number}// Days Until Product Estimated Delivery Date - */
  dimension33: ({ global, product }) =>
    product?.edd
      ? daysUntil(product?.edd)
      : daysUntil(global?.cart?.deliveryGroups?.[0]?.edd) || "no edd",
  /** @type {string} Product Collection Name - on the product record in EPCC, but not in GQL response */
  dimension35: ({ product }) => product.collection ?? "no collection",

  /** @type {'Full Price'|'Promo'|'Clearance'} Product Pricing Level - Pending Promo Work */
  dimension36: ({ price }) => {
    const promoPrice =
      price?.priceType === "sale" || price?.priceType === "discounted";
    const productPrice = price?.formattedListPrice;
    if (promoPrice) {
      if (productPrice.split(".")[1] === "99") return "Clearance";
      else return "Promo";
    } else return "Full Price";
  },
  item_category4: ({ price }) => {
    const promoPrice =
      price?.priceType === "sale" || price?.priceType === "discounted";
    const productPrice = price?.formattedListPrice;
    if (promoPrice) {
      if (productPrice.split(".")[1] === "99") return "Clearance";
      else return "Promo";
    } else return "Full Price";
  },

  /** @type {string} Product Division - on the product record in EPCC, but not in GQL response */
  dimension37: ({ product } = {}) => product?.division ?? null,
  item_category3: ({ product } = {}) => product?.division ?? null,

  /** @type {string} Product Department - on the product record in EPCC, but not in GQL response */
  dimension38: ({ product } = {}) => product?.department ?? null,
  item_category2: ({ product } = {}) => product?.department ?? null,

  /** @type {string} Product Sub-department - on the product record in EPCC, but not in GQL response */
  dimension39: ({ product }) =>
    (product?.subDepartment || product?.category) ?? null,

  /** @type {string} [hit] page type from ALL pages */
  dimension41: ({ global, pageType }) => {
    if (pageType) return pageType;

    const router = global?.router;
    if (router?.pathname === "/404") {
      return "404";
    } else {
      // Home, Grid, PDP, Cart, Checkout, Inspiration, Content Pages, Account, Shop the Look, Company Pages(contact us)
      switch (router?.asPath?.split("/")[1].split("?")[0]) {
        case "account":
          return "Account";
        case "checkout":
          return "Checkout";
        case "order-confirmation":
          return "Order Confirmation";
        case "products":
          return "PDP";
        case "search":
          return "Search";
        case "shoppingbag":
          return "Cart";
        case "category":
          return "Grid";
        case "shopthelook":
          return "Shop the Look";
        case "":
          return "homepage";
        default:
          if (router?.pathname === "/404 ") {
            return "404";
          } else {
            return router?.asPath;
          }
      }
    }
  },

  /** @type {'Simple PDP'|'Quick Shop'} [hit] pdp type from PDP pages */
  dimension42: ({ product, pageType }) => {
    if (pageType) {
      if (pageType === "404") return "OOS PDP";
    }
    return product?.isQuickview ? "Quick Shop" : "Simple PDP";
  },

  /** @type {string} [hit] we think you meant value used to show results on PLP pages */
  dimension46: ({ search }) => search?.results?.search?.searchValue ?? "n/a",

  dimension47: ({ inventories }) =>
    // eslint-disable-next-line no-nested-ternary
    inventories?.isSoldOut
      ? "Out of Stock"
      : inventories?.inventories?.every((item) => item?.quantity > 0)
      ? "Fully Stocked"
      : "Partially Stock",

  /** @type {string} [hit] top category (groups[0].displayName) on all PLP pages */
  dimension49: ({ search }) =>
    search?.results?.groups?.[0]?.children?.[0]?.display_name,

  /** @type {string} [hit] top subcategory (groups[0].parents[1].displayName) on all PLP pages */
  dimension50: ({ search }) =>
    search?.results?.groups?.[0]?.children?.[0]?.parents?.[1]?.display_name,

  /** @type {boolean} product division */
  dimension51: ({ product }) => product?.division ?? null,

  /** @type {boolean} deliverymethod */
  dimension52: () => undefined,

  /** @type {boolean} dooContactId */
  dimension53: ({ global }) => global?.cart?.netsuiteContactId ?? null,

  /** @type {boolean} [isNewSite] hardcoded to true */
  dimension55: () => true,

  /** @type {any} undefined */
  metric2: ({ price }) => (price?.prices?.[0]?.amount ?? 0) * 14.26 ?? null,

  /** @type {any} undefined */
  metric4: () => null,

  actionField: ({ actionField }) => actionField,
  orderDetails: ({ orderDetails }) => orderDetails,
  cartValue: ({ cartValue }) => cartValue,
  shippingTier: ({ shippingTier }) => shippingTier,
  paymentType: ({ paymentType }) => paymentType,

  products: ({ products } = {}) => products,

  id: ({ product } = {}) => product?.masterId || product?.netsuiteId,
  item_id: ({ detail, product }) =>
    product?.masterId || product?.netsuiteId || detail?.netsuiteId,

  variant: ({ product } = {}) => product?.variant,
  item_variant: ({ product } = {}) => product?.variant ?? null,

  name: ({ product }) => product.name,
  item_name: ({ product }) => product?.name,

  brand: () => "Serena And Lily",
  item_brand: () => "Serena And Lily",

  affiliation: () => "Online Store",

  category: ({ product }) =>
    product.category ||
    product.breadcrumbs?.[0]?.categories?.slice(-1)?.[0]?.name,
  item_category: ({ product } = {}) =>
    (product?.category ||
      product?.breadcrumbs?.[0]?.categories?.slice(-1)?.[0]?.name) ??
    null,

  list: ({ product }) => product?.list,
  item_list_name: ({ product }) => product?.list ?? null,
  item_list_id: ({ product }) => product?.listId ?? null,
  // used for checkout event, PI, list position
  position: ({ product }) => product?.position || 0,
  index: ({ product }) => product?.position ?? null,

  coupon: ({ product }) => product?.coupon,
  quantity: ({ detail, product }) =>
    detail?.action?.step || product?.quantity || null,
  currency: () => currencyCode,
  price: ({ price }) => {
    const finalPrice = price?.formattedListPrice ?? price?.totalPrice;
    return finalPrice?.[0] === "$" ? finalPrice?.slice(1) : finalPrice;
  },
  tile_price: ({ priceRange } = {}) =>
    !priceRange
      ? undefined
      : [priceRange.min, priceRange.max].filter(Boolean).join(" - "),

  discount: ({ price }) => {
    const { formattedListPrice, formattedDsoDesigner, formattedSalePrice } =
      price;
    if (formattedSalePrice) {
      const priceDiscount = getPriceDiscount({
        wasPriceRange: { min: formattedListPrice },
        nowPriceRange: { min: formattedDsoDesigner || formattedSalePrice }
      });
      return `${priceDiscount?.value}%`;
    }
    return null;
  },

  email: ({ detail }) =>
    detail?.user?.user?.data?.isLoggedIn
      ? detail?.user?.user?.data?.email
      : undefined,
  customerEmailHashed: ({ detail }) =>
    detail?.user?.user?.data?.isLoggedIn
      ? hashValue(detail?.user?.user?.data?.email)
      : "no customer email",
  storeID: ({ detail }) => detail?.user?.user?.data?.storeId ?? null,
  customerID: ({ detail }) => detail?.user?.user?.data?.id ?? "no customer id",
  productName: ({ product }) => product?.name ?? null,
  customerData: ({ customerData }) => {
    return { ...customerData };
  },
  new_customer: () => handleCookies("newUser", "true").getCookie()
};

const mapProducts = async (selector) => {
  const products = await getAnalyticsDatas(selector, (datas) =>
    datas.every((d) => d?.productTileData) ? datas : undefined
  );

  return products?.map(({ productSpecification, productTileData }) => ({
    product: {
      ...productSpecification?.product,
      netsuiteId: productSpecification?.product?.productId,
      name: productSpecification?.product?.marketingName,
      variant: productSpecification?.product?.variationId,
      productCallout: productSpecification?.product?.productCallout
    },
    price: { formattedListPrice: productTileData?.nowPriceRange?.min }
  }));
};

const mapCheckoutProducts = async (d) => {
  const selector = d?.data?.detail?.fromBag
    ? "[id^='a-cart-product-']"
    : "[id^='a-product-']";
  const products = await getAnalyticsDatas(selector, (datas) => {
    const filteredData = datas.filter((data) => !!data?.name);
    return filteredData.length > 0 ? filteredData : undefined;
  });
  return products?.map((product) => {
    const productPrice = formatProductPrice(product);

    return {
      product,
      price: {
        formattedListPrice: productPrice?.price,
        priceType: productPrice?.priceType
      }
    };
  });
};

export const DEPENDENCIES = {
  DEFAULT: {
    actionField: () => ({
      // "list": "basket",
      // "entrance": null,
      // "action": "click"
    }),
    detail: (d) => d.data?.detail ?? {},
    dom: () => domPicker,
    global: () => getAnalyticsData("#a-global"),
    search: () =>
      window.location.pathname.includes("/search")
        ? getAnalyticsData("#a-search")
        : null,
    product: () => getAnalyticsData("#a-product"),
    price: () =>
      getAnalyticsData("#a-price", (data) =>
        data?.prices?.length > 0 ? data : undefined
      ),
    inventories: () =>
      getAnalyticsData("#a-inventories", (data) =>
        data?.inventories?.length > 0 ? data : undefined
      )
  },
  PAGE_VIEW: {
    product: () => {
      return window.location.pathname.includes("/products/")
        ? getAnalyticsData("#a-product")
        : null;
    },
    pageType: (global) => global?.data?.detail?.pageType
  },

  CUSTOM_ATTRIBUTES: {
    product: () => {
      return window.location.pathname.includes("/products/")
        ? getAnalyticsData("#a-product")
        : null;
    }
  },
  IMPRESSIONS: {
    products: async () => mapProducts(".a-product")
  },
  RIMPRESSIONS: {
    products: async () => mapProducts(".r-product")
  },
  YIMPRESSIONS: {
    products: async () => mapProducts(".y-product")
  },
  PRODUCTCLICK: {
    actionField: (d) => d.data.detail.action,
    products: async (d) => {
      const products = await getAnalyticsDatas(
        `.a-product-click-${d.data.detail.tileId}`,
        (datas) => (datas.every((d) => d?.productTileData) ? datas : undefined)
      );
      return products?.map(
        ({ currentProduct, list, productSpecification, productTileData }) => ({
          product: {
            netsuiteId: currentProduct?.netsuiteId,
            name: currentProduct?.name,
            variant: productSpecification?.product?.variationId,
            productCallout: currentProduct?.productCallout,
            list,
            breadcrumbs: currentProduct?.breadcrumbs,
            quantity: 0
          },
          price: {
            formattedListPrice:
              productTileData?.nowPriceRange?.min ||
              currentProduct?.displayPrice?.formattedListPrice
          }
        })
      );
    }
  },
  CHECKOUT: {
    actionField: (d) => {
      return {
        action: "checkout",
        step: isNaN(d.data?.detail?.step) ? 1 : d.data?.detail?.step + 2
      };
    },
    inventories: () => null,
    products: async () => {
      const products = await getAnalyticsDatas(
        "[id^='a-product-']",
        (datas) => {
          const filteredData = datas.filter((data) => !!data?.name);
          return filteredData.length > 0 ? filteredData : undefined;
        }
      );
      return products?.map((product) => {
        const productPrice = formatProductPrice(product);

        return {
          product,
          price: {
            formattedListPrice: productPrice?.price,
            priceType: productPrice?.priceType
          }
        };
      });
    }
  },
  VIEWCART: {
    cartValue: async (d) => parseCartvalue(d?.data?.detail?.cartValue),
    products: async () => {
      const products = await getAnalyticsDatas(
        "[id^='a-product-']",
        (datas) => {
          const filteredData = datas.filter((data) => !!data?.name);
          return filteredData.length > 0 ? filteredData : undefined;
        }
      );
      return products?.map((product) => {
        const productPrice = formatProductPrice(product);

        return {
          product,
          price: {
            formattedListPrice: productPrice?.price,
            priceType: productPrice?.priceType
          }
        };
      });
    }
  },

  BEGINCHECKOUT: {
    products: async (d) => mapCheckoutProducts(d),
    cartValue: async (d) => {
      const value = await (d?.data?.detail ||
        getAnalyticsData("#a-begin-checkout"));
      return parseCartvalue(value?.cartValue);
    }
  },

  ADDSHIPPINGINFO: {
    products: async (d) => mapCheckoutProducts(d),
    cartValue: async (d) => parseCartvalue(d?.data?.detail?.cartValue),
    shippingTier: async (d) => {
      return d?.data?.detail?.shippingTier;
    }
  },

  ADDPAYMENTINFO: {
    products: async (d) => mapCheckoutProducts(d),
    cartValue: async (d) => parseCartvalue(d?.data?.detail?.cartValue),
    paymentType: async (d) => {
      return d?.data?.detail?.paymentType;
    }
  },
  REVIEWORDER: {
    products: async (d) => mapCheckoutProducts(d),
    cartValue: async (d) => parseCartvalue(d?.data?.detail?.cartValue),
    paymentType: async (d) => {
      return d?.data?.detail?.paymentType;
    },
    shippingTier: async (d) => {
      return d?.data?.detail?.shippingTier;
    }
  },
  CART: {
    inventories: () => null,
    products: async () => {
      const products = (await getAnalyticsData("#a-global"))?.cart?.cartItems;
      return products?.map((product) => {
        const productPrice = formatProductPrice(product);
        return {
          product: { ...product, variant: product.netsuiteId },
          price: {
            formattedListPrice: productPrice?.price,
            priceType: productPrice?.priceType
          }
        };
      });
    }
  },
  PURCHASE: {
    orderDetails: async () => {
      const order = await getAnalyticsData("#a-order");
      const shippingSum = order?.priceSummary?.deliveryFees?.reduce(
        (a, b) => a + getNumberValue(b.price),
        0
      );
      const couponCodes = order?.cartItems
        ?.map((c) => c?.appliedPromotions?.map((v) => v?.couponCode))
        .flat(999);
      const uniqueCouponCodes = [...new Set(couponCodes)];
      const subtotal = getNumberValue(order?.priceSummary?.subtotal);
      const discount = getNumberValue(order?.priceSummary?.discounts);
      const roundedDiscount = currency(discount).value;
      const roundedRevenue = currency(subtotal - discount).value;
      return {
        transaction_id: order?.orderId,
        affiliation: "Online Store",
        value: roundedRevenue,
        tax: getNumberValue(order?.priceSummary?.tax),
        coupon: uniqueCouponCodes.join(",") || undefined,
        shipping: shippingSum,
        payment_type: getPaymentType(order?.payments),
        shipping_tier: order?.shippingItems?.shippingCartItems
          ?.map((item) => item?.name ?? undefined)
          ?.join(","),
        cart_value: parseCartvalue(order?.priceSummary?.totalPrice),
        order_coupon_amount: roundedDiscount
      };
    },
    inventories: () => null,
    products: async () => {
      const order = await getAnalyticsData("#a-order");
      return order?.cartItems?.map((product) => {
        const productPrice = formatProductPrice(product);
        return {
          product: {
            ...product,
            id: product?.netsuiteId || product.masterId,
            edd: order?.deliveryGroups?.[0]?.edd,
            category: product.subDepartment,
            list: product.list || undefined,
            variant: product.netsuiteId || undefined,
            masterId: product?.netsuiteId || undefined
          },
          price: {
            formattedListPrice: productPrice?.price,
            priceType: productPrice?.priceType
          }
        };
      });
    },
    customerData: async () => {
      const order = await getAnalyticsData("#a-order");
      return {
        Email: order?.email,
        EmailHashed: hashValue(order?.email)
      };
    }
  },
  CART_ADD: {
    actionField: (d) => d.data.detail.action,
    inventories: () => null,
    price: async (d) => {
      const priceData = await getAnalyticsData(
        `#a-product-${d.data.detail.netsuiteId}`,
        (data) => {
          const productPrice = formatProductPrice(data);
          return data?.displayPrice?.prices?.length > 0
            ? {
                formattedListPrice: productPrice?.price,
                priceType: productPrice?.priceType
              }
            : undefined;
        }
      );
      return priceData;
    },
    product: async (d) => {
      const productData = await getAnalyticsData(
        `#a-product-${d.data.detail.netsuiteId}`
      );
      return {
        name: productData?.marketingName,
        variant: productData?.variations,
        ...productData
      };
    }
  },
  CART_REMOVE: {
    inventories: () => null,
    price: async (d) => {
      const priceData = await getAnalyticsData(
        `#a-product-${d.data.detail.netsuiteId}`,
        (data) => {
          const productPrice = formatProductPrice(data);
          return data?.displayPrice?.prices?.length > 0
            ? {
                formattedListPrice: productPrice?.price,
                priceType: productPrice?.priceType
              }
            : undefined;
        }
      );
      return priceData;
    },
    product: (d) => getAnalyticsData(`#a-product-${d.data.detail.netsuiteId}`)
  },
  PDP: {
    actionField: (d) => d.data.detail.action,
    inventories: () => null,
    price: async (d) => {
      const priceData = await getAnalyticsData(
        `#a-product-${d.data.detail.netsuiteId}`,
        (data) => {
          const productPrice = formatProductPrice(data);
          return data?.displayPrice?.prices?.length > 0
            ? {
                formattedListPrice: productPrice?.price,
                priceType: productPrice?.priceType
              }
            : undefined;
        }
      );
      return priceData;
    },
    product: (d) => getAnalyticsData(`#a-product-${d.data.detail.netsuiteId}`)
  },
  SIGN_UP_ATTEMPT: {
    actionField: (d) => d.data.detail.action
  },
  SIGN_UP: {
    actionField: (d) => d.data.detail.action
  },
  SIGN_UP_FAILURE: {
    actionField: (d) => d.data.detail.action
  },
  LOGIN_ATTEMPT: {
    actionField: (d) => d.data.detail.action
  },
  LOGIN: {
    actionField: (d) => d.data.detail.action
  },
  LOGIN_FAILURE: {
    actionField: (d) => d.data.detail.action
  },
  COUPON_ERROR: {
    actionField: (d) => d.data.detail.action
  },
  LOGOUT: {
    actionField: (d) => d.data.detail.action
  }
};

export const PAYLOADS = {
  PAGE_VIEW: (payload) => payload,
  CUSTOM_ATTRIBUTES: (payload) => payload,
  PDP: ({ actionField, ...fields }) => ({
    event: "view_item",
    ecommerce: {
      items: [fields]
    }
  }),
  IMPRESSIONS: ({ products }) => ({
    event: "view_item_list",
    ecommerce: {
      items: products
    }
  }),
  RIMPRESSIONS: ({ products }) => ({
    event: "view_item_list",
    ecommerce: {
      items: products
    }
  }),
  YIMPRESSIONS: ({ products }) => ({
    event: "view_item_list",
    ecommerce: {
      items: products
    }
  }),
  CART_REMOVE: (fields) => ({
    event: "remove_from_cart",
    ecommerce: {
      items: [fields]
    }
  }),
  CART_ADD: ({ actionField, ...fields }) => ({
    event: "add_to_cart",
    add_to_cart_method: actionField?.source,
    ecommerce: {
      items: [fields]
    }
  }),
  CART: ({ products }) => ({
    ecommerce: {
      content: {
        products
      }
    }
  }),
  CHECKOUT: ({ actionField, products }) => {
    return {
      ecommerce: {
        checkout: {
          actionField,
          products
        }
      }
    };
  },

  VIEWCART: ({ products, cartValue }) => {
    return {
      event: "view_cart",
      ecommerce: {
        cart_value: cartValue,
        items: products
      }
    };
  },
  BEGINCHECKOUT: ({ products, cartValue }) => {
    return {
      event: "begin_checkout",
      ecommerce: {
        cart_value: cartValue,
        items: products
      }
    };
  },
  ADDSHIPPINGINFO: ({ products, shippingTier, cartValue }) => {
    return {
      event: "add_shipping_info",
      ecommerce: {
        checkout_step_option: "add_shipping_info",
        shipping_tier: shippingTier,
        cart_value: cartValue,
        items: products
      }
    };
  },

  ADDPAYMENTINFO: ({ products, paymentType, cartValue }) => {
    return {
      event: "add_payment_info",
      ecommerce: {
        checkout_step_option: "add_payment_info",
        payment_type: paymentType,
        cart_value: cartValue,
        items: products
      }
    };
  },

  REVIEWORDER: ({ products, shippingTier, paymentType, cartValue }) => {
    return {
      event: "review_order",
      ecommerce: {
        checkout_step_option: "review_order",
        shipping_tier: shippingTier,
        payment_type: paymentType,
        cart_value: cartValue,
        items: products
      }
    };
  },

  PURCHASE: ({ products, orderDetails, customerData }) => {
    return {
      ...customerData,
      event: "purchase",
      ecommerce: {
        ...orderDetails,
        items: products
      }
    };
  },
  SIGN_UP_ATTEMPT: ({ actionField }) => ({
    event: "sign_up_attempt",
    method: actionField?.source
  }),
  SIGN_UP: ({ actionField }) => ({
    event: "sign_up",
    method: actionField?.source
  }),
  SIGN_UP_FAILURE: ({ actionField }) => ({
    event: "sign_up_failure",
    method: actionField?.source,
    failure_reason: actionField?.reason,
    page_type: "account creation"
  }),
  LOGIN_ATTEMPT: ({ actionField }) => ({
    event: "login_attempt",
    method: actionField?.source
  }),
  LOGIN: ({ actionField }) => ({
    event: "login",
    method: actionField?.source
  }),
  LOGIN_FAILURE: ({ actionField }) => ({
    event: "login_failure",
    method: actionField?.source,
    failure_reason: actionField?.reason,
    page_type: "account login"
  }),
  COUPON_ERROR: ({ actionField }) => ({
    event: "coupon_error",
    coupon_error_text: actionField?.reason,
    page_type: actionField?.source
  }),
  LOGOUT: ({ actionField }) => ({
    event: "logout",
    method: actionField?.source
  })
};

export const SUBSCRIBERS = {};
