const theme = {
  controlBorderColor: "Midnight 70%",
  controlBackgroundColor: "White",
  controlIndicatorColor: "White",

  controlBorderColorHover: "Midnight 70%",
  controlBackgroundColorHover: "White",
  controlIndicatorColorHover: "Midnight 70%",

  controlBorderColorChecked: "Midnight",
  controlBackgroundColorChecked: "Midnight",
  controlIndicatorColorChecked: "White",

  controlBorderColorCheckedHover: "Cloud Blue",
  controlBackgroundColorCheckedHover: "Cloud Blue",
  controlIndicatorColorCheckedHover: "Midnight",

  controlBorderColorDisabled: "Midnight 40%",
  controlBackgroundColorDisabled: "Fog",
  controlIndicatorColorDisabled: "Fog",

  controlBorderColorCheckedDisabled: "Midnight 70%",
  controlBackgroundColorCheckedDisabled: "Midnight 70%",
  controlIndicatorColorCheckedDisabled: "Fog",

  controlFocusColor: "Cloud Blue",

  /* radio */
  radioSize: "18px",
  radioDotSize: "6px",
  radioSizeLarge: "24px",
  radioDotSizeLarge: "8px",
  radioLabelMargin: "0 0 -2px 10px",
  radioLabelColorDisabled: "#828C9B",
  radioBorderWidth: "1px",
  radioFocusWidth: "2px",

  /* checkbox */
  checkboxSize: "18px",
  checkboxSizeLarge: "24px",
  checkboxBorderRadius: "4px",
  checkboxBorderWidth: "1px",
  checkboxLabelColorDisabled: "#828C9B",
  checkboxFocusWidth: "2px",
  checkMarkSize: "10px",
  checkMarkSizeLarge: "14px",

  /* tiles */
  tileBorderColorSelected: "Midnight",
  tileBorderWidth: "1px",
  tilePadding: "3px",
  tileSizeSmall: "30px",
  tileSizeMedium: "48px",
  tileSizeLarge: "74px",
  tileBorderColorHover: "Midnight 40%",
  tileDisabledBackground:
    "linear-gradient(135deg, rgba(36,57,83,0.5) 0%, rgba(36,57,83,0.5) 50%, rgba(238,238,238,0.5) 50%)",

  /* Text and dropdowns */
  fieldBackgroundColor: "White",
  fieldBackgroundColorDisabled: "#F0F0F0",
  fieldFontColor: "Midnight",
  fieldFontColorDisabled: "#828C9B",
  fieldFontColorError: "Midnight",
  fieldFontFamily: "Beatrice Light",
  fieldBorderColor: "Midnight 40%",
  fieldBorderColorDisabled: "#F0F0F0",
  fieldBorderColorFocus: "Midnight",
  fieldBorderColorError: "Crimson",
  fieldFontSize: "14px",
  fieldBorderWidth: 1, // must be number for calculation
  fieldPadding: 16, // must be number for calculation
  fieldGap: "8px", // space between field and messages
  fieldMargin: "16px 0",
  fieldHeight: "48px",
  fieldLabelColor: "Midnight 70%",
  fieldLabelFontFamily: "Beatrice Light",
  fieldLabelFontSize: "14px",
  fieldLabelFontSizeFocus: "12px",
  fieldLabelTop: "14px",
  fieldLabelTopFocus: "-8px",
  fieldErrorColor: "Crimson",
  fieldErrorFontSize: "14px",
  fieldErrorFontFamily: "Beatrice Light",
  fieldErrorPadding: "0 16px",
  fieldErrorMargin: "16px 0",
  fieldMessageColor: "Midnight",
  fieldMessageFontSize: "14px",
  fieldMessageFontFamily: "Beatrice Light",
  fieldMessagePadding: "0 16px",
  fieldMessageMargin: "16px 0",

  dropdownBackgroundColor: "White",
  dropdownOptionBackgroundColor: "White",
  dropdownOptionBackgroundColorFocus: "Natural",
  dropdownOptionBackgroundColorSelected: "Cloud",

  /* microform */
  microformFontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  microformFontWeight: 300,
  microformPlaceholderFontFamily:
    '"Helvetica Neue", Helvetica, Arial, sans-serif',
  microformPlaceholderFontWeight: 300,

  /* Tab Input */
  tabBorderColor: "Midnight 40%",
  tabBorderColorSelected: "Midnight",
  tabBorderColorHover: "Midnight",
  tabBorderColorDisabled: "Midnight 40%",
  tabBackgroundColor: "White",
  tabBackgroundColorDisabled: "Cloud",
  tabPadding: "16px",
  tabBorderRadius: "4px"
};

export default theme;
