import React from "react";
import PropTypes from "prop-types";
import { IconSvg } from "../IconsStyled";

const defaultSize = 18;

const ArrowUpLight = ({ height, width, fill="currentColor" }) => {
  return (
    <IconSvg
      width={width || defaultSize}
      height={height || defaultSize}
      fill={fill}
      viewBox="0 0 11 18"
    >
      <path d="M10.854 5.277c.195.2.195.524 0 .725-.196.2-.512.2-.708 0L6 1.75v15.737a.507.507 0 0 1-.5.513c-.276 0-.5-.23-.5-.513V1.75L.854 6.002c-.196.2-.512.2-.708 0a.522.522 0 0 1 0-.725l5-5.126a.491.491 0 0 1 .708 0l5 5.126Z"/>
    </IconSvg>
  );
};

ArrowUpLight.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number
};

export default ArrowUpLight;
