import React from "react";

const RemoveWishlist = (props) => {
  return (
    <svg
      width="51"
      height="56"
      viewBox="0 0 51 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M49.2172 15.7499C48.8224 15.2664 48.2364 14.9875 47.6171 14.9875H3.53756C2.92232 14.9875 2.33634 15.2662 1.94149 15.7455C1.54664 16.227 1.38039 16.8604 1.49252 17.4748L8.08053 54.2663C8.25934 55.2715 9.11961 56 10.1253 56H40.7905C41.7902 56 42.6483 55.2779 42.8333 54.2813L49.6602 17.4897C49.7723 16.871 49.6124 16.2356 49.2175 15.7499L49.2172 15.7499ZM26.3304 47.4906C25.6988 47.8665 24.8653 47.4906 24.8653 47.4906C24.2772 47.1717 10.6153 39.3739 14.0462 29.9479C14.1687 29.6712 15.7005 26.6728 18.9112 25.9971C21.0123 25.5537 23.2608 26.2041 25.599 27.9228C27.9347 26.2041 30.1709 25.5495 32.2762 25.9971C35.4952 26.6728 37.0207 29.6712 37.0808 29.8001C40.5806 39.3758 26.904 47.1508 26.3304 47.4909V47.4906ZM48.9241 8.17591H34.2773C33.8368 3.60021 30.0753 0 25.4576 0C20.84 0 17.0785 3.60021 16.6379 8.17591H2.07815C0.931066 8.17591 0 9.11974 0 10.2875C0 11.4552 0.931066 12.399 2.07815 12.399H48.9219C50.0689 12.399 51 11.4552 51 10.2875C51 9.11974 50.0712 8.17591 48.9241 8.17591ZM25.4576 4.22292C27.783 4.22292 29.7137 5.93108 30.1209 8.17568H20.7941C21.1996 5.9313 23.1301 4.22292 25.4576 4.22292Z"
        fill="#243953"
      />
    </svg>
  );
};

export default RemoveWishlist;
