/* global Sailthru */

import { getNumberValue } from "../../utils";
const BASE_AMPLIENCE_URL = "https://cdn.media.amplience.net/i/serenaandlily/";

const baseUrl = "https://www.serenaandlily.com";
const key = "email";

export const sailthruAddToCart = (cart, customerEmail) => {
  const sailthruRunning = typeof Sailthru !== "undefined";
  if (!sailthruRunning) return;
  try {
    const { cartItemsById } = cart;
    const items = Object.keys(cartItemsById).map((productKey) => {
      return {
        title: cartItemsById[productKey].name,
        url: `${baseUrl}/${cartItemsById[productKey].netsuiteId || ""}.html`,
        price: getNumberValue(
          cartItemsById[productKey].displayPrice.totalPrice
        ),
        images: {
          full: {
            url: `${BASE_AMPLIENCE_URL}${
              cartItemsById[productKey]?.overRideMediaSet ||
              cartItemsById[productKey]?.netsuiteId
            }_SET?fmt=auto&qlt=default&fmt.jp2.qlt=60&fmt.webp.qlt=70&img404=noimagesmall`
          },
          thumb: {
            url: `${BASE_AMPLIENCE_URL}${
              cartItemsById[productKey]?.overRideMediaSet ||
              cartItemsById[productKey]?.netsuiteId
            }_SET?fmt=auto&w=100&h=100&img404=noimagesmall`
          }
        },
        qty: cartItemsById[productKey].quantity
      };
    });
    Sailthru?.integration("addToCart", {
      id: customerEmail || "",
      key,
      items
    });
  } catch (e) {
    //eslint-disable-next-line  no-console
    console.log({ sailthruError: e });
  }
};

export default sailthruAddToCart;
