import { EVENTS } from "./_config.js";
import { FIELDS, PAYLOADS, SUBSCRIBERS } from "./_resolvers.js";
import { buildDeps, mapEventSpec } from "./_utils.js";

const buildField = (d, f) => {
  if (!FIELDS[f]) {
    throw new Error("Field not implemented: " + f);
  }
  return FIELDS[f](d.data);
};

const unique = (arr) => [...new Set(arr)];

const processPayload = (key, payload) => PAYLOADS[key]?.(payload) ?? payload;

const objectZip = (keys, values) =>
  keys.reduce(
    (others, key, index) => ({
      ...others,
      [key]: values[index]
    }),
    {}
  );

const objectPromise = async (obj) =>
  objectZip(Object.keys(obj), await Promise.all(Object.values(obj)));

const childFields = (parentField, fields) =>
  fields
    .filter((f) => f.includes(`${parentField}.`))
    .map((f) => f.split(".")[1]);

const checkRequiredFields = (key, REQUIRED, obj) => {
  const missingFields = REQUIRED?.filter((k) => obj[k] === undefined);
  if (missingFields?.length) {
    throw new Error(
      `Fields not populated: ${missingFields.join(", ")}. Holding event: ${key}`
    );
  }
};

const filterNullish = (obj, val = undefined) =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== val) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});

// eslint-disable-next-line
const buildFields = async (
  key,
  OPTIONAL,
  REQUIRED,
  entire,
  isSubField = false
) => {
  const _fields = (OPTIONAL || []).concat(REQUIRED);
  const immediateFields = _fields.filter((f) => !f.includes("."));
  const immediate = filterNullish(
    await objectPromise(
      Object.assign(
        {},
        ...immediateFields.map((f) => ({ [f]: buildField(entire, f) }))
      )
    )
  );
  checkRequiredFields(
    key,
    REQUIRED.filter((r) => !r.includes(".")),
    immediate
  );

  const parentFields = unique(
    _fields.filter((f) => f.includes(".")).map((f) => f.split(".")[0])
  );
  // TODO hardcoded to first parent
  const parentField = parentFields[0];
  const parents = parentField
    ? {
        [parentField]: await Promise.all(
          // TODO hardcoded to assume an array
          (
            await FIELDS[parentField](entire.data)
          ).map((data) =>
            buildFields(
              key,
              childFields(parentField, OPTIONAL),
              childFields(parentField, REQUIRED),
              { data },
              true
            )
          )
        )
      }
    : {};
  const payload = {
    ...parents,
    ...filterNullish(immediate, null)
  };
  return isSubField
    ? payload
    : {
        ...entire,
        payload
      };
};

const invokeSubscribers = (d) => {
  const eventConfig =
    EVENTS[Object.keys(EVENTS).find((e) => EVENTS[e].INT_NAME === d.type)];
  eventConfig?.SUBSCRIBER_NAMES.forEach((s) => SUBSCRIBERS[s](d.data));
  return d;
};

const addListener = (eventName, node) => {
  node.addEventListener(eventName, async (event) => {
    event.stopPropagation();

    window.dataLayer.push({ ecommerce: null });

    const entire = await buildDeps(
      mapEventSpec({
        type: eventName,
        FIELDS: { REQUIRED: [], OPTIONAL: [] },
        data: {
          detail: event.detail
        },
        payload: {}
      })
    );

    const key = Object.keys(EVENTS).find(
      (e) => EVENTS[e].INT_NAME === entire.type
    );
    const eventConfig = EVENTS[key];
    if (!eventConfig?.EXT_NAME) {
      invokeSubscribers(entire);
    } else {
      try {
        const payload = processPayload(
          key,
          (
            await buildFields(
              key,
              entire.FIELDS.OPTIONAL,
              entire.FIELDS.REQUIRED,
              invokeSubscribers(entire)
            )
          ).payload
        );

        document.body.dispatchEvent(
          new CustomEvent(eventConfig?.EXT_NAME, {
            detail: {
              description: eventConfig?.EXT_DESCRIPTION,
              ...payload
            }
          })
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  });
};

const addListeners = (node) =>
  Object.keys(EVENTS)
    .map((k) => EVENTS[k].INT_NAME)
    .forEach((eventName) => addListener(eventName, node));

export default (node) => {
  addListeners(node);
};
