export const GA4Events = Object.freeze({
  login: "login",
  login_attempt: "login_attempt",
  login_failure: "login_failure",
  logout: "logout",
  sign_up: "sign_up",
  sign_up_attempt: "sign_up_attempt",
  sign_up_failure: "sign_up_failure",
  select_item: "select_item",
  smb_carousel_click: "smb_carousel_click",
  view_item_list: "view_item_list",
  item_favorited: "item_favorited",
  item_un_favorited: "item_un_favorited",
  item_considered_un_favorited: "item_considered_un_favorited",
  favorites_show_list_view: "favorites_show_list_view",
  favorites_show_gallery_view: "favorites_show_gallery_view",
  favorites_remove_a_note: "favorites_remove_a_note",
  favorites_edit_a_note: "favorites_edit_a_note",
  favorites_add_a_note: "favorites_add_a_note",
  favorites_move_to_bag: "favorites_move_to_bag",
  favorites_register_cta: "favorites_register_cta",
  accessed_favorites_global_header_heart:
    "accessed_favorites_global_header_heart",
  accessed_favorites_my_account: "accessed_favorites_my_account",
  accessed_favorites_default_load: "accessed_favorites_default_load"
});
